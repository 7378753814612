import React, { ReactNode, Component } from 'react';

import { WithStyles, withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import ShoppingProductCard from './ShoppingProductCard';
import AuthService from '@services/AuthService';

import shoppingProductsStyles from './ShoppingProductsStyles';


interface ShoppingSeller {
	name: string;
	price: number;
}

interface ShoppingProduct {
	_id: string;
	keyword: string;
	position: number;
	image: string;
	name: string;
	description?: string;
	sellers: ShoppingSeller[];
}

interface ProductsBaseResponse {
	data: ShoppingProduct[];
	success: boolean;
	message?: string;
}

interface State {
	products: ShoppingProduct[];
	keyword: string;
	loading: boolean;
}

interface Props extends WithStyles<typeof shoppingProductsStyles> {
	keyword: string;
}

class ShoppingProducts extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			products: [],
			keyword: props.keyword,
			loading: false,
		};
	}

	public componentDidMount(): void {
		this.loadProducts();
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): object | null {
		if (nextProps.keyword !== prevState.keyword){
			return { keyword: nextProps.keyword};
		}

		return null;
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.keyword !== this.state.keyword){
			this.loadProducts();
		}
	}

	private loadProducts(): void {
		this.setState({products: [], loading: true});

		this.authService.fetch<ProductsBaseResponse>(`/api/shopping/results?keyword=${this.state.keyword}`, {
			method: 'GET'
		}).then((response): void => {
			this.setState({loading: false});

			if (response.success) {
				if (response.data) {
					this.setState({products: response.data});
				} else {
					this.setState({products: []});
				}

			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Grid
				container
				spacing={3}
				className={classes.cardWrapper}
				justify="space-evenly"
			>
				{this.state.loading &&
					<CircularProgress className={classes.progress} />
				}
				{this.state.products.map((product: ShoppingProduct, index: number): ReactNode => (
					<Grid item xs={12} sm={6} md={4} lg={3} key={index}>
						<ShoppingProductCard
							product={product}
						/>
					</Grid>
				))}
			</Grid>
		);
	}

}

export default withStyles(shoppingProductsStyles)(ShoppingProducts);
