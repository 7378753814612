import React, {ReactElement, FC, useState, useEffect} from 'react';
import {
	Button,
	Divider,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	FormControl,
	FormLabel,
	IconButton,
	List,
	ListItem,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorEditorCategoryAttributesStyles from './AdvisorEditorCategoryAttributesStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Advisor, AdvisorCategoryAttribute} from '@common/models/Advisor';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import AddIcon from '@material-ui/icons/Add';
import AdvisorCategoryAttributesEditor from 'Advisor/AdvisorCategoryAttributesEditor';
import { useQuery } from '@tanstack/react-query';
import { Category, getCategoryName, loadCategories } from '@common/models/Category';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface Props extends WithStyles<typeof advisorEditorCategoryAttributesStyles>, WithSnackbarProps {
	dialog?: ConfirmDialog;
	advisor: Advisor;
	handleAdvisorCategoryAttributesChange: (categoryAttributes: AdvisorCategoryAttribute[]) => void;
	expanded: boolean;
}

const AdvisorEditorCategoryAttributes: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const [editorOpen, setEditorOpen] = useState(false);
	const [advisorCategoryAttributeToEdit, setAdvisorCategoryAttributeToEdit] = useState<AdvisorCategoryAttribute | null>(null);
	const [advisorCategoryAttributeToEditIndex, setAdvisorCategoryAttributeToEditIndex] = useState<number | null>(null);
	const [categoryAttributesEditorPropsHandlerActive, setCategoryAttributesEditorPropsHandlerActive] = useState(false);
	const [currentAdvisor, setCurrentAdvisor] = useState<Advisor>(props.advisor);
	const [categories, setCategories] = useState<Category[]>([]);

	useQuery(['categoryAttributesCategories'], () =>
		loadCategories(),
	{
		onSuccess: (data) => {
			if (data) {
				setCategories(data);
			}
		},
	}
	);

	useEffect(() => {
		setCurrentAdvisor(props.advisor);
	}, [props.advisor]);

	function closeEditor(): void {
		setEditorOpen(false);
	}

	function handleAdvisorCategoryAttributesChange(advisorCategoryAttribute: AdvisorCategoryAttribute, index: number | null): void {
		const categoryAttributes = currentAdvisor.categoryAttributes;

		if (index || index === 0) {
			categoryAttributes[index] = advisorCategoryAttribute;
		} else {
			categoryAttributes.push(advisorCategoryAttribute);
		}
		
		props.handleAdvisorCategoryAttributesChange(categoryAttributes);
	}

	function handleAdvisorCategoryDelete(advisorCategoryAttribute: AdvisorCategoryAttribute | null, index: number): void {
		const dialog = props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Kategorien-Attribute Löschen',
				message: `Sollen die Kategorien-Attribute für die Kategorien ${advisorCategoryAttribute && advisorCategoryAttribute.categories.map(c => getCategoryName(c, categories)).join(', ')} wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				if (advisorCategoryAttribute) {
					const categoryAttributes = currentAdvisor.categoryAttributes;
					categoryAttributes.splice(index, 1);
					props.handleAdvisorCategoryAttributesChange(categoryAttributes);
					setEditorOpen(false);
				} else {
					props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
				}
			}).catch((error): void => {
				console.log(`Error while deleting category attributes: ${error}`);
			});
		}
	}

	function handleNewCategoriesAttributesButtonClicked(): void {
		setAdvisorCategoryAttributeToEdit(null);
		setAdvisorCategoryAttributeToEditIndex(null);
		setEditorOpen(true);
		setCategoryAttributesEditorPropsHandlerActive(true);
	}

	function handleEditCategoriesAttributesButtonClicked(advisorCategoryAttribute: AdvisorCategoryAttribute, index: number): void {
		setAdvisorCategoryAttributeToEdit(advisorCategoryAttribute);
		setAdvisorCategoryAttributeToEditIndex(index);
		setEditorOpen(true);
		setCategoryAttributesEditorPropsHandlerActive(true);
	}

	return (
		<ExpansionPanel
			defaultExpanded={!!props.advisor._id}
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Kategorien-Attribute</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				{(currentAdvisor.categoryCodes.length > 0) &&
				<>
					<div className={classes.addCategoriesAttributesButtonContainer}>
						<Button
							variant="outlined"
							color="secondary"
							size="small"
							className={classes.addCategoriesAttributesButton}
							startIcon={<AddIcon />}
							onClick={handleNewCategoriesAttributesButtonClicked}
						>
						Neue Kategorien-Attribute
						</Button>
					</div>
					<FormControl  className={classes.categoriesAttributesFormControl}>
						{currentAdvisor.categoryAttributes.length > 0 &&
						<>
							<FormLabel className={classes.categoriesAttributesLabel}>Kategorien-Attribute</FormLabel>
						
							<List className={classes.categoriesAttributesList}>
								{currentAdvisor.categoryAttributes.map((advisorCategoryAttribute: AdvisorCategoryAttribute, index: number) => {
									return (
										<div key={index} className={classes.categoriesAttributesListRow}>
											<ListItem className={classes.categoriesAttributesListItem}>
												<div>
													{advisorCategoryAttribute.categories.map(c => getCategoryName(c, categories)).join(', ')}
												</div>
												<div>
													<Tooltip title='Attribute Bearbeiten'>
														<span>
															<IconButton color="primary" className={classes.editCategoryAttributesButton} size="small" onClick={(event): void => {event.stopPropagation(); handleEditCategoriesAttributesButtonClicked(advisorCategoryAttribute, index);}}>
																<EditIcon />
															</IconButton>
														</span>
													</Tooltip>
													<Tooltip title='Kategorien-Attribute Löschen'>
														<span>
															<IconButton className={classes.deleteCategoryAttributesButton} size="small" onClick={(event): void => {event.stopPropagation(); handleAdvisorCategoryDelete(advisorCategoryAttribute, index);}}>
																<DeleteIcon />
															</IconButton>
														</span>
													</Tooltip>
												</div>
											</ListItem>
											<Divider />
										</div>
									);
								})}
							</List>
						</>
						}
					</FormControl>
				</>
				}
			</ExpansionPanelDetails>
			<AdvisorCategoryAttributesEditor
				editorOpen={editorOpen}
				closeHandler={closeEditor}
				advisorCategoryAttribute={advisorCategoryAttributeToEdit}
				advisorCategoryAttributeIndex={advisorCategoryAttributeToEditIndex}
				handleAdvisorCategoryChange={handleAdvisorCategoryAttributesChange}
				categoryAttributesEditorPropsHandlerActive={categoryAttributesEditorPropsHandlerActive}
				handleCategoryAttributesEditorPropsHandlerActiveChange={setCategoryAttributesEditorPropsHandlerActive}
				handleAdvisorCategoryDelete={handleAdvisorCategoryDelete}
				advisorCategories={props.advisor.categoryCodes}
			/>
		</ExpansionPanel>
	);
};

export default withSnackbar(withDialog()(withStyles(advisorEditorCategoryAttributesStyles)(AdvisorEditorCategoryAttributes)));
