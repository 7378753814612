import { Product } from './Product';

export enum RuleType {
	ProductInCategory = 1,
	ProductInCategoryOrSubcategory = 2,
	ProductNameContainsString = 3,
	ProductNameNotContainsString = 4,
	ProductContainsAttribute = 5,
	ProductNotContainsAttribute = 6,
	ProductHasAttribute = 7,
	ProductNotHasAttribute = 8,
	ProductAttributeValueEquals = 9,
	ProductAttributeValueNotEquals = 10,
	ProductAttributeValueIsGreater = 11,
	ProductAttributeValueIsGreaterEquals = 12,
	ProductAttributeValueIsSmaller = 13,
	ProductAttributeValueIsSmallerEquals = 14,
	ProductPriceEquals = 15,
	ProductPriceIsGreater = 16,
	ProductPriceIsSmaller = 17,
	ProductPriceIsGreaterEquals = 18,
	ProductPriceIsSmallerEquals = 19,
	ProductVisuallySimilar = 20
}

export enum ConnectorType {
	And = 0,
	Or = 1,
}

export interface AutomatedComparator {
	_id: string;
	name: string;
	nameBackend: string;
	position: string;
	fullPage: boolean;
	missingCount: number;
	sortedBy: number;
	sortWeight: number;
	active: boolean;
	attributes: string[];
	rules: RuleGroup;
	categoryCode: string;
	products: Product[];
	splitDetail: SplitDetail;
	mergeDetail: MergeDetail;
}

export interface Rule {
	type: RuleType;
	categoryCode?: string;
	productName?: string;
	attributeName?: string;
	attributeValue?: string;
	radioSelected?: string;
	price?: string;
	productCode?: string;
	productImage?: string;
}

export interface RuleGroup {
	name?: string;
	connectorType: ConnectorType;
	rules: Rule[];
	ruleGroups: RuleGroup[];
}

export interface MergeDetail {
	mergeComparator: boolean;
	mergeComparators: number[];
}

/****
	Split Comparator
****/

export interface SplitDetail {
	levels: SplitLevel[];
	idealNumberProducts: number;
}

export interface SplitLevel {
	splitLevelRuleType: SplitLevelRuleType;
	splitLevelAttributeName?: string;
	splitLevelGroups: SplitLevelGroup[];
}

export interface SplitLevelGroup {
	splitRuleGroup: RuleGroup;
}

export enum SplitLevelRuleType {
	SplitRestRemove = 1,
	SplitRestTogether = 2,
	SplitAttributeRestSingle = 3,
}

export function getSelectedRuleTypeString(ruleType: RuleType | null): string {
	if (!ruleType) {
		return '';
	}
	if (ruleType === RuleType.ProductInCategory) {
		return 'Produkt in Kategorie';
	} else if (ruleType === RuleType.ProductInCategoryOrSubcategory) {
		return 'Produkt in Kategorie und/oder Subkategorien';
	} else if (ruleType === RuleType.ProductNameContainsString) {
		return 'Produktname enthält';
	} else if (ruleType === RuleType.ProductNameNotContainsString) {
		return 'Produktname enthält nicht';
	} else if (ruleType === RuleType.ProductContainsAttribute) {
		return 'Produkt Attributewert enthält';
	} else if (ruleType === RuleType.ProductNotContainsAttribute) {
		return 'Produkt Attributewert enthält nicht';
	} else if (ruleType === RuleType.ProductHasAttribute) {
		return 'Produkt hat Attribut';
	} else if (ruleType === RuleType.ProductNotHasAttribute) {
		return 'Produkt hat nicht Attribut';
	} else if (ruleType === RuleType.ProductAttributeValueEquals) {
		return 'Produkt Attributwert ist gleich';
	} else if (ruleType === RuleType.ProductAttributeValueNotEquals) {
		return 'Produkt Attributwert ist nicht gleich';
	} else if (ruleType === RuleType.ProductAttributeValueIsGreater) {
		return 'Produkt Attributwert ist größer';
	} else if (ruleType === RuleType.ProductAttributeValueIsGreaterEquals) {
		return 'Produkt Attributwert ist größer gleich';
	} else if (ruleType === RuleType.ProductAttributeValueIsSmaller) {
		return 'Produkt Attributwert ist kleiner';
	} else if (ruleType === RuleType.ProductAttributeValueIsSmallerEquals) {
		return 'Produkt Attributwert ist kleiner gleich';
	} else if (ruleType === RuleType.ProductPriceEquals) {
		return 'Produkt Preis ist gleich';
	} else if (ruleType === RuleType.ProductPriceIsGreater) {
		return 'Produkt Preis ist größer';
	} else if (ruleType === RuleType.ProductPriceIsGreaterEquals) {
		return 'Produkt Preis ist größer gleich';
	} else if (ruleType === RuleType.ProductPriceIsSmaller) {
		return 'Produkt Preis ist kleiner';
	} else if (ruleType === RuleType.ProductPriceIsSmallerEquals) {
		return 'Produkt Preis ist kleiner gleich';
	} else if (ruleType === RuleType.ProductVisuallySimilar) {
		return 'Produkt ist optisch ähnlich';
	} else {
		return '';
	}
}
