import React, { ReactNode, Component } from 'react';
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { CrawlerProduct } from '@models/Crawler';

import productStatusTableStyles from './ProductStatusTableStyles';

interface Props extends WithStyles<typeof productStatusTableStyles> {
	products: CrawlerProduct[];
}

class ProductStatusTable extends Component<Props> {
	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper>
				<Typography className={classes.header} variant="h4">
					Produktmonitor Status
				</Typography>
				<Table className={classes.table} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left">Productcode</TableCell>
							<TableCell align="left">Artikelbezeichnung</TableCell>
							<TableCell align="right">gespeicherter Preis</TableCell>
							<TableCell align="right">aktueller preis</TableCell>
							<TableCell align="left">Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.products.map((productStatus: CrawlerProduct): ReactNode => (
							<TableRow className={classes.row} key={productStatus._id} style={{backgroundColor: (productStatus.productStatus === 'ONLINE') && (productStatus.actualPrice === productStatus.oldPrice) ? '#C6FFB3': '#F7C5CE'}}>
								<TableCell component="th" scope="row">{productStatus.productCode}</TableCell>
								<TableCell align="left">{productStatus.productName}</TableCell>
								<TableCell align="right">{(productStatus.oldPrice) ? productStatus.oldPrice.toFixed(2) + ' €': ''}</TableCell>
								<TableCell align="right">{(productStatus.actualPrice) ? productStatus.actualPrice.toFixed(2) + ' €': ''}</TableCell>
								<TableCell align="left">{(productStatus.actualPrice !== productStatus.oldPrice) ? productStatus.productStatus + ', Preisänderung': productStatus.productStatus}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		);
	}
}

export default withStyles(productStatusTableStyles)(ProductStatusTable);
