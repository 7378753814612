export default class TableLocalization {
	public static localization = {
		pagination: {
			labelDisplayedRows: '{from}-{to} von {count}',
			labelRowsPerPage: 'Zeilen pro Seite:',
			firstAriaLabel: 'Erste Seite',
			firstTooltip: 'Erste Seite',
			previousAriaLabel: 'Vorherige Seite',
			previousTooltip: 'Vorherige Seite',
			nextAriaLabel: 'Nächste Seite',
			nextTooltip: 'Nächste Seite',
			lastAriaLabel: 'Letzte Seite',
			lastTooltip: 'Letzte Seite',
			labelRowsSelect: 'Einträge',
		},
		toolbar: {
			nRowsSelected: '{0} Zeile(n) ausgewählt',
			showColumnsTitle: 'Spalten anzeigen',
			showColumnsAriaLabel: 'Spalten anzeigen',
			exportTitle: 'Exportieren',
			exportAriaLabel: 'Exportieren',
			exportName: 'Als CSV exportieren',
			searchTooltip: 'Suchen'
		},
		header: {
			actions: 'Aktionen'
		},
		body: {
			emptyDataSourceMessage: 'Keine Einträge',
			filterRow: {
				filterTooltip: 'Filtern'
			}
		}
	};
}
