import React, { ReactNode, Component } from 'react';
import {
	Fab,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';

import AuthService from '@services/AuthService';
import CrawlerTable from './CrawlerTable';
import crawlerStyles from './CrawlerStyles';
import CrawlerEditor from './CrawlerEditor';
import ProductStatusTable from './ProductStatusTable';

import { CrawlerModel, CrawlerProduct } from '@models/Crawler';

interface CrawlersBaseResponse {
	data: CrawlerModel[];
	success: boolean;
	message?: string;
}

interface CrawlerResponse {
	data: CrawlerModel;
	success: boolean;
	message?: string;
}

interface State {
	crawlers: CrawlerModel[];
	products: CrawlerProduct[];
	crawlerEditorOpen: boolean;
	crawlerToEdit: CrawlerModel | null;
	isMultiple: boolean;
	folderFilter: string;
}

interface Props extends WithStyles<typeof crawlerStyles> {
	type: string;
}

class Crawler extends Component<Props, State> {
	private authService: AuthService;
	private interval: number | null = null;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			crawlers: [],
			products: [],
			crawlerEditorOpen: false,
			crawlerToEdit: null,
			isMultiple: false,
			folderFilter: ''
		};

		this.loadCrawlers();
	}

	public componentDidMount(): void {
		this.interval = window.setInterval((): void => this.checkCrawlerRunning(), 5 * 1000);
	}

	public componentWillUnmount(): void {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	public componentDidUpdate(prevProps: Props): void {
		if (this.props.type !== prevProps.type) {
			this.setState({crawlers: [], products: [], folderFilter: ''});
			this.loadCrawlers();
		}
	}

	private checkCrawlerRunning(): void {
		for (const crawler of this.state.crawlers) {
			if (crawler.status === 1) {
				this.loadCrawler(crawler);
				this.loadProductStatus();
			}
		}
	}

	private loadCrawler(crawler: CrawlerModel): void {
		this.authService.fetch<CrawlerResponse>(`/api/crawlers/${crawler._id}`, {
			method: 'GET'
		}).then((response): void => {
			if (response.success && response.data) {
				for (let i = 0; i < this.state.crawlers.length; i++) {
					if (response.data._id === this.state.crawlers[i]._id) {
						const crawlers = this.state.crawlers;
						crawlers[i] = response.data;

						this.setState({
							crawlers: crawlers
						});
					}
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadCrawlers(folderFilter = '', calledFromTable = false): void {
		if (calledFromTable) {
			this.setState({folderFilter: folderFilter});
		}
		this.authService.fetch<CrawlersBaseResponse>(`/api/crawlers?filter=${this.props.type}&folderFilter=${folderFilter}`, {
			method: 'GET'
		}).then((response): void => {
			if (response.success && response.data) {
				this.setState({crawlers: response.data});
			} else if (response.message) {
				this.setState({crawlers: []});
				throw new Error(response.message);
			} else {
				this.setState({crawlers: []});
				throw new Error('Unkown Error');
			}

			this.loadProductStatus();
		});
	}

	private loadProductStatus(): void {
		if (this.props.type !== 'monitorings') {
			return;
		}

		let products: CrawlerProduct[] = [];

		for (const crawler of this.state.crawlers) {
			products = products.concat(crawler.products);
		}

		this.setState({products: this.sortProducts(products)});
	}

	private sortProducts(inputProducts: CrawlerProduct[]): CrawlerProduct[] {
		return inputProducts.sort((p1, p2): number => {
			if (p1.productStatus > p2.productStatus || p1.oldPrice === p1.actualPrice) {
				return 1;
			} else if (p1.productStatus < p2.productStatus || p2.oldPrice === p2.actualPrice) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	private startCrawler(crawler: CrawlerModel): void {
		for (let i = 0; i < this.state.crawlers.length; i++) {
			if (this.state.crawlers[i]._id === crawler._id) {
				const crawlers = this.state.crawlers;
				crawlers[i].status = 1;

				this.setState({
					crawlers: crawlers
				});
			}
		}

		this.authService.fetch<CrawlerModel>(`/api/crawlers/${crawler._id}/start`, {
			method: 'GET'
		});
	}

	private editCrawler(crawler: CrawlerModel): void {
		this.setState({
			crawlerEditorOpen: true,
			crawlerToEdit: crawler,
			isMultiple: false
		});
	}

	private openNewCrawlerEditor(): void {
		this.setState({
			crawlerEditorOpen: true,
			crawlerToEdit: null,
			isMultiple: false
		});
	};

	private openMergeEditor(): void {
		this.setState({
			crawlerEditorOpen: true,
			crawlerToEdit: null,
			isMultiple: true
		});
	};

	private closeCrawlerEditor(): void {
		this.setState({crawlerEditorOpen: false, crawlerToEdit: null});
		if (this.state.folderFilter === '') {
			this.loadCrawlers();
		} else {
			this.loadCrawlers(this.state.folderFilter);
		}
	};

	public render(): ReactNode {
		const classes = this.props.classes;

		let title = 'Crawler';
		if (this.props.type === 'feeds') {
			title = 'Kategorie Feeds';
		} else if (this.props.type === 'monitorings') {
			title = 'Monitoring';
		}

		let description = 'Der Crawler läuft jede Nacht um 1 Uhr automatisch und kann manuell auch gestartet werden. Wenn der Crawler läuft wird der Button ausgegraut, das loading Icon ist eingeblendet und momentan kann man die Daten nicht downloaden. Die Url muss mit http:// oder https:// anfangen. Die Produktnummer im .csv file muss mit 00 anfangen.';
		if (this.props.type === 'feeds') {
			description = 'Kategorie Feeds werden automatisch einmal am Tag generiert und enthalten alle Produkte einer Kategorie sowie alle dazugehörigen Attribute.';
		} else if (this.props.type === 'monitorings') {
			description = 'Das Monitoring läuft jede Nacht um 1 Uhr automatisch und kann manuell auch gestartet werden. Wenn das Monitoring läuft wird der Button ausgegraut und das loading Icon ist eingeblendet. Die Url muss mit http:// oder https:// anfangen. An erster Stelle im CSV muss die Produktnummer mit 00 beginnend stehen. An zweiter Stelle der zu vergleichende Preis (mit einem Punkt statt einem Komma).';
		}

		return (
			<div>
				<CrawlerTable
					crawlers={this.state.crawlers}
					startHandler={this.startCrawler.bind(this)}
					editHandler={this.editCrawler.bind(this)}
					title={title}
					description={description}
					type={this.props.type}
					filterCrawlers={this.loadCrawlers.bind(this)}
				/>
				{ this.props.type === 'feeds' &&
				<Tooltip
					placement="bottom"
					arrow
					title={'Neuen Kategorie-Feed aus bestehenden Kategorie-Feeds anlegen'}
				>
					<Fab
						aria-label="Merge"
						className={classes.fabMerge}
						color="secondary"
						onClick={this.openMergeEditor.bind(this)}
					>
						<CompareArrowsIcon />
					</Fab>
				</Tooltip>
				}
				<Tooltip
					placement="bottom"
					arrow
					title={this.props.type === 'feeds' ? 'Neuen Kategorie-Feed anlegen' : this.props.type === 'crawlers' ? 'Neuen Crawler anlegen' : 'Neues Monitoring anlegen'}
				>
					<Fab
						aria-label="Add"
						className={classes.fab}
						color="primary"
						onClick={this.openNewCrawlerEditor.bind(this)}
					>
						<AddIcon />
					</Fab>
				</Tooltip>
				<CrawlerEditor
					crawler={this.state.crawlerToEdit}
					type={this.props.type}
					closeHandler={this.closeCrawlerEditor.bind(this)}
					isMultiple={this.state.isMultiple}
					editorOpen={this.state.crawlerEditorOpen}
					crawlers={this.state.crawlers}
				/>
				{(this.props.type === 'monitorings') ? <ProductStatusTable products={this.state.products}/>: null}
			</div>
		);
	}
}

export default withStyles(crawlerStyles)(Crawler);
