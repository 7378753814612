import React, {ReactElement, FC, ReactNode, useState, useEffect, useCallback} from 'react';
import {
	Button,
	Checkbox,
	CircularProgress,
	IconButton,
	InputAdornment,
	Link,
	ListItemText,
	MenuItem,
	Select,
	TextField,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorTableStyles from './AdvisorTableStyles';
import MaterialTable from 'material-table';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import TableLocalization from '@common/TableLocalization';
import {Advisor, getCountByRules, AdvisorAnswerProductCount, updateAdvisorJson} from '@common/models/Advisor';
import {useQuery} from '@tanstack/react-query';
import Moment from 'react-moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FolderEditor from '@common/FolderEditor';
import {loadAdvisorFolders} from '@common/models/AdvisorFolder';
import EditIcon from '@material-ui/icons/Edit';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import PublishIcon from '@material-ui/icons/Publish';
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface Props extends WithStyles<typeof advisorTableStyles>, RouteComponentProps, WithSnackbarProps {
	dialog?: ConfirmDialog;
	advisors: Advisor[];
	isLoading: boolean;
}

const AdvisorTable: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const [selectedFolderFilters, setSelectedFolderFilters] = useState<string[]>([]);
	const [advisors, setAdvisors] = useState<Advisor[]>([]);
	const [folderEditorIsOpen, setFolderEditorIsOpen] = useState(false);
	const [answerProductsCounts, setAnswerProductsCounts] = useState<AdvisorAnswerProductCount[]>([]);
	const [isUpdatingAdvisorJson, setIsUpdatingAdvisorJson] = useState(false);
	const [updatingJsonAdvisor, setUpdatingJsonAdvisor] = useState<Advisor | null>(null);

	const advisorFoldersQuery = useQuery(['advisorFolders'], () =>
		loadAdvisorFolders()
	);

	const answerProductsCountQuery = useQuery(
		['answer-products-count'],
		() => getCountByRules(props.advisors.map(a => ({id: a._id, questions: a.questions, categoryCodes: a.categoryCodes}))),
		{
			onSuccess: (data) => {
				if (data) {
					setAnswerProductsCounts(data);
				}
			},
			enabled: false
		}
	);

	useEffect(() => {
		let filteredAdvisors = props.advisors;

		if (selectedFolderFilters.length > 0) {
			filteredAdvisors = filteredAdvisors.filter((a) => a.folder?._id ? selectedFolderFilters.includes(a.folder._id) : false);
		}
		setAdvisors(filteredAdvisors);
	}, [props.advisors, selectedFolderFilters]);

	function getFolderNamesById(selectedFolderFilterIds: string[]): string[] {
		const folderNames: string [] = [];
		for (const selectedFolderId of selectedFolderFilterIds) {
			for (const folder of advisorFoldersQuery.data ?? []) {
				if (selectedFolderId === folder._id) {
					folderNames.push(folder.name);
				}
			}
		}
		return folderNames;
	}

	function getFolderFilterComponent(): ReactElement {
		return (
			<Select
				multiple
				style={{minWidth: 150, maxWidth: 210}}
				value={selectedFolderFilters}
				onChange={(event): void => setSelectedFolderFilters(event.target.value as string[])}
				renderValue={(): string => getFolderNamesById(selectedFolderFilters).join(', ')}
			>
				{advisorFoldersQuery.data && advisorFoldersQuery.data.map((filter) => (
					<MenuItem key={filter._id} value={filter._id}>
						<Checkbox checked={filter._id ? selectedFolderFilters.includes(filter._id) : false} />
						<ListItemText primary={filter.name} />
					</MenuItem>
				))}
			</Select>
		);
	}

	function getFolderTitleComponent(): ReactElement {
		return (
			<>
				Ordner
				<Tooltip title='Bearbeiten'>
					<span>
						<IconButton disabled={isUpdatingAdvisorJson} className={classes.folderEditButton} size="small" onClick={(): void => setFolderEditorIsOpen(true)}>
							<EditIcon />
						</IconButton>
					</span>
				</Tooltip>
			</>);
	}

	function getCheckAnswersComponent(): ReactElement {
		return (
			<div className={classes.checkAnswersButtonContainer}>
				<Tooltip title='Antworten überprüfen'>
					<span>
						<IconButton disabled={answerProductsCountQuery.isFetching || isUpdatingAdvisorJson} className={classes.checkAnswersButton} size="small" onClick={(): void => {answerProductsCountQuery.refetch();}}>
							<RefreshIcon />
						</IconButton>
					</span>
				</Tooltip>
			</div>);
	}

	function getAnswersNonResultingProducts(advisor: Advisor): string {
		const answersNonResultingProducts: string[] = [];
		const answerProductCount = answerProductsCounts.find(a => a.advisorId === advisor._id);
		if (answerProductCount) {
			for (const answerProductCountAnswer of answerProductCount.answers) {
				if (answerProductCountAnswer.numberOfProducts === 0) {
					for (const question of advisor.questions) {
						for (const answer of question.answers) {
							if (answer._id === answerProductCountAnswer.answerId) {
								answersNonResultingProducts.push(answer.answer);
							}
						}
					}
				}
			}
		}

		return answersNonResultingProducts.join(', ');
	}

	const openPreview = useCallback((advisor: Advisor) => {
		const url = `/product-advisor-preview/${advisor._id}`;
		window.open(url, '_blank')?.focus();
	}, []);

	async function handleUpdateAdvisorJson(advisor: Advisor): Promise<void> {
		if (advisor._id) {
			setUpdatingJsonAdvisor(advisor);
			setIsUpdatingAdvisorJson(true);
			const response = await updateAdvisorJson(advisor._id);
			if (response) {
				props.enqueueSnackbar(`Produktberater ${advisor.originalId} wurde erfolgreich veröffentlicht!`, {variant: 'success'});
			} else {
				props.enqueueSnackbar('Veröffentlichen fehlgeschlagen!', {variant: 'error'});
			}
			setIsUpdatingAdvisorJson(false);
			setUpdatingJsonAdvisor(null);
		}
	}

	function getHTMLSnippet(originalId: string): string {
		let bucket = 'dev';
		if (window.location.origin === 'https://qc.moebelix-techportal.at') {
			bucket = 'qc';
		} else if (window.location.origin === 'https://moebelix-techportal.at') {
			bucket = 'live';
		}

		return `<script src="https://pdctcmp-moebelhaus-${bucket}.s3.eu-central-1.amazonaws.com/product-advisor.js" type="text/javascript"></script><div class="e5-mbx-product-advisor-container" data-advisor-id="${originalId}"></div>`;
	}

	async function copyToClipboard(originalId: string | null): Promise<void> {
		if (originalId) {
			navigator.clipboard.writeText(getHTMLSnippet(originalId));
			props.enqueueSnackbar('In die Zwischenablage kopiert!', {variant: 'success'});
		} else {
			props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
		}
	}

	return (
		<>
			<MaterialTable
				columns={[
					{
						title: 'Name',
						field: 'name' as const,
						render: (advisor): ReactNode =>  (
							<Link href={`/advisor/${advisor.originalId}/edit`} color='primary' className={classes.advisorTitle}>{advisor.name} (ID: {advisor.originalId})</Link>
						),
						customFilterAndSearch: (term, rowData): boolean => {
							return !!(rowData.name.toLowerCase().includes(term.toLowerCase()) || (rowData.originalId && rowData.originalId.toString().startsWith(term)));
						}
					},
					{
						title: 'HTML-Snippet',
						filtering: false,
						sorting: false,
						render: (advisor): ReactNode =>  (
							<TextField
								value={getHTMLSnippet(advisor.originalId ?? '')}
								InputProps={{
									readOnly: true,
									startAdornment: (
										<InputAdornment position='start'>
											<Tooltip title='Kopieren'>
												<span>
													<IconButton
														color='primary'
														onClick={
															async (): Promise<void> => await copyToClipboard(advisor.originalId ?? null) 
														}
													>
														<FileCopyIcon />
													</IconButton>
												</span>
											</Tooltip>
										</InputAdornment>
									),
								}}
							/>
						)
					},
					{
						title: 'Google Analytics',
						filtering: false,
						sorting: false,
						render: (_): ReactNode =>  (
							<Button
								
								color="primary"
								onClick={(): void => console.log('todo')}
								size='small'
							>
							Daten anzeigen
							</Button>
						)
					},
					{
						title: getFolderTitleComponent(),
						field: 'folder' as const,
						filterComponent: (): ReactElement => getFolderFilterComponent(),
						render: (advisor): ReactNode => {
							return advisor.folder ? advisor.folder.name : '';
						}
					},
					{
						title: 'Zuletzt Bearbeitet',
						field: 'updatedAt' as const,
						type: 'date' as const,
						filtering: false,
						render: (advisor): ReactNode => {
							return (
								<Moment format="DD.MM.YYYY HH:mm" date={advisor.updatedAt} locale="de" />
							);
						}
					},
					{
						title: getCheckAnswersComponent(),
						field: 'buttons' as const,
						filtering: false,
						sorting: false,
						headerStyle: {
							maxWidth: '5%'
						},
						cellStyle: {
							textAlign: 'right',
							whiteSpace: 'nowrap',
							maxWidth: '5%'
						},
						render: (advisor): ReactNode => {
							return (
								<div className={classes.buttonsContainer}>
									{(answerProductsCountQuery.isFetching) &&
										<CircularProgress className={`${classes.progress} ${classes.progressLeft}`} />
									}
									{!answerProductsCountQuery.isFetching && answerProductsCounts.length > 0 && getAnswersNonResultingProducts(advisor) !== '' &&
										<Tooltip title={`Aus diesen Antworten resultieren keine Produkte: ${getAnswersNonResultingProducts(advisor)}`}>
											<span className={classes.answerProductsIconContainer}>
												<WarningIcon className={classes.warningIcon} />
											</span>
										</Tooltip>
									}
									{!answerProductsCountQuery.isFetching && answerProductsCounts.length > 0 && getAnswersNonResultingProducts(advisor) === '' &&
										<Tooltip title='Aus allen Antworten resultieren Produkte'>
											<span className={classes.answerProductsIconContainer}>
												<CheckIcon color="secondary" />
											</span>
										</Tooltip>
									}
									<span className={classes.previewContainer}>
										<Tooltip title='Vorschau'>
											<span>
												<IconButton disabled={isUpdatingAdvisorJson} color="primary" size="small" onClick={(): void => openPreview(advisor)}>
													<VisibilityIcon />
												</IconButton>
											</span>
										</Tooltip>
									</span>
									{isUpdatingAdvisorJson && advisor._id === updatingJsonAdvisor?._id &&
										<CircularProgress className={`${classes.progress} ${classes.progressRight}`} />
									}
									{advisor._id !== updatingJsonAdvisor?._id &&
										<Tooltip title='Veröffentlichen'>
											<span>
												<IconButton disabled={isUpdatingAdvisorJson} color="secondary" size="small" onClick={async (): Promise<void> => {await handleUpdateAdvisorJson(advisor);}}>
													<PublishIcon />
												</IconButton>
											</span>
										</Tooltip>
									}
								</div>
							);
						}
					}
				]}
				options={{
					search: false,
					filtering: true,
					columnsButton: false,
					paging: true,
					emptyRowsWhenPaging: false,
					toolbar: false,
					pageSize: 50,
					pageSizeOptions: [25, 50, 75, 100],
				}}
				isLoading={props.isLoading}
				data={advisors}
				localization={TableLocalization.localization}
				
			/>
			<FolderEditor 
				folders={advisorFoldersQuery.data ?? []}
				open={folderEditorIsOpen}
				close={(): void => setFolderEditorIsOpen(false)}
			/>
		</>
	);
};

export default withSnackbar(withDialog()(withStyles(advisorTableStyles)(withRouter(AdvisorTable))));
