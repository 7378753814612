import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryTreeStyles = (_: Theme): any => createStyles({
	top: {
		height: '3vh'
	},
	categoryTree: {
		paddingLeft: '8px'
	}
});

export default categoryTreeStyles;
