import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryRankingStyles = (theme: Theme): any => createStyles({
	header: {
		padding: theme.spacing(2, 3)
	},
	filterContainer: {
		display: 'flex',
		padding: theme.spacing(2, 3),
	},
	filter: {
		display: 'flex'
	},
	value: {
		width: '140px',
		marginRight: '30px',
	},
	buttonProgress: {
		position: 'absolute'
	},
});

export default categoryRankingStyles;
