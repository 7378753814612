/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Button,
	Divider,
	Link,
	List,
	ListItem,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import { AutomatedComparator } from '@models/AutomatedComparator';
import { ProductStatus } from '@models/Product';
import ComparatorProductPreview from '../../Comparator/ComparatorProductPreview';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import editorToMergeOverviewStyles from './EditorToMergeOverviewStyles';

interface State {
	editorOpen: boolean;
	comparatorId: string;
}

interface Props extends WithStyles<typeof editorToMergeOverviewStyles> {
	comparators: Comparator[];
}

class EditorToMergeOverview extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			editorOpen: false,
			comparatorId: ''
		};
	}

	private offlineProductSum(comparator: Comparator | AutomatedComparator): number {
		return comparator.products.filter((p): boolean => p.status === ProductStatus.Offline).length;
	}

	private handleClickOpenEdit(id: string): void {
		this.setState({editorOpen: true, comparatorId: id});
	}

	private handleClose(): void {
		this.setState({editorOpen: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;


		return (
			<ExpansionPanel defaultExpanded={true}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Ausgewähle Produktvergleicher</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<List>
						{this.props.comparators.map((listComparator: Comparator) => {
							return (
								<div key={listComparator._id}>
									<ListItem >

										<div className={classes.comparatorName}>
											<Link target={'_blank'} href={`/comparator/${listComparator._id}/edit`}>{listComparator.nameBackend ? listComparator.nameBackend : listComparator.name} (ID: {listComparator._id}, {listComparator.active ? 'Aktiv' : 'Inaktiv'})</Link>
										</div>

										{((listComparator.products.length - this.offlineProductSum(listComparator)) < 2) &&
								<span className={classes.warning}>
									<Tooltip title={
										<span>Der Produktvergleicher enthält weniger als 2 online Produkte und wird daher nicht angezeigt.</span>
									}>
										<WarningIcon/>
									</Tooltip>
								</span>
										}
										{this.offlineProductSum(listComparator) > 0 &&
									<span className={classes.offlineBubble}>{`Offline: ${this.offlineProductSum(listComparator)}/${listComparator.products.length}`}</span>
										}
										{listComparator.missingCount > 0 &&
									<span className={classes.attributesBubble}>{`Attribute: ${listComparator.missingCount}/${listComparator.products.length}`}</span>
										}
										<span className={classes.productSumBubble}>{`Produkte: ${listComparator.products.length}`}</span>
										<Button variant="outlined" color="secondary" size="small" onClick={this.handleClickOpenEdit.bind(this, listComparator._id)}>Vorschau</Button>
									</ListItem>
									<Divider />
								</div>
							);
						})}
						<ComparatorProductPreview
							closeHandler={this.handleClose.bind(this)}
							editorOpen={this.state.editorOpen}
							comparatorId={this.state.comparatorId}
							isAutomated={false}
						/>
					</List>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(editorToMergeOverviewStyles)(EditorToMergeOverview);
