import React, { ReactNode, Component, CSSProperties } from 'react';
import {
	CircularProgress,
	FormControl,
	MenuItem,
	ButtonGroup,
	Button,
	Popper,
	Grow,
	Paper,
	Typography,
	ClickAwayListener,
	MenuList,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import AuthService from '@services/AuthService';
import { AttributeTemplate, SummarizedAttribute } from '@models/Comparator';

import Select, { ValueType } from 'react-select';

import attributeTemplateButtonStyles from './AttributeTemplateButtonStyles';

interface OptionType {
	label: string;
	value: AttributeTemplate;
};

interface AttributesTemplateResponse {
	data: AttributeTemplate[];
	success: boolean;
	message?: string;
}

interface State {
	loadedTemplates: AttributeTemplate[];
	selectedTemplate: AttributeTemplate | null;
	loading: boolean;
	menuOpen: boolean;
}

interface Props extends WithStyles<typeof attributeTemplateButtonStyles>, WithSnackbarProps {
	applyTemplate: (selectedTemplate: AttributeTemplate) => void;
	selectedAttributes: SummarizedAttribute[];
	dialog: ConfirmDialog;
}

class AttributeTemplateButton extends Component<Props, State> {
	private authService: AuthService;
	private anchorRef = React.createRef<HTMLDivElement>();

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			loadedTemplates: [],
			selectedTemplate: null,
			loading: false,
			menuOpen: false
		};
	}

	public componentDidMount(): void {
		this.loadTemplates();
	}

	private async loadTemplates(idToSelect: string | null = null): Promise<void> {
		this.setState({loadedTemplates:[], selectedTemplate: null, loading: true});

		try {
			const templatesResponse = await this.authService.fetch<AttributesTemplateResponse>('/api/attribute-templates', {
				method: 'GET'
			});

			if (templatesResponse.data) {
				let loadedTemplates = templatesResponse.data;
				loadedTemplates = loadedTemplates.sort((a, b): number => {
					if (a.name.toLowerCase() > b.name.toLowerCase()) {
						return 1;
					}

					if (a.name.toLowerCase() < b.name.toLowerCase()) {
						return -1;
					}

					return 0;
				});

				let selectedTemplate = loadedTemplates[0];

				if (idToSelect === null && this.state.selectedTemplate) {
					idToSelect = this.state.selectedTemplate._id;
				}

				if (idToSelect) {
					const tryTemplate = templatesResponse.data.find((t): boolean => (t._id === idToSelect));
					if (tryTemplate) {
						selectedTemplate = tryTemplate;
					}
				}

				this.setState({loadedTemplates: loadedTemplates, selectedTemplate: selectedTemplate, loading: false});
			}
		} catch (error) {
			this.setState({loadedTemplates:[], selectedTemplate: null, loading: false});
		}
	}

	private handleTemplateSelectionChange(selectedAttributeTemplate: ValueType<OptionType>): void {
		const selectedTemplate = this.state.loadedTemplates.find((t): boolean => (t._id === (selectedAttributeTemplate as OptionType).value._id));

		if (selectedTemplate !== undefined) {
			this.setState({selectedTemplate: selectedTemplate});
		} else {
			this.setState({selectedTemplate: null});
		}
	}

	private applyTemplate(): void {
		if (this.state.selectedTemplate !== null) {
			this.props.applyTemplate(this.state.selectedTemplate);
		}
	}

	private toggleMenu(): void {
		const bool = this.state.menuOpen;
		this.setState({menuOpen: !bool});
	}

	private closeMenu(): void {
		this.setState({menuOpen: false});
	}

	private newTemplateButtonPressed(): void {
		const { dialog } = this.props;

		const attributeNames = this.props.selectedAttributes.map((a: SummarizedAttribute): string => {
			return a.name;
		});

		const attributeCodes = this.props.selectedAttributes.map((a: SummarizedAttribute): string => {
			return a.key;
		});

		dialog.prompt({
			title: 'Neue Vorlage',
			message: `Eine neue Vorlage mit den Attributen ${attributeNames.join(', ')} erstellen?`,
			ok: {
				text: 'Erstellen',
				color: 'default',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'secondary',
				variant: 'text'
			},
			required: true,
		}).then((name: string): void => {
			const body = {
				id: null,
				name: name,
				attributes: attributeCodes
			};

			this.authService.fetch<AttributeTemplate>('/api/attribute-templates/new', {
				method: 'POST',
				body: JSON.stringify(body)
			}).then((response): void => {
				this.closeMenu();
				this.loadTemplates(response._id);
				this.props.enqueueSnackbar('Attributvorlage erfolgreich gespeichert!', {variant: 'success'});
			});
		}).catch((error): void => {
			console.log(`Error while saving attribute template: ${error}`);
		});
	}

	private updateTemplateButtonPressed(): void {
		if (this.state.selectedTemplate !== null) {
			const template = this.state.selectedTemplate;
			const { dialog } = this.props;

			const oldCodes = template.attributes;
			const oldNames = oldCodes.map((base64: string): string => {
				return Buffer.from(base64, 'base64').toString();
			});

			dialog.confirm({
				title: 'Vorlage Überschreiben',
				message: `Soll die Vorlage "${template.name}" wirklich überschrieben werden? (Alte Attribute: ${oldNames.join(', ')})`,
				ok: {
					text: 'Überschreiben',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				const body = {
					id: template._id,
					name: template.name,
					attributes: this.props.selectedAttributes.map((a): string => a.key)
				};

				this.authService.fetch<{}>(`/api/attribute-templates/${template._id}`, {
					method: 'POST',
					body: JSON.stringify(body)
				}).then((_): void => {
					this.closeMenu();
					this.loadTemplates(template._id);
					this.props.enqueueSnackbar('Attributvorlage erfolgreich gespeichert!', {variant: 'success'});
				});
			}).catch((error): void => {
				console.log(`Error while saving attribute template: ${error}`);
			});
		}
	}

	private deleteTemplateButtonPressed(): void {
		if (this.state.selectedTemplate !== null) {
			const templateId = this.state.selectedTemplate._id;
			const { dialog } = this.props;

			dialog.confirm({
				title: 'Vorlage Löschen',
				message: `Soll die Vorlage "${this.state.selectedTemplate.name}" wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<{}>(`/api/attribute-templates/${templateId}`, {
					method: 'DELETE',
				}).then((response): void => {
					this.closeMenu();
					this.loadTemplates();
					this.props.enqueueSnackbar('Attributvorlage erfolgreich gelöscht!', {variant: 'success'});
				});
			}).catch((error): void => {
				console.log(`Error while deleting attribute template: ${error}`);
			});
		}
	}

	private getOptions(): OptionType[] {
		let options: OptionType[] = [];

		this.state.loadedTemplates.sort((a: AttributeTemplate, b: AttributeTemplate) => (a.name > b.name) ? 1 : -1).forEach((a: AttributeTemplate): void => {
			options = options.concat(this.optionsForAttributeTemplates(a));
		});

		return options;
	}

	private optionsForAttributeTemplates(attributeTemplate: AttributeTemplate): OptionType[] {
		const options: OptionType[] = [];

		options.push({value: attributeTemplate, label: attributeTemplate.name});

		return options;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (this.state.loading || this.state.selectedTemplate === null) {
			return (
				<CircularProgress className={classes.loadingIndicator}/>
			);
		}

		let option: ValueType<OptionType> | null = null;
		if (this.state.selectedTemplate) {
			option = {
				label: this.state.selectedTemplate.name,
				value: this.state.selectedTemplate
			};
		}

		return (
			<div className={classes.root}>
				<Typography variant="subtitle1">Attributvorlage</Typography>
				<FormControl className={classes.templateSelect}>
					<Select
						value={option}
						onChange={this.handleTemplateSelectionChange.bind(this)}
						options={this.getOptions()}
						placeholder={'Attributvorlage auswählen...'}
						noOptionsMessage={(): string => ('Keine Treffer')}
						styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
						menuPortalTarget={document.body}
						isSearchable
					/>
				</FormControl>
				<ButtonGroup variant="contained" color="primary" ref={this.anchorRef} className={classes.splitButton}>
					<Button onClick={this.applyTemplate.bind(this)}>Vorlage Laden</Button>
					<Button
						color="primary"
						size="small"
						onClick={this.toggleMenu.bind(this)}
					>
						<ArrowDropDownIcon />
					</Button>
				</ButtonGroup>



				<Popper
					open={this.state.menuOpen}
					anchorEl={this.anchorRef.current}
					transition
				>
					{({ TransitionProps, placement }): ReactNode => (
						<Grow
							{...TransitionProps}
							style={{
								transformOrigin: 'left bottom',
							}}
						>
							<Paper>
								<ClickAwayListener
									onClickAway={this.closeMenu.bind(this)}
								>
									<MenuList>
										<MenuItem
											onClick={this.newTemplateButtonPressed.bind(this)}
										>
											{'Neue Vorlage aus Auswahl'}
										</MenuItem>
										<MenuItem
											onClick={this.updateTemplateButtonPressed.bind(this)}
										>
											{'Vorlage mit Auswahl Überschreiben'}
										</MenuItem>
										<MenuItem
											onClick={this.deleteTemplateButtonPressed.bind(this)}
											className={classes.deleteButton}
										>
											{'Vorlage Löschen'}
										</MenuItem>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</div>
		);
	}
}

export default withSnackbar(withDialog()(withStyles(attributeTemplateButtonStyles)(AttributeTemplateButton)));
