import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const automatedEditorRuleSettingsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	categorySelect: {
		width: '100%',
		marginTop: theme.spacing(1),
	},
	editRuleButton: {
		marginRight: '5px'
	},
	replaceRulesOpenDialogButton: {
		marginLeft: 'auto',
		marginBottom: theme.spacing(1)
	}
});

export default automatedEditorRuleSettingsStyles;
