import React, { ReactNode, Component, CSSProperties } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select';

import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';
import { Product, ProductsResponse } from '@models/Product';

import productSelectStyles from './ProductSelectStyles';

interface OptionType {
	label: ReactNode;
	value: string;
};

interface State {
	products: Product[];
	selectedProduct: ValueType<OptionType> | null;
	isLoadingProducts: boolean;
}

interface Props extends WithStyles<typeof productSelectStyles> {
	onChange?: (product: Product | null) => void;
	selectedProductCode: string | null;
	disabled: boolean;
	categoryCodeToLookForProducts: string | string[];
	visualRule: boolean;
}

class ProductSelect extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			products: [],
			selectedProduct: null,
			isLoadingProducts: false
		};

	}

	public componentDidMount(): void {
		this.loadProductsByCategory();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.selectedProductCode !== this.props.selectedProductCode) {
			this.updateSelectedOption();
		}
	}

	private updateSelectedOption(): void {
		let selectedOption = null;

		if (this.props.selectedProductCode) {
			const options = this.getOptions();

			for (const option of options) {
				if (option.value === this.props.selectedProductCode) {
					selectedOption = option;
				}
			}
		}

		this.setState({selectedProduct: selectedOption});
	}

	private loadProductsByCategory(): void {
		this.setState({isLoadingProducts: true});
		let categoryCodes = this.props.categoryCodeToLookForProducts;
		if (Array.isArray(categoryCodes)) {
			categoryCodes = categoryCodes.join(',');
		}
		let url = `/api/categories/${categoryCodes}/onlineProducts`;
		if (this.props.visualRule) {
			url = `/api/categories/${categoryCodes}/visualrule/products/?lean`;
		}
		this.authService.fetch<ProductsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({products: response.data});

				this.updateSelectedOption();

				if (this.props.onChange && this.props.selectedProductCode) {
					const product = this.productWithCode(this.props.selectedProductCode, response.data);
					this.props.onChange(product);
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
			this.setState({isLoadingProducts: false});
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Select
				className={classes.root}
				value={this.state.selectedProduct}
				onChange={this.handleChange.bind(this)}
				options={this.getOptions()}
				placeholder={'Produkt auswählen...'}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
				menuPortalTarget={document.body}
				isSearchable
				isLoading={this.state.isLoadingProducts}
				isDisabled={this.props.disabled}
			/>
		);
	}

	private getOptions(): OptionType[] {
		let options: OptionType[] = [];

		this.state.products.sort((a: Product, b: Product) => (a.name > b.name) ? 1 : -1).forEach((p: Product): void => {
			options = options.concat(this.optionsForProduct(p));
		});

		return options;
	}

	private optionsForProduct(product: Product): OptionType[] {
		const options: OptionType[] = [];

		options.push({value: product.productCode, label: this.getProductLabelWithImage(product)});

		return options;
	}

	private getProductLabelWithImage(product: Product): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={this.props.visualRule ? classes.optionContainerVisualRule : classes.optionContainer}>
				{this.props.visualRule &&
					<div className={classes.imageContainer}>
						{product.images[0] &&
							<img src={`${product.images[0]}?maxH=50&maxW=50&upscale=false`} alt=""/>
						}
					</div>
				}
				<div className={this.props.visualRule ? classes.nameCodeContainerVisualRule : ''}>{product.name + ' - ' + product.productCode}</div>
			</div>
		);
	}

	private handleChange(selectedProduct: ValueType<OptionType>): void {
		this.setState({selectedProduct: selectedProduct});

		if (this.props.onChange) {
			const productCode = (selectedProduct as OptionType).value;
			const product = this.productWithCode(productCode, this.state.products);
			this.props.onChange(product);
		}
	}

	private productWithCode(code: string, products: Product[]): Product | null {
		for (const product of products) {
			if (product.productCode === code) {
				return product;
			}
		}
		return null;
	}

}

export default withStyles(productSelectStyles)(ProductSelect);
