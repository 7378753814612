import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	optionContainerVisualRule: {
		display: 'flex',
		margin: 20
	},
	optionContainer: {
		display: 'flex'
	},
	imageContainer: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		height: '30px',
		width: '30px',
		textAlign: 'center',
		flexGrow: 0,
		flexShrink: 0
	},
	nameCodeContainerVisualRule: {
		marginLeft: theme.spacing(3),
	}
});

export default productSelectStyles;
