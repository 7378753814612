import {Theme, createStyles} from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const seriesTableStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3)
	},
	timeRangeContainer: {
		display: 'flex',
		flexDirection: 'row'
	},
	loadButton: {
		display: 'flex',
		alignSelf: 'center',
		height: 'fit-content',
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(2)
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'flex',
	},
	link: {
		cursor: 'pointer'
	}
});

export default seriesTableStyles;
