import React, { ReactNode, Component } from 'react';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
	Typography,
	Paper,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	CircularProgress,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import automatedComparatorEditorStyles from './AutomatedComparatorEditorStyles';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import AuditLogsTable from '../AuditLogs/AuditLogsTable';
import { EntityType } from '@models/AuditLog';
import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';
import { AutomatedComparator, Rule, RuleGroup, ConnectorType, SplitDetail, MergeDetail } from '@models/AutomatedComparator';
import { Product, ProductAttribute, ProductStatus } from '@common/models/Product';
import { CategoriesResponse, Category } from '@models/Category';

import AutomatedEditorGeneralSettings from './AutomatedComparatorEditorComponents/AutomatedEditorGeneralSettings';
import AutomatedEditorRuleSettings from './AutomatedComparatorEditorComponents/AutomatedEditorRuleSettings';
import AutomatedEditorProductSettings from './AutomatedComparatorEditorComponents/AutomatedEditorProductSettings';
import AutomatedEditorSplitSettings from './AutomatedComparatorEditorComponents/AutomatedEditorSplitSettings';
import AutomatedEditorAttributeSettings from './AutomatedComparatorEditorComponents/AutomatedEditorAttributeSettings';
import AutomatedEditorPreview from './AutomatedComparatorEditorComponents/AutomatedEditorPreview';
import AutomatedEditorToMergeOverview from './AutomatedComparatorEditorComponents/AutomatedEditorToMergeOverview';

interface OptionType {
	label: string;
	value: string;
};

interface ProductResponse extends Response {
	success: boolean;
	data: Product[];
	message?: string;
}

interface AutomatedComparatorResponse extends Response {
	success: boolean;
	data: AutomatedComparator;
	message?: string;
}

interface AutomatedComparatorsResponse extends Response {
	success: boolean;
	data: AutomatedComparator[];
	message?: string;
}

interface Params {
	comparatorId: string;
}

interface Props extends WithStyles<typeof automatedComparatorEditorStyles>, RouteComponentProps<Params>, WithSnackbarProps {
	dialog?: ConfirmDialog;
	merge: boolean;
}

interface State {
	ruleToEdit: Rule | null;
	automatedComparator: AutomatedComparator;
	savedAutomatedComparator: AutomatedComparator;
	productsByRules: Product[];
	productsMissing: Product[];
	loading: boolean;
	loadingProducts: boolean;
	refreshed: boolean;
	saving: boolean;
	categories: Category[];
	products: Product[];
	loadMissingProducts: boolean;
	selectedToMergeComparators: AutomatedComparator[];
	rulesSelectOpen: boolean;
}

class ComparatorEditor extends Component<Props, State> {

	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.productsService = new ProductsService();
		const automatedComparatorId = this.props.match.params.comparatorId;

		const dummyRuleGroup: RuleGroup = {
			connectorType: this.props.merge ? ConnectorType.Or : ConnectorType.And,
			rules: [],
			ruleGroups: []
		};

		const dummyMergeDetail: MergeDetail = {
			mergeComparator: this.props.merge ? true : false,
			mergeComparators: []
		};

		const dummyAutomatedComparator: AutomatedComparator = {
			_id: automatedComparatorId,
			active: true,
			name: 'Alternativen im Vergleich',
			nameBackend: '',
			position: '',
			fullPage: false,
			sortedBy: 1,
			sortWeight: 1,
			attributes: [],
			missingCount: 0,
			rules: dummyRuleGroup,
			categoryCode: '',
			products: [],
			mergeDetail: dummyMergeDetail,
			splitDetail: this.getDummySplitDetails()
		};

		if (this.props.merge && this.props.location.state) {

			//eslint-disable-next-line @typescript-eslint/no-explicit-any
			const automatedComparators = (this.props.location.state as any);
			dummyAutomatedComparator.categoryCode = automatedComparators[0].categoryCode;
			for (const automatedComparator of automatedComparators) {
				dummyAutomatedComparator.mergeDetail.mergeComparators.push(automatedComparator._id);
				dummyAutomatedComparator.rules.ruleGroups.push(automatedComparator.rules);
				for (const attribute of automatedComparator.attributes) {
					if (!dummyAutomatedComparator.attributes.includes(attribute)) {
						dummyAutomatedComparator.attributes.push(attribute);
					}
				}
			}
		}

		this.state = {
			ruleToEdit: null,
			automatedComparator: dummyAutomatedComparator,
			savedAutomatedComparator: dummyAutomatedComparator,
			productsByRules: [],
			productsMissing: [],
			loading: (automatedComparatorId !== 'new'),
			loadingProducts: false,
			refreshed: false,
			saving: false,
			categories: [],
			products: [],
			loadMissingProducts: false,
			selectedToMergeComparators: [],
			rulesSelectOpen: false
		};

		this.loadComparator(automatedComparatorId);
		if (this.props.merge) {
			this.loadSelectedComparators();
		}
		this.loadCategories();
	}

	private getDummySplitDetails(): SplitDetail {
		const dummySplitDetail: SplitDetail = {
			levels: [],
			idealNumberProducts: 10
		};

		return dummySplitDetail;
	}

	public componentDidMount(): void {
		window.onbeforeunload = (): (boolean | undefined) => {
			return (this.isDirty()) ? true : undefined;
		};
	}

	public componentWillUnmount(): void {
		window.onbeforeunload = null;
	}

	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({categories: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadComparator(comparatorId: string): void {
		if (comparatorId !== 'new') {
			this.authService.fetch<AutomatedComparatorResponse>(`/api/automated-comparators/${comparatorId}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					if (response.data) {
						const automatedComparator = response.data;
						if (!automatedComparator.splitDetail) {
							automatedComparator.splitDetail = this.getDummySplitDetails();
						}
						this.setState({automatedComparator: automatedComparator, savedAutomatedComparator: automatedComparator, loading: false}, () => {
							if (this.state.automatedComparator.mergeDetail && this.state.automatedComparator.mergeDetail.mergeComparator) {
								this.loadSelectedComparators();
							}
						});
					}
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private loadSelectedComparators(): void {
		this.authService.fetch<AutomatedComparatorsResponse>('/api/automated-comparators/ids', {
			method: 'POST',
			body: JSON.stringify(this.state.automatedComparator.mergeDetail.mergeComparators)
		}).then((response): void => {
			if (response.success) {
				if (response.data) {
					this.setState({selectedToMergeComparators: response.data});
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleComparatorChange(key: keyof AutomatedComparator, event: any): void {
		if (!this.state.automatedComparator) {
			return;
		}

		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.setState((state: State): any => ({automatedComparator: {...state.automatedComparator, [key]: value }}));
	}

	private deleteComparatorButtonPressed(): void {
		const dialog = this.props.dialog;
		const comparator = this.state.automatedComparator;

		if (dialog) {
			dialog.confirm({
				title: 'Produktvergleicher Löschen',
				message: `Soll der Produktvergleicher "${comparator.name}" (ID: a${comparator._id}) wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<{}>(`/api/automated-comparators/${comparator._id}`, {
					method: 'DELETE',
				}).then((_): void => {
					this.props.history.push('/comparators');
					this.props.enqueueSnackbar(`Produktvergleicher mit der ID ${comparator._id} erfolgreich gelöscht!`, {variant: 'success'});
				});
			}).catch((error): void => {
				console.log(`Error while deleting automated comparator: ${error}`);
			});
		}
	}

	private auditLogs(): ReactNode {
		const classes = this.props.classes;

		if (!this.state.automatedComparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={false}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Chronik</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<AuditLogsTable
						loadAllLogs={false}
						entityId={this.state.automatedComparator._id}
						entityType={EntityType.AutomatedComparator}
						showChrome={false}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	private handleRulesChange(ruleGroup: RuleGroup): void {
		const automatedComparator = this.state.automatedComparator;
		automatedComparator.rules = ruleGroup;
		this.setState({
			automatedComparator: automatedComparator,
			refreshed: false,
			productsByRules: []
		});
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		const currentComparator = this.state.automatedComparator;
		currentComparator.categoryCode = (selectedCategory ? selectedCategory.code : '');
		this.setState({ automatedComparator: currentComparator });
	}

	private getAttributeOptions(): OptionType[] {
		const options: OptionType[] = [];

		let attributes: ProductAttribute[] = [];

		for (const product of this.state.productsByRules) {
			for (const attribute of product.attributes) {

				if (attributes.filter(function (a) { return a.key === attribute.key; }).length === 0) {
					attributes.push(attribute);
				}
			}
		}

		attributes = attributes.sort((a, b): number => {
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}

			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}

			return 0;
		});

		attributes.forEach((attribute: ProductAttribute): void => {
			options.push({value: attribute.key, label: attribute.name});
		});

		return options;
	}

	private async refreshProductsButtonClicked(): Promise<void> {
		this.setState({
			loadingProducts: true,
			refreshed: true
		});
		await this.loadProductsByRules();
		this.setState({loadingProducts: false});

	}

	private async refreshProductsMissing(): Promise<void> {
		if (this.state.automatedComparator.rules.rules.length !== 0 || this.state.automatedComparator.rules.ruleGroups.length !== 0) {
			this.setState({
				loadingProducts: true,
				refreshed: true
			});
			await this.loadProductsMissing();
			this.setState({loadingProducts: false});
		}
	}

	private async loadProductsMissing(): Promise<void> {
		await this.loadProductsByRules();
		const allProducts: Product[] = [];
		const products = await this.productsService.loadProductsInCategory(this.state.automatedComparator.categoryCode);
		for (const product of products) {
			if (product.status !== 'OFFLINE') {
				allProducts.push(product);
			}
		}

		const missing = allProducts.filter(itemProduct => !this.state.productsByRules.find(item => item._id === itemProduct._id));
		this.setState({productsMissing: missing, loadMissingProducts: true});
	}

	private async loadProductsByRules(): Promise<void> {
		await this.authService.fetch<ProductResponse>('/api/automated-comparators/products', {
			method: 'POST',
			body: JSON.stringify(this.state.automatedComparator)
		}).then((response): void => {
			if (response.success) {
				this.setState({productsByRules: response.data, loadMissingProducts: false});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unknown Error');
			}
		});
	}

	private handleAttributesChange(selectedAttributes: string[]): void {
		const json = JSON.stringify(this.state.automatedComparator);
		const automatedComparator: AutomatedComparator = JSON.parse(json);
		automatedComparator.attributes = selectedAttributes;
		this.setState({automatedComparator: automatedComparator});
	}

	private handlesplitDetailChange(splitDetail: SplitDetail): void {
		const automatedComparator = JSON.parse(JSON.stringify(this.state.automatedComparator));
		automatedComparator.splitDetail = splitDetail;
		this.setState({automatedComparator: automatedComparator});
	}

	private async handleSaveButton(): Promise<void> {
		this.setState({saving: true});

		try {
			const response = await this.authService.fetch<AutomatedComparatorResponse>(`/api/automated-comparators/${this.state.automatedComparator._id}`, {
				method: 'POST',
				body: JSON.stringify(this.state.automatedComparator)
			});

			if (response.data) {
				const json = JSON.stringify(this.state.automatedComparator);
				const automatedComparator: AutomatedComparator = JSON.parse(json);

				automatedComparator._id = response.data._id;
				this.setState({automatedComparator: automatedComparator, savedAutomatedComparator: automatedComparator});
				this.props.enqueueSnackbar(`Produktvergleicher mit der ID a${automatedComparator._id} erfolgreich gespeichert!`, {variant: 'success'});
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	private async handleMergeInactiveSaveButton(): Promise<void> {
		this.setState({saving: true});

		let containsLessThanThreeProducts = false;

		for (const selectedAutomatedComparator of this.state.selectedToMergeComparators) {
			if (selectedAutomatedComparator.products.length < 3) {
				containsLessThanThreeProducts = true;
				break;
			}

			let counterOnlineProducts = 0;
			for (const product of selectedAutomatedComparator.products) {
				if (product.status === ProductStatus.Online) {
					counterOnlineProducts++;
				}
			}
			if (counterOnlineProducts < 3) {
				containsLessThanThreeProducts = true;
				break;
			}
		}

		try {
			if (!containsLessThanThreeProducts) {
				const automatedComparator = this.state.automatedComparator;
				automatedComparator.active = false;
				this.setState({automatedComparator: automatedComparator}, async () => {
					await this.handleSaveButton();
				});
			} else {
				await this.handleSaveButton();
			}

			for (const selectedAutomatedComparator of this.state.selectedToMergeComparators) {
				if (containsLessThanThreeProducts) {
					selectedAutomatedComparator.active = false;
				}

				await this.authService.fetch<AutomatedComparatorResponse>(`/api/automated-comparators/${selectedAutomatedComparator._id}`, {
					method: 'POST',
					body: JSON.stringify(selectedAutomatedComparator)
				});
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	private isDirty(): boolean {
		const c1 = this.state.automatedComparator;
		const c2 = this.state.savedAutomatedComparator;

		if (c1.active !== c2.active ||
			c1.name !== c2.name ||
			c1.position !== c2.position ||
			c1.fullPage !== c2.fullPage ||
			c1.sortedBy !== c2.sortedBy ||
			c1.attributes !== c2.attributes ||
			c1.sortWeight !== c2.sortWeight ||

			c1.attributes.length !== c2.attributes.length) {
			return true;
		}

		// for (const rule of c1.rules.rules) {
		// 	if (c2.rules.rules.find(this.findRuleWithID.bind(this, rule._id)) === undefined) {
		// 		return true;
		// 	}
		// }


		return false;
	}

	// private findRuleWithID(rID: string, rule: Rule): boolean {
	// 	return rule._id === rID;
	// }
	//
	// private findRuleGroupWithID(rID: string, ruleGroup: RuleGroup): boolean {
	// 	return ruleGroup._id === rID;
	// }

	private closeRulesSelect(): void {
		this.setState({
			rulesSelectOpen: false
		});
	}

	private openRulesSelect(): void {
		this.setState({
			rulesSelectOpen: true
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const automatedComparatorId = this.state.automatedComparator._id;
		const title = (automatedComparatorId === 'new') ? 'Neuer automatisierter Produktvergleicher' : `Produktvergleicher ID a${automatedComparatorId} Bearbeiten`;
		return (
			<React.Fragment>
				{this.isDirty() &&
				<Prompt
					message='Wollen Sie Ihren Vergleich wirklich verlassen? Dabei gehen die bereits erstellten Daten verloren'
				/>
				}
				<Paper square={true} className={classes.root}>
					<Typography component="h2" variant="h4" className={classes.title}>
						{title}
					</Typography>
					{this.state.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}
					{!this.state.loading && this.state.automatedComparator.mergeDetail && this.state.automatedComparator.mergeDetail.mergeComparator &&
					<AutomatedEditorToMergeOverview
						automatedComparators={this.state.selectedToMergeComparators}
					/>
					}
					{!this.state.loading &&
					<AutomatedEditorGeneralSettings
						automatedComparator={this.state.automatedComparator}
						handleComparatorChange={this.handleComparatorChange.bind(this)}
						deleteComparatorButtonPressed={this.deleteComparatorButtonPressed.bind(this)}
					/>
					}
					{!this.state.loading && this.authService.hasPermission('audit-logs') && this.state.automatedComparator._id !== 'new' && this.auditLogs() }
					{!this.state.loading && (!this.state.automatedComparator.mergeDetail || (this.state.automatedComparator.mergeDetail && !this.state.automatedComparator.mergeDetail.mergeComparator)) &&
						<AutomatedEditorRuleSettings
							automatedComparator={this.state.automatedComparator}
							handleRulesChange={this.handleRulesChange.bind(this)}
							handleCategoryChange={this.handleCategoryChange.bind(this)}
							rulesSelectOpen={this.state.rulesSelectOpen}
							closeRulesSelect={this.closeRulesSelect.bind(this)}
							openRulesSelect={this.openRulesSelect.bind(this)}
						/>
					}
					{!this.state.loading &&
						<AutomatedEditorProductSettings
							automatedComparator={this.state.automatedComparator}
							refreshProductsButtonClicked={this.refreshProductsButtonClicked.bind(this)}
							refreshProductsMissing={this.refreshProductsMissing.bind(this)}
							loadMissingProducts={this.state.loadMissingProducts}
							productsMissing={this.state.productsMissing}
							productsByRules={this.state.productsByRules}
							loadingProducts={this.state.loadingProducts}
							refreshed={this.state.refreshed}
						/>
					}
					{!this.state.loading &&
						<AutomatedEditorAttributeSettings
							automatedComparator={this.state.automatedComparator}
							handleAttributesChange={this.handleAttributesChange.bind(this)}
							productsByRules={this.state.productsByRules}
							refreshed={this.state.refreshed}
							loadingProducts={this.state.loadingProducts}
						/>
					}
					{!this.state.loading &&
						<AutomatedEditorSplitSettings
							automatedComparator={this.state.automatedComparator}
							getAttributeOptions={this.getAttributeOptions.bind(this)}
							handlesplitDetailChange={this.handlesplitDetailChange.bind(this)}
							refreshed={this.state.refreshed}
							productsByRules={this.state.productsByRules}
						/>
					}
					{!this.state.loading &&
						<AutomatedEditorPreview
							automatedComparator={this.state.automatedComparator}
							handleSaveButton={this.handleSaveButton.bind(this)}
							productsByRules={this.state.productsByRules}
							saving={this.state.saving}
							merge={(this.state.automatedComparator && this.state.automatedComparator.mergeDetail && this.state.automatedComparator.mergeDetail.mergeComparator) ? true : false}
							handleMergeInactiveSaveButton={this.handleMergeInactiveSaveButton.bind(this)}
						/>
					}
				</Paper>
			</React.Fragment>
		);
	}
}

export default withSnackbar(withDialog()(withStyles(automatedComparatorEditorStyles)(ComparatorEditor)));
