import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ruleGroupTableStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
		paddingRight: 0,
		width: '100%',
		border: `1px solid ${theme.palette.grey.A100}`,
	},
	subRule: {
		marginTop: theme.spacing(2),
		'& h6': {
			marginBottom: theme.spacing(1),
		},
	},
	connectorTypeSelector: {
		marginRight: '15px',
		marginTop: 1,
		color: theme.palette.secondary.main,
		height: 32,
		'& div': {
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
	},
	rightButtons: {
		float: 'right',
		marginRight: theme.spacing(2),
		'& button': {
			marginRight: theme.spacing(1),
		}
	},
	ruleButtons: {
		float: 'right',
		'& button': {
			marginRight: theme.spacing(1),
		}
	},
	imageContainer: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		height: '30px',
		width: '30px',
		textAlign: 'center',
		flexGrow: 0,
		flexShrink: 0,
	}
});

export default ruleGroupTableStyles;
