import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editorToMergeOverviewStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 500,
	},
	panelDetails: {
		display: 'block'
	},
	comparatorName: {
		color: theme.palette.primary.main,
		flexGrow: 1,
	},
	offlineBubble: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	attributesBubble: {
		backgroundColor: 'orange',
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	productSumBubble: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	warning: {
		marginRight: '4px',
		marginTop: '8px'
	},
	comparatorContainer: {
		padding: theme.spacing(1, 0.5),
		display: 'flex',
	}
});

export default editorToMergeOverviewStyles;
