import React, {ReactElement, FC} from 'react';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorEditorGoogleAnalyticsStyles from './AdvisorEditorGoogleAnalyticsStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Advisor} from '@common/models/Advisor';

interface Props extends WithStyles<typeof advisorEditorGoogleAnalyticsStyles> {
	advisor: Advisor;
}

const AdvisorEditorGoogleAnalytics: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	return (
		<ExpansionPanel
			defaultExpanded={!!props.advisor._id}
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Google Analytics</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				TODO
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default withStyles(advisorEditorGoogleAnalyticsStyles)(AdvisorEditorGoogleAnalytics);
