import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryMappingStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
		height: `calc(100vh - ${theme.spacing(12)}px)`,
	},
	header: {
		padding: theme.spacing(1, 2)
	},
	description: {
		paddingTop: theme.spacing(2)
	},
	categoryPagesContainer: {
		display: 'flex',
		marginTop: theme.spacing(3),
		maxHeight: `calc(100vh - ${theme.spacing(49.1)}px)`
	},
	baseCategories: {
		width: '42%',
		overflow: 'auto',
	},
	createdCategories: {
		width: '57%',
		marginLeft: 'auto',
		overflow: 'auto',
	},
	kioskSelect: {
		marginTop: theme.spacing(3),
		minWidth: 190
	},
	fasoSelect: {
		color: theme.palette.secondary.main
	},
	kmSelect: {
		color: theme.palette.primary.main
	},
	label: {
		color: '#000000',
		fontSize: '120%'
	}
});

export default categoryMappingStyles;
