import createMuiTheme, { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export default function createMoebelhausTheme(options: ThemeOptions): Theme {
	return createMuiTheme({
		palette: {
			primary: {
				main: '#1F4C79',
			},
			secondary: {
				main: '#4ABCB1',
				contrastText: '#ffffff'
			},
		},
		overrides: {
			MuiTooltip: {
				tooltip: {
					fontSize: '0.8rem',
				},
			},
		},
		...options,
	});
}
