import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cmsContentEditorStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(4, 3),
		paddingBottom: theme.spacing(8),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	rowsTitle: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(1),
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	bottomButtons: {
		marginTop: theme.spacing(4),
	},
	deleteIcon: {
		marginTop: theme.spacing(2),
	},
	saveButton: {
		float: 'right',
	},
});

export default cmsContentEditorStyles;
