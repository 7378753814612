import React, { ReactNode, Component } from 'react';
import {
	IconButton,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';

import editableCategoryTreeLabelStyles from './EditableCategoryTreeLabelStyles';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InfoIcon from '@material-ui/icons/Info';
import UndoDeleteIcon from '@material-ui/icons/RestoreFromTrash';

import CategoryMappingEditor from './CategoryMappingEditor';

import { Category, EditableCategory } from '@models/Category';

interface State {
	editorOpen: boolean;
}

interface Props extends WithStyles<typeof editableCategoryTreeLabelStyles> {
	editableCategory: EditableCategory;
	onDelete: (editableCategoryToDelete: EditableCategory) => void;
	onUndoDelete: (editableCategoryToDelete: EditableCategory) => void;
	onChange: (editableCategory: EditableCategory) => void;
	categories: Category[];
	categoriesCount: number;
	onChangePosition: (editableCategory: EditableCategory, moveUp: boolean) => void;
}

class EditableCategoryTreeLabel extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			editorOpen: false
		};
	}

	private editButtonClicked(): void {
		this.setState({
			editorOpen: true
		});
	}

	private deleteButtonClicked(): void {
		this.props.onDelete(this.props.editableCategory);
	}

	private undoDeleteButtonClicked(): void {
		this.props.onUndoDelete(this.props.editableCategory);
	}

	private closeEditor(): void {
		this.setState({editorOpen: false});
	};

	private changeEditableCategory(editableCategoryToEdit: EditableCategory): void {
		this.props.onChange(editableCategoryToEdit);
	}

	private positionUpButtonClicked(): void {
		this.props.onChangePosition(this.props.editableCategory, true);
	}

	private positionDownButtonClicked(): void {
		this.props.onChangePosition(this.props.editableCategory, false);
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={classes.root}>
				<div className={classes.name}>{this.props.editableCategory.categoryName}</div>
				<div className={classes.buttons}>
					{this.props.editableCategory.smartTerminalAdded &&
						<span className={classes.infoIcon}>
							<Tooltip title={
								<span>Durch Drag & Drop hinzugefügt!</span>
							}>
								<InfoIcon fontSize='small' color="inherit" />
							</Tooltip>
						</span>	
					}
					{this.props.editableCategory.smartTerminalDeleted &&
						<span className={classes.infoIcon}>
							<Tooltip title={
								<span>Wird nicht über die API übergeben!</span>
							}>
								<InfoIcon fontSize='small' color="inherit" />
							</Tooltip>
						</span>	
					}
					{this.props.editableCategory.sortPosition !== 0 &&
						<IconButton className={classes.upButton} color="secondary" size="small" onClick={this.positionUpButtonClicked.bind(this)}>
							<ArrowUpwardIcon />
						</IconButton>
					}
					{this.props.categoriesCount > 1 && this.props.editableCategory.sortPosition !== (this.props.categoriesCount - 1) ?
						<IconButton className={classes.downButton} color="secondary" size="small" onClick={this.positionDownButtonClicked.bind(this)}>
							<ArrowDownwardIcon />
						</IconButton> :
						<span className={classes.iconPlaceholder}></span>
					}
					<IconButton className={classes.editButton} color="primary" size="small" onClick={this.editButtonClicked.bind(this)}>
						<EditIcon />
					</IconButton>
					{this.props.editableCategory.smartTerminalDeleted ? 
						<IconButton className={classes.undoDeleteButton} size="small" onClick={this.undoDeleteButtonClicked.bind(this)}>
							<UndoDeleteIcon />
						</IconButton>
						: <IconButton className={classes.deleteButton} size="small" onClick={this.deleteButtonClicked.bind(this)}>
							<DeleteForeverOutlinedIcon />
						</IconButton>
					}
				</div>
				<CategoryMappingEditor
					editorOpen={this.state.editorOpen}
					closeHandler={this.closeEditor.bind(this)}
					editableCategory={this.props.editableCategory}
					deleteHandler={this.props.onDelete.bind(this)}
					changeHandler={this.changeEditableCategory.bind(this)}
					categories={this.props.categories}
				/>
			</div>
		);
	}
}

export default withStyles(editableCategoryTreeLabelStyles)(EditableCategoryTreeLabel);
