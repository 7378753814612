import AuthService from '@common/services/AuthService';

export interface AdvisorFolder {
	_id?: string;
	name: string;
}

// Requests

interface AdvisorFoldersResponse {
	data: AdvisorFolder[];
	success: boolean;
	message?: string;
}

interface AdvisorFolderResponse extends Response {
	success: boolean;
	data: AdvisorFolder;
	message?: string;
}

const authService = new AuthService();

export async function loadAdvisorFolders(): Promise<AdvisorFolder[] | null> {
	const response = await authService.fetch<AdvisorFoldersResponse>('/api/advisor-folders', {
		method: 'GET'
	});

	if (response.success && response.data) {
		return response.data;
	} else {
		return null;
	}
}

export async function deleteAdvisorFolder(advisorFolderId: string): Promise<boolean> {
	const response = await authService.fetch<{success: boolean}>(`/api/advisor-folders/${advisorFolderId}`, {
		method: 'DELETE',
	});

	return response.success;
}

export async function saveAdvisorFolder(advisorFolderToSave: AdvisorFolder): Promise<AdvisorFolder | null> {
	const response = await authService.fetch<AdvisorFolderResponse>(`/api/advisor-folders/${advisorFolderToSave._id ? advisorFolderToSave._id : 'new'}`, {
		method: 'POST',
		body: JSON.stringify(advisorFolderToSave)
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}