import React, { ReactNode, Component, CSSProperties } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select';

import {
	CircularProgress,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	TextField,
	WithStyles,
	withStyles } from '@material-ui/core';

import ProductsService from '@services/ProductsService';
import { Product, ProductStatus } from '@models/Product';
import { RuleType } from '@models/AutomatedComparator';

import attributeSelectStyles from './AttributeSelectStyles';

interface OptionType {
	label: string;
	value: string;
};

interface State {
	attributeNames: string[];
	attributeValues: string[];
	selectedAttributeName: ValueType<OptionType> | null;
	selectedAttributeValue: ValueType<OptionType> | null;
	inputAttributeValue: string;
	loadingAttributeNames: boolean;
	loadingAttributeValues: boolean;
	radioSelected: string;
	inputRequired: boolean;
}

interface Props extends WithStyles<typeof attributeSelectStyles> {
	onChangeAttributeName: (name: string) => void;
	onChangeAttributeValue?: (value: string, radioInputIsSelect: string) => void;
	onChangeSelectedRadio?: (value: string) => void;
	attributeName: string | undefined;
	attributeValue?: string;
	radioSelected?: string;
	ruleType?: RuleType;
	selectedCategoryCode: string | null | string[];
	attributeNameOnly: boolean;
	invalidInput: boolean;
	includeRadioButtons: boolean;
	includeTextField: boolean;
}

class AttributeSelect extends Component<Props, State> {
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.productsService = new ProductsService();

		let attributeNameOption = null;
		let attributeValueOption = null;
		let inputValue = '';
		let radioSelected = 'select';
		if (this.props.attributeName && this.props.attributeName !== '') {
			const attributeName = this.props.attributeName;
			attributeNameOption = {value: attributeName, label: attributeName};
		}

		if (this.props.attributeValue && this.props.attributeValue !== '' && this.props.radioSelected && this.props.radioSelected === 'select') {
			const attributeValue = this.props.attributeValue;
			attributeValueOption = {value: attributeValue, label: attributeValue};
		}

		if (this.props.attributeValue && this.props.attributeValue !== '' && this.props.radioSelected && this.props.radioSelected === 'input') {
			inputValue = this.props.attributeValue;
		}

		if (this.props.radioSelected && this.props.radioSelected !== '') {
			radioSelected = this.props.radioSelected;
		}

		this.state = {
			attributeNames: [],
			attributeValues: [],
			selectedAttributeName: attributeNameOption,
			selectedAttributeValue: attributeValueOption,
			inputAttributeValue: inputValue,
			loadingAttributeNames: false,
			loadingAttributeValues: false,
			radioSelected: radioSelected,
			inputRequired: false
		};
	}

	public componentDidMount(): void {
		this.loadProductAttributeNames();
		if (this.state.selectedAttributeName) {
			this.loadProductAttributeValues();
		}
	}

	public componentDidUpdate(prevProps: Props): void {
		if (this.props.ruleType && prevProps.ruleType && this.props.ruleType !== prevProps.ruleType) {
			this.setState({
				selectedAttributeName: null,
				selectedAttributeValue: null
			});
		} else if (!this.props.attributeName && this.props.selectedCategoryCode && prevProps.selectedCategoryCode && this.props.selectedCategoryCode !== prevProps.selectedCategoryCode) {
			this.loadProductAttributeNames();
			this.setState({
				selectedAttributeName: null,
				selectedAttributeValue: null
			});
		}
	}

	private async loadProductAttributeNames(): Promise<void> {
		this.setState({loadingAttributeNames: true});
		if (this.props.selectedCategoryCode !== '') {
			let products: Product[] = [];

			if (this.props.selectedCategoryCode) {
				products = await this.productsService.loadProductsInCategory(this.props.selectedCategoryCode);
			}

			const attributeNames: string[] = [];
			for (const product of products) {
				if (product.status === ProductStatus.Online) {
					for (const attribute of product.attributes) {
						if (!attributeNames.includes(attribute.name)) {
							attributeNames.push(attribute.name);
						}
					}
				}
			}
			this.setState({attributeNames: attributeNames});
		}
		this.setState({loadingAttributeNames: false});
	}

	private async loadProductAttributeValues(): Promise<void> {
		this.setState({loadingAttributeValues: true});
		if (this.props.selectedCategoryCode !== '') {
			let products: Product[] = [];

			if (this.props.selectedCategoryCode) {
				products = await this.productsService.loadProductsInCategory(this.props.selectedCategoryCode);
			}

			const attributeValues: string[] = [];
			for (const product of products) {
				if (product.status === ProductStatus.Online) {
					for (const attribute of product.attributes) {
						if (this.state.selectedAttributeName && attribute.name === ((this.state.selectedAttributeName as OptionType).value) && !attributeValues.includes(attribute.value)) {
							attributeValues.push(attribute.value);
						}
					}
				}
			}
			this.setState({attributeValues: attributeValues});
		}
		this.setState({loadingAttributeValues: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const style = { menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })};
		const styleError = { menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }),
			control: (base: CSSProperties): CSSProperties  => ({ ...base, borderColor: 'red'}),
			placeholder: (base: CSSProperties): CSSProperties  => ({ ...base, color: 'red'}),
			indicatorSeparator: (base: CSSProperties): CSSProperties  => ({ ...base, backgroundColor: 'red'}),
			dropdownIndicator: (base: CSSProperties): CSSProperties  => ({ ...base, color: 'red'})
		};

		return (
			<div>
				<Select
					className={classes.root}
					value={this.state.selectedAttributeName}
					onChange={this.handleNameChange.bind(this)}
					options={this.getOptions('names')}
					placeholder={(this.props.ruleType ? 'Attributnamen auswählen... *' : 'Attributnamen auswählen...')}
					noOptionsMessage={(): string => ('Keine Treffer')}
					styles={(this.props.invalidInput ? styleError : style)}
					menuPortalTarget={document.body}
					isSearchable
					isLoading={this.state.loadingAttributeNames}
					loadingMessage={(): string => ('Wird geladen...')}
				/>
				{this.state.selectedAttributeName && this.props.includeRadioButtons && !this.state.loadingAttributeNames && !this.state.loadingAttributeValues &&
					<RadioGroup value={this.state.radioSelected} onChange={(event): void => {this.handleRadioButtonChange(event);}}>
						<FormControlLabel value="select" control={<Radio color="primary"/>} label="Attributwert auswählen" />
						<FormControlLabel value="input" control={<Radio color="primary"/>} label="Attributwert eingeben" />
					</RadioGroup>
				}
				{this.state.selectedAttributeName && !this.props.attributeNameOnly && this.state.radioSelected !== 'input' && !this.state.loadingAttributeNames && !this.state.loadingAttributeValues &&
				<Select
					className={classes.root}
					value={this.state.selectedAttributeValue}
					onChange={this.handleValueSelectChange.bind(this)}
					options={this.getOptions('values')}
					placeholder={'Attributwert auswählen... *'}
					noOptionsMessage={(): string => ('Keine Treffer')}
					styles={(this.props.invalidInput ? styleError : style)}
					menuPortalTarget={document.body}
					isSearchable
					isLoading={this.state.loadingAttributeValues}
					loadingMessage={(): string => ('Wird geladen...')}
				/>
				}
				{this.state.selectedAttributeName && !this.props.attributeNameOnly && this.state.radioSelected === 'input' && !this.state.loadingAttributeNames && !this.state.loadingAttributeValues &&
				<FormControl className={classes.formControl}>
					<TextField
						required
						fullWidth
						error={this.props.invalidInput}
						className={classes.textInput}
						label="Attributwert"
						value={this.state.inputAttributeValue ? this.state.inputAttributeValue : ''}
						onChange={(event): void => {this.handleValueInputChange(event);}}
					/>
				</FormControl>
				}
				{(this.state.loadingAttributeNames || this.state.loadingAttributeValues) && !this.props.attributeNameOnly && this.state.selectedAttributeName &&
					<div className={classes.loadingIndicatorContainer}><CircularProgress className={classes.loadingIndicator}/></div>
				}
			</div>
		);
	}

	private getOptions(type: string): OptionType[] {
		const options: OptionType[] = [];

		if (type === 'names') {
			this.state.attributeNames.sort((a: string, b: string) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1).forEach((c: string): void => {
				options.push({value: c, label: c});
			});
		} else if (type === 'values') {
			this.state.attributeValues.sort((a: string, b: string) => (a.toLowerCase() > b.toLowerCase()) ? 1 : -1).forEach((c: string): void => {
				options.push({value: c, label: c});
			});
		}
		return options;
	}

	private handleNameChange(selectedAttributeName: ValueType<OptionType>): void {
		this.setState({selectedAttributeName: selectedAttributeName});
		this.setState({selectedAttributeValue: null});

		if (selectedAttributeName) {
			this.props.onChangeAttributeName((selectedAttributeName as OptionType).value);
		}

		this.loadProductAttributeValues();
	}

	private handleValueSelectChange(selectedAttributeValue: ValueType<OptionType>): void {
		this.setState({
			selectedAttributeValue: selectedAttributeValue
		});

		if (selectedAttributeValue && this.props.onChangeAttributeValue) {
			this.props.onChangeAttributeValue((selectedAttributeValue as OptionType).value, 'select');
		}
	}

	private handleValueInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		this.setState({inputAttributeValue: event.target.value});

		if (this.props.onChangeAttributeValue) {
			this.props.onChangeAttributeValue(event.target.value, 'input');
		}
	}

	private handleRadioButtonChange(event: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({radioSelected: (event.target as HTMLInputElement).value});
		if (this.props.onChangeSelectedRadio) {
			this.props.onChangeSelectedRadio((event.target as HTMLInputElement).value);
		}
	}
}

export default withStyles(attributeSelectStyles)(AttributeSelect);
