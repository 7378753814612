import React, { ReactNode, Component } from 'react';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	IconButton,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import splitLevelsStyles from './SplitLevelsStyles';
import { SplitLevel, SplitLevelGroup, RuleGroup, ConnectorType, SplitLevelRuleType } from '@models/AutomatedComparator';
import SplitLevelGroups from './SplitLevelGroups';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AttributeSelect from '@common/AttributeSelect';

interface Props extends WithStyles<typeof splitLevelsStyles> {
	dialog: ConfirmDialog;
	splitLevel: SplitLevel;
	splitLevelIndex: number;
	selectedCategoryCode: string;
	notifyParent: (splitLevel: SplitLevel, index: number) => void;
	loadingSplittedComparators: boolean;
	loadingPriceSplittedComparators: boolean;
}

interface State {
	splitLevel: SplitLevel;
}

class SplitLevels extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			splitLevel: props.splitLevel
		};
	}

	private addNewSplitLevelGroup(): void {
		const splitLevel = this.state.splitLevel;

		const dummySplitRuleGroup: RuleGroup = {
			connectorType: ConnectorType.And,
			rules: [],
			ruleGroups: []
		};

		const dummySplitLevelGroup: SplitLevelGroup = {
			splitRuleGroup: dummySplitRuleGroup
		};

		splitLevel.splitLevelGroups.push(dummySplitLevelGroup);

		this.props.notifyParent(splitLevel, this.props.splitLevelIndex);

		this.setState({
			splitLevel: splitLevel
		});
	}

	private deleteSplitLevelGroup(index: number): void {
		const dialog = this.props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Aufteilungsgruppe Löschen',
				message: 'Soll die Aufteilungsgruppe wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				const splitLevel = this.state.splitLevel;

				splitLevel.splitLevelGroups.splice(index, 1);

				this.props.notifyParent(splitLevel, this.props.splitLevelIndex);

				this.setState({
					splitLevel: splitLevel
				});
			}).catch((error): void => {
				console.log(`Error while deleting split level group: ${error}`);
			});
		}
	}

	private splitRuleGroupChanged(splitLevelGroup: SplitLevelGroup, index: number): void {
		const splitLevel = this.state.splitLevel;

		splitLevel.splitLevelGroups[index] = splitLevelGroup;

		this.props.notifyParent(splitLevel, this.props.splitLevelIndex);

		this.setState({
			splitLevel: splitLevel
		});
	}

	private isNumber(value: string): boolean {
		return isNaN(Number(value));
	}

	private getSelectedSplitLevelRuleTypeString(ruleType: SplitLevelRuleType | null): string {
		if (!ruleType) {
			return '';
		}
		if (ruleType === SplitLevelRuleType.SplitAttributeRestSingle) {
			return 'Aufteilung Produktvergleicher pro Attributwert';
		} else if (ruleType === SplitLevelRuleType.SplitRestTogether) {
			return 'Aufteilung Rest als eigener Produktvergleicher';
		} else if (ruleType === SplitLevelRuleType.SplitRestRemove) {
			return 'Aufteilung Rest Entfernt';
		} else {
			return '';
		}
	}

	private handleSplitLevelRuleSelectChange(event: React.ChangeEvent<{ value: unknown }>): void {
		const levelRuleType = (event.target.value as SplitLevelRuleType);

		const currentSplitLevel = this.state.splitLevel;
		currentSplitLevel.splitLevelRuleType = levelRuleType;
		if (levelRuleType === SplitLevelRuleType.SplitRestTogether || levelRuleType === SplitLevelRuleType.SplitRestRemove) {
			currentSplitLevel.splitLevelAttributeName = undefined;
		}

		this.props.notifyParent(currentSplitLevel, this.props.splitLevelIndex);

		this.setState({
			splitLevel: currentSplitLevel
		});
	}

	private handleAttributeNameChange(name: string): void {
		const currentSplitLevel = this.state.splitLevel;
		currentSplitLevel.splitLevelAttributeName = name;

		this.props.notifyParent(currentSplitLevel, this.props.splitLevelIndex);

		this.setState({
			splitLevel: currentSplitLevel
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<>
				<div className={classes.splitLevelSelects}>
					<FormControl disabled={this.props.loadingSplittedComparators || this.props.loadingPriceSplittedComparators} className={classes.splitLevelRuleSelect}>
						<InputLabel>Aufteilungsart auwählen...</InputLabel>
						<Select
							value={this.state.splitLevel.splitLevelRuleType ? this.state.splitLevel.splitLevelRuleType : ''}
							onChange={this.handleSplitLevelRuleSelectChange.bind(this)}
						>
							{Object.keys(SplitLevelRuleType).filter(x => this.isNumber(x)).map(key =>
								<MenuItem key={SplitLevelRuleType[key as keyof typeof SplitLevelRuleType]} value={SplitLevelRuleType[key as keyof typeof SplitLevelRuleType]}>{this.getSelectedSplitLevelRuleTypeString(SplitLevelRuleType[key as keyof typeof SplitLevelRuleType])}</MenuItem>)}
						</Select>
					</FormControl>
					{this.props.splitLevel.splitLevelRuleType === SplitLevelRuleType.SplitAttributeRestSingle &&
						<div className={classes.splitLevelAttributeSelect}>
							<AttributeSelect
								onChangeAttributeName={this.handleAttributeNameChange.bind(this)}
								attributeName={this.props.splitLevel.splitLevelAttributeName}
								selectedCategoryCode={this.props.selectedCategoryCode}
								attributeNameOnly={true}
								invalidInput={false}
								includeRadioButtons={false}
								includeTextField={false}
							/>
						</div>
					}
				</div>
				{this.props.splitLevel.splitLevelGroups.map((group: SplitLevelGroup, index: number) =>
					<div key={index} className={classes.splitLevelGroupContainer}>
						<div className={classes.splitLevelGroupHeadlineContainer}>
							<div>
								<Typography className={classes.panelTitle} variant="body1">{'Aufteilungsgruppe:'}</Typography>
							</div>
							<IconButton disabled={this.props.loadingSplittedComparators || this.props.loadingPriceSplittedComparators} className={classes.deleteButton} size="small" onClick={this.deleteSplitLevelGroup.bind(this, index)}>
								<HighlightOffIcon />
							</IconButton>
						</div>
						<SplitLevelGroups
							splitLevelGroup={group}
							splitLevelGroupIndex={index}
							selectedCategoryCode={this.props.selectedCategoryCode}
							notifyParent={this.splitRuleGroupChanged.bind(this)}
							loadingSplittedComparators={this.props.loadingSplittedComparators || this.props.loadingPriceSplittedComparators}
						/>
					</div>
				)}
				<div className={classes.addNewSplitRuleGroupButton}>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						startIcon={<AddIcon />}
						onClick={this.addNewSplitLevelGroup.bind(this)}
						disabled={this.props.loadingSplittedComparators || this.props.loadingPriceSplittedComparators}
					>
					Neue Aufteilungsgruppe
					</Button>
				</div>
			</>

		);
	}

}

export default withDialog()(withStyles(splitLevelsStyles)(SplitLevels));
