import React, {ReactNode, Component} from 'react';
import {
	Fab,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import AuthService from '@services/AuthService';
import DropshippingEditor from './DropshippingEditor';
import dropshippingOverviewStyles from './DropshippingOverviewStyles';
import DropshippingTable from './DropshippingTable';

import { DropshippingModel } from '@models/Dropshipping';
import { Product } from '@models/Product';

interface DropshippingsResponse {
	data: DropshippingModel[];
	success: boolean;
	message?: string;
}

interface State {
	dropshippings: DropshippingModel[];
	editorOpen: boolean;
	dropshippingToEdit: DropshippingModel | null;
	products: Product[];
}

class DropshippingOverview extends Component<WithStyles<typeof dropshippingOverviewStyles>, State> {
	private authService: AuthService;

	public constructor(props: WithStyles<typeof dropshippingOverviewStyles>) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			dropshippings: [],
			editorOpen: false,
			dropshippingToEdit: null,
			products: []
		};

		this.loadDropshippings();
	}

	private loadDropshippings(): void {
		this.authService.fetch<DropshippingsResponse>('/api/dropshippings', {
			method: 'GET'
		}).then((response): void => {
			if (response.success && response.data) {
				this.setState({dropshippings: response.data});
			} else if (response.message) {
				this.setState({dropshippings: []});
				throw new Error(response.message);
			} else {
				this.setState({dropshippings: []});
				throw new Error('Unkown Error');
			}
		});
	}

	private editDropshipping(dropshipping: DropshippingModel): void {
		this.setState({
			editorOpen: true,
			dropshippingToEdit: dropshipping,
		});
	}

	private openDropshippingEditor(): void {
		this.setState({
			editorOpen: true,
			dropshippingToEdit: null,
			products: []
		});
	}

	private closeDropshippingEditor(): void {
		this.setState({
			editorOpen: false,
			dropshippingToEdit: null,
			products: []
		});

		this.loadDropshippings();
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Dropshipping Export'}
				</Typography>
				<DropshippingTable
					dropshippings={this.state.dropshippings}
					editHandler={this.editDropshipping.bind(this)}
					dropshipping={this.state.dropshippingToEdit}
				/>

				<Tooltip
					placement="bottom"
					arrow
					title={'Neue Dropshipping Kategorie anlegen'}
				>
					<Fab
						aria-label="Add"
						className={classes.fab}
						color="primary"
						onClick={this.openDropshippingEditor.bind(this)}
					>
						<AddIcon />
					</Fab>
				</Tooltip>
				<DropshippingEditor
					editorOpen={this.state.editorOpen}
					closeHandler={this.closeDropshippingEditor.bind(this)}
					dropshipping={this.state.dropshippingToEdit}
				/>
			</Paper>
		);
	}
}

export default withStyles(dropshippingOverviewStyles)(DropshippingOverview);
