import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryOverviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
		marginBottom: theme.spacing(8)
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2)
	},
	categoryContainer: {
		display: 'flex',
		padding: theme.spacing(1, 0.5),
		alignItems: 'center'
	},
	categoryProductCount: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
		marginLeft: 'auto'
	},
	actionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(2)
	},
	filterContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	value: {
		width: '160px',
		marginRight: theme.spacing(2)
	},
	buttonProgress: {
		position: 'absolute'
	},
	expandAllButton: {
		marginRight: theme.spacing(2)
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	}
});

export default categoryOverviewStyles;
