import React, {ReactElement, FC, CSSProperties} from 'react';
import {
	FormControl,
	FormLabel,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorStartingQuestionSelectStyles from './AdvisorStartingQuestionSelectStyles';
import Select from 'react-select';
import { AdvisorQuestion } from '@common/models/Advisor';

interface OptionType {
	label: string;
	value: string;
};

interface Props extends WithStyles<typeof advisorStartingQuestionSelectStyles> {
	questions: AdvisorQuestion[];
	startingQuestionId: string | undefined;
	handleStartingQuestionChange: (startingQuestionId: string | undefined) => void;
}

const AdvisorStartingQuestionSelect: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	function getQuestionsOptions(): OptionType[] {
		const options: OptionType[] = [];
		props.questions.forEach((question: AdvisorQuestion): void => {
			options.push({value: question._id ?? '', label: question.question});
		});

		return options;
	}

	function getCurrentSelectValue(): OptionType | undefined {
		if (!props.startingQuestionId) {
			return undefined;
		} else {
			let currentQuestion = undefined;
			for (const question of props.questions) {
				if (question._id === props.startingQuestionId) {
					currentQuestion = question;
					break;
				}
			}

			if (currentQuestion) {
				return {value: currentQuestion._id ?? '', label: currentQuestion.question};
			} else {
				return undefined;
			}
		}
	}

	return (
		<FormControl className={classes.root}>
			<FormLabel className={classes.label}>Startfrage</FormLabel>
			<Select
				className={classes.select}
				value={getCurrentSelectValue()}
				onChange={(selectedQuestion): void => props.handleStartingQuestionChange(!selectedQuestion ? undefined : (selectedQuestion as OptionType).value)}
				options={getQuestionsOptions()}
				placeholder={'Startfrage auswählen...'}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
				menuPortalTarget={document.body}
				isClearable
			/>
		</FormControl>
	);
};

export default withStyles(advisorStartingQuestionSelectStyles)(AdvisorStartingQuestionSelect);
