import React, {ReactElement, ReactNode, FC} from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import {useDrop} from 'react-dnd';
import TreeItem from '@material-ui/lab/TreeItem';
import EditableCategoryTreeLabel from './EditableCategoryTreeLabel';

import { Category, EditableCategory, KioskType } from '@models/Category';

interface CategoryProp {
	editableCategory: EditableCategory;
	editableCategoryParent: EditableCategory;
	onChange: (parentCategory: EditableCategory) => void;
	onDelete: (editableCategoryToDelete: EditableCategory) => void;
	onUndoDelete: (editableCategoryToDelete: EditableCategory) => void;
	level: number;
	categories: Category[];
	hasCategory: (categories: EditableCategory[], category: EditableCategory) => boolean;
	categoriesCount: number;
	onChangePosition: (editableCategory: EditableCategory, moveUp: boolean) => void;
	kioskType: KioskType;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		nodeColor: {
			backgroundColor: theme.palette.secondary.main,
			opacity: 0.6
		},
	};
});

const EditableTreeNodes: FC<CategoryProp> = (props: CategoryProp): ReactElement => {
	const classes = useStyles();

	const ItemTypes = {
		Category: 'category'
	};

	const [{isOver, canDrop}, drop] = useDrop({
		accept: ItemTypes.Category,
		drop: (item, monitor) => {
			const didDrop = monitor.didDrop();

			/* eslint-disable @typescript-eslint/no-explicit-any */
			if (props.level === 3) {
				const dragCategory: any = item;

				const currentEditableCategory = JSON.parse(JSON.stringify(props.editableCategory));
				currentEditableCategory.categoryCode += ',' + dragCategory.categoryCode;
				notifyParent(currentEditableCategory);
				return;
			}
			if (didDrop || !(props.level < 3)) {
				return;
			}

			addCategory(item, props.editableCategory);
		},
		collect: monitor => ({
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
		})
	});

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const addCategory = (item: any, parentCategory: EditableCategory): void => {
		const childCategory: EditableCategory = {
			categoryCode: item.categoryCode,
			categoryName: item.categoryName,
			image: item.image,
			childCategories: [],
			sortPosition: parentCategory.childCategories.length,
			articleName: '',
			subgroups: []
		};

		if (props.kioskType === KioskType.SMARTTERMINAL) {
			childCategory.smartTerminalAdded = true;
		}

		const parentCopy = JSON.parse(JSON.stringify(parentCategory));

		if (!props.hasCategory(parentCopy.childCategories, childCategory)) {
			parentCopy.childCategories.push(childCategory);
			props.onChange(parentCopy);
		}
	};

	const notifyParent = (updatedCategory: EditableCategory): void => {

		if (props.editableCategoryParent._id !== updatedCategory._id) {
			const parent = props.editableCategoryParent;
			const updatedChildren: EditableCategory[] = [];

			for (const currentCategory of parent.childCategories) {
				if (currentCategory._id === updatedCategory._id) {
					updatedChildren.push(updatedCategory);
				} else {
					updatedChildren.push(currentCategory);
				}
			}

			parent.childCategories = updatedChildren;
			props.onChange(parent);
		} else {
			props.onChange(updatedCategory);
		}
	};

	const undoDeleteEditableCategory = (editableCategoryToDelete: EditableCategory): void => {
		if (props.editableCategoryParent._id !== editableCategoryToDelete._id) {
			const changedParentCategory = props.editableCategoryParent;
			changedParentCategory.childCategories.forEach((editableCategory) => {
				if (editableCategory._id === editableCategoryToDelete._id) {
					editableCategory.smartTerminalDeleted = false;
				}
			});
			props.onChange(changedParentCategory);
		} else {
			props.onUndoDelete(editableCategoryToDelete);
		}
	};

	const deleteEditableCategory = (editableCategoryToDelete: EditableCategory): void => {

		if (editableCategoryToDelete.kioskType === KioskType.SMARTTERMINAL && !editableCategoryToDelete.smartTerminalAdded) {
			if (props.editableCategoryParent._id !== editableCategoryToDelete._id) {
				const changedParentCategory = props.editableCategoryParent;
				changedParentCategory.childCategories.forEach((editableCategory) => {
					if (editableCategory._id === editableCategoryToDelete._id) {
						editableCategory.smartTerminalDeleted = true;
					}
				});
				props.onChange(changedParentCategory);

			} else {
				if (props.onDelete) {
					props.onDelete(editableCategoryToDelete);
				}
			}
		} else {

			if (props.editableCategoryParent._id !== editableCategoryToDelete._id) {
				const changedParentCategory = props.editableCategoryParent;
				let indexDeletedEditableCategory = 0;

				changedParentCategory.childCategories.forEach((editableCategory, index) => {
					if (editableCategory._id === editableCategoryToDelete._id) {
						indexDeletedEditableCategory = index;
						changedParentCategory.childCategories.splice(index, 1);
						props.onChange(changedParentCategory);
					};
				});

				if (indexDeletedEditableCategory !== (changedParentCategory.childCategories.length)) {
					const changedParentCategoryWithChangedChildren = JSON.parse(JSON.stringify(changedParentCategory));
					const changedChildren: EditableCategory[] = [];

					for (const child of changedParentCategory.childCategories) {
						if (child.sortPosition > indexDeletedEditableCategory) {
							const changedChild = JSON.parse(JSON.stringify(child));
							changedChild.sortPosition -= 1;
							changedChildren.push(changedChild);
						} else {
							changedChildren.push(child);
						}
					}
					changedParentCategoryWithChangedChildren.childCategories = changedChildren;
					props.onChange(changedParentCategoryWithChangedChildren);
				}

			} else {
				if (props.onDelete) {
					props.onDelete(editableCategoryToDelete);
				}
			}
		}

	};

	const onChangePosition = (editableCategoryClicked: EditableCategory, moveUp: boolean): void => {

		if (props.editableCategoryParent._id !== editableCategoryClicked._id) {
			const parent = props.editableCategoryParent;
			const updatedChildren: EditableCategory[] = [];

			// Get current position of editable category to replace
			let editableCategoryToReplacePosition = editableCategoryClicked.sortPosition + 1;
			if (moveUp) {
				editableCategoryToReplacePosition = editableCategoryClicked.sortPosition - 1;
			}

			for (const currentCategory of parent.childCategories) {
				if (currentCategory._id === editableCategoryClicked._id) {
					// Change sort Position of clicked editable category
					const editableCategoryChangedPosition = JSON.parse(JSON.stringify(editableCategoryClicked));
					if (moveUp) {
						editableCategoryChangedPosition.sortPosition -= 1;
					} else {
						editableCategoryChangedPosition.sortPosition += 1;
					}
					updatedChildren.push(editableCategoryChangedPosition);
				} else if (currentCategory._id !== editableCategoryClicked._id && currentCategory.sortPosition === editableCategoryToReplacePosition)  {
					// Change sort position of editable category to replace
					const editableCategoryToReplace = JSON.parse(JSON.stringify(currentCategory));
					if (moveUp) {
						editableCategoryToReplace.sortPosition += 1;
					} else {
						editableCategoryToReplace.sortPosition -= 1;
					}
					updatedChildren.push(editableCategoryToReplace);
				} else {
					updatedChildren.push(currentCategory);
				}
			}

			parent.childCategories = updatedChildren;
			props.onChange(parent);
		} else {
			props.onChangePosition(editableCategoryClicked, moveUp);
		}
	};

	const isDropableByLevel = (level: number): any => {
		if (level < 3) {
			return drop;
		} else {
			return null;
		}
	};

	const getSortedChildren = (children: EditableCategory[]): EditableCategory[] => {
		return children.sort((c1, c2) => (c1.sortPosition > c2.sortPosition) ? 1 : -1);
	};

	return (
		<TreeItem key={props.editableCategory._id} classes={(isOver && canDrop) ? {content: classes.nodeColor} : {content: undefined}} ref={drop} nodeId={props.editableCategory._id ? props.editableCategory._id : props.editableCategory.categoryCode ? props.editableCategory.categoryCode : ''} label={<EditableCategoryTreeLabel editableCategory={props.editableCategory} onChange={notifyParent} onUndoDelete={undoDeleteEditableCategory} onDelete={deleteEditableCategory} categories={props.categories} categoriesCount={props.categoriesCount} onChangePosition={onChangePosition}/>}>
			{props.editableCategory.childCategories && props.editableCategory.childCategories.length > 0 && isDropableByLevel(props.level) && getSortedChildren(props.editableCategory.childCategories).map((editableCategory: EditableCategory): ReactNode => (
				<EditableTreeNodes
					key={editableCategory._id ? editableCategory._id : editableCategory.categoryCode}
					editableCategory={editableCategory}
					editableCategoryParent={props.editableCategory}
					onChange={notifyParent}
					onDelete={deleteEditableCategory}
					onUndoDelete={undoDeleteEditableCategory}
					level={props.level + 1}
					categories={props.categories}
					hasCategory={props.hasCategory}
					categoriesCount={props.editableCategory.childCategories.length}
					onChangePosition={onChangePosition}
					kioskType={props.kioskType}
				/>
			))}
		</TreeItem>
	);
};

export default EditableTreeNodes;
