import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorNodeWidgetStyles = (theme: Theme): any => createStyles({
	questionContainer: {
		display: 'flex',
		flexDirection: 'column',
		borderWidth: 2,
		borderColor: theme.palette.primary.main,
		borderStyle: 'solid',
		borderRadius: 5,
		'&:hover': {
			cursor: 'pointer'
		},
		overflow: 'hidden'
	},
	questionRow: {
		display: 'flex',
		width: '100%',
		height: 40,
	},
	answerRow: {
		display: 'flex',
		width: '100%',
		height: 40,
		borderTopWidth: 2,
		borderTopColor: theme.palette.primary.main,
		borderTopStyle: 'solid'
	},
	questionInPortContainer: {
		backgroundColor: theme.palette.secondary.main,
		width: 40,
		minWidth: 40,
		borderRadius: '2px 0 0 0',
		position: 'relative'
	},
	questionInPort: {
		width: '100%',
		height: '100%',
		borderRadius: '2px 0 0 0'
	},
	questionOutPortContainer: {
		backgroundColor: theme.palette.secondary.main,
		width: 40,
		minWidth: 40,
		borderRadius: '0 2px 0 0',
		position: 'relative'
	},
	questionOutPort: {
		width: '100%',
		height: '100%',
		borderRadius: '0 2px 0 0'
	},
	question: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: '100%'
	},
	answer: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		width: '100%',
		justifyContent: 'space-between'
	},
	answerOutPortContainer: {
		backgroundColor: theme.palette.secondary.main,
		width: 40,
		minWidth: 40,
		position: 'relative'
	},
	answerOutPort: {
		width: '100%',
		height: '100%'
	},
	removeLinkButton: {
		width: '100%',
		height: '100%',
		color: theme.palette.error.main,
		position: 'absolute',
		borderRadius: 0,
	},
	startingQuestionIcon: {
		position: 'absolute',
		width: '100%',
		height: '100%',
	},
	priceSliderContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: 'auto',
		marginBottom: 'auto'
	},
	newAnswerContainer: {
		borderTopWidth: 2,
		borderTopColor: theme.palette.primary.main,
		borderTopStyle: 'solid',
		overflow: 'hidden',
		borderRadius: 'initial'
	}
});

export default advisorNodeWidgetStyles;