import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorQuestionEditorStyles = (theme: Theme): any => createStyles({
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	deleteButton: {
		marginRight: 'auto',
		color: '#EE0000'
	},
	subtextInput: {
		marginTop: theme.spacing(4)
	},
	advisorQuestionTypesContainer: {
		marginTop: theme.spacing(4)
	},
	advisorQuestionTypes: {
		display: 'flex',
		flexDirection: 'row',
	},
	advisorVisualTypesContainer: {
		marginTop: theme.spacing(4)
	},
	advisorVisualTypes: {
		display: 'flex',
		flexDirection: 'row',
	},
	startingQuestionCheckbox: {
		marginTop: theme.spacing(4),
	},
});

export default advisorQuestionEditorStyles;