import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorCategoryAttributeEditorStyles = (theme: Theme): any => createStyles({
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	deleteButton: {
		marginRight: 'auto',
		color: '#EE0000'
	},
	categoriesContainer: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	categoriesLabel: {
		marginBottom: theme.spacing(1)
	},
});

export default advisorCategoryAttributeEditorStyles;
