export interface UserModel {
	_id?: string;
	permissions: string[];
	username: string;
	password?: string;
}

export function translatePermission(permission: string): string {
	switch (permission) {
		case 'admin': 
			return 'Administrator';
		case 'advisor':
			return 'Produktberater';
		case 'audit-logs':
			return 'Chronik';
		case 'category-feed':
			return 'Kategorie-Feeds';
		case 'category-mapping':
			return 'Kategorie-Mapping';
		case 'category-overview':
			return 'Kategorie-Übersicht';
		case 'category-ranking':
			return 'Kategorie-Ranking';
		case 'comparator':
			return 'Produktvergleicher';
		case 'comparator-installation':
			return 'Produktvergleicher-Installation';
		case 'crawler':
			return 'Crawler';
		case 'dropshipping-export':
			return 'Dropshipping-Export';
		case 'orders':
			return 'Bestellungen';
		case 'product-monitoring':
			return 'Monitoring';
		case 'product-suggestions':
			return 'Export Vorschläge';
		case 'reporting':
			return 'Umsatz';
		case 'series':
			return 'Serienanalyse';
		case 'shopping':
			return 'Google Shopping';
		case 'slider':
			return 'Slider Installation';
		default: 
			return '';
	}
}
