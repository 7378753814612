import React, {ReactElement, FC, useCallback, useState, useEffect} from 'react';
import {
	Button,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorPreviewStyles from './AdvisorPreviewStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Advisor} from '@common/models/Advisor';
import SaveIcon from '@material-ui/icons/Save';

interface Props extends WithStyles<typeof advisorPreviewStyles> {
	advisor: Advisor;
	handleSaveButton: () => void;
	isSaving: boolean;
	handleRefreshPreview: () => void;
	previewRefreshLoading: boolean;
	expanded: boolean;
}

const AdvisorPreview: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const [missingFields, setMissingFields] = useState<string[]>([]);
	const [openPreviewEnabled, setOpenPreviewEnabled] = useState(true);
	const [currentAdvisorName, setCurrentAdvisorName] = useState(props.advisor.name);
	const [currentAdvisorCategoryCodes, setCurrentAdvisorCategoryCodes] = useState(props.advisor.categoryCodes);
	const [currentAdvisorAttributes, setCurrentAdvisorAttributes] = useState(props.advisor.attributes);

	const advisorIsComplete = useCallback(() => {
		// Check for missing fields
		const currentMissingFields: string[] = [];
		if (props.advisor.name === '') {
			currentMissingFields.push('Titel');
		}
		if (props.advisor.categoryCodes.length < 1) {
			currentMissingFields.push('Kategorien');
		}
		if (!props.advisor.startingQuestion) {
			currentMissingFields.push('Startfrage');
		}
		setMissingFields(currentMissingFields);

	}, [props.advisor.categoryCodes, props.advisor.name, props.advisor.startingQuestion]);

	useEffect(() => {
		advisorIsComplete();
	}, [advisorIsComplete]);

	useEffect(() => {
		// Check for disabling Show-Preview-Button
		if (props.advisor.name !== currentAdvisorName) {
			setOpenPreviewEnabled(false);
			setCurrentAdvisorName(props.advisor.name);
		}
		if (JSON.stringify(props.advisor.categoryCodes) !== JSON.stringify(currentAdvisorCategoryCodes)) {
			setOpenPreviewEnabled(false);
			setCurrentAdvisorCategoryCodes(props.advisor.categoryCodes);
		}
		if (JSON.stringify(props.advisor.attributes) !== JSON.stringify(currentAdvisorAttributes)) {
			setOpenPreviewEnabled(false);
			setCurrentAdvisorAttributes(props.advisor.attributes);
		}
	}, [props.advisor.name, props.advisor.categoryCodes, props.advisor.attributes, currentAdvisorName, currentAdvisorCategoryCodes, currentAdvisorAttributes, props.advisor]);

	const openPreview = useCallback(() => {
		const url = `/product-advisor-preview/${props.advisor._id}`;
		window.open(url, '_blank')?.focus();
	}, [props.advisor._id]);

	return (
		<ExpansionPanel defaultExpanded={props.expanded}>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Vorschau</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				<div className={classes.container}>
					{missingFields.length > 0 && 
					<>
						<Typography>Die Vorschau kann erst geladen werden wenn alle erforderlichen Felder ausgefüllt wurden.</Typography>
						<Typography>{`Benötigte Felder: ${missingFields.join(', ')}`}</Typography>
					</>
					}
					{missingFields.length === 0 &&
					<div className={classes.buttonContainer}>
						<div>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={(): void => {props.handleRefreshPreview(); setOpenPreviewEnabled(true);}}
									className={classes.refreshButton}
									disabled={props.isSaving || props.previewRefreshLoading}
								>
							Vorschau aktualisieren
								</Button>
							</div>
							<div>
								<Button
									variant="contained"
									color="primary"
									onClick={openPreview}
									className={classes.refreshButton}
									disabled={props.isSaving || props.previewRefreshLoading || !openPreviewEnabled}
								>
							Vorschau öffnen
								</Button>
							</div>
						</div>
						{(props.isSaving || props.previewRefreshLoading) && <CircularProgress />}
						<Button
							variant="contained"
							color="primary"
							onClick={props.handleSaveButton}
							disabled={props.isSaving || props.previewRefreshLoading}
							startIcon={<SaveIcon />}
							className={classes.saveButton}
						>
							Berater Speichern
						</Button>
							
					</div>
					}
				</div>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default withStyles(advisorPreviewStyles)(AdvisorPreview);
