import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const splitLevelPreviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	comparatorHeadline: {
		marginBottom: theme.spacing(1),
		display: 'flex'
	},
	splitComparatorProducts: {
		marginBottom: theme.spacing(6)
	},
	warning: {
		marginLeft: '4px',
		alignSelf: 'end'
	},
	comparatorHeadlineRest: {
		marginBottom: theme.spacing(1),
		display: 'flex',
		color: 'red'
	},
});

export default splitLevelPreviewStyles;
