import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const kitchenInstallationStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	subTitle: {
		marginTop: theme.spacing(3),
	},
	textField: {
		fontFamily: 'monospace',
		fontSize: '12px',
		lineHeight: '15px',
	},
});

export default kitchenInstallationStyles;
