import React, {ReactElement, FC, CSSProperties} from 'react';
import {
	FormControl,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorAnswerMultiSelectStyles from './AdvisorAnswerMultiSelectStyles';
import {AdvisorAnswer} from '@common/models/Advisor';
import Select, { ValueType } from 'react-select';

interface OptionType {
	label: string;
	value: string;
	data: AdvisorAnswer;
};

interface Props extends WithStyles<typeof advisorAnswerMultiSelectStyles> {
	answers: AdvisorAnswer[];
	allAdvisorAnswers: AdvisorAnswer[];
	handleAnswerChange: (answer: AdvisorAnswer[]) => void;
	key: number;
	placeholder: string;
}

const AdvisorAnswerMultiSelect: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	function getAnswersOptions(): OptionType[] {
		const options: OptionType[] = [];
		props.allAdvisorAnswers.forEach((answer: AdvisorAnswer): void => {
			options.push({value: answer._id ?? '', label: answer.answer, data: answer});
		});

		return options;
	}

	function getCurrentSelectValue(): OptionType[] {
		const selectedOptions: OptionType[] = [];

		if (props.answers.length === 0) {
			return selectedOptions;
		}

		const options = getAnswersOptions();

		for (const option of options) {
			for (const answer of props.answers) {
				if (option.data._id && answer._id && option.data._id === answer._id) {
					selectedOptions.push(option);
				}
			}
		}

		return selectedOptions;
	}

	function handleChange(selectedAnswers: ValueType<OptionType>): void {
		const answers: AdvisorAnswer[] = [];
		
		if (selectedAnswers) {
			for (const selectedAnswer of selectedAnswers as []) {
				const answer = (selectedAnswer as OptionType).data;
				answers.push(answer);
			}
		}

		props.handleAnswerChange(answers);
	}

	return (
		<FormControl className={classes.root} key={props.key}>
			<Select
				className={classes.select}
				value={getCurrentSelectValue()}
				onChange={(selectedAnswers): void => handleChange(selectedAnswers)}
				options={getAnswersOptions()}
				placeholder={props.placeholder}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
				menuPortalTarget={document.body}
				isClearable
				isSearchable
				isMulti
			/>
		</FormControl>
	);
};

export default withStyles(advisorAnswerMultiSelectStyles)(AdvisorAnswerMultiSelect);
