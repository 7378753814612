import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dropshippingAttributeSelectStyles = (theme: Theme): any => createStyles({
	container: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		marginTop: theme.spacing(2),
	},
	attributeContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		alignContent: 'flex-start',
	},
	attributeCheckbox: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '25%',
		minWidth: 300,
		maxWidth: '25%',
		margin: 0,
	},
	occurrenceContainer: {
		marginRight: theme.spacing(2),
		lineHeight: '250%',
		display: 'inline-block',
		width: 75,
		textAlign: 'right'
	}
});

export default dropshippingAttributeSelectStyles;
