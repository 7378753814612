import React, { ReactNode, Component, CSSProperties } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select';
import { WithStyles, withStyles } from '@material-ui/core';
import { FolderType } from '@models/Crawler';
import crawlerFolderSelectStyles from './CrawlerFolderSelectStyles';

interface OptionType {
	label: string;
	value: string;
};

interface State {
	folders: string[];
	selectedFolder: ValueType<OptionType> | null;
}

interface Props extends WithStyles<typeof crawlerFolderSelectStyles> {
	onChange: (folder: FolderType | null) => void;
	selectedFolderType: FolderType | null;
}

class CrawlerFolderSelect extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		const loadedFolders: string[] = [];
		for (const folderType in FolderType) {
			loadedFolders.push(folderType);
		}
		let selectedFolder = null;
		if (this.props.selectedFolderType) {
			selectedFolder = {value: this.props.selectedFolderType, label: this.props.selectedFolderType};
		}

		this.state = {
			folders: loadedFolders,
			selectedFolder: selectedFolder,
		};
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Select
				className={classes.root}
				value={this.state.selectedFolder}
				onChange={this.handleChange.bind(this)}
				options={this.getFolderOptions()}
				placeholder={'Ordner auswählen...'}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
				menuPortalTarget={document.body}
			/>
		);
	}

	private getFolderOptions(): OptionType[] {
		const options: OptionType[] = [];
		this.state.folders.forEach((f: string): void => {
			options.push({value: f, label: FolderType[f as keyof typeof FolderType]});
		});

		return options;
	}

	private handleChange(selectedFolder: ValueType<OptionType>): void {
		this.setState({selectedFolder: selectedFolder});
		if (this.props.onChange) {
			const folderType = (selectedFolder as OptionType).value;
			const folderTypeEnum = this.getEnumfromString(folderType);
			this.props.onChange(folderTypeEnum);
		}
	}

	private getEnumfromString(folderType: string): FolderType {
		const e: FolderType = FolderType[folderType as keyof typeof FolderType];
		return e;
	}
}

export default withStyles(crawlerFolderSelectStyles)(CrawlerFolderSelect);
