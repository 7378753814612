import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dashboardStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	spacer: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
});

export default dashboardStyles;
