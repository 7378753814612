import React, { ReactNode, Component, ChangeEvent } from 'react';
import {
	Button,
	CircularProgress,
	Paper,
	TextField,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';
import RefreshIcon from '@material-ui/icons/Refresh';
import { CategoryRankingModel } from '@models/Category';

import categoryRankingStyles from './CategoryRankingStyles';

import AuthService from '@services/AuthService';

interface State {
	categories: CategoryRankingModel[];
	revenueValue: number;
	visitsValue: number;
	topHundredValue: number;
	loading: boolean;
}

interface CategoriesResponse {
	data: CategoryRankingModel[];
	success: boolean;
	message?: string;
}

class CategoryRanking extends Component<WithStyles, State> {
	private authService: AuthService;

	public constructor(props: WithStyles) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			categories: [],
			revenueValue: 33.33,
			visitsValue: 33.33,
			topHundredValue: 33.33,
			loading: false
		};
	}

	public componentDidMount(): void {
		this.loadCategories();
	}

	private loadCategories(): void {
		this.setState({loading: true});
		this.authService.fetch<CategoriesResponse>('/api/categories/ranking', {
			method: 'POST',
			body: JSON.stringify({
				revenue: this.state.revenueValue,
				visits: this.state.visitsValue,
				topHundred: this.state.topHundredValue
			})
		}).then((response): void => {
			if (response.success) {
				const categories: CategoryRankingModel[] = [];
				let ranking = 1;
				for (const category of response.data) {
					category.ranking = ranking;
					categories.push(category);
					ranking++;
				}
				this.setState({categories: categories});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
			this.setState({loading: false});
		});
	}

	private changeValue(type: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const value = parseFloat(e.target.value);
		if (!isNaN(value) && value >= 0 && value <= 100) {
			if (type === 'revenue') {
				this.setState({revenueValue: value});
			} else if (type === 'visits') {
				this.setState({visitsValue: value});
			} else {
				this.setState({topHundredValue: value});
			}
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const dynamicLookupObject = { 'FASO': 'FASO', 'KM': 'KM' };
		return (
			<Paper>
				<Typography className={classes.header} component="h2" variant="h4">
					{'Kategorie Ranking'}
				</Typography>
				<div className={classes.filterContainer}>
					<div className={classes.filter}>
						<div className={classes.value}>
							<TextField
								type={'number'}
								value={this.state.revenueValue ?? ''}
								label={'Anteil Umsatz in %'}
								onChange={this.changeValue.bind(this, 'revenue')}
								disabled={this.state.loading}
							/>
						</div>
						<div className={classes.value}>
							<TextField
								type={'number'}
								value={this.state.visitsValue ?? ''}
								label={'Anteil Aufrufe in %'}
								onChange={this.changeValue.bind(this, 'visits')}
								disabled={this.state.loading}
							/>
						</div>
						<div className={classes.value}>
							<TextField
								type={'number'}
								value={this.state.topHundredValue ?? ''}
								label={'Anteil Top 100 in %'}
								onChange={this.changeValue.bind(this, 'topHundred')}
								disabled={this.state.loading}
							/>
						</div>
						<div className={classes.buttonContainer}>
							<Button
								variant="outlined"
								color="secondary"
								startIcon={!this.state.loading ? <RefreshIcon color="secondary"/> : null}
								onClick={this.loadCategories.bind(this)}
								disabled={this.state.loading}
							>
							Aktualisieren
								{this.state.loading  && <CircularProgress size={24} className={classes.buttonProgress} />}
							</Button>
						</div>
					</div>
				</div>
				<MaterialTable
					columns={[
						{
							title: '#',
							field: 'ranking' as const,
							cellStyle: {
								maxWidth: '2%'
							},
							headerStyle: {
								maxWidth: '2%'
							},
							filtering: false
						},
						{
							title: 'Kategorie',
							field: 'category' as const,
							filtering: false
						},
						{
							title: 'Hauptkategorie',
							field: 'topLevelCategory' as const,
							filtering: false
						},
						{
							title: 'Typ',
							field: 'type' as const,
							lookup: dynamicLookupObject,
							filtering: true

						},
						{
							title: 'Umsatz',
							field: 'revenue' as const,
							type: 'currency' as const,
							currencySetting: {
								locale: 'DE',
								currencyCode: 'EUR',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							},
							cellStyle: {
								textAlign: 'right'
							},
							headerStyle: {
								textAlign: 'right',
								flexDirection: 'row-reverse'
							},
							filtering: false
						},
						{
							title: 'Aufrufe',
							field: 'visits' as const,
							cellStyle: {
								textAlign: 'right'
							},
							headerStyle: {
								textAlign: 'right',
								flexDirection: 'row-reverse'
							},
							filtering: false,
							render: (category): ReactNode => {
								return (
									<>{category.visits.toLocaleString()}</>
								);
							},
						},
						{
							title: 'Anzahl Top-100 Umsatz Produkte',
							field: 'topHundred' as const,
							cellStyle: {
								textAlign: 'right'
							},
							headerStyle: {
								textAlign: 'right',
								flexDirection: 'row-reverse'
							},
							filtering: false
						},
					]}
					options={{
						search: false,
						filtering: true,
						columnsButton: false,
						exportButton: true,
						paging: true,
						pageSize: 100,
						pageSizeOptions: [25, 50, 75, 100, 200],
						emptyRowsWhenPaging: false,
						draggable: false,
						showTitle: false,
						exportAllData: true
					}}
					style={{
						borderRadius: 0
					}}
					data={ this.state.categories }
					title="Ranking"
					localization={ TableLocalization.localization }
					isLoading= {this.state.loading}
				/>
			</Paper>
		);
	}
}

export default withStyles(categoryRankingStyles)(CategoryRanking);
