import React, { ReactNode, Component } from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	WithStyles,
	withStyles
} from '@material-ui/core';
import {withDialog, Dialog as ConfirmDialog} from 'muibox';

import comparatorProductPreviewStyles from './ComparatorProductPreviewStyles';

import AuthService from '@services/AuthService';

import ComparatorPreview from './ComparatorPreview';
import { Comparator } from '../Common/models/Comparator';
import { AutomatedComparator} from '../Common/models/AutomatedComparator';

interface State {
	comparator: Comparator | null;
	automatedComparator: AutomatedComparator | null;
	saving: boolean;
}

interface Props extends WithStyles<typeof comparatorProductPreviewStyles> {
	dialog: ConfirmDialog;
	closeHandler: () => void;
	editorOpen: boolean;
	comparatorId: string;
	isAutomated: boolean;
}

interface ComparatorResponse extends Response {
	success: boolean;
	data: Comparator;
	message?: string;
}

interface AutomatedComparatorResponse extends Response {
	data: AutomatedComparator;
	success: boolean;
	message?: string;
}

class ComparatorProductPreview extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			comparator: null,
			automatedComparator: null,
			saving: false
		};
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.comparatorId !== this.props.comparatorId) {
			this.setState({comparator: null, automatedComparator: null});
			this.loadComparator(this.props.comparatorId);
			this.loadAutomatedComparator(this.props.comparatorId);
		}
	}

	private loadComparator(comparatorId: string): void {
		if (comparatorId !== 'new') {
			this.authService.fetch<ComparatorResponse>(`/api/comparators/${comparatorId}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					if (response.data) {
						const comparator = response.data;

						this.setState({comparator: comparator});
					}
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private loadAutomatedComparator(comparatorId: string): void {
		if (comparatorId !== 'new') {
			this.authService.fetch<AutomatedComparatorResponse>(`/api/automated-comparators/${comparatorId}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					if (response.data) {
						const automatedComparator = response.data;
						this.setState({automatedComparator: automatedComparator});
					}
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private handleSaveButton(): void {
		this.setState({saving: true});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.props.closeHandler} fullWidth={true} maxWidth={false}>
				<DialogContent className={classes.dialogContent}>
					{this.state.comparator !== null && !this.props.isAutomated &&
						<ComparatorPreview
							comparator={this.state.comparator}
							saveHandler={this.handleSaveButton.bind(this)}
							isSaving={this.state.saving}
							showButtons={false}
							isAutomated={false}
							merge={false} />
					}
					{this.state.automatedComparator !== null && this.props.isAutomated &&
						<ComparatorPreview
							comparator={this.state.automatedComparator}
							saveHandler={this.handleSaveButton.bind(this)}
							isSaving={this.state.saving}
							showButtons={false}
							isAutomated={true}
							merge={false} />
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.closeHandler}>
						Abbrechen
					</Button>
					{ this.state.comparator !== null &&
						<Button href={`/comparator/${this.state.comparator._id}/edit`}>
							Bearbeiten
						</Button>
					}
					{ this.state.automatedComparator !== null &&
						<Button href={`/automated-comparator/${this.state.automatedComparator._id}/edit`}>
							Bearbeiten
						</Button>
					}
				</DialogActions>
			</Dialog>
		);
	}

}

export default withDialog()(withStyles(comparatorProductPreviewStyles)(ComparatorProductPreview));
