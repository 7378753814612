import React, { ReactNode, Component } from 'react';
import { RouteComponentProps } from 'react-router';
import {
	Button,
	WithStyles,
	Fab,
	Tooltip,
	withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MaterialTable from 'material-table';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import AuthService from '@services/AuthService';
import automatedComparatorOverviewStyles from './AutomatedComparatorOverviewStyles';
import { AutomatedComparator} from '@models/AutomatedComparator';
import { withSnackbar, WithSnackbarProps } from 'notistack';

interface AutomatedComparatorsResponse {
	data: AutomatedComparator[];
	success: boolean;
	message?: string;
}

interface State {
	automatedComparators: AutomatedComparator[];
}

interface Props extends WithStyles<typeof automatedComparatorOverviewStyles>, WithSnackbarProps, RouteComponentProps {
	dialog: ConfirmDialog;
}

class AutomatedComparatorOverview extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			automatedComparators: []
		};
	}

	public componentDidMount(): void {
		this.loadAutomatedComparators();
	}

	private loadAutomatedComparators(): void {
		this.setState({automatedComparators: []});
		this.authService.fetch<AutomatedComparatorsResponse>('/api/automated-comparators', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				const automatedComparators = response.data;
				this.setState({automatedComparators: automatedComparators});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private deleteAutomatedComparatorButtonPressed(comparator: AutomatedComparator): void {
		const { dialog } = this.props;

		dialog.confirm({
			title: 'Automatisierten Produktvergleicher Löschen',
			message: `Soll der automatisierte Produktvergleicher "${comparator.nameBackend ? comparator.nameBackend : comparator.name}" (ID: a${comparator._id}) wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'default',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'secondary',
				variant: 'text'
			},
		}).then((): void => {
			this.authService.fetch<{}>(`/api/automated-comparators/${comparator._id}`, {
				method: 'DELETE',
			}).then((_): void => {
				this.loadAutomatedComparators();
				this.props.enqueueSnackbar(`Automatisierter Produktvergleicher mit der ID a${comparator._id} erfolgreich gelöscht!`, {variant: 'success'});
			});
		}).catch((error): void => {
			console.log(`Error while deleting automated comparator: ${error}`);
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<div>
				<MaterialTable
					columns={[
						{
							title: 'ID',
							field: '_id' as const,
							sorting: true,
							cellStyle: {
								fontSize: 18
							},
							render: (comparator): ReactNode => {
								return (
									<div>
										{'a' + comparator._id}
									</div>
								);
							}
						},
						{
							title: 'Name',
							field: 'nameBackend' as const,
							sorting: true,
							cellStyle: {
								fontSize: 18
							},
							render: (comparator): ReactNode => {
								return (
									<div>
										{(comparator.nameBackend !== '') ? comparator.nameBackend : comparator.name}
									</div>
								);
							}
						},
						{
							field: 'buttons' as const,
							cellStyle: {
								textAlign: 'right',
								whiteSpace: 'nowrap'
							},
							render: (comparator): ReactNode => {
								return (
									<div>
										<Button
											href={`/automated-comparator/${comparator._id}/edit`}
											color="primary"
											startIcon={<EditIcon color="primary"/>}
											variant="outlined"
											className={classes.editButton}
										>
											Bearbeiten
										</Button>
										<Button
											onClick={this.deleteAutomatedComparatorButtonPressed.bind(this, comparator)}
											color="primary"
											startIcon={<DeleteIcon color="primary"/>}
											variant="outlined"
											className={classes.deleteButton}
										>
										Löschen
										</Button>
									</div>
								);
							}
						},

					]}
					options={{
						search: false,
						filtering: false,
						columnsButton: false,
						paging: false,
						emptyRowsWhenPaging: false,
						draggable: false,
					}}
					style={{
						borderRadius: 0,
						width: '100%',
					}}
					data={ this.state.automatedComparators }
					title={ 'Automatisierte Produktvergleicher' }

				/>
				<Tooltip
					placement="bottom"
					arrow
					title={'Einen neuen automatisierten Produktvergleicher anlegen'}
				>
					<Fab
						color="primary"
						className={classes.addButton}
						onClick={(): void => {
							this.props.history.push('/automated-comparator/new/edit');
						}}>
						<AddIcon />
					</Fab>
				</Tooltip>
			</div>
		);

	}
}

export default withSnackbar(withDialog()(withStyles(automatedComparatorOverviewStyles)(AutomatedComparatorOverview)));
