import React, { ReactNode, Component } from 'react';
import { Moment } from 'moment';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { WithStyles, withStyles } from '@material-ui/core';

import PaymentSelect from './PaymentSelect';
import TimeRangeSelect from './TimeRangeSelect';
import OverviewTable from './OverviewTable';
import OverviewChart from './OverviewChart';

import reportingStyles from './ReportingStyles';


interface State {
	selectedPaymentMethods: string[];
	fromDate?: Moment;
	toDate?: Moment;
}

class Reporting extends Component<WithStyles<typeof reportingStyles>, State> {

	public constructor(props: WithStyles<typeof reportingStyles>) {
		super(props);
		this.state = {
			selectedPaymentMethods: []
		};
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4">
				Übersicht
				</Typography>
				<div className={classes.timeRange}>
					<TimeRangeSelect onChange={this.handleTimeRangeChanges.bind(this)} />
				</div>
				<PaymentSelect
					onChange={this.handlePaymentMethodsChanged.bind(this)}
					fromDate={this.state.fromDate}
					toDate={this.state.toDate}
				/>
				<OverviewChart
					selectedMethods={this.state.selectedPaymentMethods}
					fromDate={this.state.fromDate}
					toDate={this.state.toDate}
				/>
				<OverviewTable
					selectedMethods={this.state.selectedPaymentMethods}
					fromDate={this.state.fromDate}
					toDate={this.state.toDate}
				/>
			</Paper>
		);
	}

	private handlePaymentMethodsChanged(methods: string[]): void {
		this.setState({selectedPaymentMethods: methods});
	}

	private handleTimeRangeChanges(fromDate: Moment, toDate: Moment): void {
		this.setState({fromDate: fromDate, toDate: toDate});
	}
}

export default withStyles(reportingStyles)(Reporting);
