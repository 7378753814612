import React, { ReactNode, Component, ChangeEvent } from 'react';
import {
	Button,
	CircularProgress,
	IconButton,
	Paper,
	TextField,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import categoryOverviewStyles from './CategoryOverviewStyles';

import AuthService from '@services/AuthService';

import { CategoriesResponse, Category } from '@models/Category';

interface CategoryCsvResponse extends Response {
	success: boolean;
	message?: string;
	data: string;
}

interface State {
	categories: Category[];
	expanded: string[];
	minNumberOfProducts: number;
	maxNumberOfProducts: number;
	loading: boolean;
	filteredCategories: string[];
}

class CategoryOverview extends Component<WithStyles, State> {

	private authService: AuthService;

	public constructor(props: WithStyles) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			categories: [],
			expanded: [],
			minNumberOfProducts: 0,
			maxNumberOfProducts: 10000,
			loading: false,
			filteredCategories: []
		};
	}

	public componentDidMount(): void {
		this.loadCategories();
	}

	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({categories: response.data}, () => {
					this.setState({filteredCategories: this.getFilteredCategories(response.data, this.state.minNumberOfProducts, this.state.maxNumberOfProducts)});
				});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private hasValidChildCategory(category: Category): boolean {
		let valid = false;

		for (const child of category.children) {
			if (child.productCount >= this.state.minNumberOfProducts && child.productCount <= this.state.maxNumberOfProducts) {
				valid = true;
			}
			if (child.children.length > 0) {
				valid = this.hasValidChildCategory(child);
			}
		}

		return valid;
	}

	private treeItemForCategory(category: Category): ReactNode {
		const classes = this.props.classes;

		if ((category.productCount >= this.state.minNumberOfProducts && category.productCount <= this.state.maxNumberOfProducts) || this.hasValidChildCategory(category)) {

			return (
				<div key={category.code}>
					{(category.productCount >= this.state.minNumberOfProducts && category.productCount <= this.state.maxNumberOfProducts) ?
						<TreeItem
							nodeId={category.code}
							key={category.code}
							label={
								<div className={classes.categoryContainer}>
									<span className={classes.categoryTitle}>
										{category.name} - {category.code}
									</span>
									<span className={classes.categoryProductCount}>{`Produkte: ${category.productCount}`}</span>
									<Button
										variant="outlined"
										color="secondary"
										size="small"
										className={classes.newContentButton}
										href={'https://www.moebelix.at' + category.url}
										target={'_blank'}
									>
								Zum Shop
									</Button>
								</div>
							}
						>
							{this.sortedCategories(category.children).map((child: Category): ReactNode => (
								this.treeItemForCategory(child)
							))}
						</TreeItem>
						:
						this.hasValidChildCategory(category) && this.sortedCategories(category.children).map((child: Category): ReactNode => (
							this.treeItemForCategory(child)
						))}
				</div>
			);
		}
	}

	private sortedCategories(categories: Category[]): Category[] {
		return categories.sort((a: Category, b: Category): number => {
			if (a.children.length > 0 && b.children.length === 0) {
				return -1;
			} else if (b.children.length > 0 && a.children.length === 0) {
				return 1;
			} else {
				return (a.name > b.name) ? 1 : -1;
			}
		});
	}

	private toggleExpandAll(): void {
		if (this.state.expanded.length > 0) {
			this.setState({expanded: []});
			window.history.replaceState({expanded: []}, '', window.location.pathname);
		} else {
			const expanded = this.getFlatCategoryIds(this.state.categories).concat(['outside-categories']);
			this.setState({expanded: expanded}, () => {

				window.history.replaceState({expanded: expanded}, '', window.location.pathname);
			});
		}
	}

	private handleTreeChange(_: React.ChangeEvent<{}>, nodes: string[]): void {
		this.setState({expanded: nodes});
		window.history.replaceState({expanded: nodes}, '', window.location.pathname);
	}

	private getFlatCategoryIds(categories: Category[]): string[] {
		let categoryIds: string[] = [];

		for (const category of categories) {
			categoryIds.push(category.code);
			categoryIds = categoryIds.concat(this.getFlatCategoryIds(category.children));
		}

		return categoryIds;
	}

	private getFilteredCategories(categories: Category[], min: number, max: number): string[] {
		const categoryCodes: string[] = [];
		for (const category of categories) {
			if ((category.productCount >= min && category.productCount <= max) || this.hasValidChildCategory(category)) {
				if (category.productCount >= min && category.productCount <= max) {
					categoryCodes.push(category.code);
					categoryCodes.push(...this.getFilteredCategories(category.children, min, max));
				} else {
					categoryCodes.push(...this.getFilteredCategories(category.children, min, max));
				}
			}
		}
		return categoryCodes;
	}

	private changeValue(type: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const value = parseFloat(e.target.value);

		if (!isNaN(value) && value >= 0 && value <= 100000) {
			let filteredCategories = [];
			if (type === 'min') {
				filteredCategories = this.getFilteredCategories(this.state.categories, value, this.state.maxNumberOfProducts);
				this.setState({minNumberOfProducts: value, filteredCategories: filteredCategories});
			} else {
				filteredCategories = this.getFilteredCategories(this.state.categories, this.state.minNumberOfProducts, value);
				this.setState({maxNumberOfProducts: value, filteredCategories: filteredCategories});
			}
		}
	}

	private downloadCSV(): void {
		this.setState({loading: true});
		const minNumberOfProducts = this.state.minNumberOfProducts;
		const maxNumberOfProducts = this.state.maxNumberOfProducts;
		const filteredCategories = this.state.filteredCategories;
		this.authService.fetch<CategoryCsvResponse>('/api/categories/csv', {
			method: 'POST',
			body: JSON.stringify({minNumberOfProducts, maxNumberOfProducts, filteredCategories})
		}).then((response): void => {
			if (response.success) {
				const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
				const fileName = 'Kategorien.csv';

				if (navigator.msSaveBlob) { // IE 10+
					navigator.msSaveBlob(blob, fileName);
				} else {
					const link = document.createElement('a');
					if (link.download !== undefined) {
						const url = URL.createObjectURL(blob);
						link.setAttribute('href', url);
						link.setAttribute('download', fileName);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
			this.setState({loading: false});
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Kategorieübersicht'}
				</Typography>

				<div className={classes.actionsContainer}>
					<div className={classes.filterContainer}>
						<Button
							className={classes.expandAllButton}
							variant="contained"
							color="primary"
							onClick={this.toggleExpandAll.bind(this)}
							disabled={this.state.loading}
						>
							Alle Ein-/Ausklappen
						</Button>
						<div className={classes.value}>
							<TextField
								type={'number'}
								value={this.state.minNumberOfProducts ?? ''}
								label={'Anzahl Produkte von:'}
								onChange={this.changeValue.bind(this, 'min')}
								disabled={this.state.loading}
							/>
						</div>
						<div className={classes.value}>
							<TextField
								type={'number'}
								value={this.state.maxNumberOfProducts ?? ''}
								label={'Anzahl Produkte bis:'}
								onChange={this.changeValue.bind(this, 'max')}
								disabled={this.state.loading}
							/>
						</div>
					</div>
					{this.state.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}
					<Tooltip title={
						<span>CSV Export</span>
					}>
						<span>
							<IconButton disabled={this.state.loading} color="primary" onClick={this.downloadCSV.bind(this)}>
								<SaveAltIcon />
							</IconButton>
						</span>
					</Tooltip>

				</div>
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					expanded={this.state.expanded}
					onNodeToggle={this.handleTreeChange.bind(this)}
				>
					{this.sortedCategories(this.state.categories).map((category: Category): ReactNode => (
						this.treeItemForCategory(category)
					))}
				</TreeView>
			</Paper>
		);
	}
}

export default withStyles(categoryOverviewStyles)(CategoryOverview);
