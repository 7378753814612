// eslint-disable-next-line
/// <reference path="../dts/iframe-resizer-react.d.ts"/>
import React, { ReactNode, Component } from 'react';
import IframeResizer from 'iframe-resizer-react';
import {
	CircularProgress,
	Tabs,
	Tab,
	Box,
	Button,
	IconButton,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import InfoIcon from '@material-ui/icons/Info';

import AuthService from '@services/AuthService';
import { Comparator } from '@models/Comparator';
import { AutomatedComparator } from '@models/AutomatedComparator';
import { CmsContent } from '@models/CmsContent';

import comparatorPreviewStyles from './ComparatorPreviewStyles';

/* eslint import/no-webpack-loader-syntax: off */
import desktopIframeHTML from '!raw-loader!./iframe.html.txt';
import mobileIframeHTML from '!raw-loader!./iframe-mobile.html.txt';

interface PreviewComparators {
	data: JsonComparator[];
	success: boolean;
	message?: string;
}

export interface JsonComparator {
	version: number;
	data: ComparatorData | null;
	title: string;
	comparatorId: string;
	endpoint: string;
	position: string;
	fullpage: boolean;
	sortedBy: number;
	sortWeight: number;
}

interface ComparatorData {
	products: ComparatorProduct[];
	attributes: ComparatorAttribute[];
	cmsContents?: CmsContent[];
}

interface ComparatorProduct {
	productId: string;
	pageViews: number;
	attributes: ProductAttribute;
	backupFormattedPrice: string;
	backupPrice: string;
	backupImage: string;
	backupLink: string;
	priceIndex?: number;
}

interface ProductAttribute {
	[key: string]: string;
}

interface ComparatorAttribute {
	name: string;
	key: string;
}

interface State {
	loading: boolean;
	desktopBlobURL: string;
	mobileBlobURL: string;
	selectedTab: number;
	comparatorPage: number;
	comparatorPages: number;
	currentSplitComparator: JsonComparator | null;
}

interface Props extends WithStyles<typeof comparatorPreviewStyles> {
	comparator: Comparator | AutomatedComparator;
	saveHandler: Function;
	isSaving: boolean;
	saveButton?: string|undefined;
	showButtons: boolean;
	isAutomated: boolean;
	merge: boolean;
	handleMergeInactiveSaveButton?: Function | null;
}

class ComparatorPreview extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			loading: false,
			desktopBlobURL: '',
			mobileBlobURL: '',
			selectedTab: 0,
			comparatorPage: 1,
			comparatorPages: 1,
			currentSplitComparator: null
		};
	}

	public componentDidMount(): void {
		if (this.props.comparator._id !== 'new') {
			this.loadPreview();
		}
	}

	private handleClick(): void {
		this.setState({comparatorPage: 1},
			() => this.loadPreview()
		);
	}

	private handlePaginationLeftClick(_: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		if (this.state.comparatorPage > 1) {
			let currentComparatorPage = this.state.comparatorPage;
			currentComparatorPage--;
			this.setState({comparatorPage: currentComparatorPage}, () => {
				this.loadPreview();
			});
		}
	}

	private async handlePaginationRightClick(_: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
		if (this.state.comparatorPage < this.state.comparatorPages) {
			let currentComparatorPage = this.state.comparatorPage;
			currentComparatorPage++;
			this.setState({comparatorPage: currentComparatorPage}, () => {
				this.loadPreview();
			});
		}
	}

	private async loadComparatorPreviewData(): Promise<JsonComparator[] | null> {
		const jsonComparatorData: JsonComparator[] = [];

		await this.authService.fetch<PreviewComparators>('/api/comparators/preview-comparators', {
			method: 'POST',
			body: JSON.stringify({comparator: this.props.comparator, isAutomated: this.props.isAutomated})
		}).then((response): void => {
			if (response.success && response.data) {
				for (const data of response.data) {
					if (data && data.data && data.data.products.length > 1) {
						jsonComparatorData.push(data);
					}
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unknown Error');
			}
		});

		return jsonComparatorData;
	}

	private async loadPreview(): Promise<void> {
		if (this.comparatorIsComplete()) {
			this.setState({loading: true, desktopBlobURL: '', mobileBlobURL: '', selectedTab: 0});

			const comparators: JsonComparator[] = [];
			let comparatorData: JsonComparator = {
				version: 2,
				data: null,
				title: '',
				comparatorId: '',
				endpoint: '/api/comparators/preview-endpoint',
				position: '',
				fullpage: false,
				sortedBy: 1,
				sortWeight: 1
			};

			let comparatorPreviewData = null;
			let fakeProductPlaceholder = '';

			if (this.props.comparator.splitDetail && this.props.comparator.splitDetail.levels.length > 0) {

				try {
					comparatorPreviewData = await this.loadComparatorPreviewData();
				} catch (error) {
					console.log(`Error while fetching comparator split preview data: ${error}`);
				}
				if (comparatorPreviewData) {
					comparators.push(...comparatorPreviewData);
				}

				if (comparators[this.state.comparatorPage - 1]) {
					comparatorData = comparators[this.state.comparatorPage - 1];
					if (comparatorData && comparatorData.data && comparatorData.data.products && comparatorData.data.products[0]) {
						fakeProductPlaceholder = comparatorData.data.products[0].productId;
					}
					this.setState({currentSplitComparator: comparatorData});
				}

			} else {

				try {
					comparatorPreviewData = await this.loadComparatorPreviewData();
				} catch (error) {
					console.log(`Error while fetching comparator preview data: ${error}`);
				}
				if (comparatorPreviewData) {
					comparators.push(...comparatorPreviewData);
				}

				if (comparators[0]) {
					comparatorData = comparators[0];
					if (comparatorData && comparatorData.data && comparatorData.data.products && comparatorData.data.products[0]) {
						fakeProductPlaceholder = comparatorData.data.products[0].productId;
					}
					this.setState({currentSplitComparator: comparatorData});
				}
			}

			if (comparatorData) {
				const json = JSON.stringify(comparatorData)
					.replace(/'/g, '\\u0027');

				const desktopHtml = desktopIframeHTML
					.replace('__JSON_PLACEHOLDER__', `[${json}]`)
					.replace('__FAKE_PRODUCT_PLACEHOLDER__', fakeProductPlaceholder)
					.replace('__BASE_PLACEHOLDER__', window.location.origin);
				const desktopBlob = new Blob([desktopHtml], {type: 'text/html'});
				const desktopBlobURL = URL.createObjectURL(desktopBlob);

				let mobileBlobURL = '';

				if (!this.props.comparator.fullPage) {
					const mobileHtml = mobileIframeHTML
						.replace('__JSON_PLACEHOLDER__', `[${json}]`)
						.replace('__FAKE_PRODUCT_PLACEHOLDER__', fakeProductPlaceholder)
						.replace('__BASE_PLACEHOLDER__', window.location.origin);
					const mobileBlob = new Blob([mobileHtml], {type: 'text/html'});
					mobileBlobURL = URL.createObjectURL(mobileBlob);
				}

				this.setState({loading: false, desktopBlobURL: desktopBlobURL, mobileBlobURL: mobileBlobURL, selectedTab: 0, comparatorPages: comparators.length});
			} else {
				this.setState({loading: false, desktopBlobURL: '', mobileBlobURL: '', selectedTab: 0});
			}
		} else {
			this.setState({loading: false, desktopBlobURL: '', mobileBlobURL: '', selectedTab: 0});
		}
	}

	private comparatorIsComplete(): boolean {
		if (
			(this.props.comparator.name !== '') &&
			(this.props.comparator.products.length > 0) &&
			(this.props.comparator.attributes.length > 0)
		) {
			return true;
		}

		return false;
	}

	private handleTabChange(_: React.ChangeEvent<{}>, newValue: number): void {
		this.setState({selectedTab: newValue});
	}

	private handleSaveButton(_: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		this.props.saveHandler();
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (this.state.loading) {
			return (
				<div className={classes.container}>
					<CircularProgress className={classes.loadingIndicator}/>
				</div>
			);
		} else if (!this.state.loading && !this.comparatorIsComplete()) {
			return (
				<div className={classes.container}>
					<Typography>Die Vorschau kann erst geladen werden wenn alle erforderlichen Felder ausgefüllt wurden.</Typography>
				</div>
			);
		} else if (!this.state.loading && this.comparatorIsComplete() && this.state.desktopBlobURL === '') {
			return (
				<div className={classes.container}>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleClick.bind(this)}
					>
						Vorschau laden
					</Button>
				</div>
			);
		}

		return (
			<div className={classes.container}>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<Tabs value={this.state.selectedTab} onChange={this.handleTabChange.bind(this)}>
						<Tab label="Desktop" />
						{this.state.mobileBlobURL !== '' &&
					<Tab label="Mobile" />
						}
					</Tabs>

					<div className={classes.comparatorPreviewCountContainer}>
						<Typography style={{display: 'inline-block'}}>
								Anzahl Produkte im Vergleicher: {this.state.currentSplitComparator && this.state.currentSplitComparator.data && this.state.currentSplitComparator.data.products.length}
						</Typography>
						{this.state.currentSplitComparator && this.state.currentSplitComparator.data && this.state.currentSplitComparator.data.products.length > 13 &&
						<div className={classes.numberOfProductsTooltip}>
							<Tooltip title={
								<span>In der Vorschau werden maximal 13 Produkte angezeigt</span>
							}>
								<InfoIcon color="primary" />
							</Tooltip>
						</div>
						}
					</div>
					<div>
						<IconButton onClick={this.handlePaginationLeftClick.bind(this)}>
							<KeyboardArrowLeftIcon color="primary" />
						</IconButton>

						<Typography style={{display: 'inline-flex'}}>
								Vergleicher {this.state.comparatorPage} von {this.state.comparatorPages}
						</Typography>

						<IconButton onClick={this.handlePaginationRightClick.bind(this)}>
							<KeyboardArrowRightIcon color="primary" />
						</IconButton>
					</div>
				</div>

				<Typography
					component="div"
					role="tabpanel"
					hidden={this.state.selectedTab !== 0}
				>
					<Box p={3}>
						<IframeResizer
							src={this.state.desktopBlobURL}
							checkOrigin={false}
							title="Desktop Vorschau"
							className={classes.desktopPreview}
						/>
					</Box>
				</Typography>
				{this.state.mobileBlobURL !== '' &&
					<Typography
						component="div"
						role="tabpanel"
						hidden={this.state.selectedTab !== 1}
					>
						<Box p={3}>
							<IframeResizer
								src={this.state.mobileBlobURL}
								checkOrigin={false}
								title="Mobile Vorschau"
								className={classes.mobilePreview}
							/>
						</Box>
					</Typography>
				}
				{this.props.showButtons &&
					<React.Fragment>
						<div className={classes.buttonContainer}>
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleClick.bind(this)}
								className={classes.refreshButton}
							>
							Vorschau aktualisieren
							</Button>
							{!this.props.saveButton && !this.props.merge &&
							<Button
								variant="contained"
								color="primary"
								onClick={this.handleSaveButton.bind(this)}
								disabled={this.props.isSaving}
								startIcon={<SaveIcon />}
								className={classes.saveButton}
							>
								Vergleicher Speichern
							</Button>
							}
							{this.props.merge &&
							<>
								{this.props.handleMergeInactiveSaveButton &&
								<Button
									variant="contained"
									color="primary"
									onClick={this.props.handleMergeInactiveSaveButton.bind(this)}
									disabled={this.props.isSaving}
									startIcon={<SaveIcon />}
									className={classes.saveInactiveButton}
								>
								Vergleicher Speichern
								</Button>
								}
							</>
							}
						</div>
					</React.Fragment>
				}
			</div>
		);
	}
}

export default withStyles(comparatorPreviewStyles)(ComparatorPreview);
