import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorDiagramEditorStyles = (theme: Theme): any => createStyles({
	appBar: {
		position: 'relative',
	},
	toolbar: {
		display: 'flex', 
		flexDirection: 'column'
	},
	toolbarFirstRow: {
		display: 'flex',
		alignSelf: 'normal',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: theme.spacing(1)
	},
	toolbarSecondRow: {
		display: 'flex',
		alignSelf: 'normal',
		marginBottom: theme.spacing(2)
	},
	toolbarButtonsContainer: {
		display: 'flex'
	},
	questionSelectContainer: {
		width: '50%'
	},
	addQuestionButtonContainer: {
		display: 'flex',
		width: '50%'
	},
	addQuestionButton: {
		alignSelf: 'end',
		marginLeft: 'auto',
		height: 38
	},
	saveEditorButton: {
		alignSelf: 'end',
		height: 38,
		marginLeft: theme.spacing(2)
	}
});

export default advisorDiagramEditorStyles;