import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorEditorGeneralSettingsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	textInput: {
		marginTop: theme.spacing(2),
	},
	folderSelectContainer: {
		width: '100%',
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2)
	},
	folderSelect: {
		marginTop: theme.spacing(1),
	},
	versionSelectContainer: {
		display: 'flex',
		marginTop: theme.spacing(1),
		width: '100%',
	},
	versionSelectFormControl: {
		width: '100%',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	versionSelect: {
		width: '100%',
	},
	versionLoadButton: {
		marginLeft: theme.spacing(2),
		minWidth: 150
	},
	deleteButton: {
		marginTop: theme.spacing(2),
		color: theme.palette.error.main
	}
});

export default advisorEditorGeneralSettingsStyles;
