import React, { ReactNode, Component } from 'react';

import {
	CircularProgress,
	FormControlLabel,
	Checkbox,
	Typography,
	Tooltip,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	WithStyles,
	withStyles
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';

import ProductsService from '@services/ProductsService';
import { Product, ProductStatus, ProductInComparator } from '@models/Product';

import comparatorProductSelectStyles from './ComparatorProductSelectStyles';

enum ProductSort {
	Name = 0,
	Price
}

interface State {
	loadedProducts: Product[];
	selectedProducts: Product[];
	loading: boolean;
	sort: ProductSort;
	hideAlreadyUsedProducts: boolean;
}

interface Props extends WithStyles<typeof comparatorProductSelectStyles> {
	onChange: (selectedProducts: Product[]) => void;
	selectedCategoryCode: string | null;
	productsToDisplay?: Product[] | undefined;
	selectedProductCodes: string[];
	comparatorId: string;
	loadUsedInComparators: boolean;
	disabled: boolean;
}

class ComparatorProductSelect extends Component<Props, State> {
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.productsService = new ProductsService();

		this.state = {
			loadedProducts: [],
			selectedProducts: [],
			loading: false,
			sort: ProductSort.Name,
			hideAlreadyUsedProducts: true
		};
	}

	public componentDidMount(): void {
		this.loadProducts();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (
			prevProps.selectedCategoryCode !== this.props.selectedCategoryCode ||
			(prevProps.productsToDisplay !== this.props.productsToDisplay)
		) {
			this.loadProducts();
		}
	}

	private async loadProducts(): Promise<void> {
		if (this.props.selectedCategoryCode !== '') {
			this.setState({loadedProducts:[], loading: true});
			let products: Product[] = [];

			if (this.props.selectedCategoryCode) {
				products = await this.productsService.loadProductsInCategory(this.props.selectedCategoryCode, this.props.loadUsedInComparators);
			} else if (this.props.productsToDisplay) {
				products = this.props.productsToDisplay;
			}

			for (const selectedProductCode of this.props.selectedProductCodes) {
				if (products.find((p) => p.productCode === selectedProductCode) === undefined) {
					let categoryCodes: string[] = [];
					if (this.props.selectedCategoryCode){
						categoryCodes = [this.props.selectedCategoryCode];
					}
					const dummyProduct: Product = {
						_id: '',
						categoryCodes: categoryCodes,
						images: [],
						campaigns: [],
						productCode: selectedProductCode,
						name: 'PRODUKT NICHT GEFUNDEN',
						status: ProductStatus.Offline,
						price: 0,
						attributes: [],
						usedInComparators: []
					};

					products.push(dummyProduct);
				}
			}

			// Filter offline products that are not already selected
			products = products.filter(p => p.status === ProductStatus.Online || this.props.selectedProductCodes.includes(p.productCode));

			const selectedProducts = products.filter((p): boolean => {
				return this.props.selectedProductCodes.includes(p.productCode);
			});

			this.setState({loadedProducts: products, selectedProducts: selectedProducts, loading: false});
			this.notifyParent(selectedProducts);
		} else {
			this.setState({loadedProducts:[], loading: false});
		}
	}

	private attentionTooltip(product: Product): ReactNode {
		let comparators: ProductInComparator[] = [];

		if (product.usedInComparators) {
			comparators = product.usedInComparators;
		}

		comparators = comparators.filter((c) => c._id !== this.props.comparatorId);

		if (comparators.length === 0 || !this.props.loadUsedInComparators) {
			return (<React.Fragment />);
		}

		const tooltips: string[] = ['Bereits verwendet in:'];

		for (const comparator of comparators) {
			if (comparator.isAutomated) {
				tooltips.push(`a${comparator._id}: ${comparator.name}`);
			} else {
				tooltips.push(`${comparator._id}: ${comparator.name}`);
			}
		}

		return (
			<Tooltip title={
				<span dangerouslySetInnerHTML={{__html:tooltips.join('<br />')}} />
			}>
				<WarningIcon />
			</Tooltip>
		);
	}

	private offlineTooltip(product: Product): ReactNode {
		if (product.status === ProductStatus.Online) {
			return (<React.Fragment />);
		}

		return (
			<Tooltip title={
				<span>Produkt ist Offline</span>
			}>
				<ReportIcon />
			</Tooltip>
		);
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleSortChange(event: any): void {
		this.setState({sort: event.target.value});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const products = this.state.loadedProducts;
		products.sort((a: Product, b: Product): number => {
			if (this.state.sort === ProductSort.Name) {
				if (a.name.toUpperCase() < b.name.toUpperCase()) {
					return -1;
				} else if (a.name.toUpperCase() > b.name.toUpperCase()) {
					return 1;
				}

				return 0;
			} else if (this.state.sort === ProductSort.Price) {
				return a.price - b.price;
			}

			return 0;
		});

		return (
			<div className={classes.container}>
				{this.state.loading &&
					<CircularProgress className={classes.loadingIndicator}/>
				}
				{!this.state.loading && this.state.loadedProducts.length > 0 &&
					<FormControl className={classes.sortSelect}>
						<InputLabel id="sort-label">Sortierung</InputLabel>
						<Select
							labelId="sort-label"
							value={this.state.sort}
							onChange={(event): void => {this.handleSortChange(event);}}
						>
							<MenuItem value={ProductSort.Name}>Name A -&gt; Z</MenuItem>
							<MenuItem value={ProductSort.Price}>Preis &euro; -&gt; &euro; &euro;</MenuItem>
						</Select>
					</FormControl>
				}
				{!this.state.loading && this.state.loadedProducts.length > 0 && this.props.loadUsedInComparators &&
					<FormControlLabel
						className={classes.productsInCheckbox}
						control={
							<Checkbox
								checked={this.state.hideAlreadyUsedProducts}
								color="primary"
								onChange={(event): void => {this.setState({hideAlreadyUsedProducts: event.target.checked});}}
							/>
						}
						label="Produkte verstecken die bereits in einem Produktvergleicher vorkommen"
					/>
				}
				{!this.state.loading && this.state.loadedProducts.length > 0 &&
					<div className={classes.elementContainer}>
						{this.state.loadedProducts.map((product: Product): ReactNode => (
							this.renderProduct(product)
						))}
					</div>
				}
				{!this.state.loading && this.state.loadedProducts.length === 0 && this.props.selectedCategoryCode === '' &&
					<Typography>{'Bitte eine Kategorie auswählen!'}</Typography>
				}
				{!this.state.loading && this.state.loadedProducts.length === 0 && this.props.selectedCategoryCode !== '' &&
					<Typography>{'Keine Produkte gefunden'}</Typography>
				}
			</div>
		);
	}

	private renderProduct(product: Product): ReactNode {
		const classes = this.props.classes;

		if (
			this.props.loadUsedInComparators &&
			this.state.hideAlreadyUsedProducts &&
			product.usedInComparators &&
			!this.state.selectedProducts.find((p: Product): boolean => product.productCode === p.productCode) &&
			product.usedInComparators.find(c => c._id !== this.props.comparatorId)
		) {
			return (<React.Fragment key={product.productCode} />);
		}

		return (
			<FormControlLabel
				key={product.productCode}
				className={product.status === ProductStatus.Online ? classes.checkboxContainer : (classes.checkboxContainer + ' ' + classes.offlineProduct)}
				classes={{
					label: classes.label,
				}}
				control={
					<Checkbox
						checked={this.state.selectedProducts.find((p: Product): boolean => product.productCode === p.productCode) !== undefined}
						color="primary"
						classes={{
							checked: classes.checked,
						}}
						onChange={(event): void => {this.handleCheckboxChange(product, event);}}
						disabled={this.props.disabled}
					/>
				}
				label={
					<div className={classes.labelContainer}>
						<Tooltip
							placement="right"
							title={
								<React.Fragment>
									{product.images.length > 0 &&
										<img src={`${product.images[0]}?maxH=280&maxW=280&upscale=false`} alt=""/>
									}
								</React.Fragment>
							}
						>
							<span className={classes.imageContainer}>
								{product.images.length &&
									<img src={`${product.images[0]}?maxH=28&maxW=28&upscale=false`} alt=""/>
								}
							</span>
						</Tooltip>
						<span className={classes.codeContainer}>{product.productCode}</span>
						<span className={classes.nameContainer}>{product.name}</span>
						<span className={classes.priceContainer}>
							{product.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
						</span>
						<span className={classes.attentionContainer}>{this.attentionTooltip(product)}</span>
						<span className={classes.attentionContainer}>{this.offlineTooltip(product)}</span>
					</div>
				}
			/>
		);
	}

	private handleCheckboxChange(product: Product, event: React.ChangeEvent<HTMLInputElement>): void {
		let selectedProducts = this.state.selectedProducts;

		if (event.target.checked) {
			selectedProducts.push(product);
		} else {
			selectedProducts = selectedProducts.filter((p: Product): boolean => {
				return p.productCode !== product.productCode;
			});
		}

		this.setState({selectedProducts: selectedProducts}, () => {
			this.notifyParent(selectedProducts);
		});
	}

	private notifyParent(selectedProducts: Product[]): void {
		const json = JSON.stringify(selectedProducts);
		const products: Product[] = JSON.parse(json);

		this.props.onChange(products);
	}
}

export default withStyles(comparatorProductSelectStyles)(ComparatorProductSelect);
