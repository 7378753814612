import React, { ReactNode, Component } from 'react';
import moment, { Moment } from 'moment';

import GetAppIcon from '@material-ui/icons/GetApp';
import {
	Button,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';

import timeRangeSelectStyles from './TimeRangeSelectStyles';

interface Props extends WithStyles<typeof timeRangeSelectStyles> {
	onChange?: Function;
	onDownload?: Function;
	isChronic?: boolean;
}

interface State {
	fromDate: Moment;
	toDate: Moment;
}

class TimeRangeSelect extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			fromDate: moment().subtract(7, 'days').startOf('day'),
			toDate: moment()
		};

		if (this.props.onChange) {
			this.props.onChange(this.state.fromDate, this.state.toDate);
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.root}>
				<DateTimePicker
					className={classes.datePicker}
					value={this.state.fromDate}
					onChange={this.handleFromDateChange.bind(this)}
					ampm={false}
					disableFuture={true}
					label={'Von'}
					format={'DD. MM. YYYY, HH:mm'}
					showTodayButton={true}
					cancelLabel={'ABBRECHEN'}
					todayLabel={'HEUTE'}
					maxDate={this.state.toDate}
				/>
				<DateTimePicker
					className={classes.datePicker}
					value={this.state.toDate}
					onChange={this.handleToDateChange.bind(this)}
					ampm={false}
					disableFuture={true}
					label={'Bis'}
					format={'DD. MM. YYYY, HH:mm'}
					showTodayButton={true}
					cancelLabel={'ABBRECHEN'}
					todayLabel={'HEUTE'}
					minDate={this.state.fromDate}
				/>
				{(this.props.isChronic) ? this.getDownloadButton(): null}
			</div>
		);
	}

	private getDownloadButton(): ReactNode {
		const classes = this.props.classes;

		return (
			<Tooltip title="Exportieren als CSV">
				<Button
					onClick={(this.props.onDownload) ? this.props.onDownload.bind(this) : null}
					className={classes.downloadButton}
					color="primary"
					startIcon={<GetAppIcon color="primary"/>}
					variant="outlined"
				>
				Download
				</Button>
			</Tooltip>
		);
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleFromDateChange(date: any): void {
		const momentDate = date as Moment;
		this.setState({fromDate: momentDate});

		if (this.props.onChange) {
			this.props.onChange(momentDate, this.state.toDate);
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleToDateChange(date: any): void {
		const momentDate = date as Moment;
		this.setState({toDate: momentDate});

		if (this.props.onChange) {
			this.props.onChange(this.state.fromDate, momentDate);
		}
	}

}

export default withStyles(timeRangeSelectStyles)(TimeRangeSelect);
