import React, { ReactNode, Component } from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import splitLevelPreviewStyles from './SplitLevelPreviewStyles';

import AutomatedComparatorProducts from '../AutomatedComparator/AutomatedComparatorProducts';

import { Product } from '../Common/models/Product';
import { Comparator } from '../Common/models/Comparator';
import { AutomatedComparator} from '../Common/models/AutomatedComparator';
import WarningIcon from '@material-ui/icons/Warning';

interface Props extends WithStyles<typeof splitLevelPreviewStyles> {
	splittedComparators: Comparator[] | AutomatedComparator[];
	isAutomated: boolean;
	levelIndex: number | null;
	closeHandler: () => void;
	editorOpen: boolean;
	mainComparatorProducts: Product[];
	isPriceSplitPreview: boolean;
}

class SplitLevelPreview extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	private getToRemoveProducts(): Product[] {
		const productsToRemove: Product[] = [];
		for (const mainComparatorProduct of this.props.mainComparatorProducts) {
			let isProductToRemove = true;

			for (const splittedComparator of this.props.splittedComparators) {
				if ((splittedComparator.products.filter((product: Product): boolean => product.productCode === mainComparatorProduct.productCode)).length > 0) {
					isProductToRemove = false;
					break;
				}
			}
			if (isProductToRemove) {
				productsToRemove.push(mainComparatorProduct);
			}
		}

		return productsToRemove;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.props.closeHandler} fullWidth={true} maxWidth={false}>
				<DialogTitle>
					{this.props.isPriceSplitPreview ? 'Aufteilung nach Preis' : 'Aufteilungsebene: ' + this.props.levelIndex}
				</DialogTitle>
				<DialogContent className={classes.dialogContent}>
					{this.props.isAutomated && (this.props.splittedComparators as AutomatedComparator[]).map((automatedComparator: AutomatedComparator, index: number) =>
						<div key={index}>
							<div className={classes.comparatorHeadline}>
								<Typography variant="h6">{automatedComparator._id !== 'new' ? 'Produktvergleicher: a' + automatedComparator._id + 's' + index : 'Produktvergleicher: a(ID)s' + index}</Typography>
								{automatedComparator.products.length < 3 &&
									<span className={classes.warning}>
										<Tooltip title={
											<span>Enthält weniger als 3 Produkte.</span>
										}>
											<WarningIcon/>
										</Tooltip>
									</span>
								}
							</div>
							<div className={classes.splitComparatorProducts}>
								<AutomatedComparatorProducts
									comparator={automatedComparator}
									products={automatedComparator.products}
									loading={false}
									refreshed={true}
								/>
							</div>
						</div>
					)}
					{!this.props.isAutomated && (this.props.splittedComparators as Comparator[]).map((comparator: Comparator, index: number) =>
						<div key={index}>
							<div className={classes.comparatorHeadline}>
								<Typography variant="h6">{comparator._id !== 'new' ? 'Produktvergleicher: ' + comparator._id + 's' + index : 'Produktvergleicher: (ID)s' + index}</Typography>
								{comparator.products.length < 3 &&
									<span className={classes.warning}>
										<Tooltip title={
											<span>Enthält weniger als 3 Produkte.</span>
										}>
											<WarningIcon/>
										</Tooltip>
									</span>
								}
							</div>
							<div className={classes.splitComparatorProducts}>
								<AutomatedComparatorProducts
									comparator={comparator}
									products={comparator.products}
									loading={false}
									refreshed={true}
								/>
							</div>
						</div>
					)}
					{this.getToRemoveProducts().length > 0 &&
						<>
							<div className={classes.comparatorHeadlineRest}>
								<Typography variant="h6">{'Rest-Produkte werden entfernt:'}</Typography>
							</div>
							<div className={classes.splitComparatorProducts}>
								<AutomatedComparatorProducts
									comparator={null}
									products={this.getToRemoveProducts()}
									loading={false}
									refreshed={true}
								/>
							</div>
						</>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.closeHandler}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withStyles(splitLevelPreviewStyles)(SplitLevelPreview);
