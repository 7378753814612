import React, {ReactElement, FC, ReactNode} from 'react';
import {
	Box,
	Button,
	IconButton,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { QuestionNodeModel } from './QuestionNodeModel';
import questionNodeWidgetStyles from './QuestionNodeWidgetStyles';
import { PortWidget } from '@projectstorm/react-diagrams';
import { AdvisorAnswer, AdvisorQuestion, AdvisorQuestionType, dummyHistoryBased } from '@common/models/Advisor';
import { PortType, createPortModelName } from './PortFactory';
import ClearIcon from '@material-ui/icons/Clear';
import FlagIcon from '@material-ui/icons/Flag';

interface Props extends WithStyles<typeof questionNodeWidgetStyles> {
	node: QuestionNodeModel;
}

const QuestionNodeWidget: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const currentQuestion = (props.node.extras.question as AdvisorQuestion);

	function getQuestionText(): ReactNode {
		const fullQuestionText = currentQuestion.question;

		if (fullQuestionText.length > 55) {
			const shortenedQuestionText = fullQuestionText.slice(0, 52) + '...';
			return (
				<Tooltip title={fullQuestionText}>
					<span>
						<Box className={classes.question} onDoubleClick={(): void => props.node.extras.handleEditQuestionButtonClicked(currentQuestion)}>
							<Typography className={classes.questionText} variant="subtitle2">
								{shortenedQuestionText}
							</Typography>
						</Box>
					</span>
				</Tooltip>
			);
		} else {
			return (
				<Box className={classes.question} onDoubleClick={(): void => props.node.extras.handleEditQuestionButtonClicked(currentQuestion)}>
					<Typography className={classes.questionText} variant="subtitle2">
						{fullQuestionText}
					</Typography>
				</Box>
			);
		}
	}

	function getQuestion(): ReactNode {
		return (
			<div className={classes.questionRow}>
				<div className={classes.questionInPortContainer}>
					{currentQuestion._id && currentQuestion._id === props.node.extras.startingQuestionId && 
					<Tooltip title='Startfrage' placement='right'>
						<span>
							<FlagIcon color="primary" className={classes.startingQuestionIcon}/>
						</span>
					</Tooltip>
					}
					<PortWidget className={classes.questionInPort} name={createPortModelName(PortType.QUESTIONIN, currentQuestion._id ?? '')} node={props.node} />
				</div>
				{getQuestionText()}
				{(currentQuestion.questionType === AdvisorQuestionType.MULTISELECT || currentQuestion.questionType === AdvisorQuestionType.PRICESLIDER) &&
					<div className={classes.questionOutPortContainer}>
						{currentQuestion.followingQuestion &&
						<Tooltip title='Verknüpfung entfernen' placement='right'>
							<span>
								<IconButton color="primary" className={classes.removeLinkButton} onClick={(event): void => handleRemoveQuestionOutLink(event)}>
									<ClearIcon />
								</IconButton>
							</span>
						</Tooltip>
						}
						<PortWidget className={classes.questionOutPort} name={createPortModelName(PortType.QUESTIONOUT, currentQuestion._id ?? '')} node={props.node} />
					</div>}
			</div>
		);
	}

	function getAnswerText(advisorAnswer: AdvisorAnswer): ReactNode {
		const fullAnswerText = advisorAnswer.answer;

		if (fullAnswerText.length > 65) {
			const shortenedAnswerText = fullAnswerText.slice(0, 62) + '...';
			return (
				<Tooltip title={fullAnswerText}>
					<span>
						<Box className={classes.answer} onDoubleClick={(): void => props.node.extras.handleEditAnswerButtonClicked(currentQuestion, advisorAnswer)}>
							<Typography className={classes.answerText} variant="subtitle2">
								{shortenedAnswerText}
							</Typography>
						</Box>
					</span>
				</Tooltip>
			);
		} else {
			return (
				<Box className={classes.answer} onDoubleClick={(): void => props.node.extras.handleEditAnswerButtonClicked(currentQuestion, advisorAnswer)}>
					<Typography className={classes.answerText} variant="subtitle2">
						{fullAnswerText}
					</Typography>
				</Box>
			);
		}
	}

	function handleRemoveAnswerOutLink(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, answerToChange: AdvisorAnswer): void {
		event.stopPropagation();
		const questionToChange = currentQuestion;
		for (const answer of questionToChange.answers) {
			if (answer._id === answerToChange._id) {
				answer.followingQuestion = undefined;
				answer.historyBased = dummyHistoryBased;
			}
		}
		props.node.extras.handleQuestionChange(questionToChange);
	}

	function handleRemoveQuestionOutLink(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		event.stopPropagation();
		const questionToChange = currentQuestion;
		questionToChange.followingQuestion = undefined;
		props.node.extras.handleQuestionChange(questionToChange);
	}

	function getAnswer(advisorAnswer: AdvisorAnswer): ReactNode {
		return (
			<div key={advisorAnswer._id ?? 'new'} className={classes.answerRow}>
				{getAnswerText(advisorAnswer)}
				{currentQuestion.questionType === AdvisorQuestionType.SINGLESELECT &&
					<div className={classes.answerOutPortContainer}>
						{(advisorAnswer.followingQuestion ||  (advisorAnswer.historyBased && (advisorAnswer.historyBased.defaultFollowingQuestionId || advisorAnswer.historyBased.historyBasedFollowingQuestions.length > 0))) &&
						<Tooltip title='Verknüpfung entfernen' placement='right'>
							<span>
								<IconButton color="primary" className={classes.removeLinkButton} onClick={(event): void => handleRemoveAnswerOutLink(event, advisorAnswer)}>
									<ClearIcon />
								</IconButton>
							</span>
						</Tooltip>
						}
						<PortWidget className={classes.answerOutPort} name={createPortModelName(PortType.ANSWEROUT, advisorAnswer._id ?? '')} node={props.node} />
					</div>}
			</div>
		);
	}

	return (
		<Box
			className={classes.questionContainer}
			style={{
				position: 'relative',
				width: 320,
				height: currentQuestion.questionType === AdvisorQuestionType.PRICESLIDER ? 40 : 80 + currentQuestion.answers.length * 40
			}}
		>
			<Box onClickCapture={(): void => {
				if (currentQuestion.xCoordinate !== props.node.x || currentQuestion.yCoordinate !== props.node.y) {
					props.node.extras.handleQuestionChange({...props.node.extras.question, xCoordinate: props.node.x, yCoordinate: props.node.y});
				}
			}}>
				{getQuestion()}
				{currentQuestion.answers.map((answer: AdvisorAnswer) => (
					getAnswer(answer)
				))}
			</Box>
			{currentQuestion.questionType !== AdvisorQuestionType.PRICESLIDER &&
				<Box component={Button} className={classes.newAnswerContainer} onClick={(): void => props.node.extras.handleNewAnswerButtonClicked(props.node.extras.question)}>
					<Typography variant="subtitle1">
						+ Neue Antwort
					</Typography>
				</Box>
			}
		</Box>
	);

};

export default withStyles(questionNodeWidgetStyles)(QuestionNodeWidget);