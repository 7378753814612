import React, { ReactNode, Component, ChangeEvent, MouseEvent } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { WithStyles, withStyles } from '@material-ui/core';

import loginStyles from './LoginStyles';

import AuthService from '@services/AuthService';
import { ReactComponent as Logo } from './logo.svg';

interface Props extends WithStyles<typeof loginStyles>, RouteComponentProps {
}

interface State {
	username: string;
	password: string;
	error: string | null;
}

class Login extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.authService = new AuthService();
		this.state = {
			username: '',
			password: '',
			error: null
		};
	}

	public render(): ReactNode {
		if (this.authService.loggedIn()) {
			return (<Redirect to={{ pathname: '/' }} />);
		}

		const classes = this.props.classes;

		return (
			<Container component="main" maxWidth="xs">
				<div className={classes.paper}>
					<Logo className={classes.logo} />
					<Typography component="h1" variant="h5">
						{'Möbelix Techportal Login'}
					</Typography>
					<form className={classes.form} noValidate>
						{this.state.error !== null &&
							<p className={classes.errorMessage}>
								{`Fehler bei Login: ${this.localizeErrorMessage(this.state.error)}`}
							</p>
						}
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="username"
							label="Benutzername"
							name="username"
							autoComplete="username"
							onChange={this.handleChange}
							autoFocus
							error={this.state.error !== null}
						/>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							name="password"
							label="Passwort"
							type="password"
							id="password"
							onChange={this.handleChange}
							autoComplete="current-password"
							error={this.state.error !== null}
						/>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="secondary"
							className={classes.submit}
							onClick={this.handleFormSubmit}
						>
							{'Login'}
						</Button>
					</form>
				</div>
			</Container>
		);
	}

	private localizeErrorMessage(message: string): string {
		if (message === 'Invalid username or password!') {
			return 'Benutzername oder Passwort fehlerhaft!';
		}

		return message;
	}

	private handleChange(e: ChangeEvent<HTMLInputElement>): void {
		if (e.target.name === 'username') {
			this.setState({username: e.target.value});
		} else if (e.target.name === 'password') {
			this.setState({password: e.target.value});
		}
	}

	private handleFormSubmit(e: MouseEvent<HTMLElement>): void {
		e.preventDefault();

		this.authService.login(this.state.username, this.state.password)
			.then((response: Response): void => {
				this.setState({error: null});
				this.props.history.replace('/');
			})
			.catch((error: Error): void => {
				this.setState({error: error.message});
			});
	}
}

export default withStyles(loginStyles)(Login);
