import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorEditorQuestionsAnswersStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexDirection: 'column'
	},
	addQuestionButton: {
		marginRight: theme.spacing(2)
	},
	deleteQuestionButton: {
		color: theme.palette.error.main,
	},
	topButtonsContainer: {
		display: 'flex',
		justifyContent: 'end'
	},
	buttonsContainer: {
		display: 'flex',
	},
	treeItem: {
		'&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: 'transparent'
		},
		'&.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label': {
			backgroundColor: 'transparent'
		}
	},
	treeItemHighlighted: {
		backgroundColor: 'rgba(74, 188, 177, 0.15)'
	},
	treeLabelContainer: {
		display: 'flex',
		padding: theme.spacing(1, 0.5),
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	newAnswerButton: {
		marginRight: theme.spacing(0.5)
	},
	editButton: {
		marginRight: theme.spacing(0.5)
	},
	previewButton: {
		marginRight: theme.spacing(0.5)
	},
	questionType: {
		marginRight: theme.spacing(0.5),
		marginLeft: theme.spacing(0.5),
		fontSize: '0.7rem',
		alignSelf: 'center'
	},
	nameContainer: {
		width: '100%',
		display: 'flex',
		alignItems: 'baseline',
		justifyContent: 'space-between'
	},
	categoriesContainer: {
		width: '100%',
		marginBottom: theme.spacing(2)
	},
	categoriesLabel: {
		marginBottom: theme.spacing(1)
	},
	questionAnswersLabel: {
		marginBottom: theme.spacing(1)
	},
	dragList: {
		background: theme.palette.primary.main,
		opacity: 0.6,
	}
});

export default advisorEditorQuestionsAnswersStyles;
