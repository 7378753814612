import React, {ReactNode, Component} from 'react';
import {
	Button,
	Checkbox,
	Switch,
	WithStyles,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';
import dropshippingTable from './DropshippingTableStyles';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';

import AuthService from '@services/AuthService';
import { DropshippingModel } from '@models/Dropshipping';

interface DropshippingResponse {
	data: DropshippingModel;
	success: boolean;
	message?: string;
}

interface State {
	dropshipping: DropshippingModel;
	loadingDropshipping: boolean;
}

interface Props extends WithStyles<typeof dropshippingTable>{
	dropshippings: DropshippingModel[];
	editHandler: (dropshipping: DropshippingModel) => void;
	dropshipping: DropshippingModel | null;
}

class DropshippingTable extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			dropshipping: this.setDropshipping(this.props.dropshipping),
			loadingDropshipping: false
		};
	}

	private setDropshipping(newDropshipping: DropshippingModel | null): DropshippingModel {
		if (newDropshipping) {
			return newDropshipping;
		} else {
			const responseDropshipping: DropshippingModel = {
				name: '',
				categoryCode: '',
				categoryName: '',
				onlyDropshipping: true,
				startService: true,
				titleAttributes: [],
				priceTagAttributes: [],
				headersToAttributes: []
			};

			return responseDropshipping;
		}
	}

	private handleServiceSwitchChange(event: React.ChangeEvent<HTMLInputElement>, dropshipping: DropshippingModel): void {
		const tempDropshipping = dropshipping;
		tempDropshipping.startService = event.target.checked;
		this.authService.fetch<DropshippingResponse>(`/api/dropshippings/${tempDropshipping._id}`, {
			method: 'POST',
			body: JSON.stringify(tempDropshipping)
		}).then((response): void => {
			if (response.success) {
				this.setState({dropshipping: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			}
		}).catch((error): void => {
			console.log(`Error while updating dropshipping (startService): ${error}`);
		});
	}

	private editButtonClicked(dropshipping: DropshippingModel): void {
		this.props.editHandler(dropshipping);
	}

	private handleDropshippingDownload(dropshipping: DropshippingModel): void {
		const link = `${window.location.origin}/api/dropshippings/download/${dropshipping._id}`;
		window.open(link, '_blank');
	}

	private handleCheckboxClicked(event: React.ChangeEvent<HTMLInputElement>, dropshipping: DropshippingModel): void {
		const tempDropshipping = dropshipping;
		tempDropshipping.onlyDropshipping = event.target.checked;
		this.authService.fetch<DropshippingResponse>(`/api/dropshippings/${tempDropshipping._id}`, {
			method: 'POST',
			body: JSON.stringify(tempDropshipping)
		}).then((response): void => {
			if (response.success) {
				this.setState({dropshipping: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			}
		}).catch((error): void => {
			console.log(`Error while updating dropshipping (onlyDropshipping): ${error}`);
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<MaterialTable
				columns={[
					{
						title: 'Name',
						field: 'name' as const,
						sorting: true,
						filtering: false,
					},
					{
						title: 'Kategorie',
						field: 'categoryCode' as const,
						sorting: true,
						filtering: false,
						render: (dropshipping): ReactNode => {
							return (
								<div>
									{dropshipping.categoryCode} - {dropshipping.categoryName}
								</div>
							);
						}
					},
					{
						title: 'Nur Dropshipping Produkte',
						field: 'onlyDropshipping' as const,
						sorting: false,
						filtering: false,
						cellStyle: {
							textAlign: 'center'
						},
						headerStyle: {
							textAlign: 'center'
						},
						render: (dropshipping): ReactNode => {
							return (
								<Checkbox
									checked={dropshipping.onlyDropshipping}
									color="secondary"
									onChange={(event): void => this.handleCheckboxClicked(event, dropshipping)}
								/>
							);
						}
					},
					{
						title: 'Service starten (über Nacht)',
						field: 'startService' as const,
						sorting: false,
						cellStyle: {
							textAlign: 'center'
						},
						headerStyle: {
							textAlign: 'center'
						},
						render: (dropshipping): ReactNode => {
							return (
								<Switch
									checked={dropshipping.startService}
									onChange={(event): void => {this.handleServiceSwitchChange(event, dropshipping);}}
								/>
							);
						}
					},
					{
						field: 'buttons' as const,
						filtering: false,
						cellStyle: {
							textAlign: 'right',
							whiteSpace: 'nowrap'
						},
						render: (dropshipping): ReactNode => {
							return (
								<div>
									<Button
										onClick={this.editButtonClicked.bind(this, dropshipping)}
										color="primary"
										variant="outlined"
										startIcon={<EditIcon color="primary" />}
										className={classes.editButton}
									>
										Bearbeiten
									</Button>
									<Button
										onClick={this.handleDropshippingDownload.bind(this, dropshipping)}
										color="primary"
										variant="outlined"
										startIcon={<GetAppIcon color="primary"/>}
										className={classes.downloadButton}
									>
										Download
									</Button>
								</div>
							);
						}
					}
				]}
				options={{
					search: false,
					filtering: false,
					columnsButton: false,
					paging: true,
					emptyRowsWhenPaging: false,
					toolbar: false,
					pageSize: 50,
					pageSizeOptions: [25, 50, 75, 100],
				}}
				style={{
					marginTop: '60px'
				}}
				data={ this.props.dropshippings }
				localization={ TableLocalization.localization}
			/>
		);
	}

}

export default withStyles(dropshippingTable)(DropshippingTable);
