import React, {ReactNode, Component} from 'react';

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Link,
	WithStyles,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';

import articleCombinationStyles from './ArticleCombinationStyles';

import {ArticleCombinationModel} from '@models/SeriesOrder';

interface Props extends WithStyles<typeof articleCombinationStyles> {
	editorOpen: boolean;
	closeHandler: () => void;
	articleCombinations: ArticleCombinationModel[];
	loading: boolean;
}

class ArticleCombination extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.props.closeHandler} fullWidth={true} maxWidth={'lg'}>
				<DialogContent>
					<MaterialTable
						columns={[
							{
								title: 'Produkte',
								field: 'products' as const,
								render: (products): ReactNode => {
									return (
										<div>
											{products.products.map((product) =>(
												<div className={classes.productContainer}>
													<img alt="Produktfoto" src={product.image} width="50px" className={classes.productImage} />
													<p><Link href={`https://www.moebelix.at/p/${product.productCode}`} target="_blank">{product.productCode}</Link> - {product.name}</p>
												</div>
											))}
										</div>
									);
								}
							},
							{
								title: 'gekauft',
								field: 'amount' as const,
								headerStyle: {
									maxWidth: '15%'
								},
								cellStyle: {
									maxWidth: '15%'
								},
								render: (product): ReactNode => {
									return (
										<div>{product.amount}x</div>
									);
								}
							}
						]}
						options={{
							search: false,
							filtering: false,
							sorting: false,
							draggable: false,
							showTitle: false,
							paging: false,
							toolbar: false
						}}
						data={this.props.articleCombinations}
						isLoading={this.props.loading}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={this.props.closeHandler}>
						Schließen
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withStyles(articleCombinationStyles)(ArticleCombination);
