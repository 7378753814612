/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	FormControlLabel,
	TextField,
	InputLabel,
	MenuItem,
	Button,
	FormControl,
	Checkbox,
	Typography,
	Select,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete';

import editorGeneralSettingsStyles from './EditorGeneralSettingsStyles';

interface Props extends WithStyles<typeof editorGeneralSettingsStyles> {
	comparator: Comparator;
	handleComparatorChange: (key: keyof Comparator, event: any) => void;
	deleteComparatorButtonPressed: (() => void) | null;
	isBulk: boolean;
}

class EditorGeneralSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.comparator) {
			return;
		}

		const activeTitle = this.props.comparator.active ? 'Aktiv' : 'Inaktiv';

		return (
			<ExpansionPanel
				defaultExpanded={!this.props.isBulk}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Allgemeine Einstellungen</Typography>
					<Typography className={classes.panelSubtitle}>{activeTitle}, Titel: {this.props.comparator.name}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<FormControlLabel
						className={classes.fullscreenCheckbox}
						control={
							<Checkbox
								checked={this.props.comparator.active}
								color="primary"
								onChange={(event): void => {this.props.handleComparatorChange('active', event);}}
							/>
						}
						label="Aktiv (Inaktive Produktvergleicher werden nicht auf der Möbelix Seite angezeigt)"
					/>
					<TextField
						required
						autoFocus
						fullWidth
						className={classes.textInput}
						label="Titel"
						placeholder="Produktvergleicher"
						value={this.props.comparator.name}
						onChange={(event): void => {this.props.handleComparatorChange('name', event);}}
					/>
					<TextField
						fullWidth
						className={classes.textInput}
						label="Admin Titel"
						placeholder="Produktvergleicher Admin"
						value={this.props.comparator.nameBackend}
						onChange={(event): void => {this.props.handleComparatorChange('nameBackend', event);}}
					/>
					<TextField
						fullWidth
						className={classes.textInput}
						label="Position"
						placeholder="<div> ID an der der Vergleicher angezeigt werden soll. Wird keine ID angegeben wird der Vergleicher direkt angezeigt."
						value={this.props.comparator.position}
						onChange={(event): void => {this.props.handleComparatorChange('position', event);}}
					/>
					<FormControl className={classes.sortSelect}>
						<InputLabel id="sort-label">Sortierung</InputLabel>
						<Select
							labelId="sort-label"
							value={this.props.comparator.sortedBy}
							onChange={(event): void => {this.props.handleComparatorChange('sortedBy', event);}}
						>
							<MenuItem value={0}>Preis</MenuItem>
							<MenuItem value={1}>Meist gesehen</MenuItem>
							<MenuItem value={2}>Relevanz</MenuItem>
						</Select>
					</FormControl>
					{this.props.comparator.sortedBy === 2 &&
						<TextField
							className={classes.sortWeightInput}
							label="Sortierung Preis Gewichtung"
							placeholder="Gewichtung des Preises bei der Relevanzberechnung (1 = gleichgesetzt mit Beliebtheit)"
							value={this.props.comparator.sortWeight}
							onChange={(event): void => {this.props.handleComparatorChange('sortWeight', event);}}
						/>
					}
					<FormControlLabel
						className={classes.fullscreenCheckbox}
						control={
							<Checkbox
								checked={this.props.comparator.fullPage}
								color="primary"
								onChange={(event): void => {this.props.handleComparatorChange('fullPage', event);}}
							/>
						}
						label="Supervergleicher (alle Produkte werden untereinander angezeigt)"
					/>
					{ this.props.deleteComparatorButtonPressed && this.props.comparator._id !== 'new' &&
						<Button
							variant="outlined"
							size="small"
							className={classes.deleteButton}
							startIcon={<DeleteIcon />}
							onClick={this.props.deleteComparatorButtonPressed.bind(this)}
						>
							Löschen
						</Button>
					}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(editorGeneralSettingsStyles)(EditorGeneralSettings);
