import React, { ReactNode } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import AuthService from '@services/AuthService';

interface Props extends RouteProps {
	permission?: string;
}


class PrivateRoute extends Route<Props> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
	}

	public render(): ReactNode {
		if (this.authService.loggedIn()) {
			if (this.props.permission === undefined || this.props.permission === '' || this.authService.hasPermission(this.props.permission)) {
				return super.render();
			}
		}

		return (
			<Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />
		);
	}
}

export default PrivateRoute;
