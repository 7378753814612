import React, { ReactNode, Component } from 'react';

import AuditLogsTable from './AuditLogsTable';

class AuditLogs extends Component {
	public render(): ReactNode {
		return (
			<AuditLogsTable
				loadAllLogs={true}
				showChrome={true}
			/>
		);
	}
}

export default AuditLogs;
