import React, { ReactNode, Component } from 'react';
import {
	WithStyles,
	withStyles
} from '@material-ui/core';
import splitLevelGroupsStyles from './SplitLevelGroupsStyles';
import { SplitLevelGroup, RuleGroup } from '@models/AutomatedComparator';
import RuleGroupTable from '../AutomatedComparator/RuleGroupTable';


interface Props extends WithStyles<typeof splitLevelGroupsStyles> {
	splitLevelGroup: SplitLevelGroup;
	splitLevelGroupIndex: number;
	selectedCategoryCode: string;
	notifyParent: (splitLevelGroup: SplitLevelGroup, index: number) => void;
	loadingSplittedComparators: boolean;
}

interface State {
	splitLevelGroup: SplitLevelGroup;
}

class SplitLevelGroups extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			splitLevelGroup: props.splitLevelGroup
		};
	}

	private splitRuleGroupChanged(splitRuleGroup: RuleGroup): void {
		const splitLevelGroup = this.props.splitLevelGroup;
		splitLevelGroup.splitRuleGroup = splitRuleGroup;
		this.props.notifyParent(splitLevelGroup, this.props.splitLevelGroupIndex);
	}


	public render(): ReactNode {
		return (
			<RuleGroupTable
				ruleGroup={this.props.splitLevelGroup.splitRuleGroup}
				selectedCategoryCode={this.props.selectedCategoryCode}
				isTopLevel={true}
				editable={true}
				changeHandler={this.splitRuleGroupChanged.bind(this)}
				loading={this.props.loadingSplittedComparators}
			/>

		);
	}

}

export default withStyles(splitLevelGroupsStyles)(SplitLevelGroups);
