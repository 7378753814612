import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DialogProvider } from 'muibox';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/de';
import {timeFormatDefaultLocale} from 'd3-time-format';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/material-icons';
import './index.css';

import AppRouter from './App/AppRouter';
import createMoebelhausTheme from './App/Theme';

require('@projectstorm/react-diagrams/dist/style.min.css');

const moebelhausTheme = createMoebelhausTheme({});

// Setup Date Formats
moment.locale('de');
timeFormatDefaultLocale({
	dateTime: '%A, der %e. %B %Y, %X',
	date: '%d.%m.%Y',
	time: '%H:%M:%S',
	periods: ['AM', 'PM'],
	days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
	shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
	months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
	shortMonths: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			refetchOnMount: true,
			refetchOnReconnect: true,
			retry: false,
		},
	},
});

ReactDOM.render((
	<MuiPickersUtilsProvider utils={MomentUtils}>
		<CssBaseline />
		<ThemeProvider theme={moebelhausTheme}>
			<SnackbarProvider>
				<QueryClientProvider client={queryClient}>
					<DialogProvider>
						<AppRouter />
					</DialogProvider>
				</QueryClientProvider>
			</SnackbarProvider>
		</ThemeProvider>
	</MuiPickersUtilsProvider>
), document.getElementById('root'));
