import React, {ReactElement, FC, useState, useEffect} from 'react';
import {
	Paper,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorEditorStyles from './AdvisorEditorStyles';
import { RouteComponentProps, withRouter, Prompt } from 'react-router-dom';
import AdvisorPreview from './AdvisorPreview';
import { Advisor, AdvisorQuestion, deleteAdvisor, loadAdvisor, saveAdvisor, dummyAdvisor, AdvisorStatus, saveAdvisorCategoryCodes, AdvisorCategoryAttribute} from '@common/models/Advisor';
import AdvisorEditorGeneralSettings from './AdvisorEditorComponents/AdvisorEditorGeneralSettings';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import AdvisorEditorQuestionsAnswers from './AdvisorEditorComponents/AdvisorEditorQuestionsAnswers';
import AdvisorEditorAttributeSettings from './AdvisorEditorComponents/AdvisorEditorAttributeSettings';
import AdvisorEditorGoogleAnalytics from './AdvisorEditorComponents/AdvisorEditorGoogleAnalytics';
import { AdvisorFolder } from '@common/models/AdvisorFolder';
import AdvisorEditorCategoryAttributes from './AdvisorEditorComponents/AdvisorEditorCategoryAttributes';

interface Params {
	advisorId: string;
	action: string;
}

interface Props extends WithStyles<typeof advisorEditorStyles>, RouteComponentProps<Params>, WithSnackbarProps {
	dialog?: ConfirmDialog;
}

const AdvisorEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const queryClient = useQueryClient();
	const advisorId = props.match.params.advisorId;

	const [advisorToEdit, setAdvisorToEdit] = useState<Advisor>(dummyAdvisor);
	const [isSaving, setIsSaving] = useState(false);
	const [changesMade, setChangesMade] = useState(false);
	const [disableAnswerSort, setDisableAnswerSort] = useState(false);

	const beforeUnLoad = (e: BeforeUnloadEvent): void => {
		e.preventDefault();
		e.stopPropagation();
		e.returnValue = '';
	};

	useEffect(() => {
		window.addEventListener('beforeunload', beforeUnLoad);
		return (): void => {
			window.removeEventListener('beforeunload', beforeUnLoad);
		};
	}, []);

	const loadAdvisorQuery = useQuery(
		['advisor'],
		() => loadAdvisor(advisorId),
		{
			onSuccess: (data) => {
				if (data) {
					setAdvisorToEdit(data);
				}
			},
		}
	);

	const deleteAdvisorQuery = useMutation(
		() => deleteAdvisor(advisorToEdit.originalId ?? ''), {
			onSuccess: (data) => {
				if (!!data) {
					props.history.push('/advisors');
					props.enqueueSnackbar(`Produktberater mit der ID ${advisorToEdit.originalId} erfolgreich gelöscht!`, {variant: 'success'});
				} else {
					props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
				}
			},
		}
	);

	const saveAdvisorQuery = useMutation((variables: {advisorId: string; changes: Advisor}) => saveAdvisor(variables.advisorId, variables.changes, true), {
		onSuccess: (data) => {
			if (data) {
				if (advisorId === 'new') {
					props.history.push(`/advisor/${JSON.parse(JSON.stringify(data)).originalId}/edit`);
					loadAdvisorQuery.refetch();
				} else {
					queryClient.invalidateQueries({
						queryKey: ['advisor'],
					});
				}
				props.enqueueSnackbar(`Produktberater mit der ID ${data.originalId} erfolgreich gespeichert!`, {variant: 'success'});
			} else {
				props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			}
		}
	});

	const updateAdvisorQuestionsQuery = useMutation((advisor: Advisor) => saveAdvisor(advisor._id ?? '', {questions: advisor.questions, startingQuestion: advisor.startingQuestion ?? undefined}, false), {
		onSuccess: (data) => {
			if (data) {
				const updatedAdvisorToEdit = {...advisorToEdit, questions: data.questions, startingQuestion: data.startingQuestion};
				setAdvisorToEdit(updatedAdvisorToEdit);
			} else {
				props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			}
			setDisableAnswerSort(false);
		}
	});

	const updateAdvisorCategoryCodesQuery = useMutation((advisor: Advisor) => saveAdvisorCategoryCodes(advisor._id ?? '', advisor.categoryCodes, false), {
		onSuccess: (data) => {
			if (data) {
				const updatedAdvisorToEdit = {...advisorToEdit, categoryCodes: data.categoryCodes};
				setAdvisorToEdit(updatedAdvisorToEdit);
				queryClient.invalidateQueries({
					queryKey: ['advisorCategoryProducts'],
				});
			} else {
				props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			}
		}
	});

	const updateAdvisorForPreviewQuery = useMutation((advisor: Advisor) => saveAdvisor(advisor._id ?? '', advisor, false), {
		onSuccess: (data) => {
			if (data) {
				setAdvisorToEdit({...data});
			} else {
				props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			}
		}
	});

	function handleAdvisorChange(key: keyof Advisor, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		setChangesMade(true);
		let value: string | boolean = event.target.value;
		if (event.target.type === 'checkbox') {
			value = (event as React.ChangeEvent<HTMLInputElement>).target.checked;
		}

		setAdvisorToEdit({...advisorToEdit, [key]: value });
	}

	function handleAdvisorCategoriesChange(categoryCodes: string[]): void {
		setChangesMade(true);
		const updatedAdvisorToEdit = {...advisorToEdit, categoryCodes};
		setAdvisorToEdit(updatedAdvisorToEdit);
		updateAdvisorCategoryCodesQuery.mutateAsync(updatedAdvisorToEdit);
	}

	function handleAdvisorCategoryAttributesChange(advisorCategoryAttributes: AdvisorCategoryAttribute[]): void {
		if (JSON.stringify(advisorCategoryAttributes) !== JSON.stringify(advisorToEdit.categoryAttributes)) {
			setChangesMade(true);
		}
		setAdvisorToEdit({...advisorToEdit, categoryAttributes: advisorCategoryAttributes});
	}

	function handleAdvisorAttributesChange(selectedAttributes: string[]): void {
		//setIsBlocking(true);
		if (JSON.stringify(selectedAttributes) !== JSON.stringify(advisorToEdit.attributes)) {
			setChangesMade(true);
		}
		//console.log(advisorToEdit.attributes);
		setAdvisorToEdit({...advisorToEdit, attributes: selectedAttributes});
	}

	function handleAdvisorFolderChange(folder: AdvisorFolder | undefined): void {
		setChangesMade(true);
		setAdvisorToEdit({...advisorToEdit, folder});
	}

	function handleAdvisorQuestionsChange(questions: AdvisorQuestion[], startingQuestionId: string | undefined): void {
		setChangesMade(true);
		const updatedAdvisorToEdit = {...advisorToEdit, startingQuestion: startingQuestionId, questions};
		setAdvisorToEdit(updatedAdvisorToEdit);
		updateAdvisorQuestionsQuery.mutateAsync(updatedAdvisorToEdit);
	}

	function handleAdvisorVersionLoaded(advisor: Advisor): void {
		setChangesMade(true);
		setAdvisorToEdit({...advisor});
	}

	function handleRefreshPreview(): void {
		updateAdvisorForPreviewQuery.mutateAsync(advisorToEdit);
	}

	function deleteAdvisorButtonPressed(): void {
		const dialog = props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Produktberater Löschen',
				message: `Soll der Produktberater "${advisorToEdit.name}" (ID: ${advisorToEdit.originalId}) wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then(async (): Promise<void> => {
				await deleteAdvisorQuery.mutateAsync();
			}).catch((error): void => {
				console.log(`Error while deleting advisor: ${error}`);
			});
		}
	}

	async function handleSave(): Promise<void> {
		setChangesMade(false);
		setIsSaving(true);
		await saveAdvisorQuery.mutateAsync({advisorId: advisorToEdit._id ?? '', changes: {...advisorToEdit, status: AdvisorStatus.CURRENT}});
		setIsSaving(false);
	}

	return (
		<Paper square={true} className={classes.root}>
			<Typography component="h2" variant="h4" className={classes.title}>
				{advisorId === 'new' ? 'Neuen Produktberater erstellen' : `Produktberater ID ${advisorId} Bearbeiten`}
			</Typography>
			<AdvisorEditorGeneralSettings
				advisor={advisorToEdit}
				handleAdvisorChange={handleAdvisorChange}
				deleteAdvisorButtonPressed={deleteAdvisorButtonPressed}
				handleAdvisorFolderChange={handleAdvisorFolderChange}
				handleAdvisorVersionLoaded={handleAdvisorVersionLoaded}
				isNewAdvisor={advisorId === 'new'}
			/>
			<AdvisorEditorQuestionsAnswers
				advisor={advisorToEdit}
				handleAdvisorCategoriesChange={handleAdvisorCategoriesChange}
				handleAdvisorQuestionsChange={handleAdvisorQuestionsChange}
				expanded={props.match.params.action === 'edit' ? true : false}
				setDisableAnswerSort={setDisableAnswerSort}
				disableAnswerSort={disableAnswerSort}
			/>
			<AdvisorEditorCategoryAttributes
				advisor={advisorToEdit}
				handleAdvisorCategoryAttributesChange={handleAdvisorCategoryAttributesChange}
				expanded={props.match.params.action === 'edit' ? true : false}
			/>
			<AdvisorEditorAttributeSettings
				advisor={advisorToEdit}
				handleAdvisorAttributesChange={handleAdvisorAttributesChange}
				expanded={props.match.params.action === 'edit' ? true : false}
			/>
			<AdvisorEditorGoogleAnalytics
				advisor={advisorToEdit}
			/>
			<AdvisorPreview
				advisor={advisorToEdit}
				handleSaveButton={handleSave}
				isSaving={isSaving || updateAdvisorForPreviewQuery.isLoading}
				handleRefreshPreview={handleRefreshPreview}
				previewRefreshLoading={updateAdvisorForPreviewQuery.isLoading}
				expanded={props.match.params.action === 'edit' ? true : false}
			/>
			<Prompt
				when={changesMade}
				message={'Wollen Sie den Editor wirklich verlassen? Etwaige Änderungen würden verloren gehen.'}
			/>
		</Paper>
	);
};

export default withSnackbar(withDialog()(withStyles(advisorEditorStyles)(withRouter(AdvisorEditor))));
