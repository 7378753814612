import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const orderDetailsModalStyles = (theme: Theme): any => createStyles({
	root: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		}
	},
	dialogRoot: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			paddingTop: 0,
		}
	},
	threeColumns : {
		columnCount: 3,
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			justifyContent: 'space-between',
			columnCount: 2,
		},
		[theme.breakpoints.up('md')]: {
			display: 'block'
		},
		[theme.breakpoints.down('sm')]: {
			margin: 0,
		}
	},
	groupContainer: {
		display: 'block',
		'& dt': {
			display: 'inline-block',
			fontWeight: 500,
			minWidth: '150px',
		},
		'& dd': {
			display: 'inline-block',
			fontWeight: 400,
			width: 'auto',
			margin: 0,
			[theme.breakpoints.down('xs')]: {
				display: 'block',
			}
		},
	},
	itemsToggle: {
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	totalsList: {
		width: 'auto',
		marginLeft: 'auto',
		display: 'inline-block',
		textAlign: 'right',
		'& dt': {
			minWidth: 'unset',
			float: 'left',
		},
		'& dd': {
			minWidth: '150px',
		},
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		}
	},
	headerActions: {
		float: 'right',
		marginTop: -8,
	},
	dummyImage: {
		width: 56,
		height: 56,
	},
	productImage: {
		height: 56,
		width: 56,
		objectFit: 'contain',
	},
	panelDetails: {
		padding: 0,
		'& .MuiTableCell-root:nth-child(4)': {
			[theme.breakpoints.down('sm')]: {
				display: 'none',
			}
		}
	},
});

export default orderDetailsModalStyles;
