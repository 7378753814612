import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const crawlerEditorStyles = (theme: Theme): any => createStyles({
	deleteButton: {
		color: '#EE0000',
		marginRight: 'auto',
	},
	DialogActions: {
		display: 'flex',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
		marginTop: 0,
	},
	deleteAttributeValueButton: {
		color: '#EE0000',
		borderColor: '#EE0000',
		float: 'right'
	},
	selectedMultiFeedsTable: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
});

export default crawlerEditorStyles;
