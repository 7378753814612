import React, {ReactElement, FC, useState, useEffect} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	WithStyles,
	withStyles,
	FormLabel
} from '@material-ui/core';
import advisorCategoryAttributesEditorStyles from './AdvisorCategoryAttributesEditorStyles';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { AdvisorCategoryAttribute } from '@common/models/Advisor';
import CategoryMultiSelect from '@common/CategoryMultiSelect';
import ComparatorAttributeSelect from 'Comparator/ComparatorAttributeSelect';
import { useMutation } from '@tanstack/react-query';
import { Product, loadOnlineProductsByCategories } from '@common/models/Product';
import { Category } from '@common/models/Category';

interface Props extends WithStyles<typeof advisorCategoryAttributesEditorStyles> {
	dialog?: ConfirmDialog;
	editorOpen: boolean;
	closeHandler: () => void;
	advisorCategoryAttribute: AdvisorCategoryAttribute | null;
	advisorCategoryAttributeIndex: number | null;
	handleAdvisorCategoryChange: (advisorCategoryAttribute: AdvisorCategoryAttribute, index: number | null) => void;
	categoryAttributesEditorPropsHandlerActive: boolean;
	handleCategoryAttributesEditorPropsHandlerActiveChange: React.Dispatch<React.SetStateAction<boolean>>;
	handleAdvisorCategoryDelete: (advisorCategoryAttribute: AdvisorCategoryAttribute | null, index: number | null) => void;
	advisorCategories: string[];
}

const AdvisorCategoryAttributesEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const [productsByCategories, setProductsByCategories] = useState<Product[]>([]);
	const [advisorCategoryAttributeCategories, setAdvisorCategoryAttributeCategories] = useState<string[]>([]);
	const [advisorCategoryAttributeAttributes, setAdvisorCategoryAttributeAttributes] = useState<string[]>([]);
	const [isValid, setIsValid] = useState(true);

	const advisorCategoryAttributesProductsQuery = useMutation((categories: string[]) => loadOnlineProductsByCategories(categories), {
		onSuccess: (data) => {
			if (data) {
				setProductsByCategories(data);
			}
		},
	});

	useEffect(() => {
		if (props.categoryAttributesEditorPropsHandlerActive) {
			if (props.advisorCategoryAttribute) {
				setAdvisorCategoryAttributeCategories(props.advisorCategoryAttribute.categories);
				setAdvisorCategoryAttributeAttributes(props.advisorCategoryAttribute.attributes);
				advisorCategoryAttributesProductsQuery.mutateAsync(props.advisorCategoryAttribute.categories);
			}
			if (!props.advisorCategoryAttribute) {
				setAdvisorCategoryAttributeCategories([]);
				setAdvisorCategoryAttributeAttributes([]);
			}

			props.handleCategoryAttributesEditorPropsHandlerActiveChange(false);
		}
	}, [advisorCategoryAttributesProductsQuery, props]);

	function closeEditor(): void {
		setIsValid(true);
		props.closeHandler();
	}

	function handleSave(): void {
		if (advisorCategoryAttributeCategories.length > 0) {
			props.handleAdvisorCategoryChange({categories: advisorCategoryAttributeCategories, attributes: advisorCategoryAttributeAttributes}, props.advisorCategoryAttributeIndex);
			props.closeHandler();
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}

	function handleCategoriesChange(categories: Category[] | null): void {
		const categoryCodes = categories ? categories.map((c) => c.code) : [];
		setAdvisorCategoryAttributeCategories(categoryCodes);
		advisorCategoryAttributesProductsQuery.mutateAsync(categoryCodes);
	}

	return (
		<Dialog open={props.editorOpen} onClose={closeEditor} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>{props.advisorCategoryAttribute ? 'Kategorien-Attribute bearbeiten' : 'Kategorien-Attribute'}</DialogTitle>
			<DialogContent className={classes.content}>
				<FormControl className={classes.categoriesContainer}>
					<FormLabel className={classes.categoriesLabel} error={!isValid}>Kategorien *</FormLabel>
					<CategoryMultiSelect 
						onChange={(categories): void => handleCategoriesChange(categories)}
						selectedCategoryCodes={advisorCategoryAttributeCategories}
						topLevelCategoriesExcluded={false}
						disabled={false}
						filterCategoryCodes={props.advisorCategories}
					/>
					{advisorCategoryAttributeCategories.length > 0 &&
					<ComparatorAttributeSelect
						onChange={(selectedAttributes): void => setAdvisorCategoryAttributeAttributes(selectedAttributes)}
						onChangeDelimiterRemoveAttributes={null}
						selectedProducts={productsByCategories}
						selectedAttributeCodes={advisorCategoryAttributeAttributes}
						sortingEnabled={true}
						templatesEnabled={true}
						maintainedEnabled={false}
						categoryFeed={false}
						loadingAttributeSettings={advisorCategoryAttributesProductsQuery.isLoading}
						type={'advisor'}
					/>
					}
				</FormControl>
			</DialogContent>
			<DialogActions>
				{props.advisorCategoryAttribute &&
					<Button onClick={(): void => props.handleAdvisorCategoryDelete(props.advisorCategoryAttribute, props.advisorCategoryAttributeIndex)} className={classes.deleteButton}>
						Löschen
					</Button>
				}
				<Button onClick={closeEditor} color="primary">
						Abbrechen
				</Button>
				<Button onClick={handleSave} color="primary">
						Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withDialog()(withStyles(advisorCategoryAttributesEditorStyles)(AdvisorCategoryAttributesEditor));
