import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const attributeTemplateButtonStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(3),
		width: '100%',
	},
	templateSelect: {
		width: '35%',
	},
	splitButton: {
		marginLeft: theme.spacing(2)
	},
	deleteButton: {
		color: theme.palette.error.main,
	},
});

export default attributeTemplateButtonStyles;
