import React, { ReactNode, Component, CSSProperties } from 'react';
import {
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	FormControl,
	FormControlLabel,
	IconButton,
	InputLabel,
	MenuItem,
	Select as SelectM,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';
import CategorySelect from '../Common/CategorySelect';
import DropshippingAttributeSelect from './DropshippingAttributeSelect';
import { Category } from '@models/Category';
import { DropshippingModel, DropshippingHeaderToAttributes, AttributeToAttribute } from '@models/Dropshipping';
import { Product, ProductAttribute } from '@models/Product';
import { AttributeTemplate } from '@models/Comparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddIcon from '@material-ui/icons/Add';
import ProductSelect from '@common/ProductSelect';

import Select, { ValueType } from 'react-select';

import dropshippingEditorStyles from './DropshippingEditorStyles';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface OptionType {
	label: string;
	value: AttributeTemplate | string;
};

interface AttributesTemplateResponse {
	data: AttributeTemplate[];
	success: boolean;
	message?: string;
}

interface DropshippingPreviewResponse extends Response {
	success: boolean;
	message?: string;
	data: DropshippingPreview;
}

interface DropshippingPreview {
	title: string;
	priceTag: string;
	attributes: HeaderToWIAttributes[];
}

interface HeaderToWIAttributes {
	header: string;
	attributes: string[];
}

interface Props extends WithStyles<typeof dropshippingEditorStyles> {
	editorOpen: boolean;
	closeHandler: () => void;
	dropshipping: DropshippingModel | null;
	dialog: ConfirmDialog;
}

interface State {
	dropshipping: DropshippingModel;
	products: Product[];
	isLoadingAllProducts: boolean;
	loadedTemplates: AttributeTemplate[];
	currentAttributeHeaderToAdd: string;
	currentSelectedAttributHeader: string;
	selectedProduct: Product | null;
	dropshippingPreview: DropshippingPreview;
	isLoadingPreview: boolean;
	attributes: ProductAttribute[];
}

class DropshippingEditor extends Component<Props, State> {
	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.productsService = new ProductsService();

		const defaultDropshippingPreview: DropshippingPreview = {
			title: '',
			priceTag: '',
			attributes: []
		};

		this.state = {
			dropshipping: this.setDropshipping(this.props.dropshipping),
			products: [],
			isLoadingAllProducts: false,
			loadedTemplates: [],
			currentAttributeHeaderToAdd: '',
			currentSelectedAttributHeader: '',
			selectedProduct: null,
			dropshippingPreview: defaultDropshippingPreview,
			isLoadingPreview: false,
			attributes: []
		};
	}

	public componentDidMount(): void {
		this.loadTemplates();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.dropshipping !== this.props.dropshipping) {
			this.setState({dropshipping: this.setDropshipping(this.props.dropshipping)});
			this.loadTemplates();
			if (this.props.dropshipping) {
				this.loadDropshippingProducts(this.props.dropshipping.categoryCode);
			}
		}
	}

	private setDropshipping(newDropshipping: DropshippingModel | null): DropshippingModel {
		if (newDropshipping) {
			return newDropshipping;
		} else {
			const responseDropshipping: DropshippingModel = {
				name: '',
				categoryCode: '',
				categoryName: '',
				onlyDropshipping: true,
				startService: true,
				titleAttributes: [],
				priceTagAttributes: [],
				headersToAttributes: []
			};

			return responseDropshipping;
		}
	}

	private async loadTemplates(id: string | null = null): Promise<void> {
		const templates = await this.authService.fetch<AttributesTemplateResponse>('/api/attribute-templates', {
			method: 'GET'
		});

		if (templates.data) {
			const loadedTemplates = templates.data.sort((a, b): number => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
			loadedTemplates.unshift({_id: 'no-attribute-template-id', name: 'Keine Attributvorlage', attributes: []});

			const tempDropshipping = this.state.dropshipping;
			if (id) {
				const templateByID = templates.data.find((t): boolean => (t._id === id));
				if (templateByID) {
					 tempDropshipping.selectedTitleAttributeTemplate = templateByID;
				}
			}

			this.setState({loadedTemplates: loadedTemplates, dropshipping: tempDropshipping});
		}
	}

	private async loadDropshippingProducts(categoryCode: string): Promise<void> {
		this.setState({isLoadingAllProducts: true});

		const products = await this.productsService.loadOnlineProductsInCategory(categoryCode);

		this.setState({products: products, isLoadingAllProducts: false});
	}

	private closeEditor(): void {
		this.props.closeHandler();
		this.setState({dropshipping: this.setDropshipping(null), products: [], currentAttributeHeaderToAdd: '', currentSelectedAttributHeader: '', selectedProduct: null});
	}

	private async handleCategoryChange(selectedCategory: Category | null): Promise<void> {
		const tempDropshipping = this.state.dropshipping;
		let categoryCode = '';
		let categoryName = '';

		if (selectedCategory && selectedCategory.code) {
			categoryCode = selectedCategory.code;
			categoryName = selectedCategory.name;
		}

		if (tempDropshipping.categoryCode !== categoryCode) {
			tempDropshipping.categoryCode = categoryCode;
			tempDropshipping.categoryName = categoryName;
		}

		this.setState({dropshipping: tempDropshipping});

		this.loadDropshippingProducts(tempDropshipping.categoryCode);
	}

	private categorySelect(): ReactNode {
		let categoryCode = null;

		if (this.props.dropshipping) {
			categoryCode = this.props.dropshipping.categoryCode;
		}

		return (
			<CategorySelect
				onChange={this.handleCategoryChange.bind(this)}
				selectedCategoryCode={categoryCode}
				topLevelCategoriesExcluded={false}
				disabled={false}
			/>
		);
	}

	private attributeSelect(type: string): ReactNode {
		const classes = this.props.classes;
		if (this.state.products.length > 0) {

			if (type === 'title') {
				return (
					<DropshippingAttributeSelect
						onChange={this.handleAttributesChange.bind(this)}
						products={this.state.products}
						selectedAttributes={this.state.dropshipping.titleAttributes}
						selectedTemplate={this.state.dropshipping.selectedTitleAttributeTemplate}
					/>
				);
			} else if (type === 'priceTag') {
				return (
					<DropshippingAttributeSelect
						onChange={this.handlePriceTagAttributesChange.bind(this)}
						products={this.state.products}
						selectedAttributes={this.state.dropshipping.priceTagAttributes}
						selectedTemplate={this.state.dropshipping.selectedPriceTagAttributeTemplate}
					/>
				);
			} else {
				return (
					<DropshippingAttributeSelect
						onChange={this.handleWIAttributesChange.bind(this)}
						products={this.state.products}
						selectedAttributes={this.getSelectedWIAttributes()}
						selectedTemplate={this.state.dropshipping.selectedWIAttributeTemplate}
					/>
				);
			}

		} else if (this.state.isLoadingAllProducts) {
			return (
				<CircularProgress className={classes.loadingIndicator}/>
			);
		}
	}

	private getSelectedWIAttributes(): string[] {
		let selectedAttributes: string[] = [];
		for (const headerToAttributes of this.state.dropshipping.headersToAttributes) {
			if (headerToAttributes.attributeHeader === this.state.currentSelectedAttributHeader) {
				selectedAttributes = headerToAttributes.wiAttributes.map(w => w.attributeNameAttribute);
				break;
			}
		}
		return selectedAttributes;
	}

	private handleAttributesChange(selectedAttributes: string[]): void {
		if (this.state.products.length === 0) {
			return;
		}

		const tempDropshipping = this.state.dropshipping;
		tempDropshipping.titleAttributes = selectedAttributes;
		this.setState({dropshipping: tempDropshipping});
	}

	private handlePriceTagAttributesChange(selectedAttributes: string[]): void {
		if (this.state.products.length === 0) {
			return;
		}

		const tempDropshipping = this.state.dropshipping;
		tempDropshipping.priceTagAttributes = selectedAttributes;
		this.setState({dropshipping: tempDropshipping});
	}

	private handleWIAttributesChange(selectedAttributes: string[]): void {
		if (this.state.products.length === 0) {
			return;
		}

		const tempDropshipping = this.state.dropshipping;
		for (const headerToAttributes of tempDropshipping.headersToAttributes) {
			if (this.state.currentSelectedAttributHeader === headerToAttributes.attributeHeader) {

				const wiAttributes: AttributeToAttribute[] = [];
				for (const selectedAttribute of selectedAttributes) {
					let isAlreadySelected = false;
					for (const wiAttribute of headerToAttributes.wiAttributes) {
						if (selectedAttribute === wiAttribute.attributeNameAttribute) {
							isAlreadySelected = true;
							wiAttributes.push(wiAttribute);
						}
					}
					if (!isAlreadySelected) {
						const newAttributeToAttribute: AttributeToAttribute = {
							attributeNameAttribute: selectedAttribute,
							attributeValueAttribute: ''
						};
						wiAttributes.push(newAttributeToAttribute);
					}
				}
				headerToAttributes.wiAttributes = wiAttributes;
			}
		}

		this.setState({dropshipping: tempDropshipping});
	}

	private handleTextChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const tempDropshipping = this.state.dropshipping;
		let name = '';

		name = event.target.value;
		if (tempDropshipping.name !== name) {
			tempDropshipping.name = name;
		}

		this.setState({dropshipping: tempDropshipping});
	}

	private saveDropshipping(): void {
		let url = '/api/dropshippings/create';
		if (this.props.dropshipping) {
			url = `/api/dropshippings/${this.props.dropshipping._id}`;
		}

		const dropshippingToSave = JSON.parse(JSON.stringify(this.state.dropshipping));
		if (this.state.dropshipping.selectedTitleAttributeTemplate && this.state.dropshipping.selectedTitleAttributeTemplate._id === 'no-attribute-template-id') {
			dropshippingToSave.selectedTitleAttributeTemplate = undefined;
		}

		if (this.state.dropshipping.selectedPriceTagAttributeTemplate && this.state.dropshipping.selectedPriceTagAttributeTemplate._id === 'no-attribute-template-id') {
			dropshippingToSave.selectedPriceTagAttributeTemplate = undefined;
		}

		if (this.state.dropshipping.selectedWIAttributeTemplate && this.state.dropshipping.selectedWIAttributeTemplate._id === 'no-attribute-template-id') {
			dropshippingToSave.selectedWIAttributeTemplate = undefined;
		}

		this.authService.fetch<DropshippingModel>(url, {
			method: 'POST',
			body: JSON.stringify(dropshippingToSave)
		}).then((_): void => {
			this.closeEditor();
			this.setState({dropshipping: this.setDropshipping(null), products: [], loadedTemplates: [], currentAttributeHeaderToAdd: '', currentSelectedAttributHeader: '', selectedProduct: null});
		});
	}

	private deleteDropshipping(): void {
		if (this.props.dropshipping) {
			const dropshippingId = this.props.dropshipping._id;
			const { dialog } = this.props;

			dialog.confirm({
				title: 'Eintrag löschen',
				message: 'Soll der Eintrag wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'error',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<DropshippingModel>(`/api/dropshippings/${dropshippingId}`, {
					method: 'DELETE',
					body: JSON.stringify(this.state.dropshipping)
				}).then((_): void => {
					this.closeEditor();
				});
			});
		}
	}

	private handleCheckedDropshippingChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const tempDropshipping = this.state.dropshipping;

		tempDropshipping.onlyDropshipping = event.target.checked;
		this.setState({dropshipping: tempDropshipping});
	}

	private handleTitleTemplateSelectionChange(selectedAttributeTemplate: ValueType<OptionType>): void {
		const selectedTemplate = this.state.loadedTemplates.find((t): boolean => (t._id === ((selectedAttributeTemplate as OptionType).value as AttributeTemplate)._id));
		const tempDropshipping = this.state.dropshipping;

		if (selectedTemplate) {
			tempDropshipping.selectedTitleAttributeTemplate = selectedTemplate;
		} else {
			tempDropshipping.selectedTitleAttributeTemplate = undefined;
		}
		this.setState({dropshipping: tempDropshipping});
	}

	private handlePriceTagTemplateSelectionChange(selectedAttributeTemplate: ValueType<OptionType>): void {
		const selectedTemplate = this.state.loadedTemplates.find((t): boolean => (t._id === ((selectedAttributeTemplate as OptionType).value as AttributeTemplate)._id));
		const tempDropshipping = this.state.dropshipping;

		if (selectedTemplate) {
			tempDropshipping.selectedPriceTagAttributeTemplate = selectedTemplate;
		} else {
			tempDropshipping.selectedPriceTagAttributeTemplate = undefined;
		}
		this.setState({dropshipping: tempDropshipping});
	}

	private handleWITemplateSelectionChange(selectedAttributeTemplate: ValueType<OptionType>): void {
		const selectedTemplate = this.state.loadedTemplates.find((t): boolean => (t._id === ((selectedAttributeTemplate as OptionType).value as AttributeTemplate)._id));
		const tempDropshipping = this.state.dropshipping;

		if (selectedTemplate) {
			tempDropshipping.selectedWIAttributeTemplate = selectedTemplate;
		} else {
			tempDropshipping.selectedWIAttributeTemplate = undefined;
		}
		this.setState({dropshipping: tempDropshipping});
	}

	private textInput(): ReactNode {

		return (
			<TextField
				id={'name'}
				label={'NAME'}
				className={this.props.classes.textField}
				onChange={(event): void => {this.handleTextChange(event);}}
				placeholder={'NAME'}
				fullWidth
				value={this.state.dropshipping.name}
			/>
		);
	}

	private handleRemoveTitleChip(attributeToDelete: string, type: string): void {
		const currentDropshipping = this.state.dropshipping;

		if (type === 'title') {
			const titleAttributes = currentDropshipping.titleAttributes.filter((a: string): boolean => a !== attributeToDelete);
			currentDropshipping.titleAttributes = titleAttributes;
		} else if (type === 'priceTag') {
			const priceTagAttributes = currentDropshipping.priceTagAttributes.filter((a: string): boolean => a !== attributeToDelete);
			currentDropshipping.priceTagAttributes = priceTagAttributes;
		} else if (type === 'wi') {
			for (const headerToAttributes of currentDropshipping.headersToAttributes) {
				if (headerToAttributes.attributeHeader === this.state.currentSelectedAttributHeader) {
					const attributes = headerToAttributes.wiAttributes.filter((a: AttributeToAttribute): boolean => a.attributeNameAttribute !== attributeToDelete);
					headerToAttributes.wiAttributes = attributes;
				}
			}
		}

		this.setState({dropshipping: currentDropshipping});
	}

	private handleCurrentAttributeHeaderChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		this.setState({currentAttributeHeaderToAdd : event.target.value});
	}

	private handleAttributHeaderSelectionChange(event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>): void {

		this.setState({currentSelectedAttributHeader: event.target.value as string});
	}

	private addAttributeHeaderButtonClicked(): void {
		const currentAttributeHeaderToAdd = this.state.currentAttributeHeaderToAdd;
		const dropshippingToEdit = JSON.parse(JSON.stringify(this.state.dropshipping));

		if (currentAttributeHeaderToAdd !== '' && dropshippingToEdit.headersToAttributes.filter((h: DropshippingHeaderToAttributes): boolean => h.attributeHeader === currentAttributeHeaderToAdd).length === 0) {
			dropshippingToEdit.headersToAttributes.push({attributeHeader: currentAttributeHeaderToAdd, wiAttributes: []});
		}

		this.setState({
			dropshipping: dropshippingToEdit, currentAttributeHeaderToAdd: ''
		});
	}

	private getAddAttributeHeaderArea(): ReactNode {
		const classes = this.props.classes;
		return (

			<div className={classes.dropshippingAddContainer}>
				<div className={classes.dropshippingTextField}>
					<TextField
						label={'Neue Attributüberschrift'}
						value={this.state.currentAttributeHeaderToAdd}
						margin="dense"
						onChange={(event): void => {this.handleCurrentAttributeHeaderChange(event);}}
						fullWidth
					/>
				</div>
				<div className={classes.addDropshippingButton}>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						startIcon={<AddIcon />}
						onClick={this.addAttributeHeaderButtonClicked.bind(this)}
					>
						Hinzufügen
					</Button>
				</div>
			</div>

		);
	}

	private removeHeaderToAttributes(headerToAttributes: DropshippingHeaderToAttributes): void {
		const currentDropshipping = this.state.dropshipping;
		let currentSelectedAttributHeader = this.state.currentSelectedAttributHeader;

		let index = -1;

		for (let i = 0; i < currentDropshipping.headersToAttributes.length; i++) {
			if (currentDropshipping.headersToAttributes[i].attributeHeader === headerToAttributes.attributeHeader) {
				index = i;
				break;
			}
		}

		if (index > -1) {
			currentDropshipping.headersToAttributes.splice(index, 1);
			if (headerToAttributes.attributeHeader === this.state.currentSelectedAttributHeader) {
				currentSelectedAttributHeader = '';
			}
		}

		this.setState({dropshipping: currentDropshipping, currentSelectedAttributHeader: currentSelectedAttributHeader});
	}

	private getAttributeHeadersTable(): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={classes.dropshippingsTable}>
				<Typography className={classes.dropshippingsTableHeader}>
					{'Attributüberschriften'}
				</Typography>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.dropshipping.headersToAttributes.map((headerToAttributes: DropshippingHeaderToAttributes): ReactNode => (
							<TableRow key={headerToAttributes.attributeHeader}>
								<TableCell component="th" scope="row">
									{headerToAttributes.attributeHeader}
								</TableCell>
								<TableCell align="right">{
									<IconButton className={classes.deleteButton} size="small" onClick={this.removeHeaderToAttributes.bind(this, headerToAttributes)}>
										<DeleteForeverOutlinedIcon />
									</IconButton>
								}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}

	private handleProductChange(selectedProduct: Product | null): void {
		this.setState({selectedProduct: selectedProduct});
		if (selectedProduct) {
			this.setState({isLoadingPreview: true});
			this.authService.fetch<DropshippingPreviewResponse>(`/api/dropshippings/preview/${selectedProduct.productCode}`, {
				method: 'POST',
				body: JSON.stringify({titleAttributes: this.state.dropshipping.titleAttributes, priceTagAttributes: this.state.dropshipping.priceTagAttributes, headersToAttributes: this.state.dropshipping.headersToAttributes})
			}).then((response): void => {
				if (response.success) {
					this.setState({dropshippingPreview: response.data});
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
				this.setState({isLoadingPreview: false});
			});
		}
	}

	private getOptions(): OptionType[] {
		const attributeTemplate: AttributeTemplate = {
			_id: 'no-attribute-template-id',
			name: 'Keine Attibutvorlage',
			attributes: []
		};
		let options: OptionType[] = [{label: attributeTemplate.name, value: attributeTemplate}];

		this.state.loadedTemplates.sort((a: AttributeTemplate, b: AttributeTemplate) => (a.name > b.name) ? 1 : -1).forEach((a: AttributeTemplate): void => {
			options = options.concat(this.optionsForAttributeTemplates(a));
		});

		return options;
	}

	private optionsForAttributeTemplates(attributeTemplate: AttributeTemplate): OptionType[] {
		const options: OptionType[] = [];

		options.push({value: attributeTemplate, label: attributeTemplate.name});

		return options;
	}

	private getAttributeValueOptions(attributes: ProductAttribute[]): OptionType[] {
		let options: OptionType[] = [];

		attributes.sort((a: ProductAttribute, b: ProductAttribute) => (a.name > b.name) ? 1 : -1).forEach((a: ProductAttribute): void => {
			options = options.concat(this.optionsForAttributeValue(a));
		});

		return options;
	}

	private optionsForAttributeValue(attribute: ProductAttribute): OptionType[] {
		const options: OptionType[] = [];

		options.push({value: attribute.name, label: attribute.name});

		return options;
	}

	private createWIChipContainer(attribute: AttributeToAttribute, index: number): ReactNode {
		const classes = this.props.classes;
		const attributes = this.getAttributes();

		let attributeValueOption: ValueType<OptionType> | null = null;
		if (attribute.attributeValueAttribute !== '') {
			attributeValueOption = {
				label: attribute.attributeValueAttribute,
				value: attribute.attributeValueAttribute
			};
		}

		return (
			<div className={classes.wiChipContainer}>
				<Chip key={index} color="primary" style={{marginRight: 8, alignSelf: 'flex-start'}} label={Buffer.from(attribute.attributeNameAttribute, 'base64').toString()} variant="outlined" onDelete={this.handleRemoveTitleChip.bind(this, attribute.attributeNameAttribute, 'wi')} />
				<Typography style={{marginLeft: 'auto' }} display="inline" variant="h6">:</Typography>
				<Select
					className={classes.attributeValueAttributeSelect}
					value={attributeValueOption}
					onChange={(option): void => {this.handleWIAttributeSelectionChange(option, attribute);}}
					options={this.getAttributeValueOptions(attributes)}
					placeholder={'Attributwert auswählen...'}
					noOptionsMessage={(): string => ('Keine Treffer')}
					styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
					menuPortalTarget={document.body}
					isSearchable
				/>
			</div>
		);
	}

	private getAttributes(): ProductAttribute[] {
		let attributes: ProductAttribute[] = [];
		for (const product of this.state.products) {
			for (const attribute of product.attributes) {
				const attributeFound = attributes.find((a) => a.key === attribute.key);
				if (!attributeFound) {
					attributes.push(attribute);
				}
			}
		}

		attributes = attributes.sort((a, b): number => (a.name > b.name) ? 1 : -1);

		return attributes;
	}

	private handleWIAttributeSelectionChange(selectedAttributeValueAttribute: ValueType<OptionType>, attribute: AttributeToAttribute): void {
		if (selectedAttributeValueAttribute) {
			const currentDropshipping = this.state.dropshipping;

			for (const headerToAttributes of this.state.dropshipping.headersToAttributes) {
				if (this.state.currentSelectedAttributHeader === headerToAttributes.attributeHeader) {
					for (const wiAttribute of headerToAttributes.wiAttributes) {
						if (wiAttribute.attributeNameAttribute === attribute.attributeNameAttribute) {
							wiAttribute.attributeValueAttribute = ((selectedAttributeValueAttribute as OptionType).value as string);
						}
					}
				}
			}
			this.setState({dropshipping: currentDropshipping});
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		let optionTitle: ValueType<OptionType> | null = null;
		if (this.state.dropshipping.selectedTitleAttributeTemplate) {
			optionTitle = {
				label: this.state.dropshipping.selectedTitleAttributeTemplate.name,
				value: this.state.dropshipping.selectedTitleAttributeTemplate
			};
		}

		let optionPriceTag: ValueType<OptionType> | null = null;
		if (this.state.dropshipping.selectedPriceTagAttributeTemplate) {
			optionPriceTag = {
				label: this.state.dropshipping.selectedPriceTagAttributeTemplate.name,
				value: this.state.dropshipping.selectedPriceTagAttributeTemplate
			};
		}

		let optionWI: ValueType<OptionType> | null = null;
		if (this.state.dropshipping.selectedWIAttributeTemplate) {
			optionWI = {
				label: this.state.dropshipping.selectedWIAttributeTemplate.name,
				value: this.state.dropshipping.selectedWIAttributeTemplate
			};
		}

		return (
			<Dialog open={this.props.editorOpen} onClose={this.closeEditor.bind(this)} fullWidth={true} maxWidth={'xl'}>
				<DialogTitle id="form-dialog-title">{'Neue Dropshipping Kategorie'}</DialogTitle>
				<DialogContent>
					{this.textInput()}
					{this.categorySelect()}
					<FormControlLabel
						className={classes.dropshippingCheckbox}
						control={
							<Checkbox
								checked={this.state.dropshipping.onlyDropshipping}
								onChange={(event): void => {this.handleCheckedDropshippingChange(event);}}
								color="primary"
								value={this.state.dropshipping.onlyDropshipping}
							/>
						}
						label="Nur Dropshipping Produkte"
					/>
					{this.state.dropshipping.categoryCode &&
						<>
							<ExpansionPanel defaultExpanded={false}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
								>
									<Typography className={classes.panelTitle} variant="h6">Titel</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.panelDetails}>
									<div className={classes.chipContainer}>
										<Typography color="primary" variant="h6" className={classes.chipContainerTitle}>Titel zusammengestellt aus: </Typography>
										<Chip style={{marginRight: 8, marginBottom: 8}} label={'Produktname'} variant="outlined" />
										{this.state.dropshipping.titleAttributes.map((attribute, index): ReactNode => (
											<Chip key={index} color="primary" style={{marginRight: 8, marginBottom: 8}} label={Buffer.from(attribute, 'base64').toString()} variant="outlined" onDelete={this.handleRemoveTitleChip.bind(this, attribute, 'title')} />
										))}
									</div>
									<FormControl className={classes.templateSelect}>
										<Typography variant="subtitle1">Attributvorlage</Typography>
										<Select
											value={optionTitle}
											onChange={this.handleTitleTemplateSelectionChange.bind(this)}
											options={this.getOptions()}
											placeholder={'Attributvorlage auswählen...'}
											noOptionsMessage={(): string => ('Keine Treffer')}
											styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											isSearchable
										/>
									</FormControl>

									{this.attributeSelect('title')}
								</ExpansionPanelDetails>
							</ExpansionPanel>
							<ExpansionPanel defaultExpanded={false}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
								>
									<Typography className={classes.panelTitle} variant="h6">Preisschild</Typography>
								</ExpansionPanelSummary>

								<ExpansionPanelDetails className={classes.panelDetails}>
									<div className={classes.chipContainer}>
										<Typography color="primary" variant="h6" className={classes.chipContainerTitle}>Preisschild zusammengestellt aus: </Typography>
										{this.state.dropshipping.priceTagAttributes.map((attribute, index): ReactNode => (
											<Chip key={index} color="primary" style={{marginRight: 8, marginBottom: 8}} label={Buffer.from(attribute, 'base64').toString()} variant="outlined" onDelete={this.handleRemoveTitleChip.bind(this, attribute, 'priceTag')} />
										))}
									</div>
									<FormControl className={classes.templateSelect}>
										<Typography variant="subtitle1">Attributvorlage</Typography>
										<Select
											value={optionPriceTag}
											onChange={this.handlePriceTagTemplateSelectionChange.bind(this)}
											options={this.getOptions()}
											placeholder={'Attributvorlage auswählen...'}
											noOptionsMessage={(): string => ('Keine Treffer')}
											styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
											menuPortalTarget={document.body}
											isSearchable
										/>
									</FormControl>

									{this.attributeSelect('priceTag')}
								</ExpansionPanelDetails>

							</ExpansionPanel>
							<ExpansionPanel defaultExpanded={false}>
								<ExpansionPanelSummary
									expandIcon={<ExpandMoreIcon />}
								>
									<Typography className={classes.panelTitle} variant="h6">Wichtigsten Infos</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails className={classes.panelDetails}>
									{this.getAddAttributeHeaderArea()}
									{this.state.dropshipping.headersToAttributes.length > 0 &&
										<>
											{this.getAttributeHeadersTable()}
											<FormControl className={classes.attributeHeaderSelect}>
												<InputLabel>Attributüberschrift</InputLabel>
												<SelectM
													value={this.state.currentSelectedAttributHeader ? this.state.currentSelectedAttributHeader : ''}
													onChange={this.handleAttributHeaderSelectionChange.bind(this, )}
												>
													{this.state.dropshipping.headersToAttributes.map((headerToAttributes): ReactNode => (
														<MenuItem value={headerToAttributes.attributeHeader} key={headerToAttributes.attributeHeader}>
															{headerToAttributes.attributeHeader}
														</MenuItem>
													))}
												</SelectM>
											</FormControl>
											{this.state.currentSelectedAttributHeader !== '' &&
												<>
													<div style={{marginTop: 40}}>
														<Typography color="primary" variant="h6" className={classes.chipContainerTitle}>Attributüberschrift mit Attributen: </Typography>
														{this.state.dropshipping.headersToAttributes.filter(headerToAttributes => headerToAttributes.attributeHeader === this.state.currentSelectedAttributHeader).map((headerToAttributes, index): ReactNode => (
															<div key={index} className={classes.wiAttributesContainer}>
																{headerToAttributes.wiAttributes.map((attribute, index): ReactNode => (
																	this.createWIChipContainer(attribute, index)
																))}
															</div>

														))}
													</div>
													<FormControl className={classes.templateSelect}>
														<Typography variant="subtitle1">Attributvorlage</Typography>
														<Select
															value={optionWI}
															onChange={this.handleWITemplateSelectionChange.bind(this)}
															options={this.getOptions()}
															placeholder={'Attributvorlage auswählen...'}
															noOptionsMessage={(): string => ('Keine Treffer')}
															styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
															menuPortalTarget={document.body}
															isSearchable
														/>
													</FormControl>
													{this.attributeSelect('wi')}
												</>
											}
										</>
									}

								</ExpansionPanelDetails>
							</ExpansionPanel>
							{this.state.dropshipping.categoryCode !== '' &&
								<>
									<Typography variant="h6" className={classes.previewHeader}>Vorschau: </Typography>
									<ProductSelect
										onChange={this.handleProductChange.bind(this)}
										selectedProductCode={null}
										disabled={false}
										categoryCodeToLookForProducts={this.state.dropshipping.categoryCode}
										visualRule={false}
									/>
									{this.state.isLoadingPreview &&
										<CircularProgress className={classes.loadingIndicator}/>
									}
									{this.state.selectedProduct && !this.state.isLoadingPreview &&
										<>
											<div>
												<Typography display="inline" variant="h6">Titel: </Typography>
												<Typography display="inline">{this.state.dropshippingPreview.title}</Typography>
											</div>
											<div>
												<Typography display="inline" variant="h6">Preisschild: </Typography>
												<Typography display="inline">{this.state.dropshippingPreview.priceTag}</Typography>
											</div>
											<Typography display="inline" variant="h6">Wichtigsten Infos: </Typography>
											{this.state.dropshippingPreview.attributes.map((attribute, index): ReactNode => (
												<div key={index}>
													{attribute.attributes.map((attributeText, index): ReactNode => (
														<div className={classes.wiContainer} key={index}>
															<Typography variant="subtitle2">{attribute.header}</Typography>
															<Typography>{attributeText}</Typography>
														</div>
													))}
												</div>
											))}
										</>
									}
								</>
							}
						</>
					}

				</DialogContent>
				<DialogActions>
					{this.props.dropshipping &&
						<Button onClick={this.deleteDropshipping.bind(this)} className={classes.deleteButton}>
							Löschen
						</Button>
					}
					<Button onClick={this.closeEditor.bind(this)} color="primary">
						Abbrechen
					</Button>
					<Button onClick={this.saveDropshipping.bind(this)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withDialog()(withStyles(dropshippingEditorStyles)(DropshippingEditor));
