import React, {ReactElement, FC, useState, ReactNode, useEffect, useCallback} from 'react';
import {
	Button,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	FormControl,
	FormLabel,
	IconButton,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorEditorQuestionsAnswersStyles from './AdvisorEditorQuestionsAnswersStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Advisor, AdvisorAnswer, AdvisorQuestion, AdvisorQuestionType, getAdvisorQuestionTypeName } from '@common/models/Advisor';
import CategoryMultiSelect from '@common/CategoryMultiSelect';
import AdvisorQuestionEditor from 'Advisor/AdvisorQuestionEditor';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AdvisorAnswerEditor from 'Advisor/AdvisorAnswerEditor';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import AdvisorDiagramEditor from 'Advisor/AdvisorDiagramEditor';
import FlagIcon from '@material-ui/icons/Flag';
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle, DropResult } from 'react-beautiful-dnd';


interface Props extends WithStyles<typeof advisorEditorQuestionsAnswersStyles>, WithSnackbarProps {
	dialog?: ConfirmDialog;
	advisor: Advisor;
	handleAdvisorCategoriesChange: (categoryCodes: string[]) => void;
	handleAdvisorQuestionsChange: (questions: AdvisorQuestion[], startingQuestionId: string | undefined) => void;
	setDisableAnswerSort: React.Dispatch<React.SetStateAction<boolean>>;
	disableAnswerSort: boolean;
	expanded: boolean;
}

const AdvisorEditorQuestionsAnswers: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const [questionEditorOpen, setQuestionEditorOpen] = useState(false);
	const [answerEditorOpen, setAnswerEditorOpen] = useState(false);
	const [diagramEditorOpen, setDiagramEditorOpen] = useState(false);
	const [advisorQuestionToEdit, setAdvisorQuestionToEdit] = useState<AdvisorQuestion | null>(null);
	const [advisorAnswerToEdit, setAdvisorAnswerToEdit] = useState<AdvisorAnswer | null>(null);
	const [questionEditorPropsHandlerActive, setQuestionEditorPropsHandlerActive] = useState(false);
	const [answerEditorPropsHandlerActive, setAnswerEditorPropsHandlerActive] = useState(false);
	const [followingQuestionIdSourceHovered, setFollowingQuestionIdSourceHovered] = useState<string | null>(null);
	const [currentAdvisor, setCurrentAdvisor] = useState<Advisor>(props.advisor);

	useEffect(() => {
		setCurrentAdvisor(props.advisor);
	}, [props.advisor]);

	function closeQuestionEditor(): void {
		setQuestionEditorOpen(false);
	}

	function closeAnswerEditor(): void {
		setAnswerEditorOpen(false);
	}

	function closeDiagramEditor(): void {
		setDiagramEditorOpen(false);
	}

	function handleQuestionChange(advisorQuestion: AdvisorQuestion): void {
		const advisorQuestions = currentAdvisor.questions;

		if (advisorQuestion._id) {
			const questionIndex = advisorQuestions.findIndex(a => a._id === advisorQuestion._id);
			advisorQuestions[questionIndex] = advisorQuestion;
		} else {
			advisorQuestions.push(advisorQuestion);
		}
		
		props.handleAdvisorQuestionsChange(advisorQuestions, currentAdvisor.startingQuestion);
	}

	function handleAnswerChange(advisorAnswer: AdvisorAnswer): void {
		const advisorQuestions = currentAdvisor.questions;

		if (advisorQuestionToEdit) {
			const questionIndex = advisorQuestions.findIndex(q => q._id === advisorQuestionToEdit._id);
			if (!advisorAnswer._id) {
				advisorQuestions[questionIndex].answers.push(advisorAnswer);
			} else {
				const answerIndex = advisorQuestions[questionIndex].answers.findIndex(a => a._id === advisorAnswer._id);
				advisorQuestions[questionIndex].answers[answerIndex] = advisorAnswer;
			}
			props.handleAdvisorQuestionsChange(advisorQuestions, currentAdvisor.startingQuestion);
		}
	}

	function getQuestionsUsedByHistoryBased(questionToLookFor: AdvisorQuestion): AdvisorAnswer[] {
		const usedInAnswers: AdvisorAnswer[] = [];
		for (const question of currentAdvisor.questions) {
			for (const answer of question.answers) {
				if (answer.historyBased) {
					if (questionToLookFor && answer.historyBased.defaultFollowingQuestionId === questionToLookFor._id) {
						usedInAnswers.push(answer);
					}
					for (const historyBasedFollowingQuestion of answer.historyBased.historyBasedFollowingQuestions) {
						if (questionToLookFor._id && historyBasedFollowingQuestion.followingQuestionId === questionToLookFor._id) {
							usedInAnswers.push(answer);
						}
					}
				}
			}
		}
		return usedInAnswers;
	}

	function getAnswersUsedByHistoryBased(answerToLookFor: AdvisorAnswer): AdvisorAnswer[] {
		const usedInAnswers: AdvisorAnswer[] = [];
		for (const question of currentAdvisor.questions) {
			for (const answer of question.answers) {
				if (answer.historyBased) {
					for (const historyBasedFollowingQuestion of answer.historyBased.historyBasedFollowingQuestions) {
						for (const historyBasedAnswerId of historyBasedFollowingQuestion.historyBasedAnswerIds) {
							if (answerToLookFor._id && historyBasedAnswerId === answerToLookFor._id) {
								usedInAnswers.push(answer);
							}
						}
					}
				}
			}
		}
		return usedInAnswers;
	}

	function handleQuestionDelete(advisorQuestion: AdvisorQuestion | null): void {
		const dialog = props.dialog;

		if (!advisorQuestion) {
			props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			return;
		}

		if (dialog) {
			const questionsUsedByHistoryBased = getQuestionsUsedByHistoryBased(advisorQuestion);
			if (questionsUsedByHistoryBased.length > 0) {
				dialog.confirm({
					title: 'Frage Löschen',
					message: `Für diese Frage besteht eine Verknüpfung (Vergangenheitsabhängige nachfolgende Fragen) bei den Antworten: ${questionsUsedByHistoryBased.map(a => a.answer).join(', ')}. Diese Verknüpfungen müssen entfernt werden, bevor die Frage gelöscht werden kann!`,
					ok: {
						text: 'Löschen',
						color: 'default',
						variant: 'text'
					}
				});
			} else {
				dialog.confirm({
					title: 'Frage Löschen',
					message: 'Soll die Frage wirklich gelöscht werden?',
					ok: {
						text: 'Löschen',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					const advisorQuestions = currentAdvisor.questions;
	
					// remove qusetion
					const questionIndex = advisorQuestions.findIndex(q => q._id === advisorQuestion._id);
					advisorQuestions.splice(questionIndex, 1);
	
					// remove link as following question
					for (const question of advisorQuestions) {
						if (question.followingQuestion === advisorQuestion._id) {
							question.followingQuestion = undefined;
						}
						for (const answer of question.answers) {
							if (answer.followingQuestion === advisorQuestion._id) {
								answer.followingQuestion = undefined;
							}
						}
					}
	
					props.handleAdvisorQuestionsChange(advisorQuestions, currentAdvisor.startingQuestion);
					setQuestionEditorOpen(false);
				}).catch((error): void => {
					console.log(`Error while deleting advisor question: ${error}`);
				});
			}
		}
	}

	function handleAnswerDelete(advisorAnswer: AdvisorAnswer | null, advisorQuestion: AdvisorQuestion | null): void {
		const dialog = props.dialog;

		if (!advisorAnswer || !advisorQuestion) {
			props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			return;
		}

		if (dialog) {
			const answersUsedByHistoryBased = getAnswersUsedByHistoryBased(advisorAnswer);
			if (answersUsedByHistoryBased.length > 0) {
				dialog.confirm({
					title: 'Antwort Löschen',
					message: `Für diese Antwort besteht eine Verknüpfung (Vergangenheitsabhängige nachfolgende Fragen) bei den Antworten: ${answersUsedByHistoryBased.map(a => a.answer).join(', ')}. Diese Verknüpfungen müssen entfernt werden, bevor die Antwort gelöscht werden kann!`,
					ok: {
						text: 'OK',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: '',
						color: 'secondary',
						variant: 'text'
					},
				});
			} else {
				dialog.confirm({
					title: 'Antwort Löschen',
					message: 'Soll die Antwort wirklich gelöscht werden?',
					ok: {
						text: 'Löschen',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					const advisorQuestions = currentAdvisor.questions;
					const questionIndex = advisorQuestions.findIndex(q => q._id === advisorQuestion._id);
					const answerIndex = advisorQuestions[questionIndex].answers.findIndex(a => a._id === advisorAnswer._id);
					advisorQuestions[questionIndex].answers.splice(answerIndex, 1);
					props.handleAdvisorQuestionsChange(advisorQuestions, currentAdvisor.startingQuestion);
					setAnswerEditorOpen(false);
				}).catch((error): void => {
					console.log(`Error while deleting advisor answer: ${error}`);
				});
			}
		}
	}

	function handleHoverFollowingQuestion(question: AdvisorQuestion, answer: AdvisorAnswer | null, questionType: AdvisorQuestionType, hoverOverType: string): void {
		if (hoverOverType === 'question') {
			if ((questionType === AdvisorQuestionType.MULTISELECT || questionType === AdvisorQuestionType.PRICESLIDER) && question.followingQuestion) {
				setFollowingQuestionIdSourceHovered(question.followingQuestion);
			}
		} else if (hoverOverType === 'nestedAnswer') {
			if (questionType === AdvisorQuestionType.SINGLESELECT && answer && answer.followingQuestion) {
				setFollowingQuestionIdSourceHovered(answer.followingQuestion);
			}
		}
	}

	function handleHoverOutFollowingQuestion(): void {
		if (followingQuestionIdSourceHovered) {
			setFollowingQuestionIdSourceHovered(null);
		}
	}

	function handleNewQuestionButtonClicked(): void {
		setAdvisorQuestionToEdit(null);
		setQuestionEditorOpen(true);
		setQuestionEditorPropsHandlerActive(true);
	}

	function handleEditQuestionButtonClicked(advisorQuestion: AdvisorQuestion): void {
		setAdvisorQuestionToEdit(advisorQuestion);
		setQuestionEditorOpen(true);
		setQuestionEditorPropsHandlerActive(true);
	}

	function handleNewAnswerButtonClicked(advisorQuestion: AdvisorQuestion): void {
		setAdvisorAnswerToEdit(null);
		setAdvisorQuestionToEdit(advisorQuestion);
		setAnswerEditorOpen(true);
		setAnswerEditorPropsHandlerActive(true);
	}

	function handleEditAnswerButtonClicked(advisorQuestion: AdvisorQuestion, advisorAnswer: AdvisorAnswer): void {
		setAdvisorQuestionToEdit(advisorQuestion);
		setAdvisorAnswerToEdit(advisorAnswer);
		setAnswerEditorOpen(true);
		setAnswerEditorPropsHandlerActive(true);
	}

	const openPreview = useCallback((advisor: Advisor, advisorQuestion: AdvisorQuestion) => {
		const url = `/product-advisor-preview/${advisor._id}/?q=${advisorQuestion._id}`;
		window.open(url, '_blank')?.focus();
	}, []);

	function handleOnDragEnd(result: DropResult, advisorQuestion: AdvisorQuestion): void {
		if (!result.destination) {
			return;
		}
		props.setDisableAnswerSort(true);

		const tempAdvisorQuestion = JSON.parse(JSON.stringify(advisorQuestion));

		const answers = tempAdvisorQuestion.answers;
		const [dragedAnswer] = answers.splice(result.source.index, 1);
		answers.splice(result.destination.index, 0, dragedAnswer);

		handleQuestionChange(tempAdvisorQuestion);
	}

	function treeItemForQuestion(advisorQuestion: AdvisorQuestion, advisor: Advisor): ReactNode {
		return (
			<TreeItem
				onMouseOver={(): void => handleHoverFollowingQuestion(advisorQuestion, null, advisorQuestion.questionType, 'question')}
				onMouseOut={(): void => handleHoverOutFollowingQuestion()}
				className={`${classes.treeItem} ${advisorQuestion._id === followingQuestionIdSourceHovered ? classes.treeItemHighlighted : ''}`}
				key={advisorQuestion._id ?? 'new-question'}
				nodeId={advisorQuestion._id ?? ''}
				label={
					<div className={classes.treeLabelContainer}>
						<div className={classes.nameContainer}>
							{advisorQuestion.question}
						</div>
						<div className={classes.buttonsContainer}>
							{advisorQuestion._id && advisorQuestion._id === currentAdvisor.startingQuestion && 
									<Tooltip title='Startfrage' placement='top'>
										<span>
											<FlagIcon color="primary" className={classes.startingQuestionIcon}/>
										</span>
									</Tooltip>
							}
							<Typography noWrap className={classes.questionType} variant="subtitle2">{getAdvisorQuestionTypeName(advisorQuestion.questionType)}</Typography>
							<Tooltip title='Neue Antwort'>
								<span>
									<IconButton disabled={advisorQuestion.questionType === AdvisorQuestionType.PRICESLIDER} color="secondary" className={classes.newAnswerButton} size="small" onClick={(event): void => {event.stopPropagation(); handleNewAnswerButtonClicked(advisorQuestion);}}>
										<AddCircleOutlineIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title='Vorschau'>
								<span>
									<IconButton color="inherit" disabled={currentAdvisor.categoryCodes.length === 0} className={classes.previewButton} size="small" onClick={(event): void => {event.stopPropagation(); openPreview(advisor, advisorQuestion);}}>
										<VisibilityIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title='Frage Bearbeiten'>
								<span>
									<IconButton color="primary" className={classes.editQuestionButton} size="small" onClick={(event): void => {event.stopPropagation(); handleEditQuestionButtonClicked(advisorQuestion);}}>
										<EditIcon />
									</IconButton>
								</span>
							</Tooltip>
							<Tooltip title='Frage Löschen'>
								<span>
									<IconButton className={classes.deleteQuestionButton} size="small" onClick={(event): void => {event.stopPropagation(); handleQuestionDelete(advisorQuestion);}}>
										<DeleteIcon />
									</IconButton>
								</span>
							</Tooltip>
						</div>
					</div>
				}
			>
				{advisorQuestion.answers.length > 0 && 
				<DragDropContext onDragEnd={(dragResult): void => handleOnDragEnd(dragResult, advisorQuestion)}>
					<Droppable droppableId={advisorQuestion._id ?? ''}>
						{(provided, snapshot): ReactElement => (
							<div {...provided.droppableProps} ref={provided.innerRef} style={getDragListStyle(snapshot.isDraggingOver)}>
								{advisorQuestion.answers.map((answer: AdvisorAnswer, index: number): ReactNode => (
									treeItemForAnswer(answer, advisorQuestion, index)
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				}
			</TreeItem>
		);
	}

	const getDragItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined): React.CSSProperties => ({
		background: isDragging ? '#1F4C79' : '',
		...draggableStyle
	});

	const getDragListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
		background: isDraggingOver ? '#4ABCB1' : '',
		opacity: isDraggingOver ? 0.8 : '',
	});

	function treeItemForAnswer(advisorAnswer: AdvisorAnswer, advisorQuestion: AdvisorQuestion, index: number): ReactNode {
		return (
			<Draggable key={advisorAnswer._id ?? 'new-answer'} draggableId={advisorAnswer._id ?? 'new'} index={index} isDragDisabled={props.disableAnswerSort}>
				{(provided, snapshot): ReactElement => (
					<TreeItem
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getDragItemStyle(
							snapshot.isDragging,
							provided.draggableProps.style
						)}
						onMouseOver={(event): void => {event.stopPropagation(); handleHoverFollowingQuestion(advisorQuestion, advisorAnswer, advisorQuestion.questionType, 'nestedAnswer');}}
						onMouseOut={(): void => handleHoverOutFollowingQuestion()}
						key={advisorAnswer._id ?? 'new'}
						nodeId={advisorAnswer._id ?? ''}
						label={
							<div className={classes.treeLabelContainer}>
								<div>
									{advisorAnswer.answer}
								</div>
								<div className={classes.buttonsContainer}>
									<Tooltip title='Antwort Bearbeiten'>
										<span>
											<IconButton color="primary" className={classes.editButton} size="small" onClick={(): void => handleEditAnswerButtonClicked(advisorQuestion, advisorAnswer)}>
												<EditIcon />
											</IconButton>
										</span>
									</Tooltip>
									<Tooltip title='Antwort Löschen'>
										<span>
											<IconButton className={classes.deleteQuestionButton} size="small" onClick={(): void => {handleAnswerDelete(advisorAnswer, advisorQuestion);}}>
												<DeleteIcon />
											</IconButton>
										</span>
									</Tooltip>
								</div>
							</div>
						}
					>
					</TreeItem>
				)}
			</Draggable>
		);
	}

	return (
		<ExpansionPanel
			defaultExpanded={props.expanded}
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Fragen & Antworten</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				<FormControl className={classes.categoriesContainer}>
					<FormLabel className={classes.categoriesLabel}>Kategorien *</FormLabel>
					<CategoryMultiSelect 
						onChange={(categories): void => {props.handleAdvisorCategoriesChange(categories ? categories.map((c) => c.code) : []);}}
						selectedCategoryCodes={currentAdvisor.categoryCodes}
						topLevelCategoriesExcluded={false}
						disabled={false}
					/>
				</FormControl>
				{(currentAdvisor.categoryCodes.length > 0 || currentAdvisor.questions.length > 0) &&
				<>
					<div className={classes.topButtonsContainer}>
						<Button
							variant="outlined"
							color="secondary"
							size="small"
							className={classes.addQuestionButton}
							startIcon={<AddIcon />}
							onClick={handleNewQuestionButtonClicked}
						>
						Neue Frage
						</Button>
						<Button
							variant="contained"
							color="secondary"
							size="small"
							className={classes.openEditorButton}
							startIcon={<PresentToAllIcon />}
							onClick={(): void => setDiagramEditorOpen(true)}
						>
						Editor
						</Button>
					</div>
					<FormControl>
						{currentAdvisor.questions.length > 0 &&
							<FormLabel className={classes.questionAnswersLabel}>Fragen & Antworten</FormLabel>
						}
						<TreeView
							defaultCollapseIcon={<ExpandMoreIcon />}
							defaultExpandIcon={<ChevronRightIcon />}
						>
							{currentAdvisor.questions.map((question: AdvisorQuestion): ReactNode => (
								treeItemForQuestion(question, currentAdvisor)
							))}
						</TreeView>
					</FormControl>
				</>
				}
			</ExpansionPanelDetails>
			<AdvisorQuestionEditor
				editorOpen={questionEditorOpen}
				closeHandler={closeQuestionEditor}
				advisorQuestion={advisorQuestionToEdit}
				handleQuestionChange={handleQuestionChange}
				questionEditorPropsHandlerActive={questionEditorPropsHandlerActive}
				handleQuestionEditorPropsHandlerActiveChange={setQuestionEditorPropsHandlerActive}
				handleQuestionDelete={handleQuestionDelete}
			/>
			<AdvisorAnswerEditor
				editorOpen={answerEditorOpen}
				closeHandler={closeAnswerEditor}
				advisorQuestion={advisorQuestionToEdit}
				advisorAnswer={advisorAnswerToEdit}
				handleAnswerChange={handleAnswerChange}
				categoryCodes={currentAdvisor.categoryCodes}
				answerEditorPropsHandlerActive={answerEditorPropsHandlerActive}
				handleAnswerEditorPropsHandlerActiveChange={setAnswerEditorPropsHandlerActive}
				handleAnswerDelete={handleAnswerDelete}
				advisorQuestions={currentAdvisor.questions}
			/>
			<AdvisorDiagramEditor 
				editorOpen={diagramEditorOpen}
				closeHandler={closeDiagramEditor}
				advisor={currentAdvisor}
				handleNewQuestionButtonClicked={handleNewQuestionButtonClicked}
				handleEditQuestionButtonClicked={handleEditQuestionButtonClicked}
				handleNewAnswerButtonClicked={handleNewAnswerButtonClicked}
				handleEditAnswerButtonClicked={handleEditAnswerButtonClicked}
				handleDiagramEditorChanged={props.handleAdvisorQuestionsChange}
				handleSetCurrentDiagramAdvisor={setCurrentAdvisor}
			/>
		</ExpansionPanel>
	);
};

export default withSnackbar(withDialog()(withStyles(advisorEditorQuestionsAnswersStyles)(AdvisorEditorQuestionsAnswers)));

