import React, { ReactNode, Component } from 'react';
import moment from 'moment';
import Moment from 'react-moment';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';
import overviewTableStyles from './OverviewTableStyles';


interface Props extends WithStyles<typeof overviewTableStyles>{
	selectedMethods: string[];
	fromDate?: moment.Moment;
	toDate?: moment.Moment;
}

interface State {
	selectedMethods: string[];
	reports: PaymentReports[];
	fromDate?: moment.Moment;
	toDate?: moment.Moment;
}

interface PaymentByMethodReport {
	paymentMethod: string;
	totalSum: number;
	orderCount: number;
}

interface PaymentReports {
	time: Date;
	orderCount: number;
	totalSum: number;
	paymentsByMethod: PaymentByMethodReport[];
}

interface TotalPaymentsResponse extends Response {
	success: boolean;
	message?: string;
	data: PaymentReports[];
}

class OverviewTable extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			reports: [],
			selectedMethods: props.selectedMethods,
			fromDate: props.fromDate,
			toDate: props.toDate
		};
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): object | null {
		if (nextProps.selectedMethods !== prevState.selectedMethods){
			return { selectedMethods: nextProps.selectedMethods};
		} else if (nextProps.fromDate !== prevState.fromDate || nextProps.toDate !== prevState.toDate){
			return { fromDate: nextProps.fromDate, toDate: nextProps.toDate};
		}

		return null;
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.selectedMethods !== this.state.selectedMethods){
			this.updateData();
		} else if (prevProps.fromDate !== this.state.fromDate || prevProps.toDate !== this.state.toDate){
			this.updateData();
		}
	}

	private updateData(): void {
		let url = `/api/reporting/totalPayments?hourly=false&methods=${encodeURI(this.state.selectedMethods.join(','))}`;

		if (this.state.fromDate !== undefined && this.state.toDate !== undefined) {
			url += `&timeFrom=${encodeURI(this.state.fromDate.toISOString())}`;
			url += `&timeTo=${encodeURI(this.state.toDate.toISOString())}`;
		}

		this.authService.fetch<TotalPaymentsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({reports: response.data});
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.root}>
				{this.state.reports.map((report): ReactNode =>  (this.renderReport(report)))}
			</div>
		);
	}

	private renderReport(report: PaymentReports): ReactNode {
		const classes = this.props.classes;

		const sortedPaymentsByMethod = report.paymentsByMethod.sort((a: PaymentByMethodReport, b: PaymentByMethodReport): number => a.paymentMethod.localeCompare(b.paymentMethod));

		return (
			<div key={report.time.toString()}>
				<Table size="small" className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>
								<Moment format="dddd, D. MMMM YYYY" date={report.time} locale="de" />
							</TableCell>
							<TableCell align="right">{'Umsatz in %'}</TableCell>
							<TableCell align="right">{'Umsatz in €'}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{sortedPaymentsByMethod.map((rep): ReactNode => (this.renderPaymentMethod(rep, report.totalSum)))}
						<TableRow key="Gesamt">
							<TableCell component="th" scope="row">
								<b>{'Gesamt:'}</b>
							</TableCell>
							<TableCell align="right">
								<b>100 %</b>
							</TableCell>
							<TableCell align="right">
								<b>{report.totalSum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</div>
		);
	}

	private renderPaymentMethod(paymentMethod: PaymentByMethodReport, totalSum: number): ReactNode {
		return (
			<TableRow key={paymentMethod.paymentMethod}>
				<TableCell component="th" scope="row">
					{paymentMethod.paymentMethod}
				</TableCell>
				<TableCell align="right">{(paymentMethod.totalSum / totalSum).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
				<TableCell align="right">{paymentMethod.totalSum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
			</TableRow>
		);
	}
}

export default withStyles(overviewTableStyles)(OverviewTable);
