import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorProductSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginBottom: theme.spacing(3),
	},
	container: {
		width: '100%',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	elementContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		border: `1px solid ${theme.palette.primary.main}`,
	},
	checkboxContainer: {
		border: `1px solid ${theme.palette.primary.main}`,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '50%',
		minWidth: 330,
		maxWidth: '50%',
		margin: 0,
	},
	labelContainer: {
		display: 'flex',
	},
	imageContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		height: '30px',
		width: '30px',
		textAlign: 'center',
		flexGrow: 0,
		flexShrink: 0,
	},
	codeContainer: {
		color: theme.palette.text.secondary,
		fontFamily: 'monospace',
		marginRight: theme.spacing(1),
		fontSize: '13px',
		lineHeight: '30px',
		minWidth: 100,
	},
	nameContainer: {
		flexGrow: 1,
		lineHeight: '30px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		minWidth: 0,
		marginRight: theme.spacing(1),
	},
	priceContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		whiteSpace: 'nowrap',
	},
	checked: {
		'&, & + $label, & + $label $codeContainer': {
			backgroundColor: theme.palette.primary.main,
			borderRadius: 0,
			color: '#ffffff !important',
		},
		'&:hover': {
			backgroundColor: `${theme.palette.primary.main} !important`,
			borderRadius: 0,
			color: '#ffffff !important',
		},
	},
	label: {
		width: 'calc(100% - 42px)',
		height: '100%',
		paddingTop: 8,
	},
	attentionContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
	},
	sortSelect: {
		float: 'right'
	},
	productsInCheckbox: {
		marginBottom: theme.spacing(2),
		display: 'block',
		width: '100%',
	},
	offlineProduct: {
		textDecoration: 'line-through',
	},
});

export default comparatorProductSelectStyles;
