import React, { ReactNode, Component } from 'react';
import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TextField,
	TableRow,
	Typography,
	Button,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { withDialog} from 'muibox';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';

import categoryMappingEditorStyles from './CategoryMappingEditorStyles';

import { Category, EditableCategory } from '@models/Category';

interface Props extends WithStyles<typeof categoryMappingEditorStyles> {
	editorOpen: boolean;
	closeHandler: () => void;
	editableCategory: EditableCategory | null;
	deleteHandler?: (editableCategoryToDelete: EditableCategory) => void;
	changeHandler: (editableCategoryToEdit: EditableCategory) => void;
	categories: Category[];
}

interface State {
	editableCategoryToEdit: EditableCategory;
	ownImageChecked: boolean;
	inputRequired: boolean;
	currentSubgroup: string;
}

class CategoryMappingEditor extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);
		this.state = {
			editableCategoryToEdit: this.getEditableCategoryToSet(this.props.editableCategory),
			ownImageChecked: this.isOwnImage(this.props.editableCategory),
			inputRequired: false,
			currentSubgroup: ''
		};
	}

	public componentDidUpdate(prevProps: Props): void {
		if (this.props.editableCategory && !this.editableCategoryChildrenEqual(this.state.editableCategoryToEdit.childCategories, this.props.editableCategory.childCategories)) {
			this.setState({editableCategoryToEdit: JSON.parse(JSON.stringify(this.props.editableCategory))});
		}

		if (this.props.editableCategory?.categoryCode !== prevProps.editableCategory?.categoryCode) {
			this.setState({editableCategoryToEdit: JSON.parse(JSON.stringify(this.props.editableCategory))});
		}
	}

	private getEditableCategoryToSet(editableCategory: EditableCategory | null): EditableCategory {
		if (editableCategory) {
			return editableCategory;
		} else {
			const responseEditableCategory: EditableCategory = {
				categoryName: '',
				image: '',
				childCategories: [],
				sortPosition: 0,
				articleName: '',
				subgroups: []
			};

			return responseEditableCategory;
		}
	}

	private editableCategoryChildrenEqual(children1: EditableCategory[], children2: EditableCategory[]): boolean {
		return JSON.stringify(children1) === JSON.stringify(children2);
	}

	private closeEditor(): void {
		this.setState({
			editableCategoryToEdit: this.getEditableCategoryToSet(this.props.editableCategory),
			ownImageChecked: this.isOwnImage(this.getEditableCategoryToSet(this.props.editableCategory)),
			currentSubgroup: ''
		});
		this.props.closeHandler();
	}

	private deleteButtonPressed(): void {
		if (this.props.deleteHandler && this.props.editableCategory) {
			this.props.deleteHandler(this.props.editableCategory);
		}
		this.props.closeHandler();
	}

	private deleteButton(): ReactNode {
		const classes = this.props.classes;
		return (
			<Button className={classes.deleteButton} onClick={this.deleteButtonPressed.bind(this)}>
				Löschen
			</Button>
		);
	}

	private saveButtonPressed(): void {
		if (this.state.editableCategoryToEdit.categoryName.length > 0) {
			this.props.changeHandler(this.state.editableCategoryToEdit);
			if (!this.props.editableCategory) {
				this.setState({editableCategoryToEdit: this.getEditableCategoryToSet(this.props.editableCategory)});
			} else {
				this.setState({ownImageChecked: this.isOwnImage(this.getEditableCategoryToSet(this.state.editableCategoryToEdit))});
			}
			this.props.closeHandler();
		} else {
			this.setState({inputRequired: true});
		}
	}

	private textField(key: keyof EditableCategory | string): ReactNode {
		const classes = this.props.classes;
		let placeholder = '';
		let label = '';

		if (key === 'categoryName') {
			placeholder = 'Name';
			label = 'Name';
		}  else if (key === 'categoryCode') {
			placeholder = 'C#';
			label = 'Online-Shop Kategoriecode';
		} else if (key === 'shopCategoryName') {
			placeholder = 'Name';
			label = 'Online-Shop Kategoriename';
		} else if (key === 'articleName') {
			placeholder = 'z.B. ECKKOMMODE';
			label = 'Artikelbezeichnung';
		}

		if (key === 'articleName') {
			return (
				<TextField
					label={label}
					placeholder={placeholder}
					value={this.state.editableCategoryToEdit.articleName}
					margin="dense"
					onChange={(event): void => {this.handleTextChange(key, event);}}
					fullWidth
				/>
			);
		} else {

			return (
				<TextField
					label={label}
					placeholder={placeholder}
					error={key === 'categoryName' && this.state.inputRequired}
					required={key === 'categoryName'}
					className={key === 'categoryCode' ? classes.disabledMargin : undefined}
					value={(key === 'categoryName' || key === 'categoryCode') ?  this.state.editableCategoryToEdit[key] : (this.state.editableCategoryToEdit.categoryCode) && this.getOnlineShopCategoryName(this.state.editableCategoryToEdit.categoryCode)}
					margin="dense"
					onChange={(event): void => {this.handleTextChange(key, event);}}
					fullWidth
					disabled={(key === 'categoryCode' || key === 'shopCategoryName') ? true : false}
				/>
			);
		}
	}

	private getOnlineShopCategoryName(categoryCode: string): string {
		const onlineShopCategory = this.getOnlineShopCategoryString(this.props.categories, categoryCode);
		if (onlineShopCategory) {
			return onlineShopCategory;
		} else {
			return '';
		}
	}

	private getOnlineShopCategoryString(categories: Category[], categoryCode: string): string {
		const categoryCodes = categoryCode.split(',');
		let categoryOnlineShopNames = '';
		for (const categoryCode of categoryCodes) {
			const category = this.getOnlineShopCategory(categories, categoryCode);
			if (categoryOnlineShopNames !== '') {
				categoryOnlineShopNames += ',';
			}
			categoryOnlineShopNames += category?.name;
		}
		return categoryOnlineShopNames;
	}

	private getOnlineShopCategory(categories: Category[], categoryCode: string): Category | null {
		for (const category of categories) {
			if (category.code === categoryCode) {
				return category;
			}
			const result = this.getOnlineShopCategory(category.children, categoryCode);
			if (result) {
				return result;
			}
		}
		return null;
	}

	private isOwnImage(editableCategory: EditableCategory | null): boolean {
		if (!editableCategory || !editableCategory.categoryCode) {
			return true;
		}

		const onlineShopCategory = this.getOnlineShopCategory(this.props.categories, editableCategory.categoryCode.split(',')[0]);
		if (onlineShopCategory) {
			if (editableCategory.image === onlineShopCategory.image) {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	private useOwnImageClicked(event: React.ChangeEvent<HTMLInputElement>): void {

		const tempEditableCategory = JSON.parse(JSON.stringify(this.state.editableCategoryToEdit));
		if (!event.target.checked) {
			const onlineShopCategory = this.getOnlineShopCategory(this.props.categories, tempEditableCategory.categoryCode.split(',')[0]);
			if (onlineShopCategory) {
				tempEditableCategory.image = onlineShopCategory.image;
			} else {
				tempEditableCategory.image = '';
			}
			this.setState({editableCategoryToEdit: tempEditableCategory});
		}
		this.setState({ownImageChecked: event.target.checked});
	}

	private imageContainer(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				{this.state.editableCategoryToEdit.categoryCode &&
				<FormControlLabel
					control={
						<Checkbox
							checked={this.state.ownImageChecked}
							color="primary"
							onChange={(event): void => this.useOwnImageClicked(event)}
						/>
					}
					label="Eigenes Bild verwenden"
					className={classes.ownImageCheckbox}
				/>
				}
				<TextField
					label={'Bild'}
					value={this.state.editableCategoryToEdit.image}
					margin="dense"
					placeholder={'Bild'}
					disabled={!this.state.ownImageChecked}
					fullWidth={true}
					onChange={(event): void => {this.handleTextChange('image', event);}}
				/>
			</div>
		);
	}

	private removeSubgroup(subgroup: string): void {
		const editableCategoryToEdit = this.state.editableCategoryToEdit;
		if (editableCategoryToEdit.subgroups) {
			const indexSubgroup = editableCategoryToEdit.subgroups.indexOf(subgroup, 0);
			if (indexSubgroup > -1) {
				editableCategoryToEdit.subgroups.splice(indexSubgroup, 1);
			}
			this.setState({editableCategoryToEdit: editableCategoryToEdit});
		}
	}

	private handleSubgroupChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		this.setState({currentSubgroup : event.target.value});
	}

	private getAddSubgroupsArea(): ReactNode {
		const classes = this.props.classes;
		return (

			<div className={classes.subgroupAddContainer}>
				<div className={classes.subgroupTextField}>
					<TextField
						label={'Neue Unterwarengruppe'}
						placeholder={'Unterwarengruppe'}
						value={this.state.currentSubgroup}
						margin="dense"
						onChange={(event): void => {this.handleSubgroupChange(event);}}
						fullWidth
					/>
				</div>
				<div className={classes.addSubgroupButton}>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						startIcon={<AddIcon />}
						onClick={this.addSubgroupButtonClicked.bind(this)}
					>
						Hinzufügen
					</Button>
				</div>
			</div>

		);
	}

	private addSubgroupButtonClicked(): void {
		const currentSubgroup = this.state.currentSubgroup;
		const editableCategoryToEdit = JSON.parse(JSON.stringify(this.state.editableCategoryToEdit));

		if (this.props.editableCategory && !this.state.editableCategoryToEdit.subgroups) {
			editableCategoryToEdit.subgroups = [];
		}

		if (!editableCategoryToEdit.subgroups.includes(currentSubgroup)) {
			editableCategoryToEdit.subgroups.push(currentSubgroup);
		}
		this.setState({
			editableCategoryToEdit: editableCategoryToEdit,
			currentSubgroup: ''
		});
	}

	private getSelectedAttributeValuesTable(): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={classes.subgroupsTable}>
				<Typography className={classes.subgroupsTableHeader}>
					{'Unterwarengruppen'}
				</Typography>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.editableCategoryToEdit.subgroups && this.state.editableCategoryToEdit.subgroups.map((subgroup: string): ReactNode => (
							<TableRow key={subgroup}>
								<TableCell component="th" scope="row">
									{subgroup}
								</TableCell>
								<TableCell align="right">{
									<IconButton className={classes.deleteButton} size="small" onClick={this.removeSubgroup.bind(this, subgroup)}>
										<DeleteForeverOutlinedIcon />
									</IconButton>
								}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}

	private handleTextChange(key: keyof EditableCategory | string, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const tempEditableCategory: EditableCategory = JSON.parse(JSON.stringify(this.state.editableCategoryToEdit));

		if (key === 'categoryName') {
			tempEditableCategory.categoryName = event.target.value;
		} else if (key === 'image') {
			tempEditableCategory.image = event.target.value;
			this.setState({ownImageChecked: this.isOwnImage(tempEditableCategory)});
		} else if (key === 'articleName') {
			tempEditableCategory.articleName = event.target.value;
		}

		this.setState({editableCategoryToEdit: tempEditableCategory});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.closeEditor.bind(this)} fullWidth={true} maxWidth={'md'}>
				<DialogTitle id="form-dialog-title">{this.props.editableCategory ? 'Kategorie bearbeiten' : 'Neue Hauptkategorie'}</DialogTitle>
				<DialogContent>
					{this.textField('categoryName')}
					{this.textField('articleName')}
					{this.imageContainer()}
					{this.getAddSubgroupsArea()}
					{this.state.editableCategoryToEdit.subgroups && this.state.editableCategoryToEdit.subgroups.length > 0 && this.getSelectedAttributeValuesTable()}
					{this.state.editableCategoryToEdit && this.state.editableCategoryToEdit.categoryCode && this.textField('categoryCode')}
					{this.state.editableCategoryToEdit && this.state.editableCategoryToEdit.categoryCode && this.textField('shopCategoryName')}
				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					{this.props.deleteHandler && this.deleteButton()}
					<Button onClick={this.closeEditor.bind(this)} color="primary">
						Abbrechen
					</Button>
					<Button onClick={this.saveButtonPressed.bind(this)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withDialog()(withStyles(categoryMappingEditorStyles)(CategoryMappingEditor));
