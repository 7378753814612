import React, { ReactNode, Component } from 'react';
import {
	Checkbox,
	FormControlLabel,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Product } from '@models/Product';
import { SummarizedAttribute, AttributeTemplate } from '@models/Comparator';

import dropshippingAttributeSelectStyles from './DropshippingAttributeSelectStyles';

interface Props extends WithStyles<typeof dropshippingAttributeSelectStyles> {
	onChange: (selectedAttributes: string[]) => void;
	products: Product[];
	selectedAttributes: string[];
	selectedTemplate?: AttributeTemplate;
}

interface State {
	attributes: SummarizedAttribute[];
	productCount: number;
}

class DropshippingAttributeSelect extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			attributes: [],
			productCount: 0
		};
	}

	public componentDidMount(): void {
		this.getAttributes();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if ((prevProps.products !== this.props.products) || prevProps.selectedTemplate !== this.props.selectedTemplate) {
			this.getAttributes();
		}
	}

	private getAttributes(): void {
		let attributes: SummarizedAttribute[] = [];

		let id = 0;

		for (const product of this.props.products) {
			for (const attribute of product.attributes) {
				let sumAttribute = attributes.find((a) => a.key === attribute.key);

				if (sumAttribute) {
					sumAttribute.count++;
				} else {
					sumAttribute = {
						id: id,
						name: attribute.name,
						key: attribute.key,
						count: 1,
						hidden: true,
						maintainedCount: 0,
						source: attribute.source
					};
					attributes.push(sumAttribute);
					id++;
				}
			}
		}

		attributes = attributes.sort((a, b): number => (a.name > b.name) ? 1 : -1);

		const selectedAttributes: SummarizedAttribute[] = [];
		for (const selectedAttribute of this.props.selectedAttributes) {
			const attribute = attributes.find((a) => a.key === selectedAttribute);
			if (attribute) {
				selectedAttributes.push(attribute);
			}
		}

		this.setState({attributes: attributes, productCount: this.props.products.length});
		this.updateParentSelectedAttributes(selectedAttributes);
	}

	private updateParentSelectedAttributes(selectedAttributes: SummarizedAttribute[]): void {
		const attributes: string[] =[];
		for (const selectedAttribute of selectedAttributes) {
			attributes.push(selectedAttribute.key);
		}

		this.props.onChange(attributes);
	}

	private handleCheckboxChange(attribute: SummarizedAttribute, event: React.ChangeEvent<HTMLInputElement>): void {
		let selectedAttributes = this.props.selectedAttributes;
		if (event.target.checked) {
			selectedAttributes.push(attribute.key);
		} else {
			selectedAttributes = selectedAttributes.filter((a): boolean => {
				return attribute.key !== a;
			});
		}

		this.props.onChange(selectedAttributes);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.container}>
				{this.state.attributes.length > 0 &&
					<div className={classes.attributeContainer}>
						{this.state.attributes.filter((attribute): boolean => {
							if ((this.props.selectedTemplate && this.props.selectedTemplate._id === 'no-attribute-template-id') || !this.props.selectedTemplate) {
								return true;
							}
							if (this.props.selectedTemplate) {
								for (const templateAttribute of this.props.selectedTemplate.attributes) {
									if (templateAttribute === attribute.key) {
										return true;
									}
								}
							}
							return false;
						}).map((attribute): ReactNode => (
							<React.Fragment key={attribute.key}>
								<FormControlLabel
									className={classes.attributeCheckbox}
									key={attribute.key}
									control={
										<Checkbox
											checked={this.props.selectedAttributes.find((a): boolean => attribute.key === a) !== undefined}
											color="primary"
											onChange={(event): void => {this.handleCheckboxChange(attribute, event);}}
										/>
									}
									label={
										<span className={classes.nameContainer}>
											{attribute.name}
										</span>
									}
								/>
								<span className={classes.occurrenceContainer}>{`${attribute.count}/${this.state.productCount}`}</span>
							</React.Fragment>
						))}
					</div>
				}
			</div>
		);
	}
}

export default withStyles(dropshippingAttributeSelectStyles)(DropshippingAttributeSelect);
