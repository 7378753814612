import AuthService from './AuthService';
import { CmsContent } from '@models/CmsContent';

export interface CmsContentResponse {
	success: boolean;
	message?: string;
	data: CmsContent[];
}

class CmsContentService {
	private authService: AuthService;

	public constructor() {
		this.authService = new AuthService();
	}

	// Loads all contents
	public async loadAllContents(): Promise<CmsContent[]> {
		try {
			const url = '/api/cms-content/';
			const response = await  this.authService.fetch<CmsContentResponse>(url, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading contents: ${error}`);
		}

		return [];
	}

	// Loads contents of category
	public async loadContentsOfCategory(categoryCode: string): Promise<CmsContent[]> {
		try {
			const url = `/api/cms-content/?category=${categoryCode}`;
			const response = await  this.authService.fetch<CmsContentResponse>(url, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading contents: ${error}`);
		}

		return [];
	}

	// Load single content
	public async loadContent(contentId: string): Promise<CmsContent | null> {
		try {
			const url = `/api/cms-content/${contentId}`;
			const response = await  this.authService.fetch<CmsContentResponse>(url, { method: 'GET'});

			if (response.success) {
				return response.data[0];
			}
		} catch (error) {
			console.log(`Error while loading content: ${error}`);
		}

		return null;
	}

	// Delete content
	public async deleteContent(contentId: string): Promise<boolean> {
		try {
			const url = `/api/cms-content/${contentId}`;
			const response = await  this.authService.fetch<CmsContentResponse>(url, { method: 'DELETE'});

			return response.success;
		} catch (error) {
			console.log(`Error while loading content: ${error}`);
		}

		return false;
	}

	// Save Content
	public async saveContent(content: CmsContent): Promise<CmsContent> {
		try {
			const url = `/api/cms-content/${content._id}`;
			const response = await  this.authService.fetch<CmsContentResponse>(url, {
				method: 'POST',
				body: JSON.stringify(content)
			});

			if (response.success) {
				return response.data[0];
			}
		} catch (error) {
			console.log(`Error while loading content: ${error}`);
		}

		throw new Error('Could not Save Content');
	}

}

export default CmsContentService;
