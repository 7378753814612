import { createStyles, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cmsContentColumnStyles = (theme: Theme): any => createStyles({
	previewImage: {
		width: '100%',
		objectFit: 'contain',
	},
	textField: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	colorSelect: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	categoryRow: {
		display: 'inline-block',
		marginTop: theme.spacing(1),
	},
	showImagesCheckbox: {
		marginLeft: theme.spacing(1),
	},
	info: {
		marginLeft: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	category: {
		marginLeft: theme.spacing(1),
	},
	imageLine: {
		display: 'flex'
	},
	ownImageCheckbox: {
		minWidth: '20%',
		marginLeft: theme.spacing(1),
	},
	imageLink: {
		marginBottom: '14px'
	}
});

export default cmsContentColumnStyles;
