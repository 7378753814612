import React, { ReactNode, Component } from 'react';

import {
	Tooltip,
	Link,
	WithStyles,
	withStyles
} from '@material-ui/core';

import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';

import { AnalyticsModel } from '@models/AnalyticsModel';
import { Product } from '@models/Product';
import { Comparator } from '@models/Comparator';

import analyticsBestProductStyles from './AnalyticsBestProductStyles';

interface ComparatorResponse extends Response {
	success: boolean;
	data: Comparator;
	message?: string;
}

interface Props extends WithStyles<typeof analyticsBestProductStyles> {
	analytics: AnalyticsModel;
}

interface State {
	product: Product | null;
	loading: boolean;
}

class AnalyticsBestProduct extends Component<Props, State> {
	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.productsService = new ProductsService();

		this.state = {
			product: null,
			loading: false
		};
	}

	public componentDidMount(): void {
		this.loadBestProduct();
	}

	private async loadBestProduct(): Promise<void> {
		this.setState({loading: true, product: null});

		const bestProduct = await this.bestProductForAnalytics(this.props.analytics);

		this.setState({loading: false, product: bestProduct});
	}

	private async bestProductForAnalytics(a: AnalyticsModel): Promise<Product | null> {
		try {
			const comparatorResponse = await this.authService.fetch<ComparatorResponse>(`/api/comparators/${a.ComparatorID}`, {method: 'GET'});

			if (comparatorResponse.success !== true) {
				return null;
			}

			// Find best product
			const products = comparatorResponse.data.products;
			let bestClicks = 0;
			let bestProduct = products.length > 0 ? products[0] : null;

			for (const product of products) {
				let clicksForProduct = 0;

				if (a[`${product.productCode}:Clicks`] && typeof a[`${product.productCode}:Clicks`] === 'number') {
					clicksForProduct = a[`${product.productCode}:Clicks`] as number;
				}

				if (clicksForProduct > bestClicks) {
					bestClicks = clicksForProduct;
					bestProduct = product;
				}
			}

			// Load Product
			if (bestProduct !== null) {
				return await this.productsService.loadProduct(bestProduct.productCode);
			}

			return null;
		} catch {
			return null;
		}
	}


	private renderProduct(product: Product): ReactNode {
		const classes = this.props.classes;

		return (
			<div key={product.productCode} className={classes.rowContainer}>
				<Tooltip
					placement="right"
					title={
						<React.Fragment>
							{product.images.length > 0 &&
								<img src={`${product.images[0]}?maxH=280&maxW=280&upscale=false`} alt=""/>
							}
						</React.Fragment>
					}
				>
					<span className={classes.imageContainer}>
						{product.images.length > 0 &&
							<img src={`${product.images[0]}?maxH=28&maxW=28&upscale=false`} alt=""/>
						}
					</span>
				</Tooltip>
				<span className={classes.codeContainer}>
					{product.productCode}
				</span>
				<span className={classes.nameContainer}>
					<Link
						href={`https://moebelix.at/p/${product.productCode}`}
						target="_blank"
						rel="noreferrer"
					>
						{product.name}
					</Link>
				</span>
				<span className={classes.priceContainer}>
					{product.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
				</span>
			</div>
		);
	}

	public render(): ReactNode {
		if (this.state.loading || this.state.product === null) {
			return (<div />);
		}

		return this.renderProduct(this.state.product);
	}
}

export default withStyles(analyticsBestProductStyles)(AnalyticsBestProduct);
