import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorEditorGoogleAnalyticsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
});

export default advisorEditorGoogleAnalyticsStyles;
