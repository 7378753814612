import React, {ReactNode, Component} from 'react';
import moment, {Moment} from 'moment';
import {
	Button,
	Link,
	Paper,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';

import RefreshIcon from '@material-ui/icons/Refresh';

import AuthService from '@services/AuthService';

import {SeriesOrderRanking, SeriesOrderResponse, SingleSeriesOrder} from '@models/SeriesOrder';

import seriesTableStyles from './SeriesTableStyles';
import TimeRangeSelect from './TimeRangeSelect';
import ProductsPreview from './ProductsPreview';

interface State {
	keyword: string | null;
	series: SeriesOrderRanking[];
	editorOpen: boolean;
	seriesName: string;
	fromDate: Moment;
	toDate: Moment;
	products: SingleSeriesOrder[];
	loading: boolean;
	articleCombinations: string[];
}

class SeriesTable extends Component<WithStyles<typeof seriesTableStyles>, State> {
	private authService: AuthService;

	public constructor(props: WithStyles<typeof seriesTableStyles>) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			keyword: null,
			series: [],
			editorOpen: false,
			seriesName: '',
			fromDate: moment().subtract(1, 'months').startOf('day'),
			toDate: moment(),
			products: [],
			loading: false,
			articleCombinations: []
		};
	}

	componentDidMount(): void {
		this.loadSeries();
	}

	private handleClose(): void {
		this.setState({editorOpen: false});
	}

	private handleClickOpenEdit(name: string): void {
		const singleSeriesOrders: SingleSeriesOrder[] = [];
		//get products
		for (const serie of this.state.series) {
			if (serie.seriesName === name) {
				for (const product of serie.products) {
					if (!singleSeriesOrders.find(item => item.productCode === product.productCode)) {
						const singleSeriesOrder: SingleSeriesOrder = {
							image: product.image,
							productCode: product.productCode,
							name: product.name,
							price: product.price,
							amount: product.amount
						};
						singleSeriesOrders.push(singleSeriesOrder);
					} else {
						for (const singleSeriesOrder of singleSeriesOrders) {
							if (singleSeriesOrder.productCode === product.productCode) {
								singleSeriesOrder.amount += product.amount;
							}
						}
					}
				}
			}
		}

		this.setState({editorOpen: true, seriesName: name, products: singleSeriesOrders});
	}

	private handleTimeRangeChanges(fromDate: Moment, toDate: Moment): void {
		this.setState({fromDate: fromDate, toDate: toDate});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4">
					{'Serienanalyse'}
				</Typography>
				<div className={classes.timeRangeContainer}>
					<TimeRangeSelect
						onChange={this.handleTimeRangeChanges.bind(this)}
						fromDate={this.state.fromDate}
						toDate={this.state.toDate}
					/>
					<Button
						variant='outlined'
						color='secondary'
						onClick={this.loadSeries.bind(this)}
						className={classes.loadButton}
						startIcon={!this.state.loading ? <RefreshIcon color="secondary"/> : null}
						disabled={this.state.loading}
					>
						Aktualisieren
					</Button>
				</div>


				<MaterialTable
					columns={[
						{
							title: '#',
							field: 'ranking' as const,
							cellStyle: {
								maxWidth: '2%'
							},
							headerStyle: {
								maxWidth: '2%'
							},
							filtering: false,
							render: (serie): ReactNode => {
								return (
									<div>{serie.ranking}</div>
								);
							}
						},
						{
							title: 'Serienname',
							field: 'seriesName' as const,
						},
						{
							title: 'Serienbestellungen',
							field: 'amount' as const,
							filtering: false,
							render: (serie): ReactNode => {
								return (
									<div>{serie.amount}</div>
								);
							}
						},
						{
							title: 'durchschnittlich gekaufte Artikel pro Serie',
							field: 'averageProduct' as const,
							filtering: false,
							render: (serie): ReactNode => {
								return (
									<div>{serie.averageProduct}</div>
								);
							}
						},
						{
							title: '',
							render: (serie): ReactNode => {
								if (serie.products.length === 0) {
									return;
								} else {
									return (
										<React.Fragment>
											<Link onClick={this.handleClickOpenEdit.bind(this, serie.seriesName)} className={classes.link}>
												Artikel ansehen
											</Link>
										</React.Fragment>
									);
								}
							}
						}
					]}
					options={{
						search: false,
						paging: true,
						filtering: true,
						sorting: true,
						columnsButton: false,
						showTitle: false,
						draggable: false,
						toolbar: false,
						pageSize: 50,
						pageSizeOptions: [25, 50, 75, 100],
						emptyRowsWhenPaging: false,
					}}
					data={this.state.series}
					isLoading={this.state.loading}
				/>

				<ProductsPreview
					closeHandler={this.handleClose.bind(this)}
					editorOpen={this.state.editorOpen}
					products={this.state.products}
					seriesName={this.state.seriesName}
					fromDate={this.state.fromDate}
					toDate={this.state.toDate}
				/>
			</Paper>
		);
	}

	private loadSeries(): void {
		this.setState({loading: true});
		this.authService.fetch<SeriesOrderResponse>('/api/series/seriesByDate', {
			method: 'POST',
			body: JSON.stringify({
				fromDate: this.state.fromDate,
				toDate: this.state.toDate
			})
		}).then((response): void => {
			if (response.success){
				const data = [];
				if (response.data) {
					let ranking = 1;
					for (const series of response.data) {
						series.ranking = ranking;
						data.push(series);
						ranking++;
					}
					this.setState({series: response.data});
				} else {
					this.setState({series: []});
				}
			} else {
				throw new Error(response.message);
			}
			this.setState({loading: false});
		});
	}

}

export default withStyles(seriesTableStyles)(SeriesTable);
