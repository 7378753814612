import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shoppingCategorySelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginBottom: theme.spacing(3),
	},
});

export default shoppingCategorySelectStyles;
