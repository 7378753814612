import React, { ReactNode, Component } from 'react';
import moment, { Moment } from 'moment';

import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { DatePicker, DatePickerView } from '@material-ui/pickers';

import analyticsDateSelectorStyles from './AnalyticsDateSelectorStyles';

enum DateType {
	Year,
	Month,
	Day
}

interface Props extends WithStyles<typeof analyticsDateSelectorStyles> {
	onChange?: Function;
}

interface State {
	type: DateType;
	date: Moment;
}

class AnalyticsDateSelector extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			type: DateType.Day,
			date: moment()
		};
	}

	public componentDidMount(): void {
		this.notifyOfChange(this.state.type, this.state.date);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const views: DatePickerView[] = ['year'];
		let format = 'YYYY';

		if (this.state.type !== DateType.Year) {
			views.push('month' as DatePickerView);
			format = 'MM. YYYY';
		}

		if (this.state.type === DateType.Day) {
			views.push('date' as DatePickerView);
			format = 'DD. MM. YYYY';
		}

		return (
			<div className={classes.root}>
				<FormControl className={classes.formControl}>
					<InputLabel id="dateTypeSelectLabel">Typ</InputLabel>
					<Select
						labelId="dateTypeSelectLabel"
						id="dateTypeSelect"
						value={this.state.type}
						onChange={this.handleTypeChange.bind(this)}
					>
						<MenuItem value={DateType.Day}>Tag</MenuItem>
						<MenuItem value={DateType.Month}>Monat</MenuItem>
						<MenuItem value={DateType.Year}>Jahr</MenuItem>
					</Select>
				</FormControl>
				<DatePicker
					className={classes.datePicker}
					value={this.state.date}
					onChange={this.handleDateChange.bind(this)}
					disableFuture={true}
					label={'Zeitraum'}
					showTodayButton={false}
					cancelLabel={'ABBRECHEN'}
					views={views}
					format={format}
					// openTo={openTo}
				/>
			</div>
		);
	}

	private handleTypeChange(event: React.ChangeEvent<{ value: unknown }>): void {
		this.setState({type: event.target.value as DateType});
		this.notifyOfChange(event.target.value as DateType, this.state.date);
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleDateChange(date: any): void {
		const momentDate = date as Moment;
		this.setState({date: momentDate});
		this.notifyOfChange(this.state.type, momentDate);
	}

	private notifyOfChange(type: DateType, date: Moment): void {
		let format = 'YYYY-MM-DD';
		let prefix = 'DATE:';

		if (type === DateType.Month) {
			format = 'YYYY-MM';
			prefix = 'MONTH:';
		} else if (type === DateType.Year) {
			format = 'YYYY';
			prefix = 'YEAR:';
		}


		if (this.props.onChange) {
			this.props.onChange(`${prefix}${date.format(format)}`);
		}
	}
}

export default withStyles(analyticsDateSelectorStyles)(AnalyticsDateSelector);
