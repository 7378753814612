import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorAnswerEditorStyles = (theme: Theme): any => createStyles({
	content: {
		display: 'flex',
		flexDirection: 'column'
	},
	deleteButton: {
		marginRight: 'auto',
		color: '#EE0000'
	},
	combinableCheckbox: {
		marginTop: theme.spacing(4),
	},
	rulesHeader: {
		marginTop: theme.spacing(4),
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: theme.spacing(1)
	},
	productsContainer: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2)
	},
	refreshProductsButton: {
		alignSelf: 'flex-start',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(2)
	},
	productHeaderContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	imageInput: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	}
});

export default advisorAnswerEditorStyles;