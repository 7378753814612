import React, { ReactNode, Component } from 'react';
import { WithStyles, withStyles } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';
import OrdersTableFilterRow from './OrdersTableFilterRow';
import Moment from 'react-moment';

import ordersTableStyles from './OrdersTableStyles';

import AuthService from '@services/AuthService';
import { StatusUpdate } from '@models/Order';

interface PaymentMethod {
	name: string;
}

interface PaymentMethodsResponse extends Response {
	success: boolean;
	message?: string;
	data: PaymentMethod[];
}

interface State {
	paymentLookup: { [s: string]: string };
}

interface Props extends WithStyles<typeof ordersTableStyles>{
	orderSelectedHandler: Function;
	loadDataHandler: Function;
}

class OrdersTable extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.state = {
			paymentLookup: {}
		};
		this.authService = new AuthService();

		this.loadPaymentMethods();
	}

	public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
		const vitalPropsChange = (
			this.props.orderSelectedHandler !== nextProps.orderSelectedHandler ||
			this.props.loadDataHandler !== nextProps.loadDataHandler
		);

		const vitalStateChange = this.state.paymentLookup !== nextState.paymentLookup;

		return vitalPropsChange || vitalStateChange;
	}

	private loadPaymentMethods(): void {
		const url = '/api/orders/paymentMethods';

		this.authService.fetch<PaymentMethodsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				const paymentLookup: { [s: string]: string } = {};

				for (const paymentMethod of response.data) {
					paymentLookup[paymentMethod.name] = paymentMethod.name;
				}

				this.setState({paymentLookup: paymentLookup});
			}
		});
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleRowClicked(event?: React.MouseEvent<Element>, order?: any): void {
		if (order) {
			this.props.orderSelectedHandler(order);
		}
	}

	private nameForMailType(mailType: number): string {
		const lookup: {[k: number]: string } = {
			0: 'Bestellt',
			1: 'Versandt',
			2: 'Geladen',
			3: 'Nicht zugestellt',
			4: '3. Angenommen',
			5: 'Reserviert',
			6: 'Selbstabholbus',
			7: 'Storno Selbstabholbus',
			8: 'Rechnung'
		};

		if (!lookup[mailType]) {
			return 'Unbekannt';
		}

		return lookup[mailType];
	}

	public render(): ReactNode {
		return (
			<MaterialTable
				columns={[
					{
						title: 'Bestelldatum',
						field: 'imapReceived' as const,
						filtering: true,
						defaultSort: 'desc' as const,
						type: 'date' as const,
						render: (order): ReactNode => {
							return (
								<Moment format="DD.MM.YYYY HH:mm" date={order.imapReceived} locale="de" />
							);
						}
					},
					{
						title: 'Auftragsnummer',
						filtering: true,
						field: 'jobNumber' as const
					},
					{
						title: 'Bestellnummer',
						filtering: true,
						field: 'orderNumber' as const
					},
					{
						title: 'Status',
						field: 'statusUpdate' as const,
						filtering: false,
						sorting: false,
						render: (order): ReactNode => {
							let statusUpdates: StatusUpdate[] = [];
							if (order.statusUpdate) {
								statusUpdates = order.statusUpdate;
							}

							statusUpdates = statusUpdates.sort((a: StatusUpdate, b: StatusUpdate): number => {
								return new Date(b.mailDate).getTime() - new Date(a.mailDate).getTime();
							});

							return statusUpdates.length > 0 ? (this.nameForMailType(statusUpdates[0].mailType)) : (<div />);
						}
					},
					{
						title: 'Zahlungsmethode',
						filtering: true,
						field: 'paymentMethod' as const,
						lookup: this.state.paymentLookup
					},
					{
						title: 'Positionen',
						filtering: false,
						field: 'orderItems.length' as const,
						type: 'numeric' as const,
						sorting: false
					},
					{
						title: 'Gutschein',
						field: 'coupons' as const,
						filtering: false,
						sorting: false,
						render: (order): ReactNode => {
							if (!order.coupons) {
								return (<div />);
							}
							return order.coupons.length > 0 ? (<CheckIcon />) : (<div />);
						},
						cellStyle: {
							textAlign: 'right'
						},
						headerStyle: {
							textAlign: 'right',
							flexDirection: 'row-reverse'
						}
					},
					{
						title: 'Gesamtsumme',
						filtering: false,
						field: 'totalSum' as const,
						type: 'currency' as const,
						currencySetting: {
							locale: 'DE',
							currencyCode: 'EUR',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						},
						cellStyle: {
							textAlign: 'right'
						},
						headerStyle: {
							textAlign: 'right',
							flexDirection: 'row-reverse'
						}
					}
				]}
				options={{
					search: false,
					filtering: true,
					columnsButton: false,
					exportButton: true,
					paging: true,
					pageSize: 200,
					pageSizeOptions: [25, 50, 75, 100, 200],
					emptyRowsWhenPaging: false,
					draggable: false,
				}}
				style={{
					borderRadius: 0,
				}}
				onRowClick={ this.handleRowClicked.bind(this) }
				data={ this.props.loadDataHandler.bind(this) }
				title="Bestellungen"
				localization={ TableLocalization.localization }
				components={{
					//eslint-disable-next-line @typescript-eslint/no-explicit-any
					FilterRow: (props): any => (
						<OrdersTableFilterRow {...props} />
					)
				}}
			/>
		);
	}
}

export default withStyles(ordersTableStyles)(OrdersTable);
