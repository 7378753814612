import { createStyles, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cmsContentRowsEditorStyles = (theme: Theme): any => createStyles({
	row: {
		padding: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	deleteButton: {
		marginTop: theme.spacing(2),
	},
});

export default cmsContentRowsEditorStyles;
