import React, { ReactNode, Component } from 'react';

import Link from '@material-ui/core/Link';
import {
	Paper,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';
import TableLocalization from '@common/TableLocalization';
import Moment from 'react-moment';
import {Moment as MomentMoment} from 'moment';
import TimeRangeSelect from '../Reporting/TimeRangeSelect';

import AuthService from '@services/AuthService';
import { AuditLog, EntityType } from '@models/AuditLog';

import auditLogsTableStyles from './AuditLogsTableStyles';

interface AuditLogsResponse extends Response {
	success: boolean;
	message?: string;
	data: AuditLog[];
}

interface AuditLogsCsvResponse extends Response {
	success: boolean;
	message?: string;
	data: string;
}

interface State {
	auditLogs: AuditLog[];
	fromDate?: MomentMoment;
	toDate?: MomentMoment;
}

interface Props extends WithStyles<typeof auditLogsTableStyles>{
	loadAllLogs: boolean;
	entityId?: string;
	entityType?: EntityType;
	showChrome: boolean;
}

class AuditLogsTable extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.state = {
			auditLogs: []
		};
		this.authService = new AuthService();

		this.loadAuditLogs();
	}

	private loadAuditLogs(): void {
		let url = '/api/audit-logs';

		if (!this.props.loadAllLogs && this.props.entityId !== undefined) {
			url += `?entityType=${this.props.entityType === undefined ? EntityType.Comparator : this.props.entityType}&entityId=${this.props.entityId}`;
		}

		this.authService.fetch<AuditLogsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({auditLogs: response.data});
			}
		});
	}

	private nameForAction(actionType: number): string {
		const lookup: {[k: number]: string } = {
			0: 'Erstellt',
			1: 'Bearbeitet',
			2: 'Gelöscht'
		};

		if (!lookup[actionType]) {
			return 'Unbekannt';
		}

		return lookup[actionType];
	}

	private handleTimeRangeChanges(fromDate: MomentMoment, toDate: MomentMoment): void {
		this.setState({fromDate: fromDate, toDate: toDate});
	}

	private async handleDownload(): Promise<void> {
		let url = '/api/audit-logs/csv';

		if (this.state.fromDate !== undefined && this.state.toDate !== undefined) {
			url += `?timeFrom=${encodeURI(this.state.fromDate.toISOString())}`;
			url += `&timeTo=${encodeURI(this.state.toDate.toISOString())}`;
		}

		this.authService.fetch<AuditLogsCsvResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {

				const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
				const fileName = 'Chronik.csv';

				if (navigator.msSaveBlob) { // IE 10+
					navigator.msSaveBlob(blob, fileName);
				} else {
					const link = document.createElement('a');
					if (link.download !== undefined) {
						const url = URL.createObjectURL(blob);
						link.setAttribute('href', url);
						link.setAttribute('download', fileName);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private renderTable(): ReactNode {
		return (
			<MaterialTable
				columns={[
					{
						title: 'Datum',
						field: 'created_at' as const,
						type: 'date' as const,
						render: (auditLog): ReactNode => {
							return (
								<Moment format="DD.MM.YYYY HH:mm" date={auditLog.created_at} locale="de" />
							);
						}
					},
					{
						title: 'Benutzer',
						field: 'user.username' as const
					},
					{
						title: 'Vergleicher',
						field: 'entityId' as const,
						hidden: !this.props.loadAllLogs,
						render: (auditLog): ReactNode => {
							if (auditLog.entityType === 1) {
								return (<Link href={`/automated-comparator/${auditLog.entityId}/edit`}>a{auditLog.entityId}</Link>);
							}
							return (<Link href={`/comparator/${auditLog.entityId}/edit`}>{auditLog.entityId}</Link>);
						}
					},
					{
						title: 'Aktion',
						field: 'action' as const,
						render: (auditLog): ReactNode => {
							return this.nameForAction(auditLog.action);
						}
					},
				]}
				options={{
					search: false,
					filtering: false,
					columnsButton: false,
					paging: true,
					pageSize: this.props.loadAllLogs ? 100 : 5,
					pageSizeOptions: [5, 100, 200],
					emptyRowsWhenPaging: false,
					draggable: false,
					toolbar: this.props.showChrome,
					showTitle: this.props.showChrome
				}}
				style={{
					borderRadius: 0,
					width: '100%',
					marginTop: '30px',
				}}
				data={ this.state.auditLogs }
				title={ 'Aktivitäten' }
				localization={ TableLocalization.localization }
			/>
		);

	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.showChrome) {
			return this.renderTable();
		}

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h1" variant="h4" className={classes.title}>
					{'Chronik'}
				</Typography>
				<Paper square={true} className={classes.export}>
					<Typography component="h2" variant="h6" className={classes.titleExport}>
						{'Chronik Exportieren'}
					</Typography>
					<TimeRangeSelect onChange={this.handleTimeRangeChanges.bind(this)} onDownload={this.handleDownload.bind(this)} isChronic={true} />
				</Paper>
				{this.renderTable()}
			</Paper>
		);
	}
}

export default withStyles(auditLogsTableStyles)(AuditLogsTable);
