import { Theme, createStyles} from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorTableStyles = (theme: Theme): any => createStyles({
	startButton: {
		marginRight: '5px',
	},
	editButton: {
		marginRight: '5px'
	},
	folderFilterContainer: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
	},
	folderEditButton: {
		alignSelf: 'flex-end',
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},
	checkAnswersButtonContainer: {
		float: 'right'
	},
	advisorTitle: {
		cursor: 'pointer'
	},
	progress: {
		width: '20px!important',
		height: '20px!important',
	},
	progressLeft: {
		marginRight: theme.spacing(1)
	},
	progressRight: {
		marginLeft: theme.spacing(1)
	},
	buttonsContainer: {
		display: 'flex',
		justifyContent: 'end',
		alignItems: 'center'
	},
	previewContainer: {
		marginLeft: theme.spacing(0.5),
		marginRight: theme.spacing(0.5)
	},
	warningIcon: {
		color: theme.palette.error.main
	},
	answerProductsIconContainer: {
		height: '24px'
	}
});

export default advisorTableStyles;