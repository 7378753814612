import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const paymentSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: 32,
		maxWidth: '250px',
		minWidth: '250px',
		width: '250px',
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
});

export default paymentSelectStyles;
