import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const automatedComparatorRulesSelectStyles = (theme: Theme): any => createStyles({
	dialogTitle: {
		display: 'inline-block',
	},
	dialogActions: {
		marginTop: '10px',
		marginRight: '5px'
	},
	rulesContainer: {
		marginTop: theme.spacing(2)
	}
});

export default automatedComparatorRulesSelectStyles;
