import React, { ReactNode, Component } from 'react';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import {
	Checkbox,
	Typography,
	Paper,
	Link,
	FormControl,
	Select,
	MenuItem,
	Button,
	WithStyles,
	Fab,
	Tooltip,
	withStyles
} from '@material-ui/core';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import QueueIcon from '@material-ui/icons/Queue';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import TuneIcon from '@material-ui/icons/Tune';
import WarningIcon from '@material-ui/icons/Warning';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CallMergeIcon from '@material-ui/icons/CallMerge';

import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import comparatorOverviewStyles from './ComparatorOverviewStyles';

import AuthService from '@services/AuthService';
import CmsContentService from '@services/CmsContentService';
import { CategoriesResponse, Category } from '@models/Category';
import { Comparator } from '@models/Comparator';
import { AutomatedComparator } from '@models/AutomatedComparator';
import { ProductStatus } from '@models/Product';
import { CmsContent } from '@models/CmsContent';

interface State {
	categories: Category[];
	comparators: Comparator[];
	automatedComparators: AutomatedComparator[];
	expanded: string[];
	filter: ComparatorFilter;
	cmsContent: CmsContent[];
	comparatorsCount: ComparatorCount[];
	automatedComparatorsCount: ComparatorCount[];
	selectedComparators: Comparator[];
	selectedAutomatedComparators: AutomatedComparator[];
	tooltipDisabledCheckbox: string;
}

interface Props extends WithStyles<typeof comparatorOverviewStyles>, WithSnackbarProps, RouteComponentProps {
	dialog: ConfirmDialog;
}

interface ComparatorsResponse {
	data: Comparator[];
	success: boolean;
	message?: string;
}

interface AutomatedComparatorsResponse {
	data: AutomatedComparator[];
	success: boolean;
	message?: string;
}

interface ComparatorCount {
	categoryCode: string;
	count: number;
}

interface ComparatorsCountResponse {
	data: ComparatorCount[];
	success: boolean;
	message?: string;
}

enum ComparatorFilter {
	All = 0,
	Normal,
	Automatic,
	Inactive,
	Active,
	LessThanFourOnline,
	LessThanFourOnlineWithMoreThanFourProducts,
	MoreThanTenProducts,
	MoreThanTenProductsWithoutMergedWithoutSplitted
}

class ComparatorOverview extends Component<Props, State> {

	private authService: AuthService;
	private cmsContentService: CmsContentService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.cmsContentService = new CmsContentService();

		this.state = {
			categories: [],
			comparators: [],
			automatedComparators: [],
			expanded: [],
			filter: ComparatorFilter.All,
			cmsContent: [],
			comparatorsCount: [],
			automatedComparatorsCount: [],
			selectedComparators: [],
			selectedAutomatedComparators: [],
			tooltipDisabledCheckbox: ''
		};
	}

	public componentDidMount(): void {
		this.loadCategories();
		this.loadCmsContent();

		if (window.history.state && window.history.state.expanded) {
			this.setState({expanded: window.history.state.expanded});
		}
		this.loadNumberOfComparators();
		if (window.history.state && window.history.state.expanded) {
			this.loadComparators(window.history.state.expanded, false);
		} else {
			this.loadComparators([], false);
		}
	}

	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({categories: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadNumberOfComparators(): void {
		if (this.state.filter !== 2) {
			this.authService.fetch<ComparatorsCountResponse>(`/api/comparators/count/${this.state.filter}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					this.setState({comparatorsCount: response.data});
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}

		if (this.state.filter !== 1) {
			this.authService.fetch<ComparatorsCountResponse>(`/api/automated-comparators/count/${this.state.filter}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					this.setState({automatedComparatorsCount: response.data});
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private loadComparators(nodes: string[], treeItemClicked: boolean): void {
		if (nodes) {
			let newExpanded = nodes;
			if (treeItemClicked) {
				newExpanded = nodes.filter(x => !this.state.expanded.includes(x));
			}

			for (const currentNewExpanded of newExpanded) {
				if ((currentNewExpanded.match(/C/g) || []).length < 2) {
					if (this.state.filter !== 2) {
						this.authService.fetch<ComparatorsResponse>(`/api/comparators/category/${currentNewExpanded}/${this.state.filter}`, {
							method: 'GET'
						}).then((response): void => {
							if (response.success) {
								const currentComparators = this.state.comparators;
								for (const comparator of response.data) {
									if (!currentComparators.some(c => c._id === comparator._id)) {
										currentComparators.push(comparator);
									}
								}
								this.setState({comparators: currentComparators});
							} else if (response.message) {
								throw new Error(response.message);
							} else {
								throw new Error('Unkown Error');
							}
						});
					}

					if (this.state.filter !== 1) {
						this.authService.fetch<AutomatedComparatorsResponse>(`/api/automated-comparators/category/${currentNewExpanded}/${this.state.filter}`, {
							method: 'GET'
						}).then((response): void => {
							if (response.success) {
								const currentAutomatedComparators = this.state.automatedComparators;
								for (const automatedComparator of response.data) {
									if (!currentAutomatedComparators.some(c => c._id === automatedComparator._id)) {
										currentAutomatedComparators.push(automatedComparator);
									}
								}
								this.setState({automatedComparators: currentAutomatedComparators});
							} else if (response.message) {
								throw new Error(response.message);
							} else {
								throw new Error('Unkown Error');
							}
						});
					}
				}
			}
		}

	}

	private async loadCmsContent(): Promise<void> {
		const content = await this.cmsContentService.loadAllContents();
		this.setState({cmsContent: content});
	}

	private offlineProductSum(comparator: Comparator | AutomatedComparator): number {
		return comparator.products.filter((p): boolean => p.status === ProductStatus.Offline).length;
	}

	private cmsContentButton(category: Category): ReactNode {
		const classes = this.props.classes;
		const cmsContent = this.state.cmsContent.filter((c: CmsContent): boolean => c.categoryCode === category.code);

		if (cmsContent.length > 0) {
			return (
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					className={classes.editContentButton}
					startIcon={<EditIcon />}
					onClick={(): void => {
						this.props.history.push(`/cms-content/${category.code}/${cmsContent[0]._id}`);
					}}
				>
					CMS bearbeiten
				</Button>
			);
		} else {
			return (
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					className={classes.newContentButton}
					startIcon={<AddIcon />}
					onClick={(): void => {
						this.props.history.push(`/cms-content/${category.code}/new`);
					}}
				>
					CMS Neu
				</Button>
			);
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private sortComparators(comparatorsMixed: any[]): any[] {
		return comparatorsMixed.sort(function (c1: Comparator | AutomatedComparator, c2: Comparator | AutomatedComparator): number {
			let c1NameToCompare = c1.name;
			let c2NameToCompare = c2.name;
			if (c1.nameBackend && c1.nameBackend !== '') {
				c1NameToCompare = c1.nameBackend;
			}
			if (c2.nameBackend && c2.nameBackend !== '') {
				c2NameToCompare = c2.nameBackend;
			}
			return c1NameToCompare.localeCompare(c2NameToCompare);
		});
	}

	private treeItemForCategory(category: Category): ReactNode {
		const classes = this.props.classes;
		const comparators = this.state.comparators.filter((comparator: Comparator): boolean => comparator.categoryCode === category.code);
		const automatedComparators = this.state.automatedComparators.filter((comparator: AutomatedComparator): boolean => comparator.categoryCode === category.code);

		if (comparators.length === 0 && automatedComparators.length === 0 && category.children.length === 0) {
			return (
				<TreeItem
					nodeId={category.code}
					key={category.code}
					label={<div className={classes.categoryContainer}>
						<span className={classes.categoryTitle}>{category.name}</span>
						{this.cmsContentButton(category)}
					</div>}
				/>
			);
		}

		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		const comparatorsMixed: any[] = comparators.concat(automatedComparators);

		return (
			<TreeItem
				nodeId={category.code}
				key={category.code}
				label={<div className={classes.categoryContainer}>
					<span className={classes.categoryTitle}>{category.name}</span>
					{this.cmsContentButton(category)}
					{this.comparatorCountForCategory(category) > 0 &&
						<span className={classes.categoryCount}>{this.comparatorCountForCategory(category)}</span>
					}
				</div>}
			>
				{this.sortedCategories(category.children).map((child: Category): ReactNode => (
					this.treeItemForCategory(child)
				))}

				{ this.sortComparators(comparatorsMixed).map((comparator: Comparator | AutomatedComparator): ReactNode => {
					if ((comparator as AutomatedComparator).rules) {
						return this.treeItemForAutomatedComparator(comparator as AutomatedComparator);
					} else {
						return this.treeItemForComparator(comparator);
					}
				})}
			</TreeItem>
		);
	}

	private handleCheckboxComparatorHover(comparator: Comparator): void {
		if (this.state.selectedAutomatedComparators.length > 0 && this.state.selectedAutomatedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Produktvergleichertyp und Kategorie unterscheiden sich zu den bereits ausgewählten Produktvergleicher'});
		} else if (this.state.selectedAutomatedComparators.length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Ein normaler Produktvergleicher kann nicht mit automatisierten Produktvergleichern zusammengefügt werden'});
		} else if (this.state.selectedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Die Kategorie dieses Produktvergleichers unterscheidet sich zu der Kategorie der bereits ausgewählten Produktvergleicher'});
		} else {
			this.setState({tooltipDisabledCheckbox: ''});
		}
	}

	private treeItemForComparator(comparator: Comparator): ReactNode {
		const classes = this.props.classes;

		return (
			<TreeItem
				nodeId={`${comparator._id}`}
				key={comparator._id}
				label={
					<div className={classes.comparatorContainer}>
						<span>
							<Tooltip
								placement="bottom"
								arrow
								title={this.state.tooltipDisabledCheckbox}
							>
								<div onMouseOver={(_): void => {this.handleCheckboxComparatorHover(comparator);}}>
									<Checkbox
										color="primary"
										onChange={(event): void => {this.handleComparatorCheckboxChange(comparator, event);}}
										disabled={(this.state.selectedAutomatedComparators.length > 0 || this.state.selectedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) ? true : false}
										className={classes.comparatorCheckbox}
									/>
								</div>
							</Tooltip>
						</span>
						<span className={classes.comparatorName}>
							<Link color={(comparator.mergeDetail && comparator.mergeDetail.mergeComparator) ? 'secondary' : 'primary'} href={`/comparator/${comparator._id}/edit`}>{comparator.nameBackend ? comparator.nameBackend : comparator.name} (ID: {comparator._id}, {comparator.active ? 'Aktiv' : 'Inaktiv'})</Link>
						</span>
						{comparator.mergeDetail && comparator.mergeDetail.mergeComparator &&
							<span className={classes.mergeIcon}>
								<Tooltip title={
									<span>{comparator.mergeDetail.mergeComparators.join(', ')}</span>
								}>
									<CallMergeIcon color="secondary" />
								</Tooltip>
							</span>
						}
						{comparator.splitDetail && comparator.splitDetail.levels && comparator.splitDetail.levels.length > 0 &&
							<span className={classes.splitIcon}>
								<CallSplitIcon color="primary" />
							</span>
						}
						{((comparator.products.length - this.offlineProductSum(comparator)) < 2) &&
						<span className={classes.warning}>
							<Tooltip title={
								<span>Der Produktvergleicher enthält weniger als 2 online Produkte und wird daher nicht angezeigt.</span>
							}>
								<WarningIcon/>
							</Tooltip>
						</span>
						}
						{this.offlineProductSum(comparator) > 0 &&
							<span className={classes.offlineBubble}>{`Offline: ${this.offlineProductSum(comparator)}/${comparator.products.length}`}</span>
						}
						{comparator.missingCount > 0 &&
							<span className={classes.attributesBubble}>{`Attribute: ${comparator.missingCount}/${comparator.products.length}`}</span>
						}
						<span className={classes.productSumBubble}>{`Produkte: ${comparator.products.length}`}</span>

						<Button
							variant="outlined"
							color="secondary"
							size="small"
							className={classes.deleteButton}
							startIcon={<DeleteIcon />}
							onClick={this.deleteComparatorButtonPressed.bind(this, comparator)}
						>
							Löschen
						</Button>
					</div>
				}
			/>
		);
	}

	private handleCheckboxAutomatedComparatorHover(comparator: AutomatedComparator): void {
		if (this.state.selectedComparators.length > 0 && this.state.selectedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Produktvergleichertyp und Kategorie unterscheiden sich zu den bereits ausgewählten Produktvergleicher'});
		} else if (this.state.selectedComparators.length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Ein automatisierter Produktvergleicher kann nicht mit normalen Produktvergleichern zusammengefügt werden'});
		} else if (this.state.selectedAutomatedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) {
			this.setState({tooltipDisabledCheckbox: 'Die Kategorie dieses Produktvergleichers unterscheidet sich zu der Kategorie der bereits ausgewählten Produktvergleicher'});
		} else {
			this.setState({tooltipDisabledCheckbox: ''});
		}
	}

	private treeItemForAutomatedComparator(comparator: AutomatedComparator): ReactNode {
		const classes = this.props.classes;

		return (
			<TreeItem
				nodeId={`a${comparator._id}`}
				key={comparator._id}
				label={
					<div className={classes.comparatorContainer}>
						<span>
							<Tooltip
								placement="bottom"
								arrow
								title={this.state.tooltipDisabledCheckbox}
							>
								<div onMouseOver={(_): void => {this.handleCheckboxAutomatedComparatorHover(comparator);}}>
									<Checkbox
										color="primary"
										onChange={(event): void => {this.handleAutomatedComparatorCheckboxChange(comparator, event);}}
										disabled={(this.state.selectedComparators.length > 0 || this.state.selectedAutomatedComparators.filter(c => c.categoryCode !== comparator.categoryCode).length > 0) ? true : false}
										className={classes.comparatorCheckbox}
									/>
								</div>
							</Tooltip>
						</span>
						<span className={classes.comparatorName}>
							<Link color={(comparator.mergeDetail && comparator.mergeDetail.mergeComparator) ? 'secondary' : 'primary'} href={`/automated-comparator/${comparator._id}/edit`}>{comparator.nameBackend ? comparator.nameBackend : comparator.name} (ID: a{comparator._id}, {comparator.active ? 'Aktiv' : 'Inaktiv'})</Link>
						</span>
						{comparator.mergeDetail && comparator.mergeDetail.mergeComparator &&
							<span className={classes.mergeIcon}>
								<Tooltip title={
									<span>{comparator.mergeDetail.mergeComparators.map((id: number): string => {
										const comparators = 'a' + id;
										return comparators;
									}).join(', ')}</span>
								}>
									<CallMergeIcon color="secondary" />
								</Tooltip>
							</span>
						}
						{comparator.splitDetail && comparator.splitDetail.levels && comparator.splitDetail.levels.length > 0 &&
							<span className={classes.splitIcon}>
								<CallSplitIcon color="primary" />
							</span>
						}
						{((comparator.products.length - this.offlineProductSum(comparator)) < 2) &&
							<span className={classes.warning}>
								<Tooltip title={
									<span>Der Produktvergleicher enthält weniger als 2 online Produkte und wird daher nicht angezeigt.</span>
								}>
									<WarningIcon/>
								</Tooltip>
							</span>
						}
						{this.offlineProductSum(comparator) > 0 &&
							<span className={classes.offlineBubble}>{`Offline: ${this.offlineProductSum(comparator)}/${comparator.products.length}`}</span>
						}
						{comparator.missingCount > 0 &&
							<span className={classes.attributesBubble}>{`Attribute: ${comparator.missingCount}/${comparator.products.length}`}</span>
						}
						<span className={classes.productSumBubble}>{`Produkte: ${comparator.products.length}`}</span>

						<Button
							variant="outlined"
							color="secondary"
							size="small"
							className={classes.deleteButton}
							startIcon={<DeleteIcon />}
							onClick={this.deleteAutomatedComparatorButtonPressed.bind(this, comparator)}
						>
							Löschen
						</Button>
					</div>
				}
			/>
		);
	}

	private treeItemForComparatorsOutsideOfCategories(): ReactNode {
		const classes = this.props.classes;

		const categoryIds = this.getFlatCategoryIds(this.state.categories);
		let comparators: Comparator[] = [];
		if (this.state.filter !== ComparatorFilter.Automatic) {
			comparators = this.state.comparators.filter((comparator): boolean => {
				return (!categoryIds.includes(comparator.categoryCode) || comparator.categoryCode === '');
			});
		}

		let automatedComparators: AutomatedComparator[] = [];
		if (this.state.filter !== ComparatorFilter.Normal) {
			automatedComparators = this.state.automatedComparators.filter((comparator): boolean => {
				return (!categoryIds.includes(comparator.categoryCode) || comparator.categoryCode === '');
			});
		}

		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		const comparatorsMixed: any[] = comparators.concat(automatedComparators);

		let noCategoryCount = 0;
		for (const comparatorCount of this.state.comparatorsCount) {
			if (comparatorCount.categoryCode === 'outside-categories') {
				noCategoryCount += comparatorCount.count;
			}
		}
		for (const automatedComparatorCount of this.state.automatedComparatorsCount) {
			if (automatedComparatorCount.categoryCode === 'outside-categories') {
				noCategoryCount += automatedComparatorCount.count;
			}
		}

		if (comparators.length === 0 && automatedComparators.length === 0) {
			return (
				<TreeItem
					nodeId="outside-categories"
					key="outside-categories"
					label={<div className={classes.categoryContainer}>
						<span className={classes.categoryTitle}>Produktvergleicher ohne Kategorie</span>
						{(noCategoryCount > 0) &&
							<span className={classes.categoryCount}>{noCategoryCount}</span>
						}
					</div>}
				>
					{noCategoryCount > 0 && <></>}
				</TreeItem>
			);
		}

		return (
			<TreeItem
				nodeId="outside-categories"
				key="outside-categories"
				label={<div className={classes.categoryContainer}>
					<span className={classes.categoryTitle}>Produktvergleicher ohne Kategorie</span>
					{(noCategoryCount > 0) &&
						<span className={classes.categoryCount}>{noCategoryCount}</span>
					}
				</div>}
			>
				{ this.sortComparators(comparatorsMixed).map((comparator: Comparator | AutomatedComparator): ReactNode => {
					if ((comparator as AutomatedComparator).rules) {
						return this.treeItemForAutomatedComparator(comparator as AutomatedComparator);
					} else {
						return this.treeItemForComparator(comparator);
					}
				})}

			</TreeItem>
		);

	}

	private comparatorCountForCategory(category: Category): number {
		if ((category.code.match(/C/g) || []).length < 2) {
			let comparatorSum = 0;
			for (const comparatorCount of this.state.comparatorsCount) {
				if (comparatorCount.categoryCode === category.code) {
					comparatorSum += comparatorCount.count;
				}
			}
			for (const automatedComparatorCount of this.state.automatedComparatorsCount) {
				if (automatedComparatorCount.categoryCode === category.code) {
					comparatorSum += automatedComparatorCount.count;
				}
			}
			return comparatorSum;
		} else {
			let comparators = [];
			if (this.state.filter !== ComparatorFilter.Automatic) {
				comparators = this.state.comparators.filter((comparator: Comparator): boolean => comparator.categoryCode === category.code);
			}

			let automatedComparators = [];
			if (this.state.filter !== ComparatorFilter.Normal) {
				automatedComparators = this.state.automatedComparators.filter((comparator: AutomatedComparator): boolean => comparator.categoryCode === category.code);
			}

			let comparatorCount = comparators.length + automatedComparators.length;

			for (const child of category.children) {
				comparatorCount += this.comparatorCountForCategory(child);
			}

			return comparatorCount;
		}
	}

	private sortedCategories(categories: Category[]): Category[] {
		return categories.sort((a: Category, b: Category): number => {
			if (a.children.length > 0 && b.children.length === 0) {
				return -1;
			} else if (b.children.length > 0 && a.children.length === 0) {
				return 1;
			} else {
				return (a.name > b.name) ? 1 : -1;
			}
		});
	}

	private handleComparatorCheckboxChange(comparator: Comparator, event: React.ChangeEvent<HTMLInputElement>): void {
		event.persist();
		const currentSelectedComparators = this.state.selectedComparators;

		if (event.currentTarget.checked) {
			currentSelectedComparators.push(comparator);
		} else {
			const indexComparator = currentSelectedComparators.findIndex(c => c._id === comparator._id);
			if (indexComparator > -1) {
				currentSelectedComparators.splice(indexComparator, 1);
			}
		}
		this.setState({selectedComparators: currentSelectedComparators});
	}

	private handleAutomatedComparatorCheckboxChange(automatedComparator: AutomatedComparator, event: React.ChangeEvent<HTMLInputElement>): void {
		event.persist();
		const currentSelectedAutomatedComparators = this.state.selectedAutomatedComparators;

		if (event.currentTarget.checked) {
			currentSelectedAutomatedComparators.push(automatedComparator);
		} else {
			const indexAutomatedComparator = currentSelectedAutomatedComparators.findIndex(c => c._id === automatedComparator._id);
			if (indexAutomatedComparator > -1) {
				currentSelectedAutomatedComparators.splice(indexAutomatedComparator, 1);
			}
		}
		this.setState({selectedAutomatedComparators: currentSelectedAutomatedComparators});
	}

	private deleteComparatorButtonPressed(comparator: Comparator): void {
		const { dialog } = this.props;

		dialog.confirm({
			title: 'Produktvergleicher Löschen',
			message: `Soll der Produktvergleicher "${comparator.nameBackend ? comparator.nameBackend : comparator.name}" (ID: ${comparator._id}) wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'default',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'secondary',
				variant: 'text'
			},
		}).then((): void => {
			this.authService.fetch<{}>(`/api/comparators/${comparator._id}`, {
				method: 'DELETE',
			}).then((_): void => {
				this.setState({comparators: [], automatedComparators: [], comparatorsCount: [], automatedComparatorsCount: []}, () => {
					this.loadNumberOfComparators();
					this.loadComparators(this.state.expanded, false);
					this.props.enqueueSnackbar(`Produktvergleicher mit der ID ${comparator._id} erfolgreich gelöscht!`, {variant: 'success'});
				});
			});
		}).catch((error): void => {
			console.log(`Error while deleting comparator: ${error}`);
		});
	}

	private deleteAutomatedComparatorButtonPressed(comparator: AutomatedComparator): void {
		const { dialog } = this.props;

		dialog.confirm({
			title: 'Produktvergleicher Löschen',
			message: `Soll der Produktvergleicher "${comparator.nameBackend ? comparator.nameBackend : comparator.name}" (ID: a${comparator._id}) wirklich gelöscht werden?`,
			ok: {
				text: 'Löschen',
				color: 'default',
				variant: 'text'
			},
			cancel: {
				text: 'Abbrechen',
				color: 'secondary',
				variant: 'text'
			},
		}).then((): void => {
			this.authService.fetch<{}>(`/api/automated-comparators/${comparator._id}`, {
				method: 'DELETE',
			}).then((_): void => {
				this.setState({comparators: [], automatedComparators: [], comparatorsCount: [], automatedComparatorsCount: []}, () => {
					this.loadNumberOfComparators();
					this.loadComparators(this.state.expanded, false);
					this.props.enqueueSnackbar(`Produktvergleicher mit der ID a${comparator._id} erfolgreich gelöscht!`, {variant: 'success'});
				});
			});
		}).catch((error): void => {
			console.log(`Error while deleting comparator: ${error}`);
		});
	}


	private handleTreeChange(_: React.ChangeEvent<{}>, nodes: string[]): void {
		this.loadComparators(nodes, true);

		this.setState({expanded: nodes});
		window.history.replaceState({expanded: nodes}, '', window.location.pathname);
	}

	private toggleExpandAll(): void {
		if (this.state.expanded.length > 0) {
			this.setState({expanded: []});
			window.history.replaceState({expanded: []}, '', window.location.pathname);
		} else {
			const expanded = this.getFlatCategoryIds(this.state.categories).concat(['outside-categories']);
			this.setState({expanded: expanded}, () => {
				this.loadComparators(expanded, false);
				window.history.replaceState({expanded: expanded}, '', window.location.pathname);
			});

		}
	}

	private getFlatCategoryIds(categories: Category[]): string[] {
		let categoryIds: string[] = [];

		for (const category of categories) {
			categoryIds.push(category.code);
			categoryIds = categoryIds.concat(this.getFlatCategoryIds(category.children));
		}

		return categoryIds;
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleFilterChange(event: any): void {
		this.setState({filter: event.target.value, comparators: [], automatedComparators: [], comparatorsCount: [], automatedComparatorsCount: [], selectedComparators: [], selectedAutomatedComparators: []}, () => {
			this.loadNumberOfComparators();
			this.loadComparators(this.state.expanded, false);
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Produktvergleicher Übersicht'}
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={this.toggleExpandAll.bind(this)}
				>
					Alle Ein-/Ausklappen
				</Button>
				<FormControl className={classes.filterSelect}>
					<Select
						labelId="filter-label"
						value={this.state.filter}
						onChange={(event): void => {this.handleFilterChange(event);}}
					>
						<MenuItem value={ComparatorFilter.All}>Alle Vergleicher</MenuItem>
						<MenuItem value={ComparatorFilter.Normal}>Normale Produktvergleicher</MenuItem>
						<MenuItem value={ComparatorFilter.Inactive}>Inaktive Vergleicher</MenuItem>
						<MenuItem value={ComparatorFilter.Active}>Aktive Vergleicher</MenuItem>
						<MenuItem value={ComparatorFilter.LessThanFourOnline}>Weniger als 2 Produkte Online</MenuItem>
						<MenuItem value={ComparatorFilter.MoreThanTenProducts}>Mehr als 10 Produkte</MenuItem>
						<MenuItem value={ComparatorFilter.MoreThanTenProductsWithoutMergedWithoutSplitted}>Mehr als 10 Produkte, ohne zusammengefügte PVGL und ohne bereits aufgeteilte PVGL</MenuItem>
					</Select>
				</FormControl>
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					expanded={this.state.expanded}
					onNodeToggle={this.handleTreeChange.bind(this)}
				>
					{this.sortedCategories(this.state.categories).map((category: Category): ReactNode => (
						this.treeItemForCategory(category)
					))}
					{this.treeItemForComparatorsOutsideOfCategories()}
				</TreeView>
				<Tooltip
					placement="bottom"
					arrow
					title={'Einen neuen Produktvergleicher anlegen'}
				>
					<Fab
						color="primary"
						className={classes.addButton}
						onClick={(): void => {
							this.props.history.push('/comparator/new/edit');
						}}>
						<AddIcon />
					</Fab>
				</Tooltip>
				<Tooltip
					placement="bottom"
					arrow
					title={'Mehrere neue Produktvergleicher anlegen'}
				>
					<Fab
						color="secondary"
						className={classes.addMultipleButton}
						onClick={(): void => {
							this.props.history.push('/comparator-bulk/new/edit');
						}}>
						<QueueIcon />
					</Fab>
				</Tooltip>
				<Tooltip
					placement="bottom"
					arrow
					title={'Einen neuen Automatisierten Produktvergleicher anlegen'}
				>
					<Fab
						color="primary"
						className={classes.addAutomatedButton}
						onClick={(): void => {
							this.props.history.push('/automated-comparator/new/edit');
						}}>
						<TuneIcon />
					</Fab>
				</Tooltip>
				<Tooltip
					placement="bottom"
					arrow
					title={'Einen neuen zusammengefügten (Automatisierten) Produktvergleicher anlegen'}
				>
					<Fab
						color="secondary"
						className={classes.mergeComparatorButton}
						onClick={(): void => {
							if (this.state.selectedComparators.length < 2 && this.state.selectedAutomatedComparators.length < 2) {
								this.props.enqueueSnackbar('Bitte mindestens zwei Vergleicher auswählen!', {variant: 'error'});
							} else if (this.state.selectedComparators.length > 1) {
								this.props.history.push({pathname: '/comparator-merge/new', state: this.state.selectedComparators});
							} else if (this.state.selectedAutomatedComparators.length > 1) {
								this.props.history.push({pathname: '/automated-comparator-merge/new', state: this.state.selectedAutomatedComparators});
							}
						}}>
						<CallMergeIcon />
					</Fab>
				</Tooltip>
			</Paper>
		);
	}
}

export default withSnackbar(withDialog()(withStyles(comparatorOverviewStyles)(withRouter(ComparatorOverview))));
