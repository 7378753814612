import React, { ReactNode, Component } from 'react';
import equal from 'fast-deep-equal';
import {
	CircularProgress,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	Switch,
	Typography,
	Tooltip,
	WithStyles,
	withStyles
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import WarningIcon from '@material-ui/icons/Warning';

import RLDD from 'react-list-drag-and-drop/lib/RLDD';

import AttributeTemplateButton from './AttributeTemplateButton';
import { Product, AttributeSource } from '@models/Product';
import { AttributeTemplate, SummarizedAttribute } from '@models/Comparator';
import AttributeUtils from '../Common/AttributeUtils';

import ProductsMissingAttributes from '../AutomatedComparator/ProductsMissingAttributes';
import comparatorAttributeSelectStyles from './ComparatorAttributeSelectStyles';

interface SelectedAttributeToMissingProducts {
	[key: string]: Product[];
}

interface State {
	attributes: SummarizedAttribute[];
	selectedAttributes: SummarizedAttribute[];
	selectedDelimiterAttributes: SummarizedAttribute[];
	productsMissingAttributesOpen: boolean;
	attribute: SummarizedAttribute | null;
	selectedAttributeToMissingProducts: SelectedAttributeToMissingProducts;
	selectedAttributeSource: string;
}

interface Props extends WithStyles<typeof comparatorAttributeSelectStyles> {
	onChange: (selectedAttributes: string[]) => void;
	onChangeDelimiterRemoveAttributes: ((selectedDelimiterAttributes: string[]) => void) | null;
	selectedProducts: Product[];
	selectedAttributeCodes: string[];
	selectedDelimiterAttributeCodes?: string[];
	sortingEnabled: boolean;
	templatesEnabled: boolean;
	loadingAttributeSettings?: boolean;
	maintainedEnabled: boolean;
	type: string;
	categoryFeed: boolean;
}

class ComparatorAttributeSelect extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			attributes: [],
			selectedAttributes: [],
			selectedDelimiterAttributes: [],
			productsMissingAttributesOpen: false,
			attribute: null,
			selectedAttributeToMissingProducts: {},
			selectedAttributeSource: 'wi'
		};
	}

	public componentDidMount(): void {
		this.summarizeAttributes();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (!equal(prevProps.selectedProducts, this.props.selectedProducts) ||
		!equal(prevProps.selectedAttributeCodes, this.props.selectedAttributeCodes)) {
			this.summarizeAttributes();
		}
	}

	private summarizeAttributes(): void {
		let attributes: SummarizedAttribute[] = [];
		if ((this.props.type !== 'automatedComparator' && this.props.type !== 'advisor') || (this.props.type === 'automatedComparator' && this.props.selectedProducts.length > 0) || (this.props.type === 'advisor' && this.props.selectedProducts.length > 0)) {

			let id = 0;

			for (const product of this.props.selectedProducts) {

				const attributesAddedOfProduct: string[] = [];

				for (const attribute of product.attributes) {

					let sumAttribute = attributes.find((a) => a.key === attribute.key);

					if (!attributesAddedOfProduct.includes(attribute.key)) {
						attributesAddedOfProduct.push(attribute.key);

						const isMaintained = AttributeUtils.isMaintained(attribute.value);

						if (sumAttribute) {
							sumAttribute.count++;
							if (isMaintained) {
								sumAttribute.maintainedCount++;
							}
						} else {
							sumAttribute = {
								key: attribute.key,
								name: attribute.name,
								count: 1,
								id: id,
								hidden: true,
								maintainedCount: isMaintained ? 1 : 0,
								source: attribute.source
							};
							attributes.push(sumAttribute);
							id++;
						}
					}
				}
			}

			for (const attribute of attributes) {
				if (attribute.maintainedCount > this.props.selectedProducts.length * 0.25) {
					attribute.hidden = false;
				}
			}

			attributes = attributes.sort((a, b): number => {
				if (a.name > b.name) {
					return 1;
				}

				if (a.name < b.name) {
					return -1;
				}

				return 0;
			});

			const selectedAttributes: SummarizedAttribute[] = [];

			for (const selectedAttributeCode of this.props.selectedAttributeCodes) {
				const attribute = attributes.find((a) => a.key === selectedAttributeCode);
				if (attribute) {
					selectedAttributes.push(attribute);
				}
			}

			const selectedDelimiterAttributes: SummarizedAttribute[] = [];
			if (this.props.selectedDelimiterAttributeCodes) {
				for (const selectedDelimiterAttributeCode of this.props.selectedDelimiterAttributeCodes) {
					const attribute = attributes.find((a) => a.key === selectedDelimiterAttributeCode);
					if (attribute) {
						selectedDelimiterAttributes.push(attribute);
					}
				}
			}

			// Get missing products of selected attributes
			const selectedAttributeToMissingProducts = this.state.selectedAttributeToMissingProducts;
			for (const attribute of selectedAttributes) {
				const productsMissingAttributes = this.getProductsNotContainingAttribute(attribute);
				selectedAttributeToMissingProducts[attribute.id] = productsMissingAttributes;

			}

			this.setState({attributes: attributes, selectedAttributes: selectedAttributes, selectedDelimiterAttributes: selectedDelimiterAttributes});

			this.notifyParentSelectedAttributes(selectedAttributes);
			this.notifyParentSelectedDelimiterAttributes(selectedDelimiterAttributes);
		} else {
			this.setState({attributes: [], selectedAttributes: [], selectedDelimiterAttributes: []});
		}

	}

	private handleSelectedAttributeSourceChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({selectedAttributeSource: event.target.value});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const productCount = this.props.selectedProducts.length;

		return (
			<div className={classes.container}>
				{this.props.loadingAttributeSettings &&
				<CircularProgress className={classes.loadingIndicator}/>
				}
				{this.state.attributes.length > 0 && !this.props.loadingAttributeSettings &&
					<div className={this.props.sortingEnabled ? classes.attributeContainer : classes.attributeContainerSolo}>
						<RadioGroup style={{flexDirection: 'row'}} className={classes.radioButtons}value={this.state.selectedAttributeSource} onChange={(event): void => {this.handleSelectedAttributeSourceChanged(event);}}>
							<FormControlLabel value="all" control={<Radio color="primary"/>} label="Alle" />
							<FormControlLabel value="wi" control={<Radio color="primary"/>} label="Wichtige Infos" />
						</RadioGroup>
						<Typography component="h3" variant="subtitle2" display="block" className={classes.subtitle}>
							{`Verfügbare Attribute (${this.state.selectedAttributes.length}/${this.state.attributes.length})`}
						</Typography>
						{ this.props.categoryFeed &&
						<div className={classes.switchContainer}>
							<div className={classes.switchAll}>
								<Typography component="h3" variant="subtitle2" display="block" className={classes.subtitleAttributeSwitch}>
									{'Globale Einstellung'}
								</Typography>
								<Switch
									color="primary"
									onChange={(event): void => {this.handleAllAttributeSwitchChange(event);}}
								/>
								Entfernen der Trennzeichen für alle ausgewählten Attribute aktivieren bzw. deaktivieren
							</div>
							<div className={classes.switchExplainationContainer}>
								<Typography component="h3" variant="subtitle2" display="block">
									{'Anleitung'}
								</Typography>
								<div className={classes.switchExplaination}>
									<div><Switch color="primary" checked={false} disableRipple={true} style={{ backgroundColor: 'transparent' }}/>Attributewert wird originalgetreu übernommen</div>
									<div><Switch color="primary" checked={true} disableRipple={true} style={{ backgroundColor: 'transparent' }}/>{'Trennzeichen in Attributwert werden entfernt (z.B. 1.000 => 1000)'}</div>
								</div>
							</div>
						</div>
						}
						{this.state.attributes.map((attribute): ReactNode => {

							if (this.state.selectedAttributeSource === 'all' || (this.state.selectedAttributeSource === 'wi' && attribute.source && attribute.source === AttributeSource.WI)) {
								return (
									<FormControlLabel
										className={classes.attributeCheckbox}
										key={attribute.key}
										classes={{
											label: attribute.hidden && this.props.maintainedEnabled ? `${classes.label} ${classes.labelHidden}` : classes.label,
										}}
										control={
											<Checkbox
												className={classes.checkbox}
												checked={this.state.selectedAttributes.find((a): boolean => attribute.key === a.key) !== undefined}
												color="primary"
												onChange={(event): void => {this.handleCheckboxChange(attribute, event);}}
											/>
										}
										label={<React.Fragment>
											<span className={classes.nameContainer}>{attribute.name}</span>
											{this.props.categoryFeed && this.state.selectedAttributes.find((a): boolean => attribute.key === a.key) &&
										<span className={classes.attributeSwitchContainer}>
											<Switch
												color="primary"
												onChange={(event): void => {this.handleSingleAttributeSwitchChange(attribute, event);}}
												checked={this.state.selectedDelimiterAttributes.find((a): boolean => attribute.key === a.key) !== undefined}
											/>
										</span>
											}
											<span className={classes.occurrenceContainer}>{`${attribute.count}/${productCount}`}</span>
											{ this.props.maintainedEnabled && attribute.hidden &&
										<Tooltip title={
											<span>Attribut bei zu wenigen Artikel gepflegt oder mehr als 75% haben den Wert „k.A.“. Attribut wird versteckt.</span>
										}>
											<WarningIcon />
										</Tooltip>
											}
										</React.Fragment>}
									/>
								);
							} else {
								return null;
							}
						})}
					</div>
				}
				{this.state.attributes.length > 0 && !this.props.loadingAttributeSettings && this.props.sortingEnabled &&
					<div className={classes.selectedAttributeContainer}>
						<Typography component="h3" variant="subtitle2" display="block" className={classes.subtitle}>
							{'Ausgewählte Attribute (sortierbar mit Drag and Drop)'}
						</Typography>
						<RLDD
							cssClasses={classes.dragContainer}
							items={this.state.selectedAttributes}
							itemRenderer={(attribute: SummarizedAttribute): JSX.Element => {
								return (
									<Typography className={attribute.hidden && this.props.maintainedEnabled ? `${classes.dragableAttribute} ${classes.dragableAttributeHidden}` : classes.dragableAttribute}>
										<DragIndicatorIcon />
										<span className={classes.nameContainer}>{attribute.name}</span>
										<span className={classes.occurrenceContainerSelected} onClick={this.handleShowProductsMissingAttributes.bind(this, attribute)}><u>{`${attribute.count}/${productCount}`}</u></span>
										{ this.props.maintainedEnabled && attribute.hidden &&
											<Tooltip title={
												<span>Attribut bei zu wenigen Artikel gepflegt oder mehr als 75% haben den Wert „k.A.“. Attribut wird versteckt.</span>
											}>
												<WarningIcon />
											</Tooltip>
										}
									</Typography>
								);
							}}
							onChange={this.handleReorder.bind(this)}
						/>
						<ProductsMissingAttributes
							closeHandler={this.closeProductsMissingAttributes.bind(this)}
							editorOpen={this.state.productsMissingAttributesOpen}
							attribute={this.state.attribute}
							productsMissingAttributes={(this.state.attribute) ? this.state.selectedAttributeToMissingProducts[this.state.attribute.id] : null}
						/>
					</div>
				}
				{this.state.attributes.length > 0 && !this.props.loadingAttributeSettings && this.props.templatesEnabled &&
					<AttributeTemplateButton
						applyTemplate={this.handleApplyTemplate.bind(this)}
						selectedAttributes={this.state.selectedAttributes}
					/>
				}
				{this.props.type !== 'advisor' && this.props.type === 'automatedComparator' && this.props.sortingEnabled && this.props.selectedProducts.length === 0 && this.state.attributes.length === 0 &&
					<Typography>{'Bitte zuerst Produkte aktualisieren'}</Typography>
				}
				{this.props.type !== 'advisor' && this.props.sortingEnabled && this.props.type !== 'automatedComparator' && this.props.selectedProducts.length === 0 && this.state.attributes.length === 0 &&
					<Typography>{'Bitte zuerst Produkte auswählen'}</Typography>
				}
				{this.props.type === 'advisor' && !this.props.loadingAttributeSettings && this.props.selectedProducts.length === 0 && this.state.attributes.length === 0 &&
					<Typography>{'Bitte zuerst Kategorien auswählen'}</Typography>
				}
				{!this.props.loadingAttributeSettings && !this.props.sortingEnabled && this.props.selectedProducts.length === 0 && this.state.attributes.length === 0 &&
					<Typography>{'Bitte eine Kategorie auswählen!'}</Typography>
				}
				{this.props.selectedProducts.length !== 0 && this.state.attributes.length === 0 &&
					<Typography>{'Keine Attribute gefunden'}</Typography>
				}
			</div>
		);
	}

	private handleShowProductsMissingAttributes(attribute: SummarizedAttribute): void {
		this.setState({
			productsMissingAttributesOpen: true,
			attribute: attribute
		});
	}

	private closeProductsMissingAttributes(): void {
		this.setState({productsMissingAttributesOpen: false});
	};

	private handleSingleAttributeSwitchChange(attribute: SummarizedAttribute, event: React.ChangeEvent<HTMLInputElement>): void {
		const selectedAttributes = this.state.selectedAttributes;
		let delimiterRemoveAttributes = this.state.selectedDelimiterAttributes;

		if (event.target.checked) {
			if (selectedAttributes.filter(a => a.id === attribute.id).length > 0) {
				delimiterRemoveAttributes.push(attribute);
			}
		} else {
			delimiterRemoveAttributes = delimiterRemoveAttributes.filter((a): boolean => {
				return attribute.key !== a.key;
			});
		}

		this.setState({selectedDelimiterAttributes: delimiterRemoveAttributes});
		this.notifyParentSelectedDelimiterAttributes(delimiterRemoveAttributes);
	}

	private handleAllAttributeSwitchChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const delimiterRemoveAttributes = [];
		if (event.target.checked) {
			for (const selectedAttribute of this.state.selectedAttributes) {
				delimiterRemoveAttributes.push(selectedAttribute);
			}
		}

		this.setState({selectedDelimiterAttributes: delimiterRemoveAttributes});
		this.notifyParentSelectedDelimiterAttributes(delimiterRemoveAttributes);
	}

	private handleApplyTemplate(template: AttributeTemplate): void {
		const attributesFromTemplate = template.attributes.reverse();

		const selectedAttributes = [];

		for (const attributeCode of attributesFromTemplate) {
			const loadedAttribute = this.state.attributes.find((a): boolean => a.key === attributeCode);
			if (loadedAttribute) {
				selectedAttributes.unshift(loadedAttribute);
			}
		}

		this.setState({selectedAttributes: selectedAttributes});
		this.notifyParentSelectedAttributes(selectedAttributes);
	}

	private handleCheckboxChange(attribute: SummarizedAttribute, event: React.ChangeEvent<HTMLInputElement>): void {
		let selectedAttributes = this.state.selectedAttributes;
		let selectedDelimiterAttributes = this.state.selectedDelimiterAttributes;
		const selectedAttributeToMissingProducts = this.state.selectedAttributeToMissingProducts;
		if (event.target.checked) {
			selectedAttributes.push(attribute);

			const productsMissingAttributes = this.getProductsNotContainingAttribute(attribute);
			selectedAttributeToMissingProducts[attribute.id] = productsMissingAttributes;

		} else {
			selectedAttributeToMissingProducts[attribute.id] = [];
			selectedAttributes = selectedAttributes.filter((a): boolean => {
				return attribute.key !== a.key;
			});
			selectedDelimiterAttributes = selectedDelimiterAttributes.filter((a): boolean => {
				return attribute.key !== a.key;
			});
		}

		this.setState({
			selectedAttributes: selectedAttributes,
			selectedDelimiterAttributes: selectedDelimiterAttributes,
			selectedAttributeToMissingProducts: selectedAttributeToMissingProducts
		}, () => {
			this.notifyParentSelectedAttributes(selectedAttributes);
			this.notifyParentSelectedDelimiterAttributes(selectedDelimiterAttributes);
		});
	}

	private getProductsNotContainingAttribute(attribute: SummarizedAttribute): Product[] {
		const productsMissingAttributes: Product[] = [];
		for (const product of this.props.selectedProducts) {

			const productContainsAttribute = product.attributes.find((a) => a.key === attribute.key);
			if (!productContainsAttribute) {
				productsMissingAttributes.push(product);
			}
		}
		return productsMissingAttributes;
	}

	private handleReorder(attributes: SummarizedAttribute[]): void {
		this.setState({ selectedAttributes: attributes });
		this.notifyParentSelectedAttributes(attributes);
	}

	private notifyParentSelectedAttributes(selectedAttributes: SummarizedAttribute[]): void {
		const attributes: string[] = [];
		for (const selectedAttribute of selectedAttributes) {
			attributes.push(selectedAttribute.key);
		}
		this.props.onChange(attributes);
	}

	private notifyParentSelectedDelimiterAttributes(selectedAttributes: SummarizedAttribute[]): void {
		if (this.props.onChangeDelimiterRemoveAttributes) {
			const attributes: string[] = [];
			for (const selectedAttribute of selectedAttributes) {
				attributes.push(selectedAttribute.key);
			}
			this.props.onChangeDelimiterRemoveAttributes(attributes);
		}
	}
}

export default withStyles(comparatorAttributeSelectStyles)(ComparatorAttributeSelect);
