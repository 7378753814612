import { createStyles, Theme } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cmsContentRowStyles = (theme: Theme): any => createStyles({
	columnContainer: {
		display: 'flex',
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	column: {
		flexBasis: 0,
		flexGrow: 1,
		flexShrink: 1,
		width: 0,
		padding: theme.spacing(1),
		marginRight: theme.spacing(2),
		'&:last-child': {
			marginRight: theme.spacing(0),
		}
	},
	columnTypeSelect: {
		width: '100%',
		marginBottom: theme.spacing(2),
	},
	deleteButton: {
		marginTop: theme.spacing(2),
	},
	info: {
		display: 'inline-block'
	},
});

export default cmsContentRowStyles;
