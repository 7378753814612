import React, { ReactNode, Component, ReactElement } from 'react';
import {
	Button,
	IconButton,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	Tooltip,
	Typography,
	TableRow,
	WithStyles,
	withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SwapHorizontalCircleOutlinedIcon from '@material-ui/icons/SwapHorizontalCircleOutlined';
import RuleEditor from './RuleEditor';
import RuleGroupEditor from './RuleGroupEditor';

import { Rule, RuleType, RuleGroup, ConnectorType, getSelectedRuleTypeString } from '@models/AutomatedComparator';
import ruleGroupTableStyles from './RuleGroupTableStyles';
import AuthService from '@common/services/AuthService';
import { CategoriesResponse, Category, getCategoryName } from '@common/models/Category';

interface Props extends WithStyles<typeof ruleGroupTableStyles> {
	ruleGroup: RuleGroup;
	changeHandler?: (ruleGroup: RuleGroup) => void;
	deleteHandler?: () => void;
	selectedCategoryCode: string | string[];
	isTopLevel: boolean;
	editable: boolean;
	loading: boolean;
	isAdvisor?: boolean;
}

interface State {
	ruleToEdit: Rule | null;
	ruleToEditIndex: number;
	ruleGroupToEdit: RuleGroup | null;
	categories: Category[];
}

class RuleGroupTable extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			ruleToEdit: null,
			ruleToEditIndex: 0,
			ruleGroupToEdit: null,
			categories: []
		};
	}

	public componentDidMount(): void {
		this.loadCategories();
	}

	private getRuleName(rule: Rule): ReactElement {
		const classes = this.props.classes;
		if (rule.type === RuleType.ProductInCategory) {
			return (
				<div>
					<Typography> {getSelectedRuleTypeString(rule.type) + ': ' + rule.categoryCode + ' ' + getCategoryName(rule.categoryCode ? rule.categoryCode : '', this.state.categories)}</Typography>
				</div>
			);
		} else if (rule.type === RuleType.ProductNameContainsString || rule.type === RuleType.ProductNameNotContainsString) {
			return (
				<div>
					<Typography> {getSelectedRuleTypeString(rule.type) + ': ' + rule.productName}</Typography>
				</div>
			);
		} else if (rule.type === RuleType.ProductContainsAttribute || rule.type === RuleType.ProductNotContainsAttribute) {
			return (
				<div>
					<Typography> {getSelectedRuleTypeString(rule.type) + ': ' + rule.attributeName + ' = ' + rule.attributeValue}</Typography>
				</div>
			);
		} else if (rule.type === RuleType.ProductHasAttribute || rule.type === RuleType.ProductNotHasAttribute) {
			return (
				<div>
					<Typography> {getSelectedRuleTypeString(rule.type) + ': ' + rule.attributeName} {rule.attributeValue && ' = ' + rule.attributeValue}</Typography>
				</div>
			);
		} else if (rule.type === RuleType.ProductAttributeValueEquals || rule.type  === RuleType.ProductAttributeValueNotEquals ||
			rule.type === RuleType.ProductAttributeValueIsGreater || rule.type  === RuleType.ProductAttributeValueIsGreaterEquals ||
			rule.type === RuleType.ProductAttributeValueIsSmaller || rule.type  === RuleType.ProductAttributeValueIsSmallerEquals) {
			return (
				<div>
					<Typography> {getSelectedRuleTypeString(rule.type).substring(0, 21) + 'von "' + rule.attributeName + '"' + getSelectedRuleTypeString(rule.type).substring(20, 100) + ': ' + rule.attributeValue}</Typography>
				</div>
			);
		} else if (rule.type === RuleType.ProductPriceIsSmaller || rule.type  === RuleType.ProductPriceIsGreater || rule.type  === RuleType.ProductPriceEquals || rule.type  === RuleType.ProductPriceIsGreaterEquals || rule.type === RuleType.ProductPriceIsSmallerEquals) {
			let float = null;
			if (rule.price) {
				float = parseFloat(rule.price.split(',').join('.'));
			}
			if (float) {
				return (
					<div>
						<Typography> {getSelectedRuleTypeString(rule.type) + ': ' + float.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Typography>
					</div>
				);
			} else {
				return <div></div>;
			}
		} else if (rule.type === RuleType.ProductVisuallySimilar) {
			return (
				<div>
					<Typography display="inline"> {getSelectedRuleTypeString(rule.type) + ' zu: '}</Typography>
					<Tooltip
						placement="right"
						title={
							<React.Fragment>
								{rule.productImage &&
								<img src={`${rule.productImage}?maxH=280&maxW=280&upscale=false`} alt=""/>
								}
							</React.Fragment>
						}
					>
						<span className={classes.imageContainer}>
							{rule.productImage &&
							<img src={`${rule.productImage}?maxH=28&maxW=28&upscale=false`} alt=""/>
							}
						</span>
					</Tooltip>
					<span>{rule.productCode}</span>
				</div>
			);
		} else {
			return <div></div>;
		}
	}

	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({categories: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	// Rule Functions
	private newRuleButtonClicked(): void {
		const newRule: Rule = {
			type: RuleType.ProductNameContainsString,
			productName: '',
		};

		const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
		ruleGroup.rules.push(newRule);
		if (this.props.changeHandler) {
			this.props.changeHandler(ruleGroup);
		}

		this.editRule(newRule, ruleGroup.rules.length - 1);
	}

	private editRule(rule: Rule, index: number): void {
		this.setState({
			ruleToEdit: rule,
			ruleToEditIndex: index
		});
	}

	private handleRuleEditorClose(rule: Rule | null, removeDummyRule: boolean): void {

		if (rule !== null) {
			const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
			ruleGroup.rules[this.state.ruleToEditIndex] = rule;
			if (this.props.changeHandler) {
				this.props.changeHandler(ruleGroup);
			}

		} else {
			if (removeDummyRule) {
				const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
				ruleGroup.rules.splice(this.state.ruleToEditIndex, 1);
				if (this.props.changeHandler) {
					this.props.changeHandler(ruleGroup);
				}
			}
		}


		this.setState({
			ruleToEdit: null,
			ruleToEditIndex: 0,
		});
	};

	private deleteRule(index: number): void {
		const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
		ruleGroup.rules.splice(index, 1);
		if (this.props.changeHandler) {
			this.props.changeHandler(ruleGroup);
		}
	}

	// Rule Group Functions
	private addRuleGroup(): void {
		const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
		ruleGroup.ruleGroups.push({
			name: 'Regelgruppe',
			connectorType: ConnectorType.And,
			rules: [],
			ruleGroups: []
		});
		if (this.props.changeHandler) {
			this.props.changeHandler(ruleGroup);
		}
	}

	private editRuleGroup(): void {
		this.setState({
			ruleGroupToEdit: this.props.ruleGroup
		});
	}

	private handleRuleGroupEditorClose(): void {
		this.setState({
			ruleGroupToEdit: null
		});
	};

	private deleteRuleGroup(): void {
		if (this.props.deleteHandler) {
			this.props.deleteHandler();
		}
	}

	private changeRule(rule: Rule, index: number): void {
		const currentRuleGroup = this.props.ruleGroup;

		if (rule.type === RuleType.ProductNameContainsString) {
			rule.type = RuleType.ProductNameNotContainsString;
		} else if (rule.type === RuleType.ProductNameNotContainsString) {
			rule.type = RuleType.ProductNameContainsString;
		} else if (rule.type === RuleType.ProductContainsAttribute) {
			rule.type = RuleType.ProductNotContainsAttribute;
		} else if (rule.type === RuleType.ProductNotContainsAttribute) {
			rule.type = RuleType.ProductContainsAttribute;
		} else if (rule.type === RuleType.ProductHasAttribute) {
			rule.type = RuleType.ProductNotHasAttribute;
		} else if (rule.type === RuleType.ProductNotHasAttribute) {
			rule.type = RuleType.ProductHasAttribute;
		} else if (rule.type === RuleType.ProductAttributeValueEquals) {
			rule.type = RuleType.ProductAttributeValueNotEquals;
		} else if (rule.type === RuleType.ProductAttributeValueNotEquals) {
			rule.type = RuleType.ProductAttributeValueEquals;
		} else if (rule.type === RuleType.ProductAttributeValueIsGreater) {
			rule.type = RuleType.ProductAttributeValueIsSmallerEquals;
		} else if (rule.type === RuleType.ProductAttributeValueIsSmaller) {
			rule.type = RuleType.ProductAttributeValueIsGreaterEquals;
		} else if (rule.type === RuleType.ProductAttributeValueIsGreaterEquals) {
			rule.type = RuleType.ProductAttributeValueIsSmaller;
		} else if (rule.type === RuleType.ProductAttributeValueIsSmallerEquals) {
			rule.type = RuleType.ProductAttributeValueIsGreater;
		} else if (rule.type === RuleType.ProductPriceIsGreater) {
			rule.type = RuleType.ProductPriceIsSmallerEquals;
		} else if (rule.type === RuleType.ProductPriceIsSmallerEquals) {
			rule.type = RuleType.ProductPriceIsGreater;
		} else if (rule.type === RuleType.ProductPriceIsSmaller) {
			rule.type = RuleType.ProductPriceIsGreaterEquals;
		} else if (rule.type === RuleType.ProductPriceIsGreaterEquals) {
			rule.type = RuleType.ProductPriceIsSmaller;
		}

		currentRuleGroup.rules[index] = rule;
		if (this.props.changeHandler) {
			this.props.changeHandler(currentRuleGroup);
		}
	}

	private changeChildrenRules(index: number, newRuleGroup: RuleGroup): void {
		const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
		ruleGroup.ruleGroups[index] = newRuleGroup;
		if (this.props.changeHandler) {
			this.props.changeHandler(ruleGroup);
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.root}>
				<div className={classes.topBar}>
					<Select
						variant="outlined"
						disabled={!this.props.editable || this.props.loading}
						className={classes.connectorTypeSelector}
						value={this.props.ruleGroup.connectorType}
						onChange={(event): void => {
							const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
							ruleGroup.connectorType = event.target.value;
							if (this.props.changeHandler) {
								this.props.changeHandler(ruleGroup);
							}
						}}
					>
						<MenuItem value={ConnectorType.And}>Und</MenuItem>
						<MenuItem value={ConnectorType.Or}>Oder</MenuItem>
					</Select>
					<div className={classes.rightButtons}>
						<Button
							disabled={!this.props.editable  || this.props.loading}
							variant="outlined"
							color="secondary"
							size="small"
							className={classes.addRuleButton}
							startIcon={<AddIcon />}
							onClick={this.newRuleButtonClicked.bind(this)}
						>
				Neue Regel
						</Button>
						<Button
							disabled={!this.props.editable || this.props.loading}
							variant="outlined"
							color="secondary"
							size="small"
							startIcon={<AddIcon />}
							onClick={this.addRuleGroup.bind(this)}
						>
				Neue Regelgruppe
						</Button>
						{this.props.isTopLevel === false &&
					<React.Fragment>
						<Button
							disabled={!this.props.editable || this.props.loading}
							onClick={this.editRuleGroup.bind(this)}
							variant="outlined"
							color="secondary"
							size="small"
							startIcon={<EditIcon color={this.props.editable ? 'secondary' : 'disabled'} />}
							className={classes.editRuleButton}
						>
						Bearbeiten
						</Button>
						<Button
							disabled={!this.props.editable || this.props.loading}
							onClick={this.deleteRuleGroup.bind(this)}
							variant="outlined"
							color="secondary"
							size="small"
							startIcon={<DeleteIcon color={this.props.editable ? 'secondary' : 'disabled'} />}
							className={classes.deleteButton}
						>
						Löschen
						</Button>
					</React.Fragment>
						}
					</div>
				</div>
				<div className={classes.rules}>
					{this.props.ruleGroup.rules.length > 0 &&
						<div>
							<Table>
								<TableBody>
									{this.props.ruleGroup.rules.map((rule: Rule, index: number) =>
										<TableRow key={index}>
											<TableCell>
												{(index > 0) ? (this.props.ruleGroup.connectorType === ConnectorType.And ? 'und' : 'oder') : ''}
												{this.getRuleName(rule)}
											</TableCell>
											<TableCell>
												<div className={classes.ruleButtons}>
													{rule.type !== RuleType.ProductVisuallySimilar && rule.type !== RuleType.ProductPriceEquals && rule.type !== RuleType.ProductInCategory && rule.type !== RuleType.ProductInCategoryOrSubcategory &&
													<IconButton disabled={!this.props.editable || this.props.loading} color="primary" onClick={this.changeRule.bind(this, rule, index)}>
														<SwapHorizontalCircleOutlinedIcon />
													</IconButton>
													}
													<Button
														disabled={!this.props.editable || this.props.loading}
														onClick={this.editRule.bind(this, rule, index)}
														color="primary"
														size="small"
														startIcon={<EditIcon color={this.props.editable ? 'primary' : 'disabled'}/>}
														variant="outlined"
														className={classes.editRuleButton}
													>
													Bearbeiten
													</Button>
													<Button
														disabled={!this.props.editable || this.props.loading}
														onClick={this.deleteRule.bind(this, index)}
														color="primary"
														size="small"
														startIcon={<DeleteIcon color={this.props.editable ? 'primary' : 'disabled'}/>}
														variant="outlined"
														className={classes.deleteButton}
													>
													Löschen
													</Button>
												</div>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</div>
					}
				</div>
				<div className={classes.ruleGroups}>
					{this.props.ruleGroup.ruleGroups.length > 0 &&
						<React.Fragment>
							{this.props.ruleGroup.ruleGroups.map((ruleGroup: RuleGroup, index: number) =>
								<div key={index} className={classes.subRule}>
									<Typography variant="subtitle2">{(index > 0 || this.props.ruleGroup.rules.length > 0) ? (this.props.ruleGroup.connectorType === ConnectorType.And ? 'und' : 'oder') : ''} {ruleGroup.name}</Typography>
									<RuleGroupTable
										{...this.props}
										ruleGroup={ruleGroup}
										changeHandler={this.changeChildrenRules.bind(this, index)}
										deleteHandler={(): void => {
											const ruleGroup = JSON.parse(JSON.stringify(this.props.ruleGroup));
											ruleGroup.ruleGroups.splice(index, 1);
											if (this.props.changeHandler) {
												this.props.changeHandler(ruleGroup);
											}
										}}
										selectedCategoryCode={this.props.selectedCategoryCode}
										isTopLevel={false}
										isAdvisor={this.props.isAdvisor}
									/>
								</div>
							)}
						</React.Fragment>
					}
				</div>
				{this.state.ruleToEdit !== null &&
					<RuleEditor
						rule={this.state.ruleToEdit}
						ruleEditorOpen={this.state.ruleToEdit !== null}
						closeHandler={this.handleRuleEditorClose.bind(this)}
						selectedCategory={this.props.selectedCategoryCode}
						isAdvisor={this.props.isAdvisor}
					/>
				}
				{this.state.ruleGroupToEdit &&
					<RuleGroupEditor
						ruleGroup={this.state.ruleGroupToEdit}
						ruleGroupEditorOpen={this.state.ruleGroupToEdit !== null}
						closeHandler={this.handleRuleGroupEditorClose.bind(this)}
						ruleGroupHandler={(ruleGroup: RuleGroup): void => {
							const ruleGroupCopy = JSON.parse(JSON.stringify(ruleGroup));
							if (this.props.changeHandler) {
								this.props.changeHandler(ruleGroupCopy);
							}
							this.setState({ruleGroupToEdit: null});
						}}
					/>
				}
			</div>
		);
	}
}

export default withStyles(ruleGroupTableStyles)(RuleGroupTable);
