import React, {ReactElement, FC, useState, useEffect} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	TextField,
	WithStyles,
	withStyles,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormLabel
} from '@material-ui/core';
import advisorQuestionEditorStyles from './AdvisorQuestionEditorStyles';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { AdvisorQuestion, AdvisorQuestionType, AdvisorVisualType, dummyHistoryBased, getAdvisorQuestionType, getAdvisorVisualType } from '@common/models/Advisor';

interface Props extends WithStyles<typeof advisorQuestionEditorStyles> {
	dialog?: ConfirmDialog;
	editorOpen: boolean;
	closeHandler: () => void;
	advisorQuestion: AdvisorQuestion | null;
	handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void;
	questionEditorPropsHandlerActive: boolean;
	handleQuestionEditorPropsHandlerActiveChange: React.Dispatch<React.SetStateAction<boolean>>;
	handleQuestionDelete: (advisorQuestion: AdvisorQuestion | null) => void;
}

const AdvisorQuestionEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const dummyAdvisorQuestion: AdvisorQuestion = {
		question: '',
		subtext: '',
		questionType: AdvisorQuestionType.SINGLESELECT,
		visualType: AdvisorVisualType.IMAGES,
		answers: [],
		xCoordinate: 0,
		yCoordinate: 0
	};

	const [advisorQuestionToEdit, setAdvisorQuestionToEdit] = useState<AdvisorQuestion>(dummyAdvisorQuestion);
	const [isValid, setIsValid] = useState(true);

	useEffect(() => {
		if (props.questionEditorPropsHandlerActive) {
			if (props.advisorQuestion && props.advisorQuestion._id) {
				setAdvisorQuestionToEdit(props.advisorQuestion);
			}
			if (!props.advisorQuestion) {
				setAdvisorQuestionToEdit(dummyAdvisorQuestion);
			}

			props.handleQuestionEditorPropsHandlerActiveChange(false);
		}
	}, [dummyAdvisorQuestion, props]);

	function closeEditor(): void {
		setIsValid(true);
		props.closeHandler();
	}

	function handleSave(): void {
		if (advisorQuestionToEdit.question !== '') {
			props.handleQuestionChange(advisorQuestionToEdit);
			props.closeHandler();
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}

	function handleAdvisorQuestionTypeChange(advisorQuestionType: AdvisorQuestionType | null): void {
		if (advisorQuestionType) {
			const currentAdvisorQuestionToEdit = advisorQuestionToEdit;

			if (!((advisorQuestionType === AdvisorQuestionType.MULTISELECT && advisorQuestionToEdit.questionType === AdvisorQuestionType.PRICESLIDER) || 
				(advisorQuestionType === AdvisorQuestionType.PRICESLIDER && advisorQuestionToEdit.questionType === AdvisorQuestionType.MULTISELECT))) {
				if (currentAdvisorQuestionToEdit.followingQuestion) {
					currentAdvisorQuestionToEdit.followingQuestion = undefined;
				}
				for (const answer of currentAdvisorQuestionToEdit.answers) {
					if (answer.followingQuestion) {
						answer.followingQuestion = undefined;
					}
					answer.historyBased = dummyHistoryBased;
				}
			}
			setAdvisorQuestionToEdit({...currentAdvisorQuestionToEdit, questionType: advisorQuestionType, answers: advisorQuestionType === AdvisorQuestionType.PRICESLIDER ? [] : currentAdvisorQuestionToEdit.answers});
		}
	}

	function checkAdvisorQuestionTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const advisorQuestionType = getAdvisorQuestionType((event.target as HTMLInputElement).value);
		if (advisorQuestionType && advisorQuestionType === AdvisorQuestionType.PRICESLIDER && advisorQuestionToEdit.answers.length > 0) {
			const dialog = props.dialog;
			if (dialog) {
				dialog.confirm({
					title: 'Status',
					message: 'Soll der Fragentyp wirklich auf "Preis-Slider" geändert werden? Die aktuellen Antwortmöglichkeiten dieser Frage würden dadurch verloren gehen.',
					ok: {
						text: 'Ändern',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					handleAdvisorQuestionTypeChange(advisorQuestionType);
				}).catch((error): void => {
					console.log(`Error while changing advisor question type to price-slider: ${error}`);
				});
			}
		} else {
			handleAdvisorQuestionTypeChange(advisorQuestionType);
		}
	}

	function handleAdvisorVisualTypeChange(event: React.ChangeEvent<HTMLInputElement>): void {
		const advisorVisualType = getAdvisorVisualType((event.target as HTMLInputElement).value);
		if (advisorVisualType) {
			setAdvisorQuestionToEdit({...advisorQuestionToEdit, visualType: advisorVisualType});
		}
	}

	return (
		<Dialog open={props.editorOpen} onClose={closeEditor} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>{props.advisorQuestion ? 'Frage bearbeiten' : 'Neue Frage'}</DialogTitle>
			<DialogContent className={classes.content}>
				<TextField
					required
					fullWidth
					className={classes.textInput}
					label="Frage"
					value={advisorQuestionToEdit?.question}
					onChange={(event): void => {setAdvisorQuestionToEdit({...advisorQuestionToEdit, question: event.target.value});}}
					error={!isValid}
				/>
				<TextField
					fullWidth
					className={classes.subtextInput}
					label="Subtext"
					value={advisorQuestionToEdit?.subtext}
					onChange={(event): void => {setAdvisorQuestionToEdit({...advisorQuestionToEdit, subtext: event.target.value});}}
				/>
				<FormControl className={classes.advisorQuestionTypesContainer}>
					<FormLabel>Fragentyp</FormLabel>
					<RadioGroup className={classes.advisorQuestionTypes} value={advisorQuestionToEdit.questionType} onChange={(event): void => checkAdvisorQuestionTypeChange(event)}>
						<FormControlLabel value={AdvisorQuestionType.SINGLESELECT} control={<Radio color="primary"/>} label="Single-Select" />
						<FormControlLabel value={AdvisorQuestionType.MULTISELECT} control={<Radio color="primary"/>} label="Multi-Select" />
						<FormControlLabel value={AdvisorQuestionType.PRICESLIDER} control={<Radio color="primary"/>} label="Preis-Slider" />
					</RadioGroup>
				</FormControl>
				{advisorQuestionToEdit.questionType !== AdvisorQuestionType.PRICESLIDER && 
				<FormControl className={classes.advisorVisualTypesContainer}>
					<FormLabel>Darstellungstyp</FormLabel>
					<RadioGroup className={classes.advisorVisualTypes} value={advisorQuestionToEdit.visualType} onChange={(event): void => handleAdvisorVisualTypeChange(event)}>
						<FormControlLabel value={AdvisorVisualType.IMAGES} control={<Radio color="primary"/>} label="Bilder" />
						<FormControlLabel value={AdvisorVisualType.ICONS} control={<Radio color="primary"/>} label="Icons" />
					</RadioGroup>
				</FormControl>
				}
			</DialogContent>
			<DialogActions>
				{props.advisorQuestion &&
					<Button onClick={(): void => props.handleQuestionDelete(props.advisorQuestion)} className={classes.deleteButton}>
						Löschen
					</Button>
				}
				<Button onClick={closeEditor} color="primary">
						Abbrechen
				</Button>
				<Button onClick={handleSave} color="primary">
						Speichern
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withDialog()(withStyles(advisorQuestionEditorStyles)(AdvisorQuestionEditor));
