import { Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 240;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appStyles = (theme: Theme): any => createStyles({
	root: {
		display: 'flex',
	},
	logout: {
		marginLeft: 'auto',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	container: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
	containerShift: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		overflowX: 'hidden'
	},
	toolbar: (theme.mixins.toolbar),
	inset: {
		marginLeft: theme.spacing(2),
	},
});

export default appStyles;
