import React, { ReactNode, Component } from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { withDialog } from 'muibox';
import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';
import { Product } from '@models/Product';
import { SummarizedAttribute } from '@models/Comparator';
import productsMissingAttributesStyles from './ProductsMissingAttributesStyles';

interface Props extends WithStyles<typeof productsMissingAttributesStyles> {
	editorOpen: boolean;
	closeHandler: () => void;
	attribute: SummarizedAttribute;
	productsMissingAttributes: Product[] | null;
}

class ProductsMissingAttributes extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	private closeEditor(): void {
		this.props.closeHandler();
	}

	private productsList(): ReactNode {
		let products: Product[] = [];
		if (this.props.productsMissingAttributes) {
			products = this.props.productsMissingAttributes;
		}

		return (
			<MaterialTable
				columns={[
					{
						title: 'Produktetcode',
						field: 'productCode' as const,
					},
					{
						title: 'Produktname',
						field: 'name' as const,
						defaultSort: 'asc',
						headerStyle: {
							whiteSpace: 'nowrap'
						},
						cellStyle: {
							whiteSpace: 'nowrap'
						},
					},
					{
						title: 'URL',
						field: 'name' as const,
						render: (product): ReactNode => {
							return (
								<TextField
									value={'https://www.moebelix.at/p/' + product.productCode}
									margin="normal"
									fullWidth={true}
									onFocus={(event): void => {
										event.target.select();
									}}
									InputProps={{
										readOnly: true,
									}}
								/>
							);
						}
					},
					{
						title: 'Link',
						field: 'link' as const,
						headerStyle: {
							textAlign: 'center',
						},
						cellStyle: {
							textAlign: 'center',
						},
						render: (product): ReactNode => {
							return (
								<Button
									href={'https://www.moebelix.at/p/' + product.productCode}
									color="secondary"
									variant="outlined"
									target="_blank"
								>
								Zum Produkt
								</Button>
							);
						}
					},
				]}
				options={{
					search: false,
					filtering: false,
					sorting: false,
					columnsButton: false,
					paging: false,
					draggable: false,
					showTitle: false
				}}
				style={{
					width: '100%',
				}}
				data={ products }
				localization={ TableLocalization.localization}
			/>
		);
	}

	private getAttributeName(attribute: SummarizedAttribute): ReactNode {
		const classes = this.props.classes;
		return (<div className={classes.dialogAttributeTitle}>{attribute.name}</div>);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.closeEditor.bind(this)} fullWidth={true} maxWidth={'lg'}>
				{this.props.attribute &&
					<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
						{'Produkte mit fehlendem Attribut: '}
						{this.getAttributeName(this.props.attribute)}
					</DialogTitle>
				}
				<DialogContent>
					{this.props.attribute && this.productsList()}
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					<Button onClick={this.closeEditor.bind(this)} color="primary">
						Zurück
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withDialog()(withStyles(productsMissingAttributesStyles)(ProductsMissingAttributes));
