import { Theme, createStyles} from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dropshippingTableStyles = (theme: Theme): any => createStyles({
	startButton: {
		marginRight: '5px',
	},
	editButton: {
		marginRight: '5px'
	}
});

export default dropshippingTableStyles;
