import React, { ReactNode, Component } from 'react';
import {
	CircularProgress,
	IconButton,
	Link,
	Paper,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import WarningIcon from '@material-ui/icons/Warning';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';

import comparatorProductOverviewStyles from './ComparatorProductOverviewStyles';

import { Category } from '@models/Category';
import { Product, ProductInComparator } from '@models/Product';
import ProductsService from '@services/ProductsService';
import CategorySelect from '../Common/CategorySelect';
import DownloadService from '@services/DownloadService';

import ComparatorProductPreview from './ComparatorProductPreview';

interface State {
	keyword: string;
	products: Product[];
	loading: boolean;
	showComparatorName: boolean;
	editorOpen: boolean;
	comparatorId: string;
	categoryName: string;
	isAutomated: boolean;
	loadingDownload: boolean;
}

interface Props extends WithStyles<typeof comparatorProductOverviewStyles> {
	keyword: string;
	comparatorId: string;
}

class ComparatorProductOverview extends Component<Props, State> {
	private productsService: ProductsService;
	private downloadService: DownloadService;

	public constructor(props: Props) {
		super(props);

		this.downloadService = new DownloadService();
		this.productsService = new ProductsService();

		this.state = {
			keyword: props.keyword,
			products: [],
			loading: false,
			showComparatorName: true,
			editorOpen: false,
			comparatorId: '',
			categoryName: '',
			isAutomated: false,
			loadingDownload: false
		};
	}

	private async loadProducts(keyword: string): Promise<void> {
		this.setState({loading: true});
		const newProducts: Product[] = [];
		if (keyword !== '') {
			const products = await this.productsService.loadProductsInCategory(keyword, true);
			for (const product of products) {
				if (product.status === 'ONLINE') {
					newProducts.push(product);
				}
			}
		}

		this.setState({products: newProducts, loading: false});
	}

	private async handleCategoryChange(selectedCategory: Category | null): Promise<void> {
		if (selectedCategory !== null) {
			this.loadProducts(selectedCategory.code);
			this.setState({keyword:  selectedCategory.code, categoryName: selectedCategory.name});
		}
	}

	private handleClose(): void {
		this.setState({editorOpen: false});
	}

	private handleClickOpenEdit(id: string, automated: boolean): void {
		this.setState({editorOpen: true, comparatorId: id, isAutomated: automated});
	}

	private downloadExcel(categoryCode: string | undefined): void {
		this.setState({loadingDownload: true});
		if (categoryCode === '' || categoryCode === undefined) {
			window.open(`${window.location.origin}/api/comparators/product-overview/download`, '_blank');
			this.setState({loadingDownload: false});
		} else {
			const path = `/api/comparators/product-overview/download/${categoryCode}`;
			const fileName = 'Produktübersicht-' + categoryCode + '.xlsx';
			this.downloadService.downloadBase64Excel(path, fileName, this.downloadFinished.bind(this));
		}
	}

	private downloadFinished(): void {
		this.setState({loadingDownload: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Produktübersicht'}
				</Typography>
				<div className={classes.selectExportContainer}>
					<div className={classes.categorySelect}>
						<CategorySelect
							onChange={this.handleCategoryChange.bind(this)}
							selectedCategoryCode={this.state.keyword}
							topLevelCategoriesExcluded={false}
							disabled={this.state.loading || this.state.loadingDownload}
						/>
					</div>
					<Tooltip title={this.state.keyword === '' || this.state.keyword === undefined ? <span>Excel Export - Alle Produkte</span> : <span>Excel Export - Kategorie {this.state.categoryName}</span>}>
						<span>
							<IconButton disabled={this.state.loading || this.state.loadingDownload} color="primary" onClick={this.downloadExcel.bind(this, this.state.keyword)}>
								<SaveAltIcon />
							</IconButton>
						</span>
					</Tooltip>
				</div>
				{(this.state.loading || this.state.loadingDownload) &&
					<CircularProgress className={classes.loadingIndicator}/>
				}
				{!this.state.loading &&
					<MaterialTable
						columns={[
							{
								title: '',
								field: 'image' as const,
								filtering: false,
								sorting: false,
								render: (product): ReactNode => {
									return (
										<img src={product.images[0]+'?maxH=56&maxW=56&upscale=false'} alt="" className={classes.productImage}/>
									);
								}
							},
							{
								title: 'Productcode',
								field: 'productCode' as const,
								filtering: true
							},
							{
								title: 'Artikelbezeichnung',
								field: 'name' as const,
								filtering: true,
								render: (product): ReactNode => {
									return (
										<Link href={`https://www.moebelix.at/p/${product.productCode}`} target="_blank">
											{product.name}
										</Link>
									);
								}
							},
							{
								title: 'Preis',
								field: 'price' as const,
								filtering: false,
								cellStyle: {textAlign: 'right'},
								headerStyle: {width: '100'},
								render: (product): ReactNode => {
									return (
										<div className={classes.productPrice}>{product.price.toFixed(2)} €</div>
									);
								}
							},
							{
								title: 'Produktvergleicher',
								field: 'usedInComparators' as const,
								filtering: false,
								render: (product): ReactNode => {
									if (product.usedInComparators !== undefined) {
										const nonMergeComparators = product.usedInComparators.filter((comparator: ProductInComparator) => {return (!comparator.mergeDetail ||  !comparator.mergeDetail.mergeComparator);});

										return (
											<React.Fragment>
												<div className={classes.productComparatorColumn}>
													<div className={classes.productComparatorLinks}>
														{ product.usedInComparators.map(c => (
															<p key={c._id} className={classes.link}>
																{ c.isAutomated &&
																	<Link color={c.mergeDetail && c.mergeDetail.mergeComparator ? 'secondary': 'primary'} onClick={this.handleClickOpenEdit.bind(this, c._id, true)}>{c.mergeDetail && c.mergeDetail.mergeComparator && 'Zusammengefügter Vergleicher: '}{c.name} (ID: a{c._id})</Link>
																}
																{ !c.isAutomated &&
																	<Link color={c.mergeDetail && c.mergeDetail.mergeComparator ? 'secondary': 'primary'} onClick={this.handleClickOpenEdit.bind(this, c._id, false)}>{c.mergeDetail && c.mergeDetail.mergeComparator && 'Zusammengefügter Vergleicher: '}{c.name} (ID: {c._id})</Link>
																}
															</p>
														))}
													</div>
													<div className={classes.warning}>
														{ nonMergeComparators.length >= 2 &&
															<Tooltip title={<span>Dieses Produkt kommt in mehreren Produktvergleichern vor.</span>}>
																<WarningIcon className={classes.warningIcon}/>
															</Tooltip>
														}
													</div>
												</div>
											</React.Fragment>
										);
									}
								}
							}
						]}
						options={{
							search: false,
							filtering: true,
							sorting: true,
							columnsButton: false,
							paging: false,
							draggable: false,
							showTitle: true
						}}
						style={{

						}}
						data={this.state.products}
						localization={ TableLocalization.localization}
						title={this.state.categoryName}
					/>
				}

				<ComparatorProductPreview
					closeHandler={this.handleClose.bind(this)}
					editorOpen={this.state.editorOpen}
					comparatorId={this.state.comparatorId}
					isAutomated={this.state.isAutomated}
				/>
			</Paper>
		);
	}

}

export default withStyles(comparatorProductOverviewStyles)(ComparatorProductOverview);
