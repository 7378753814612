import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsDetailsStyles = (theme: Theme): any => createStyles({
	rowContainer: {
		display: 'flex',
		width: 'calc(100% - 60px)',
		lineHeight: '30px',
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1),
		borderBottom: `1px solid ${theme.palette.text.secondary}`,
		marginLeft: 60,
	},
	clickContainer: {
		minWidth: 50,
	},
	imageContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		height: '30px',
		width: '30px',
		textAlign: 'center',
		flexGrow: 0,
		flexShrink: 0,
	},
	codeContainer: {
		color: theme.palette.text.secondary,
		fontFamily: 'monospace',
		marginRight: theme.spacing(1),
		fontSize: '13px',
		lineHeight: '30px',
		minWidth: 100,
	},
	nameContainer: {
		flexGrow: 1,
		lineHeight: '30px',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		minWidth: 0,
		marginRight: theme.spacing(1),
	},
	priceContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		whiteSpace: 'nowrap',
	},
});

export default analyticsDetailsStyles;
