import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ComparatorAttributeSelect from '../../Comparator/ComparatorAttributeSelect';
import editorAttributeSettingsStyles from './EditorAttributeSettingsStyles';

interface Props extends WithStyles<typeof editorAttributeSettingsStyles> {
	comparator: Comparator;
	handleAttributesChange: (selectedAttributes: string[]) => void;
}

class EditorAttributeSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.comparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.comparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Attribute</Typography>
					<Typography className={classes.panelSubtitle}>{this.props.comparator.attributes.length} Attribute ausgewählt</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<ComparatorAttributeSelect
						onChange={this.props.handleAttributesChange.bind(this)}
						onChangeDelimiterRemoveAttributes={null}
						selectedProducts={this.props.comparator.products}
						selectedAttributeCodes={this.props.comparator.attributes}
						sortingEnabled={true}
						templatesEnabled={true}
						maintainedEnabled={true}
						categoryFeed={false}
						type={'comparator'}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(editorAttributeSettingsStyles)(EditorAttributeSettings);
