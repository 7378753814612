import AuthService from '@common/services/AuthService';
import { RuleGroup } from './AutomatedComparator';

export enum ProductStatus {
	Online = 'ONLINE',
	Offline = 'OFFLINE'
}

export enum AttributeSource {
	Api,
	Description,
	WI
}

export interface ProductAttribute {
	_id: string;
	key: string;
	name: string;
	value: string;
	source?: AttributeSource;
}

export interface ProductInComparator {
	_id: string;
	name: string;
	mergeDetail?: MergeDetail;
	isAutomated?: boolean;
}

export interface MergeDetail {
	mergeComparator: boolean;
	mergeComparators: number[];
}

export interface Product extends ProductInComparator {
	_id: string;
	categoryCodes: string[];
	images: string[];
	campaigns: string[][];
	productCode: string;
	name: string;
	status: ProductStatus;
	price: number;
	attributes: ProductAttribute[];

	usedInComparators?: ProductInComparator[];
}

// Requests

export interface ProductsResponse {
	data: Product[];
	success: boolean;
	message?: string;
}

const authService = new AuthService();

export async function getProductsByRules(categories: string | string[], rules: RuleGroup): Promise<Product[] | null> {
	const response = await authService.fetch<ProductsResponse>('/api/products/rules', {
		method: 'POST',
		body: JSON.stringify({categoryCodes: categories, rules})
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}

export async function getProductsByRulesMissing(categories: string | string[], rules: RuleGroup): Promise<Product[] | null> {
	const response = await authService.fetch<ProductsResponse>('/api/products/rules-missing', {
		method: 'POST',
		body: JSON.stringify({categoryCodes: categories, rules})
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}

export async function loadOnlineProductsByCategories(categoryCodes: string[]): Promise<Product[]> {
	try {
		const url = '/api/categories/products';
		const response = await authService.fetch<ProductsResponse>(url, { method: 'POST', body: JSON.stringify({categoryCodes})});

		if (response.success) {
			return response.data;
		}
	} catch (error) {
		console.log(`Error while loading online products by categories: ${error}`);
	}

	return [];
}
