import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dropshippingEditorStyles = (theme: Theme): any => createStyles({
	title: {
		padding: theme.spacing(2, 3),
	},
	textField: {
		marginBottom: '20px'
	},
	deleteButton: {
		marginRight: 'auto',
		color: '#EE0000'
	},
	dropshippingCheckbox: {
		marginBottom: theme.spacing(2),
		display: 'none'
	},
	templateSelect: {
		width: '100%',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		flexWrap: 'wrap',
		display: 'block'
	},
	chipContainer: {
		display: 'flex',
		marginBottom: theme.spacing(5),
		flexWrap: 'wrap',
		alignItems: 'baseline',
		width: '100%'
	},
	chipContainerTitle: {
		marginRight: theme.spacing(1)
	},
	dropshippingAddContainer: {
		display: 'flex'
	},
	addDropshippingButton: {
		alignSelf: 'flex-end',
		marginBottom: '4px',
		marginRight: theme.spacing(2)
	},
	dropshippingTextField: {
		width: '100%',
		marginRight: '8px'
	},
	dropshippingsTable: {
		marginBottom: '16px'
	},
	dropshippingsTableHeader: {
		marginTop: theme.spacing(3)
	},
	attributeHeaderSelect: {
		width: '100%',
		marginTop: theme.spacing(3)
	},
	previewHeader: {
		marginTop: theme.spacing(4)
	},
	wiContainer: {
		marginTop: theme.spacing(2)
	},
	wiAttributesContainer: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column'
	},
	attributeValueAttributeSelect: {
		display: 'inline-block',
		width: '60%',
		marginLeft: theme.spacing(2)
	},
	wiChipContainer: {
		display: 'flex',
		width: '60%',
		marginBottom: theme.spacing(2)
	}
});

export default dropshippingEditorStyles;
