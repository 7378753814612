import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { AutomatedComparator } from '@models/AutomatedComparator';
import ComparatorPreview from '../../Comparator/ComparatorPreview';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Product } from '@common/models/Product';
import automatedEditorPreviewStyles from './AutomatedEditorPreviewStyles';

interface Props extends WithStyles<typeof automatedEditorPreviewStyles> {
	automatedComparator: AutomatedComparator;
	handleSaveButton: () => void;
	productsByRules: Product[];
	saving: boolean;
	merge: boolean;
	handleMergeInactiveSaveButton: () => void;
}

class AutomatedEditorPreview extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.automatedComparator) {
			return;
		}

		const comparator: AutomatedComparator = {
			_id: this.props.automatedComparator._id,
			active: this.props.automatedComparator.active,
			name: this.props.automatedComparator.name,
			nameBackend: this.props.automatedComparator.nameBackend,
			position: this.props.automatedComparator.position,
			fullPage: this.props.automatedComparator.fullPage,
			sortedBy: this.props.automatedComparator.sortedBy,
			missingCount: this.props.automatedComparator.missingCount,
			sortWeight: this.props.automatedComparator.sortWeight,
			products: this.props.productsByRules,
			attributes: this.props.automatedComparator.attributes,
			categoryCode: this.props.automatedComparator.categoryCode,
			rules: this.props.automatedComparator.rules,
			mergeDetail: this.props.automatedComparator.mergeDetail,
			splitDetail: this.props.automatedComparator.splitDetail
		} ;

		return (
			<ExpansionPanel defaultExpanded={this.props.automatedComparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Vorschau</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<ComparatorPreview
						comparator={comparator}
						saveHandler={this.props.handleSaveButton.bind(this)}
						isSaving={this.props.saving}
						showButtons={true}
						isAutomated={true}
						merge={this.props.merge}
						handleMergeInactiveSaveButton={this.props.handleMergeInactiveSaveButton.bind(this)}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(automatedEditorPreviewStyles)(AutomatedEditorPreview);
