import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const  advisorHistoryBasedFollowingQuestionStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(3)
	},
	defaultContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2) + 'px',
		marginTop: theme.spacing(2)
	},
	hisoryBasedFollowingQuestionsContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2) + 'px'
	},
	historyBasedAnswersColumn: {
		flex: 0.55
	},
	followingQuestionColumn: {
		flex: 0.4
	},
	deleteColumn: {
		display: 'flex',
		justifyContent: 'end',
		flex: 0.05
	},
	addHistoryBasedFollowingQuestionButtonContainer: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(25)
	},
	historyBasedFollowingQuestionsLabel: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(1)
	},
	divider: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	deleteHistoryBasedFollowingQuestionButton: {
		color: theme.palette.error.main,
	},
});

export default advisorHistoryBasedFollowingQuestionStyles;
