import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const crawlerTableStyles = (theme: Theme): any => createStyles({
	title: {
		marginTop: theme.spacing(1),
	},
	description: {
		marginTop: theme.spacing(2),
	},
	buttonProgress: {
		position: 'absolute',
	},
	startButton: {
		marginRight: '5px',
	},
	editButton: {
		marginRight: '5px',
	},
});

export default crawlerTableStyles;
