import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CategorySelect from '@common/CategorySelect';
import ComparatorProductSelect from '../ComparatorProductSelect';
import { Category } from '@common/models/Category';
import { Product } from '@common/models/Product';

import editorProductSettingsStyles from './EditorProductSettingsStyles';

interface Props extends WithStyles<typeof editorProductSettingsStyles> {
	comparator: Comparator;
	selectedCategoryName: string;
	selectedCategoryCode: string | null;
	handleCategoryChange: (selectedCategory: Category | null) => void;
	handleProductsChange: (selectedProducts: Product[]) => void;
	disabled: boolean;
}

class EditorProductSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.comparator) {
			return;
		}

		const selectedProductCodes = [];
		for (const product of this.props.comparator.products) {
			selectedProductCodes.push(product.productCode);
		}

		let subtitle = `${this.props.comparator.products.length} Produkte ausgewählt`;
		if (this.props.selectedCategoryName !== '') {
			subtitle = `${this.props.selectedCategoryName}, ${subtitle}`;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.comparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Produkte</Typography>
					<Typography className={classes.panelSubtitle}>{subtitle}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>

					<div className={classes.categorySelect}>
						<CategorySelect
							onChange={this.props.handleCategoryChange.bind(this)}
							selectedCategoryCode={this.props.selectedCategoryCode}
							topLevelCategoriesExcluded={false}
							disabled={this.props.disabled}
						/>
					</div>
					<ComparatorProductSelect
						onChange={this.props.handleProductsChange.bind(this)}
						selectedCategoryCode={this.props.selectedCategoryCode}
						selectedProductCodes={selectedProductCodes}
						comparatorId={this.props.comparator._id}
						loadUsedInComparators={true}
						disabled={this.props.disabled}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(editorProductSettingsStyles)(EditorProductSettings);
