import React, {ReactNode, Component} from 'react';
import {Moment} from 'moment';

import {
	WithStyles,
	withStyles
} from '@material-ui/core';
import {DateTimePicker} from '@material-ui/pickers';

import timeRangeSelectStyles from './TimeRangeSelectStyles';

interface Props extends WithStyles<typeof timeRangeSelectStyles> {
	onChange?: Function;
	fromDate: Moment;
	toDate: Moment;
}

interface State {
	newFromDate: Moment;
	newToDate: Moment;
}

class TimeRangeSelect extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);

		this.state = {
			newFromDate: props.fromDate,
			newToDate: props.toDate
		};
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.root}>
				<DateTimePicker
					value={this.state.newFromDate}
					onChange={this.handleFromDateChange.bind(this)}
					className={classes.datePicker}
					label={'Von'}
					format={'DD. MM. YYYY, HH:mm'}
					cancelLabel={'Abbrechen'}
					maxDate={this.state.newToDate}
					showTodayButton={true}
					todayLabel={'HEUTE'}
				/>
				<DateTimePicker
					value={this.state.newToDate}
					onChange={this.handleToDateChange.bind(this)}
					className={classes.datePicker}
					label={'Bis'}
					format={'DD. MM. YYYY, HH:mm'}
					cancelLabel={'Abbrechen'}
					minDate={this.state.newFromDate}
					showTodayButton={true}
					todayLabel={'HEUTE'}
				/>
			</div>
		);
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleFromDateChange(date: any): void {
		const momentDate = date as Moment;
		this.setState({newFromDate: momentDate});

		if (this.props.onChange) {
			this.props.onChange(momentDate, this.state.newToDate);
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleToDateChange(date: any): void {
		const momentDate = date as Moment;
		this.setState({newToDate: momentDate});

		if (this.props.onChange) {
			this.props.onChange(this.state.newFromDate, momentDate);
		}
	}

}

export default withStyles(timeRangeSelectStyles)(TimeRangeSelect);
