import React, {ReactNode, Component} from 'react';
import {Moment} from 'moment';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Link,
	withStyles,
	WithStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';

import {SingleSeriesOrder} from '@models/SeriesOrder';
import productsPreviewStyles from './ProductsPreviewStyles';

import {ArticleCombinationModel, ArticleCombinationResponse} from '@models/SeriesOrder';
import ArticleCombination from './ArticleCombination';
import AuthService from '@services/AuthService';

interface State {
	editorOpen: boolean;
	loading: boolean;
	articleCombinations: ArticleCombinationModel[];
}

interface Props extends WithStyles<typeof productsPreviewStyles> {
	closeHandler: () => void;
	editorOpen: boolean;
	products: SingleSeriesOrder[];
	seriesName: string;
	fromDate?: Moment;
	toDate?: Moment;
}

class ProductsPreview extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			editorOpen: false,
			loading: false,
			articleCombinations: []
		};
	}

	private handleClose(): void {
		this.setState({editorOpen: false});
	}

	private handleClickOpenEdit(): void {
		try {
			this.loadArticleCombinations();
		} catch (error) {
			console.log('ERROR: ' + error);
		}

		this.setState({editorOpen: true, articleCombinations: []});
	}

	private loadArticleCombinations(): void {
		this.setState({loading: true});
		this.authService.fetch<ArticleCombinationResponse>('/api/series/seriesByDateAndName', {
			method: 'POST',
			body: JSON.stringify({
				fromDate: this.props.fromDate,
				toDate: this.props.toDate,
				seriesName: this.props.seriesName
			})
		}).then((response): void => {
			if (response.success) {
				if (response.data) {
					this.setState({articleCombinations: response.data, loading: false});
				}
			} else {
				throw new Error(response.message);
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<Dialog open={this.props.editorOpen} onClose={this.props.closeHandler} fullWidth={true} maxWidth={'lg'}>
					<DialogContent>
						<div className={classes.articleButtonContainer}>
							<Link onClick={this.handleClickOpenEdit.bind(this)} className={classes.link}>
								Artikelkombinationen
							</Link>
						</div>
						<MaterialTable
							columns={[
								{
									title: 'Bild',
									field: 'image' as const,
									cellStyle: {
										maxWidth: '15%'
									},
									headerStyle: {
										maxWidth: '15%'
									},
									render: (product): ReactNode => {
										return (
											<img alt="Produktfoto" src={product.image} height="100px" />
										);
									}
								},
								{
									title: 'Produktcode',
									field: 'productCode' as const,
									cellStyle: {
										maxWidth: '15%',
										cursor: 'pointer'
									},
									headerStyle: {
										maxWidth: '15%'
									},
									render: (product): ReactNode => {
										return (
											<Link href={`https://www.moebelix.at/p/${product.productCode}`} target="_blank">{product.productCode}</Link>
										);
									}

								},
								{
									title: 'Produktname',
									field: 'name' as const,
									render: (product): ReactNode => {
										return (
											<div>{product.name}</div>
										);
									}
								},
								{
									title: 'Preis',
									field: 'price' as const,
									cellStyle: {
										maxWidth: '5%',

									},
									headerStyle: {
										maxWidth: '5%',

									},
									render: (product): ReactNode => {
										return (
											<div>{product.price.toFixed(2)} €</div>
										);
									}
								},
								// {
								// 	title: 'gekauft',
								// 	render: (product): ReactNode => {
								// 		return(
								// 			<div>{product.amount}x</div>
								// 		);
								// 	}
								// }
							]}
							options={{
								search: false,
								filtering: false,
								sorting: false,
								draggable: false,
								showTitle: true,
								paging: false
							}}
							style={{
								width: '100%'
							}}
							data={this.props.products}
							title={'Serie: ' + this.props.seriesName}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.props.closeHandler}>
							Schließen
						</Button>
					</DialogActions>
				</Dialog>
				<ArticleCombination
					closeHandler={this.handleClose.bind(this)}
					editorOpen={this.state.editorOpen}
					articleCombinations={this.state.articleCombinations}
					loading={this.state.loading}
				/>
			</div>
		);
	}
}

export default withStyles(productsPreviewStyles)(ProductsPreview);
