import React, { ReactNode, Component } from 'react';
import {
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Button,
	FormControlLabel,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	Tooltip,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import AttributeSelect from '@common/AttributeSelect';
import CategorySelect from '@common/CategorySelect';
import { Category } from '@models/Category';
import { Product, ProductStatus } from '@models/Product';
import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';
import AddIcon from '@material-ui/icons/Add';
import { AttributeValueSynonym } from '@models/AttributeValueSynonym';
import attributeValueSynonymEditorStyles from './AttributeValueSynonymEditorStyles';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface AttributeValueSynonymResponse {
	data: AttributeValueSynonym;
	success: boolean;
	message?: string;
}

interface Props extends WithStyles<typeof attributeValueSynonymEditorStyles> {
	synonym: AttributeValueSynonym | null;
	editorOpen: boolean;
	closeHandler: () => void;
	dialog: ConfirmDialog;
	editorPropsHandlerActive: boolean;
	handleEditorPropsHandlerActiveChange: (active: boolean) => void;
}

interface State {
	synonym: AttributeValueSynonym;
	synonymAddType: string;
	currentOwnAttributeValue: string;
	selectedCategoryCode: string;
	selectedAttributeName: string;
	loadingAttributeValues: boolean;
	productAttributeValues: string[];
	noNameError: boolean;
	attributeValueContainToCreate: string;
}

class AttributeValueSynonymEditor extends Component<Props, State> {
	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.productsService = new ProductsService();

		this.state = {
			synonym: this.getSynonymToSet(null),
			synonymAddType: 'select',
			currentOwnAttributeValue: '',
			selectedCategoryCode: '',
			selectedAttributeName: '',
			loadingAttributeValues: false,
			productAttributeValues: [],
			noNameError: false,
			attributeValueContainToCreate: ''
		};
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (this.props.editorPropsHandlerActive) {
			if (this.props.synonym && this.props.synonym._id) {
				this.loadAttributeValueSynonym();
			} else {
				this.setState({
					synonym: this.getSynonymToSet(null),
					synonymAddType: 'select',
					selectedCategoryCode:'',
					currentOwnAttributeValue: '',
					productAttributeValues: [],
					noNameError: false,
					attributeValueContainToCreate: ''
				});
			}
			this.props.handleEditorPropsHandlerActiveChange(false);
		}
	}

	private loadAttributeValueSynonym(): void {
		if (this.props.synonym) {
			this.authService.fetch<AttributeValueSynonymResponse>(`/api/attribute-value-synonyms/${this.props.synonym._id}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success && response.data) {
					let categoryCode = '';
					let attributeName = '';
					
					let synonymAddType = 'select';

					if (response.data.categoryCode) {
						categoryCode = response.data.categoryCode;
					}
					if (response.data.attributeName) {
						attributeName = response.data.attributeName;
					}
					if (response.data.containedValues && response.data.containedValues.length > 0) {
						synonymAddType = 'contains';
					}

					this.setState({
						synonym: response.data,
						selectedCategoryCode: categoryCode,
						selectedAttributeName: attributeName,
						synonymAddType: synonymAddType
					}, () => {
						this.loadProductAttributeValues();
					});
				} else if (response.message) {
					this.setState({synonym: this.getSynonymToSet(null)});
					throw new Error(response.message);
				} else {
					this.setState({synonym: this.getSynonymToSet(null)});
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private getSynonymToSet(newSynonym: AttributeValueSynonym | null): AttributeValueSynonym {
		if (newSynonym) {
			return newSynonym;
		} else {
			const responseSynonym: AttributeValueSynonym = {
				name: '',
				values: []
			};

			return responseSynonym;
		}
	}

	private closeEditor(): void {
		this.setState({
			noNameError: false,
			attributeValueContainToCreate: ''
		});
		this.props.closeHandler();
	}

	private getEditorTitle(): ReactNode {
		let dialogTitle = 'Neues Attributwert-Synonym anlegen';

		if (this.props.synonym) {
			dialogTitle = 'Attributwert-Synonym bearbeiten';
		}

		return (
			<DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
		);
	}

	private deleteButton(): ReactNode {
		if (this.props.synonym) {
			const classes = this.props.classes;

			return (
				<Button className={classes.deleteButton} onClick={this.deleteSynonymButtonClicked.bind(this)} color="primary">
					Löschen
				</Button>
			);
		}

		return null;
	}

	private deleteSynonymButtonClicked(): void {
		if (this.props.synonym) {
			const synonymId = this.props.synonym._id;
			const { dialog } = this.props;

			dialog.confirm({
				title: 'Eintrag Löschen',
				message: 'Soll der Eintrag wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'error',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<AttributeValueSynonym>(`/api/attribute-value-synonyms/${synonymId}`, {
					method: 'DELETE',
					body: JSON.stringify(this.props.synonym)
				}).then((_): void => {
					this.closeEditor();
				});
			});
		}
	}

	private saveSynonymButtonClicked(): void {
		let currentSynonym = this.state.synonym;

		if (currentSynonym.name !== '') {
			// Make sure to not save wrong attributeName in db
			if (currentSynonym.attributeName !== this.state.selectedAttributeName && this.state.selectedAttributeName === '') {
				const synonymWithoutAttributeName: AttributeValueSynonym = {
					name: currentSynonym.name,
					values: currentSynonym.values,
					categoryCode: currentSynonym.categoryCode,
					containedValues: currentSynonym.containedValues
				};
				currentSynonym = synonymWithoutAttributeName;
			}

			if (this.state.synonymAddType !== 'contains') {
				currentSynonym.containedValues = undefined;
			}

			let url = 'api/attribute-value-synonyms/new';
			if (this.props.synonym) {
				url = `/api/attribute-value-synonyms/${this.props.synonym._id}`;
			}

			this.authService.fetch<AttributeValueSynonym>(url, {
				method: 'POST',
				body: JSON.stringify(currentSynonym)
			}).then((response): void => {
				this.loadAttributeValueSynonym();
				this.props.closeHandler();
			});
		} else  {
			this.setState({noNameError: true});
		}
	}

	private handleTextChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, type: string, index: number | null): void {
		if (type === 'name') {
			const tempSynonym = this.state.synonym;
			tempSynonym.name = event.target.value.normalize('NFC');
			this.setState({synonym : tempSynonym});
		} else if (type === 'value') {
			this.setState({currentOwnAttributeValue : event.target.value.normalize('NFC')});
		}
	}

	private handleAddSynonymTypeChanged(event: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({synonymAddType: (event.target as HTMLInputElement).value});
	}

	private addAttributeValueButtonClicked(): void {
		const currentSynonym = this.state.synonym;
		if (!currentSynonym.values.includes(this.state.currentOwnAttributeValue)) {
			currentSynonym.values.push(this.state.currentOwnAttributeValue);
		}
		this.setState({
			synonym: currentSynonym,
			currentOwnAttributeValue: ''
		});
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		const currentSynonym = this.state.synonym;
		currentSynonym.categoryCode = (selectedCategory ? selectedCategory.code : '');

		this.setState({
			selectedCategoryCode: (selectedCategory ? selectedCategory.code : ''),
			selectedAttributeName: '',
			synonym: currentSynonym
		}, () => {
			if (this.state.synonym.categoryCode && this.state.synonym.attributeName) {
				this.setState({selectedAttributeName: this.state.synonym.attributeName});
			}
		});
	}

	private handleAttributeNameChange(name: string): void {
		const currentSynonym = this.state.synonym;
		currentSynonym.attributeName = name;

		currentSynonym.values = [];

		this.setState({
			synonym: currentSynonym,
			selectedAttributeName: name
		}, () => {
			this.loadProductAttributeValues();
		});
	}

	private async loadProductAttributeValues(): Promise<void> {
		this.setState({loadingAttributeValues: true});
		if (this.state.selectedCategoryCode !== '' && this.state.selectedAttributeName !== '') {
			let products: Product[] = [];
			if (this.state.selectedCategoryCode) {
				products = await this.productsService.loadProductsInCategory(this.state.selectedCategoryCode);
			}
			const productAttributeValues: string[] = [];
			for (const product of products) {
				if (product.status === ProductStatus.Online) {
					for (const attribute of product.attributes) {
						if (attribute.name === this.state.synonym.attributeName && !productAttributeValues.includes(attribute.value)) {
							productAttributeValues.push(attribute.value);
						}
					}
				}
			}
			this.setState({productAttributeValues: productAttributeValues});
		} else {
			this.setState({productAttributeValues: []});
		}
		this.setState({loadingAttributeValues: false});
	}

	private handleCheckboxChange(attribute: string, event: React.ChangeEvent<HTMLInputElement>): void {
		event.persist();
		const currentSynonym = this.state.synonym;
		if (event.currentTarget.checked) {
			if (!currentSynonym.values.includes(attribute)) {
				currentSynonym.values.push(attribute);
			}
		} else {
			const indexSynonymListAttributes = currentSynonym.values.indexOf(attribute, 0);
			if (indexSynonymListAttributes > -1) {
				currentSynonym.values.splice(indexSynonymListAttributes, 1);
			}
		}
		this.setState({synonym: currentSynonym});
	}

	private removeAttributeValue(attributeValue: string): void {
		const currentSynonym = this.state.synonym;
		const indexSynonymListAttributes = currentSynonym.values.indexOf(attributeValue, 0);
		if (indexSynonymListAttributes > -1) {
			currentSynonym.values.splice(indexSynonymListAttributes, 1);
		}
		this.setState({synonym: currentSynonym});
	}

	private getOwnDefineArea(): ReactNode {
		const classes = this.props.classes;
		return (

			<div className={classes.attributeValueAddContainer}>
				<div className={classes.attributeValueTextField}>
					<TextField
						id={'attributeValue'}
						label={'NEUER ATTRIBUTEWERT'}
						placeholder={'Attributwert'}
						value={this.state.currentOwnAttributeValue}
						margin="dense"
						onChange={(event): void => {this.handleTextChange(event, 'value', null);}}
						fullWidth
					/>
				</div>
				<div className={classes.addAttributeValueButton}>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						className={classes.addRuleButton}
						startIcon={<AddIcon />}
						onClick={this.addAttributeValueButtonClicked.bind(this)}
					>
						Hinzufügen
					</Button>
				</div>
			</div>

		);
	}

	private getSelectAttributesArea(): ReactNode {
		const classes = this.props.classes;
		return (
			<div>
				<div className={classes.categorySelect}>
					<CategorySelect
						onChange={this.handleCategoryChange.bind(this)}
						selectedCategoryCode={this.state.selectedCategoryCode}
						topLevelCategoriesExcluded={false}
						disabled={false}
					/>
				</div>
				{this.state.selectedCategoryCode !== '' &&
					<div className={classes.attributeNameSelect}>
						<AttributeSelect
							onChangeAttributeName={this.handleAttributeNameChange.bind(this)}
							attributeName={this.state.selectedAttributeName}
							selectedCategoryCode={this.state.selectedCategoryCode}
							attributeNameOnly={true}
							invalidInput={false}
							includeRadioButtons={false}
							includeTextField={false}
						/>
					</div>}
				{this.state.loadingAttributeValues &&
					<CircularProgress className={classes.loadingIndicator}/>
				}
				{!this.state.loadingAttributeValues && this.state.productAttributeValues.map((attributeValue): ReactNode => (
					<FormControlLabel
						className={classes.attributeCheckbox}
						key={attributeValue}
						control={
							<Checkbox
								className={classes.checkbox}
								checked={this.state.synonym.values.find((value): boolean => attributeValue === value) !== undefined}
								color="primary"
								onChange={(event): void => {this.handleCheckboxChange(attributeValue, event);}}
							/>}
						label={
							<React.Fragment>
								<span className={classes.nameContainer}>{attributeValue}</span>
							</React.Fragment>}
					/>
				))}
			</div>
		);
	}

	private isChecked(attributeValue: string): boolean {
		if (this.state.synonym.containedValues) {
			for (const containsAttributeValue of this.state.synonym.containedValues) {
				if (containsAttributeValue !== '' && containsAttributeValue !== ' ' && attributeValue.toLowerCase().includes(containsAttributeValue.toLowerCase())) {
					return true;
				}
			}
		}
		return false;
	}

	private addAttributeValueContain(): void {
		let currentContainedValues: string[] = [];
		if (this.state.synonym.containedValues) {
			currentContainedValues = this.state.synonym.containedValues;
		}
		currentContainedValues.push(this.state.attributeValueContainToCreate);
		this.setState({synonym: {...this.state.synonym, containedValues: currentContainedValues, values: []}, attributeValueContainToCreate: ''}, () => {
			this.loadProductAttributeValues();
		});
	}

	private deleteAttributeValueContain(index: number): void {
		let currentContainedValues: string[] = [];
		if (this.state.synonym.containedValues) {
			currentContainedValues = this.state.synonym.containedValues;
		}

		currentContainedValues.splice(index, 1);
		this.setState({synonym: {...this.state.synonym, containedValues: currentContainedValues}});
	}

	private getContainedValuesList(): ReactNode {
		const classes = this.props.classes;
		return (
			<>
				{this.state.synonym.containedValues && this.state.synonym.containedValues.length > 0 && (
					<>
						<Typography variant="subtitle1" className={classes.subtitle}>
							{'Alle Enthält-Attributwerte:'}
						</Typography>
						<List className={classes.list}>
							{this.state.synonym.containedValues.map(
								(value: string, index: number) => (
									<div key={index}>
										<ListItem>
											<>
												<TextField
													fullWidth
													value={value}
													disabled
													InputProps={{
														endAdornment: (
															<>
																<Tooltip title='Löschen'>
																	<span>
																		<IconButton
																			edge='end'
																			className={classes.containedValueDeleteButton}
																			onClick={(): void => (
																				this.deleteAttributeValueContain(index)
																			)}
																			size='small'
																		>
																			<DeleteForeverOutlinedIcon />
																		</IconButton>
																	</span>
																</Tooltip>
															</>
														),
													}}
												/>
											</>
										</ListItem>
									</div>
								)
							)}
						</List>
					</>
				)}
			</>
		);
	}

	private getContainsAttributesArea(): ReactNode {
		const classes = this.props.classes;
		return (
			<div>
				<div className={classes.attributeValueContainsFieldsContainer}>
					<TextField
						fullWidth
						value={this.state.attributeValueContainToCreate}
						onChange={(event): void => this.setState({attributeValueContainToCreate: event.target.value.normalize('NFC')})}
						placeholder='Neuer Enthält-Attributwert ...'
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<Tooltip title='Hinzufügen'>
										<span>
											<IconButton
												color='secondary'
												onClick={
													async (): Promise<void> => this.addAttributeValueContain()
												}
												disabled={this.state.attributeValueContainToCreate === ''}
											>
												<AddCircleIcon />
											</IconButton>
										</span>
									</Tooltip>
								</InputAdornment>
							),
						}}
					/>
					{this.getContainedValuesList()}
				</div>
				<div className={classes.categorySelect}>
					<CategorySelect
						onChange={this.handleCategoryChange.bind(this)}
						selectedCategoryCode={this.state.selectedCategoryCode}
						topLevelCategoriesExcluded={false}
						disabled={false}
					/>
				</div>
				{this.state.selectedCategoryCode !== '' &&
					<div className={classes.attributeNameSelect}>
						<AttributeSelect
							onChangeAttributeName={this.handleAttributeNameChange.bind(this)}
							attributeName={this.state.selectedAttributeName}
							selectedCategoryCode={this.state.selectedCategoryCode}
							attributeNameOnly={true}
							invalidInput={false}
							includeRadioButtons={false}
							includeTextField={false}
						/>
					</div>}
				{this.state.loadingAttributeValues &&
					<CircularProgress className={classes.loadingIndicator}/>
				}
				{!this.state.loadingAttributeValues && this.state.productAttributeValues.map((attributeValue): ReactNode => (
					<FormControlLabel
						className={classes.attributeCheckbox}
						key={attributeValue}
						control={
							<Checkbox
								className={classes.checkbox}
								checked={this.isChecked(attributeValue)}
								color="primary"
								disabled
							/>}
						label={
							<React.Fragment>
								<span className={classes.nameContainer}>{attributeValue}</span>
							</React.Fragment>}
					/>
				))}
			</div>
		);
	}

	private getSelectedAttributeValuesTable(): ReactNode {
		const classes = this.props.classes;
		return (
			<div className={classes.selectedAttributeValuesTable}>
				<Typography className={classes.selectedAttributeValueTableHeader}>
					{'Ausgewählte Attributwerte'}
				</Typography>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.synonym.values.map((attributeValue: string): ReactNode => (
							<TableRow key={attributeValue}>
								<TableCell component="th" scope="row">
									{attributeValue}
								</TableCell>
								
								<TableCell align="right">
									{this.state.synonymAddType !== 'contains' &&
									<IconButton className={classes.deleteButton} size="small" onClick={this.removeAttributeValue.bind(this, attributeValue)}>
										<DeleteForeverOutlinedIcon />
									</IconButton>
									}
								</TableCell>
								
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.closeEditor.bind(this)} fullWidth={true} maxWidth={'lg'}>
				{this.getEditorTitle()}
				<DialogContent>
					<TextField
						id={'name'}
						label={'NAME'}
						placeholder={'Name'}
						value={this.state.synonym.name}
						margin="dense"
						onChange={(event): void => {this.handleTextChange(event, 'name', null);}}
						error={this.state.noNameError}
						fullWidth
					/>
					<RadioGroup className={classes.radioButtons}value={this.state.synonymAddType} onChange={(event): void => {this.handleAddSynonymTypeChanged(event);}}>
						<FormControlLabel value="select" control={<Radio color="primary"/>} label="Attributwerte auswählen" />
						<FormControlLabel value="own" control={<Radio color="primary"/>} label="Eigene Attributwerte" />
						<FormControlLabel value="contains" control={<Radio color="primary"/>} label="Enthält Wert" />
					</RadioGroup>
					{this.state.synonymAddType === 'select' ? this.getSelectAttributesArea() : this.state.synonymAddType === 'own' ? this.getOwnDefineArea() : this.getContainsAttributesArea()}
					{this.state.synonym.values.length > 0 && this.getSelectedAttributeValuesTable()}
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					{this.deleteButton()}
					<Button onClick={this.closeEditor.bind(this)} color="primary">
						Abbrechen
					</Button>
					<Button onClick={this.saveSynonymButtonClicked.bind(this)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withDialog()(withStyles(attributeValueSynonymEditorStyles)(AttributeValueSynonymEditor));
