import AuthService from './AuthService';
import { Product, ProductsResponse } from '@models/Product';

class ProductsService {
	private authService: AuthService;

	public constructor() {
		this.authService = new AuthService();
	}

	// Loads all products that are in a specified category
	public async loadProductsInCategory(categoryCode: string | string[], withComparators = false): Promise<Product[]> {
		try {
			let categoryCodes = categoryCode;
			if (Array.isArray(categoryCodes)) {
				categoryCodes = categoryCodes.join(',');
			}
			const url = `/api/categories/${categoryCodes}/products/${withComparators ? '?withComparators' : ''}`;
			const response = await  this.authService.fetch<ProductsResponse>(url, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading products: ${error}`);
		}

		return [];
	}

	// Loads all online products that are in a specified category
	public async loadOnlineProductsInCategory(categoryCode: string): Promise<Product[]> {
		try {
			const url = `/api/categories/${categoryCode}/onlineProducts`;
			const response = await this.authService.fetch<ProductsResponse>(url, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading online products: ${error}`);
		}

		return [];
	}

	// Loads all products that are used in a order
	public async loadProductsFromOrder(orderId: string): Promise<Product[]> {
		try {
			const response = await  this.authService.fetch<ProductsResponse>(`/api/orders/${orderId}/products`, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading products: ${error}`);
		}

		return [];
	}

	// Loads single Product
	public async loadProduct(productCode: string): Promise<Product|null> {
		try {
			const response = await  this.authService.fetch<ProductsResponse>(`/api/products/${productCode}`, { method: 'GET'});

			if (response.success && response.data.length > 0) {
				return response.data[0];
			}
		} catch (error) {
			console.log(`Error while loading products: ${error}`);
		}

		return null;
	}

	// Loads multiple products
	public async loadProducts(productCodes: string[]): Promise<Product[]> {
		try {
			const response = await  this.authService.fetch<ProductsResponse>(`/api/products/${productCodes.join(',')}`, { method: 'GET'});

			if (response.success) {
				return response.data;
			}
		} catch (error) {
			console.log(`Error while loading products: ${error}`);
		}

		return [];
	}
}

export default ProductsService;
