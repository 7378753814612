import { DefaultLinkModel, PortModel } from '@projectstorm/react-diagrams';
import { PortType } from './PortFactory';
import { AdvisorQuestion } from '@common/models/Advisor';
import { QuestionPortModel } from './QuestionPortModel';

export class QuestionLinkModel extends DefaultLinkModel {
	constructor(handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void, sourcePort?: PortModel, targetPort?: PortModel) {
		super('link');
		this.width = 10;

		if (sourcePort && targetPort) {
			this.setSourcePort(sourcePort);
			this.setTargetPort(targetPort);
		}

		this.addListener({
			targetPortChanged: (event) => {
				if (event.port && this.sourcePort) {
					const sourcePortType = (this.sourcePort as QuestionPortModel).portType;
					const eventPortType = (event.port as QuestionPortModel).portType;

					if (sourcePortType === PortType.ANSWEROUT && eventPortType === PortType.QUESTIONIN) {
						this.connectAnswerWithQuestion(handleQuestionChange, this.sourcePort.parent.extras.question, this.sourcePort.id, event.port.id);
					}

					if (sourcePortType === PortType.QUESTIONIN && eventPortType === PortType.ANSWEROUT) {
						this.connectAnswerWithQuestion(handleQuestionChange, event.port.parent.extras.question, event.port.id, this.sourcePort.id);
					}

					if (sourcePortType === PortType.QUESTIONOUT && eventPortType === PortType.QUESTIONIN) {
						this.connectQuestionWithQuestion(handleQuestionChange, this.sourcePort.parent.extras.question, event.port.id);
					}

					if (sourcePortType === PortType.QUESTIONIN && eventPortType === PortType.QUESTIONOUT) {
						this.connectQuestionWithQuestion(handleQuestionChange, event.port.parent.extras.question, this.sourcePort.id);
					}
				}
			}
		});
	}

	private connectAnswerWithQuestion(handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void, questionToChange: AdvisorQuestion, answerId: string, followingQuestionId: string): void {
		for (const answer of questionToChange.answers) {
			if (answer._id === answerId) {
				answer.followingQuestion = followingQuestionId;
				break;
			}
		}
		handleQuestionChange(questionToChange);
	}

	private connectQuestionWithQuestion(handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void, questionToChange: AdvisorQuestion, followingQuestionId: string): void {
		questionToChange.followingQuestion = followingQuestionId;
		handleQuestionChange(questionToChange);
	}
}