import { createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const automatedComparatorOverviewStyles = (): any => createStyles({
	addButton: {
		right: 20,
		bottom: 20,
		position: 'fixed',
	},
	editButton: {
		marginRight: '5px'
	}
});

export default automatedComparatorOverviewStyles;
