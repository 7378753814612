import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shoppingProductsStyles = (theme: Theme): any => createStyles({
	cardWrapper: {
		width: '100%',
	},
});

export default shoppingProductsStyles;
