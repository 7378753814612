import React, {ReactElement, FC, useState, useEffect} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	WithStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
	Typography
} from '@material-ui/core';
import advisorAnswerEditorStyles from './AdvisorAnswerEditorStyles';
import { AdvisorQuestion, AdvisorQuestionType, AdvisorAnswer, dummyHistoryBased, HistoryBased } from '@common/models/Advisor';
import { ConnectorType, RuleGroup } from '@common/models/AutomatedComparator';
import RuleGroupTable from 'AutomatedComparator/RuleGroupTable';
import TuneIcon from '@material-ui/icons/Tune';
import AdvisorAnswerRulesSelect from './AdvisorAnswerRulesSelect';
import AutomatedComparatorProducts from 'AutomatedComparator/AutomatedComparatorProducts';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Product, getProductsByRules, getProductsByRulesMissing } from '@common/models/Product';
import { useQuery } from '@tanstack/react-query';
import AdvisorHistoryBasedFollowingQuestions from './AdvisorHistoryBasedFollowingQuestions';

interface Props extends WithStyles<typeof advisorAnswerEditorStyles> {
	editorOpen: boolean;
	closeHandler: () => void;
	advisorQuestion: AdvisorQuestion | null;
	advisorAnswer: AdvisorAnswer | null;
	handleAnswerChange: (advisorAnswer: AdvisorAnswer) => void;
	categoryCodes: string[];
	answerEditorPropsHandlerActive: boolean;
	handleAnswerEditorPropsHandlerActiveChange: React.Dispatch<React.SetStateAction<boolean>>;
	handleAnswerDelete: (advisorAnswer: AdvisorAnswer | null, advisorQuestion: AdvisorQuestion | null) => void;
	advisorQuestions: AdvisorQuestion[];
}

const AdvisorAnswerEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const dummyRuleGroup: RuleGroup = {
		connectorType: ConnectorType.And,
		rules: [],
		ruleGroups: []
	};

	const dummyAdvisorAnswer: AdvisorAnswer = {
		answer: '',
		image: '',
		rules: dummyRuleGroup,
		combinable: true,
		historyBased: dummyHistoryBased,
	};

	const [advisorAnswerToEdit, setAdvisorAnswerToEdit] = useState<AdvisorAnswer>(dummyAdvisorAnswer);
	const [isValid, setIsValid] = useState(true);
	const [rulesSelectDialogOpen, setRulesSelectDialogOpen] = useState(false);
	const [productsRefreshed, setProductsRefreshed] = useState(false);
	const [productsToDisplay, setProductsToDisplay] = useState<Product[]>([]);

	const advisorProductsByRulesQuery = useQuery(
		['advisor-products-by-rules'],
		() => getProductsByRules(props.categoryCodes, advisorAnswerToEdit.rules),
		{
			onSuccess: (data) => {
				if (data) {
					setProductsToDisplay(data);
				}
			},
			enabled: false
		}
	);

	const advisorProductsByRulesMissingQuery = useQuery(
		['advisor-products-by-rules-missing'],
		() => getProductsByRulesMissing(props.categoryCodes, advisorAnswerToEdit.rules),
		{
			onSuccess: (data) => {
				if (data) {
					setProductsToDisplay(data);
				}
			},
			enabled: false
		}
	);

	useEffect(() => {
		if (props.answerEditorPropsHandlerActive) {
			if (props.advisorAnswer && props.advisorAnswer._id) {
				const tempAdvisorAnswer = JSON.parse(JSON.stringify(props.advisorAnswer));
				if (!tempAdvisorAnswer.historyBased) { // Add historyBased for old advisor answers
					tempAdvisorAnswer.historyBased = dummyHistoryBased;
				}
				setAdvisorAnswerToEdit(tempAdvisorAnswer);
			}
			if (!props.advisorAnswer) {
				setAdvisorAnswerToEdit(dummyAdvisorAnswer);
			}
			setProductsToDisplay([]);
			setProductsRefreshed(false);
			props.handleAnswerEditorPropsHandlerActiveChange(false);
		}
	}, [dummyAdvisorAnswer, props]);

	function closeEditor(): void {
		setIsValid(true);
		props.closeHandler();
	}

	function closeRulesSelectDialog(): void {
		setRulesSelectDialogOpen(false);
	}

	function handleSave(): void {
		if (advisorAnswerToEdit.answer !== '') {
			props.handleAnswerChange(advisorAnswerToEdit);
			props.closeHandler();
			setIsValid(true);
		} else {
			setIsValid(false);
		}
	}

	function handleLoadProducts(): void {
		advisorProductsByRulesQuery.refetch();
		setProductsRefreshed(true);
	}

	function handleLoadMissingProducts(): void {
		advisorProductsByRulesMissingQuery.refetch();
		setProductsRefreshed(true);
	}

	function handleHistoryBasedChanged(historyBased: HistoryBased): void {
		if (historyBased.defaultFollowingQuestionId || historyBased.historyBasedFollowingQuestions.length > 0) {
			advisorAnswerToEdit.followingQuestion = undefined;
		}
		setAdvisorAnswerToEdit({...advisorAnswerToEdit, historyBased});
	}

	return (
		<Dialog open={props.editorOpen} onClose={closeEditor} fullWidth={true} maxWidth={'lg'}>
			<DialogTitle>{props.advisorAnswer ? 'Antwort bearbeiten' : 'Neue Antwort'}</DialogTitle>
			<DialogContent className={classes.content}>
				<TextField
					required
					fullWidth
					className={classes.textInput}
					label="Antwort"
					value={advisorAnswerToEdit?.answer}
					onChange={(event): void => {setAdvisorAnswerToEdit({...advisorAnswerToEdit, answer: event.target.value});}}
					error={!isValid}
				/>
				<TextField
					fullWidth
					className={classes.imageInput}
					label="Bild/Icon"
					value={advisorAnswerToEdit?.image}
					onChange={(event): void => {setAdvisorAnswerToEdit({...advisorAnswerToEdit, image: event.target.value});}}
				/>
				{props.advisorQuestion?.questionType === AdvisorQuestionType.MULTISELECT &&
					<FormControlLabel
						className={classes.combinableCheckbox}
						control={
							<Checkbox
								checked={advisorAnswerToEdit.combinable}
								color="primary"
								onChange={(event): void => {setAdvisorAnswerToEdit({...advisorAnswerToEdit, combinable: event.currentTarget.checked});}}
							/>
						}
						label="Kombinierbar (Kann gemeinsam mit anderen Antwortmöglichkeiten ausgewählt werden)"
					/>
				}
				<div className={classes.rulesHeader}>
					<Typography className={classes.panelTitle} variant="body1">Regeln:</Typography>
					<Button
						variant="outlined"
						color="primary"
						size="small"
						className={classes.replaceRulesOpenDialogButton}
						onClick={(): void => setRulesSelectDialogOpen(true)}
						startIcon={<TuneIcon color={'primary'}/>}
					>
							Regeln auswählen
					</Button>
				</div>
				<RuleGroupTable
					ruleGroup={advisorAnswerToEdit.rules}
					changeHandler={(ruleGroup: RuleGroup): void => setAdvisorAnswerToEdit({...advisorAnswerToEdit, rules: ruleGroup})}
					selectedCategoryCode={props.categoryCodes}
					isTopLevel={true}
					editable={true}
					loading={false}
					isAdvisor
				/>
				<div className={classes.productsContainer}>
					<div className={classes.productHeaderContainer}>
						<Typography className={classes.panelTitle} variant="body1">Produkte:</Typography>
						<Typography className={classes.panelTitle} variant="body1">{`${productsToDisplay.length} Produkte`}</Typography>
					</div>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						startIcon={<RefreshIcon color="secondary"/>}
						className={classes.refreshProductsButton}
						onClick={handleLoadProducts}
					>
						Aktualisieren
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						className={classes.refreshMissingProductsButton}
						startIcon={<RefreshIcon color="secondary"/>}
						onClick={handleLoadMissingProducts}
					>
						Produkte die nicht vorkommen
					</Button>
					<AutomatedComparatorProducts
						comparator={null}
						products={productsToDisplay}
						loading={advisorProductsByRulesQuery.isFetching || advisorProductsByRulesMissingQuery.isFetching}
						refreshed={productsRefreshed}
					/>
				</div>
				<AdvisorHistoryBasedFollowingQuestions
					advisorQuestions={props.advisorQuestions}
					advisorAnswer={advisorAnswerToEdit}
					changeHandler={(historyBased: HistoryBased): void => handleHistoryBasedChanged(historyBased)}
				/>
			</DialogContent>
			<DialogActions>
				{props.advisorAnswer &&
					<Button onClick={(): void => props.handleAnswerDelete(props.advisorAnswer, props.advisorQuestion)} className={classes.deleteButton}>
						Löschen
					</Button>
				}
				<Button onClick={closeEditor} color="primary">
						Abbrechen
				</Button>
				<Button onClick={handleSave} color="primary">
						Speichern
				</Button>
			</DialogActions>
			<AdvisorAnswerRulesSelect
				dialogOpen={rulesSelectDialogOpen}
				closeHandler={closeRulesSelectDialog}
				currentRules={advisorAnswerToEdit.rules}
				handleRulesChange={(ruleGroup: RuleGroup): void => setAdvisorAnswerToEdit({...advisorAnswerToEdit, rules: ruleGroup})}
			/>
		</Dialog>
	);
};

export default withStyles(advisorAnswerEditorStyles)(AdvisorAnswerEditor);
