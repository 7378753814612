import React, { ReactNode, Component, CSSProperties } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select';

import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';
import { CategoriesResponse, Category } from '@models/Category';

import categorySelectStyles from './CategorySelectStyles';

interface OptionType {
	label: string;
	value: string;
};

interface State {
	categories: Category[];
	selectedCategory: ValueType<OptionType> | null;
}

interface Props extends WithStyles<typeof categorySelectStyles> {
	onChange: (category: Category | null) => void;
	selectedCategoryCode: string | null;
	topLevelCategoriesExcluded: boolean;
	disabled: boolean;
}

class CategorySelect extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			categories: [],
			selectedCategory: null
		};

		this.loadCategories();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.selectedCategoryCode !== this.props.selectedCategoryCode) {
			this.updateSelectedOption();
		}
	}

	private updateSelectedOption(): void {
		let selectedOption = null;

		if (this.props.selectedCategoryCode) {
			const options = this.getOptions();

			for (const option of options) {
				if (option.value === this.props.selectedCategoryCode) {
					selectedOption = option;
				}
			}
		}

		this.setState({selectedCategory: selectedOption});
	}


	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				if (!this.props.topLevelCategoriesExcluded) {
					this.setState({categories: response.data});
				} else {
					const categories: Category[] = [];
					for (const category of response.data) {
						for (const categoryChild of category.children) {
							categories.push(categoryChild);
						}
					}
					this.setState({categories: categories});
				}

				this.updateSelectedOption();

				if (this.props.onChange && this.props.selectedCategoryCode) {
					const category = this.categoryWithCode(this.props.selectedCategoryCode, response.data);
					this.props.onChange(category);
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Select
				className={classes.root}
				value={this.state.selectedCategory}
				onChange={this.handleChange.bind(this)}
				options={this.getOptions()}
				placeholder={'Kategorie auswählen...'}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
				menuPortalTarget={document.body}
				isSearchable
				isDisabled={this.props.disabled}
			/>
		);
	}

	private getOptions(): OptionType[] {
		let options: OptionType[] = [];

		this.state.categories.sort((a: Category, b: Category) => (a.name > b.name) ? 1 : -1).forEach((c: Category): void => {
			options = options.concat(this.optionsForCategory(c, 0));
		});

		return options;
	}

	private optionsForCategory(category: Category, level: number): OptionType[] {
		let options: OptionType[] = [];

		options.push({value: category.code, label: this.leftPad(`${category.name} (${category.productCount})`, level)});

		category.children.sort((a: Category, b: Category) => (a.name > b.name) ? 1 : -1).forEach((c: Category): void => {
			options = options.concat(this.optionsForCategory(c, level+1));
		});

		return options;
	}

	private leftPad(string: string, level: number): string {
		let result = (level > 0) ? '|' : '';

		for (let i = 0; i < level; i++) {
			result += '–';
		}

		return result + string;
	}

	private handleChange(selectedCategory: ValueType<OptionType>): void {
		this.setState({selectedCategory: selectedCategory});

		if (this.props.onChange) {
			const categoryCode = (selectedCategory as OptionType).value;
			const category = this.categoryWithCode(categoryCode, this.state.categories);
			this.props.onChange(category);
		}
	}

	private categoryWithCode(code: string, categories: Category[]): Category | null {
		for (const category of categories) {
			if (category.code === code) {
				return category;
			}

			if (category.children && category.children.length > 0) {
				const result = this.categoryWithCode(code, category.children);
				if (result) {
					return result;
				}
			}
		}

		return null;
	}

}

export default withStyles(categorySelectStyles)(CategorySelect);
