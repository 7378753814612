import AuthService from '@services/AuthService';

interface ExcelResponse {
	data: string;
	success: boolean;
	message?: string;
}

class DownloadService {
	private authService: AuthService;

	public constructor() {
		this.authService = new AuthService();
	}

	public downloadBase64Excel(path: string, fileName: string, downloadFinished: () => void): void {
		this.authService.fetch<ExcelResponse>(path, {
			method: 'GET'
		}).then((response): void => {
			if (response.success && response.data) {
				// base64 string to working blob
				const byteCharacters = atob(response.data);
				const byteNumbers = new Array(byteCharacters.length);
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i);
				}
				const byteArray = new Uint8Array(byteNumbers);
				const blob = new Blob([byteArray], { type: 'contentType' });

				if (navigator.msSaveBlob) { // IE 10+
					navigator.msSaveBlob(blob, fileName);
				} else {
					const link = document.createElement('a');
					if (link.download !== undefined) {
						const url = URL.createObjectURL(blob);
						link.setAttribute('href', url);
						link.setAttribute('download', fileName);
						link.style.visibility = 'hidden';
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				}

			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}

			downloadFinished();
		});
	}

}

export default DownloadService;
