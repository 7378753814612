import React, { ReactNode, Component } from 'react';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
	Typography,
	Paper,
	Button,
	CircularProgress,
	WithStyles,
	withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import CmsContentRowsEditor from './CmsContentRowsEditor';

import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import cmsContentEditorStyles from './CmsContentEditorStyles';

import { CmsContent } from '@models/CmsContent';

import CmsContentService from '@services/CmsContentService';

interface Params {
	contentId: string;
	categoryCode: string;
}

interface Props extends WithStyles<typeof cmsContentEditorStyles>, RouteComponentProps<Params>, WithSnackbarProps {
	dialog: ConfirmDialog;
}

interface State {
	content: CmsContent;
	savedContent: CmsContent;
	loading: boolean;
	saving: boolean;
}

class CtaEditor extends Component<Props, State> {

	private cmsContentService: CmsContentService;

	public constructor(props: Props) {
		super(props);

		this.cmsContentService = new CmsContentService();
		const contentId = this.props.match.params.contentId;
		const categoryCode = this.props.match.params.categoryCode;

		const dummyContent: CmsContent = {
			_id: contentId,
			categoryCode: categoryCode,
			topRows: [],
			bottomRows: []
		};

		this.state = {
			content: dummyContent,
			savedContent: dummyContent,
			loading: (contentId !== 'new'),
			saving: false,
		};

		this.loadContent(contentId);
	}

	public componentDidMount(): void {
		window.onbeforeunload = (): (boolean | undefined) => {
			return (this.isDirty()) ? true : undefined;
		};
	}

	public componentWillUnmount(): void {
		window.onbeforeunload = null;
	}

	private async loadContent(contentId: string): Promise<void> {
		if (contentId !== 'new') {
			try {
				const content = await this.cmsContentService.loadContent(contentId);

				if (content !== null) {
					this.setState({content: content, savedContent: content, loading: false});
				} else {
					throw new Error('Content nicht gefunden');
				}

			} catch (error) {
				this.props.enqueueSnackbar(`Fehler beim Laded ${contentId}`, {variant: 'error'});
			}
		}
	}

	private async deleteContentButtonPressed(): Promise<void> {
		const { dialog } = this.props;
		const content = this.state.content;

		try {
			await dialog.confirm({
				title: 'CMS Löschen',
				message: 'Soll der CMS wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			});

			await this.cmsContentService.deleteContent(content._id);

			this.props.history.push('/comparators');
			this.props.enqueueSnackbar('CMS erfolgreich gelöscht!', {variant: 'success'});
		} catch (error) {
			console.log(`Error while deleting comparator: ${error}`);
		}
	}

	private async handleSaveButton(): Promise<void> {
		this.setState({saving: true});

		try {
			const content = await this.cmsContentService.saveContent(this.state.content);

			this.setState({content: content, savedContent: content});
			this.props.enqueueSnackbar('CMS erfolgreich gespeichert!', {variant: 'success'});

		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	private rowsDisplay(topRows: boolean): ReactNode {
		return (
			<CmsContentRowsEditor
				rows={topRows ? this.state.content.topRows : this.state.content.bottomRows}
				onChange={(newRows): void => {
					if (topRows) {
						this.setState((oldState) => ({
							content: {
								...oldState.content,
								topRows: JSON.parse(JSON.stringify(newRows))
							}
						}));
					} else {
						this.setState((oldState) => ({
							content: {
								...oldState.content,
								bottomRows: JSON.parse(JSON.stringify(newRows))
							}
						}));
					}
				}}
				categoryCode={this.props.match.params.categoryCode}
			/>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const comparatorId = this.state.content._id;
		const title = (comparatorId === 'new') ? 'Neuer CMS' : 'CMS Bearbeiten';

		return (
			<React.Fragment>
				{this.isDirty() &&
					<Prompt
						message='Wollen Sie den Editor wirklich verlassen? Dabei gehen die bereits erstellten Daten verloren.'
					/>
				}
				<Paper square={true} className={classes.root}>
					<Typography component="h2" variant="h4" className={classes.title}>
						{title}
					</Typography>
					{this.state.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}
					{!this.state.loading &&
						<React.Fragment>
							<Button
								variant="outlined"
								color="primary"
								className={classes.backButton}
								onClick={(): void => {
									this.props.history.goBack();
								}}
								startIcon={<ArrowBackIcon />}
							>
								Zurück
							</Button>
							<Typography component="h3" variant="h5" className={classes.rowsTitle}>
								Über Produktvergleicher
							</Typography>
							{this.rowsDisplay(true)}
							<Typography component="h3" variant="h5" className={classes.rowsTitle}>
								Unter Produktvergleicher
							</Typography>
							{this.rowsDisplay(false)}
							<div className={classes.bottomButtons}>
								{ this.state.content._id !== 'new' &&
								<Button
									variant="outlined"
									color="secondary"
									size="small"
									className={classes.deleteButton}
									startIcon={<DeleteIcon />}
									onClick={this.deleteContentButtonPressed.bind(this)}
								>
									CMS Löschen
								</Button>
								}
								<Button
									variant="contained"
									color="primary"
									onClick={this.handleSaveButton.bind(this)}
									disabled={this.state.saving}
									startIcon={<SaveIcon />}
									className={classes.saveButton}
								>
								CMS Speichern
								</Button>
							</div>
						</React.Fragment>
					}
				</Paper>
			</React.Fragment>
		);
	}

	private isDirty(): boolean {
		const c1 = this.state.content;
		const c2 = this.state.savedContent;

		if (c1.categoryCode !== c2.categoryCode) {
			return true;
		}

		return false;
	}
}

export default withSnackbar(withDialog()(withStyles(cmsContentEditorStyles)(CtaEditor)));
