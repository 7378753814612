import { UserModel } from '@models/User';

export enum EntityType {
	Comparator = 0,
	AutomatedComparator = 1,
}

export interface AuditLog {
	_id: string;
	user: UserModel;
	created_at: Date;
	entityType: number;
	entityId: string;
	action: number;
}
