import React, { ReactNode, Component } from 'react';
import {
	Button,
	TextField,
	FormControl,
	InputAdornment,
	InputLabel,
	Select,
	MenuItem,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	WithStyles,
	withStyles
} from '@material-ui/core';
import AttributeSelect from '@common/AttributeSelect';
import ProductSelect from '@common/ProductSelect';
import ruleEditorStyles from './RuleEditorStyles';
import { RuleType, Rule, getSelectedRuleTypeString } from '@models/AutomatedComparator';
import { Product } from '@models/Product';
import CategorySelect from '@common/CategorySelect';

interface Props extends WithStyles<typeof ruleEditorStyles> {
	rule: Rule;
	ruleEditorOpen: boolean;
	closeHandler: (rule: Rule | null, removeDummyRule: boolean) => void;
	selectedCategory: string | string[];
	isAdvisor?: boolean; 
}

interface State {
	currentRule: Rule;
	ruleSelectOpen: boolean;
	inputRequired: boolean;
	selectedRadio: string;
	radioInputIsSelect: string;
}

class RuleEditor extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		let selectedRadio = 'select';

		if (this.props.rule && this.props.rule.radioSelected) {
			selectedRadio = this.props.rule.radioSelected;
		}

		this.state = {
			currentRule: JSON.parse(JSON.stringify(props.rule)),
			ruleSelectOpen: false,
			inputRequired: false,
			selectedRadio: selectedRadio,
			radioInputIsSelect: selectedRadio
		};
	}

	private saveButtonClicked(): void {
		const currentRule = this.state.currentRule;
		if (currentRule.type === RuleType.ProductInCategory) {
			if (currentRule.categoryCode && currentRule.categoryCode.length > 0) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (currentRule.type === RuleType.ProductNameContainsString || currentRule.type === RuleType.ProductNameNotContainsString) {
			if (currentRule.productName && currentRule.productName.length > 0) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (currentRule.type === RuleType.ProductContainsAttribute || currentRule.type === RuleType.ProductNotContainsAttribute) {
			if (currentRule.attributeName && currentRule.attributeName.length > 0 && currentRule.attributeValue && currentRule.attributeValue.length > 0 && this.state.selectedRadio === this.state.radioInputIsSelect) {
				currentRule.radioSelected = this.state.selectedRadio;
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (currentRule.type === RuleType.ProductHasAttribute || currentRule.type === RuleType.ProductNotHasAttribute) {
			if (currentRule.attributeName && currentRule.attributeName.length > 0) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (currentRule.type === RuleType.ProductAttributeValueEquals || currentRule.type  === RuleType.ProductAttributeValueNotEquals ||
			currentRule.type === RuleType.ProductAttributeValueIsGreater || currentRule.type  === RuleType.ProductAttributeValueIsGreaterEquals ||
			currentRule.type === RuleType.ProductAttributeValueIsSmaller || currentRule.type  === RuleType.ProductAttributeValueIsSmallerEquals) {
			if (currentRule.attributeName && currentRule.attributeName.length > 0 && currentRule.attributeValue && currentRule.attributeValue.length > 0) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (this.state.currentRule.type === RuleType.ProductPriceIsSmaller || this.state.currentRule.type  === RuleType.ProductPriceIsGreater || this.state.currentRule.type  === RuleType.ProductPriceEquals || this.state.currentRule.type === RuleType.ProductPriceIsSmallerEquals || this.state.currentRule.type  === RuleType.ProductPriceIsGreaterEquals) {
			if (currentRule.price && currentRule.price.length > 0 && Number(currentRule.price)) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		} else if (currentRule.type === RuleType.ProductVisuallySimilar) {
			if (currentRule.productCode) {
				this.props.closeHandler(currentRule, false);
			} else {
				this.setState({inputRequired: true});
			}
		}
	}

	private cancelButtonClicked(): void {
		const rule = this.props.rule;
		if (rule && rule.type === RuleType.ProductNameContainsString && rule.productName === '') {
			this.props.closeHandler(null, true);
		} else {
			this.props.closeHandler(null, false);
		}
	}

	private handleRuleSelectChange(event: React.ChangeEvent<{ value: unknown }>): void {
		const ruleType = (event.target.value as RuleType);

		const currentRule = {
			type: ruleType
		};

		this.setState({
			currentRule: currentRule,
			inputRequired: false
		});
	}

	private handleRadioChange(value: string): void {
		this.setState({selectedRadio: value});
	}

	private getFilterFieldsByRuleType(): ReactNode {
		const classes = this.props.classes;
		if (this.state.currentRule) {
			if (this.state.currentRule.type === RuleType.ProductInCategory) {
				return (
					<div className={classes.categorySelectContainer}>
						<CategorySelect
							onChange={(event): void => {this.handleRuleInputChange('category', event);}}
							selectedCategoryCode={this.state.currentRule.categoryCode ? this.state.currentRule.categoryCode : null}
							topLevelCategoriesExcluded={false}
							disabled={false}
						/>
					</div>
				);
			} else if (this.state.currentRule.type === RuleType.ProductNameContainsString || this.state.currentRule.type  === RuleType.ProductNameNotContainsString) {
				return (
					<FormControl className={classes.formControl}>
						<TextField
							required
							fullWidth
							error={this.state.inputRequired}
							className={classes.textInput}
							label="Produktname"
							value={(this.state.currentRule.productName) ? (this.state.currentRule.productName) : ''}
							onChange={(event): void => {this.handleRuleInputChange('productName', event);}}
						/>
					</FormControl>
				);
			} else if (this.state.currentRule.type === RuleType.ProductContainsAttribute || this.state.currentRule.type  === RuleType.ProductNotContainsAttribute) {
				return (
					<div>
						<AttributeSelect
							onChangeAttributeName={this.handleRuleAttributeNameChange.bind(this)}
							onChangeAttributeValue={this.handleRuleAttributeValueChange.bind(this)}
							onChangeSelectedRadio={this.handleRadioChange.bind(this)}
							attributeName={this.state.currentRule.attributeName}
							attributeValue={this.state.currentRule.attributeValue}
							radioSelected={this.state.selectedRadio}
							selectedCategoryCode={this.props.selectedCategory}
							ruleType={this.state.currentRule.type}
							attributeNameOnly={false}
							invalidInput={this.state.inputRequired}
							includeRadioButtons={true}
							includeTextField={true}
						/>
					</div>
				);
			} else if (this.state.currentRule.type === RuleType.ProductHasAttribute || this.state.currentRule.type  === RuleType.ProductNotHasAttribute) {
				return (
					<AttributeSelect
						onChangeAttributeName={this.handleRuleAttributeNameChange.bind(this)}
						attributeName={this.state.currentRule.attributeName}
						selectedCategoryCode={this.props.selectedCategory}
						ruleType={this.state.currentRule.type}
						attributeNameOnly={true}
						invalidInput={this.state.inputRequired}
						includeRadioButtons={false}
						includeTextField={false}
					/>
				);
			} else if (this.state.currentRule.type === RuleType.ProductAttributeValueEquals || this.state.currentRule.type  === RuleType.ProductAttributeValueNotEquals ||
				this.state.currentRule.type === RuleType.ProductAttributeValueIsGreater || this.state.currentRule.type  === RuleType.ProductAttributeValueIsGreaterEquals ||
				this.state.currentRule.type === RuleType.ProductAttributeValueIsSmaller || this.state.currentRule.type  === RuleType.ProductAttributeValueIsSmallerEquals) {
				return (
					<div>
						<AttributeSelect
							onChangeAttributeName={this.handleRuleAttributeNameChange.bind(this)}
							attributeName={this.state.currentRule.attributeName}
							selectedCategoryCode={this.props.selectedCategory}
							ruleType={this.state.currentRule.type}
							attributeNameOnly={true}
							invalidInput={this.state.inputRequired}
							includeRadioButtons={false}
							includeTextField={false}
						/>
						<div className={classes.formControlRelational}>
							<div className={classes.relationalOperator}>{this.getRelationalOperatorString()}</div>
							<div className={classes.relationalAttributeValue}>
								<TextField
									required
									fullWidth
									error={this.state.inputRequired}
									label="Attributwert"
									value={(this.state.currentRule.attributeValue) ? (this.state.currentRule.attributeValue) : ''}
									onChange={(event): void => {this.handleRuleInputChange('attributeValue', event);}}
								/>
							</div>
						</div>
					</div>
				);
			} else if (this.state.currentRule.type === RuleType.ProductPriceIsSmaller || this.state.currentRule.type  === RuleType.ProductPriceIsGreater || this.state.currentRule.type  === RuleType.ProductPriceEquals || this.state.currentRule.type === RuleType.ProductPriceIsSmallerEquals || this.state.currentRule.type  === RuleType.ProductPriceIsGreaterEquals) {
				return (
					<div className={classes.formControlRelationalPrice}>
						<div className={classes.relationalOperator}>{this.getRelationalOperatorString()}</div>
						<div className={classes.relationalAttributeValue}>
							<TextField
								required
								fullWidth
								error={this.state.inputRequired}
								label="Preis"
								value={(this.state.currentRule.price) ? (this.state.currentRule.price) : ''}
								InputProps={{
									startAdornment: <InputAdornment position="start">€</InputAdornment>,
								}}
								onChange={(event): void => {this.handleRuleInputChange('price', event);}}
							/>
						</div>
					</div>
				);
			} else if (this.state.currentRule.type === RuleType.ProductVisuallySimilar) {
				return (
					<ProductSelect
						onChange={this.handleProductChange.bind(this)}
						selectedProductCode={this.state.currentRule.productCode ? this.state.currentRule.productCode : null}
						disabled={false}
						categoryCodeToLookForProducts={this.props.selectedCategory}
						visualRule={true}
					/>
				);
			}
		}
	}

	private getRelationalOperatorString(): string  {
		const currentRuleType = this.state.currentRule.type;
		if (currentRuleType === RuleType.ProductAttributeValueEquals || currentRuleType === RuleType.ProductPriceEquals) {
			return '=';
		} else if (currentRuleType === RuleType.ProductAttributeValueNotEquals) {
			return '≠';
		} else if (currentRuleType === RuleType.ProductAttributeValueIsGreater || currentRuleType === RuleType.ProductPriceIsGreater) {
			return '>';
		} else if (currentRuleType === RuleType.ProductAttributeValueIsGreaterEquals || currentRuleType === RuleType.ProductPriceIsGreaterEquals) {
			return '>=';
		} else if (currentRuleType === RuleType.ProductAttributeValueIsSmaller || currentRuleType === RuleType.ProductPriceIsSmaller) {
			return '<';
		} else if (currentRuleType === RuleType.ProductAttributeValueIsSmallerEquals || currentRuleType === RuleType.ProductPriceIsSmallerEquals) {
			return '<=';
		} else {
			return '';
		}
	}

	private handleRuleAttributeNameChange(name: string): void {
		const currentRule = this.state.currentRule;
		currentRule.attributeName = name;
		this.setState({
			currentRule: currentRule
		});
	}

	private handleRuleAttributeValueChange(value: string, radioInputIsSelect: string): void {
		const currentRule = this.state.currentRule;
		currentRule.attributeValue = value;
		this.setState({
			currentRule: currentRule,
			radioInputIsSelect: radioInputIsSelect
		});
	}

	private handleProductChange(selectedProduct: Product | null): void {
		const currentRule = this.state.currentRule;
		if (selectedProduct) {
			if (selectedProduct.images[0]) {
				currentRule.productImage = selectedProduct.images[0];
			}

			currentRule.productCode = selectedProduct.productCode;
			this.setState({
				currentRule: currentRule
			});
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleRuleInputChange(type: string, event: any): void {
		const currentRule = this.state.currentRule;
		if (type === 'category') {
			if (event) {
				currentRule.categoryCode = event.code;
			}
			this.setState({
				currentRule: currentRule
			});
		} else {
			const value = event.target.value.normalize('NFC');
			if (type === 'productName') {
				currentRule.productName = value;
				this.setState({
					currentRule: currentRule
				});
			} else if (type === 'attributeValue') {
				currentRule.attributeValue = value;
				this.setState({
					currentRule: currentRule
				});
			} else if (type === 'price') {
				currentRule.price = value.replace(',', '.');
				this.setState({
					currentRule: currentRule
				});
			}
		}
	}

	private isNumber(value: string): boolean {
		return isNaN(Number(value));
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.ruleEditorOpen} onClose={this.cancelButtonClicked} fullWidth={true}>
				<DialogTitle>Neue Regel anlegen</DialogTitle>
				<DialogContent>
					<FormControl className={classes.formControl}>
						<InputLabel>Regeltyp auwählen...</InputLabel>
						<Select
							value={(this.state.currentRule) ? this.state.currentRule.type: ''}
							onChange={this.handleRuleSelectChange.bind(this)}
						>
							{Object.keys(RuleType).filter(x => this.isNumber(x) && (this.props.isAdvisor || (x !== 'ProductInCategory')) && x !== 'ProductInCategoryOrSubcategory').map(key =>
								<MenuItem key={RuleType[key as keyof typeof RuleType]} value={RuleType[key as keyof typeof RuleType]}>{getSelectedRuleTypeString(RuleType[key as keyof typeof RuleType])}</MenuItem>)}
						</Select>
					</FormControl>
					{this.getFilterFieldsByRuleType()}
				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					<Button onClick={this.cancelButtonClicked.bind(this)} color="primary">
					Abbrechen
					</Button>
					<Button onClick={this.saveButtonClicked.bind(this)} color="primary">
					Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withStyles(ruleEditorStyles)(RuleEditor);
