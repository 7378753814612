import React, { ReactNode, Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AuthService from '@services/AuthService';
import PrivateRoute from './PrivateRoute';

import AppWrapper from './AppWrapper';
import Login from '../Authentication/Login';
import Logout from '../Authentication/Logout';

class AppRouter extends Component {
	private authService: AuthService;

	public constructor(props: object) {
		super(props);
		this.authService = new AuthService();
	}

	public render(): ReactNode {
		return (
			<BrowserRouter>
				<Switch>
					<Route path='/login' component={Login} />
					<PrivateRoute path='/logout' component={Logout} />
					<PrivateRoute component={AppWrapper} />
				</Switch>
			</BrowserRouter>
		);
	}
}

export default AppRouter;
