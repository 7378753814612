import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const folderEditorStyles = (theme: Theme): any => createStyles({
	deleteButton: {
		color: '#EE0000',
		marginLeft: theme.spacing(1)
	},
	subtitle: {
		marginTop: theme.spacing(2)
	}
});

export default folderEditorStyles;
