import React, { ReactNode, Component } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	Select,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import categoryMappingStyles from './CategoryMappingStyles';

import CategoryTree from './Categorytree';
import EditableCategoryTree from './EditableCategoryTree';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { CategoriesResponse, Category, KioskType } from '@models/Category';

import AuthService from '@services/AuthService';

interface State {
	categories: Category[];
	selectedType: KioskType;
}

class CategoryMapping extends Component<WithStyles, State> {
	private authService: AuthService;

	public constructor(props: WithStyles) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			categories: [],
			selectedType: KioskType.FASO
		};
	}

	public componentDidMount(): void {
		this.loadCategories();
	}

	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({categories: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private handleKioskTypeChange(event: React.ChangeEvent<{ value: unknown }>): void {
		this.setState({selectedType: event.target.value as KioskType});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<DndProvider backend={HTML5Backend}>
				<Paper className={classes.root}>
					<Typography component="h2" variant="h4">
						{'Kategorie Mapping (Drag & Drop)'}
					</Typography>
					<Typography className={classes.description} component="h4" variant="body1">
						{`Einzelne Online-Shop Kategorien (links) können durch Drag & Drop zu den erstellten Kategorien (rechts) hinzugefügt werden.
							Die Online-Shop Kategorien werden durch Hinzufügen auf der rechten Seite automatisch als erstellte Kategorien gespeichert.
							Der Zielbereich wird farblich hervorgehoben.
							Befindet man sich mit einer Online-Shop Kategorie über einer erstellten Kategorie, so wird die Online-Shop Kategorie als Unterkategorie hinzugefügt (max. 3 Ebenen).
							Wird eine Online-Shop Kategorie zu einer Kategorie auf dritter Ebene hinzugefügt, so wird der Kategoriecode der erstellten Kategorie erweitert.
							Eine Online-Shop Kategorie kann nur einmal pro Ebene pro erstellter Kategorie hinzugefügt werden.
							Die erstellten Kategorien unterscheiden sich je nach ausgewähltem Kiosk-Typ.
							 `}
					</Typography>
					<FormControl className={classes.kioskSelect}>
						<InputLabel id="kiosk-type-label" className={classes.label}>Ausgewählter Kiosk-Typ:</InputLabel>
						<Select
							labelId="kiosk-type-label"
							value={this.state.selectedType}
							onChange={(event): void => {this.handleKioskTypeChange(event);}}
							className={this.state.selectedType === KioskType.FASO ? classes.fasoSelect : this.state.selectedType === KioskType.KM ? classes.kmSelect : ''}
						>
							<MenuItem className={classes.fasoSelect} value={KioskType.FASO}>FASO</MenuItem>
							<MenuItem className={classes.kmSelect} value={KioskType.KM}>KM</MenuItem>
							<MenuItem value={KioskType.SMARTTERMINAL}>Smart Terminal</MenuItem>
						</Select>
					</FormControl>
					<div className={classes.categoryPagesContainer}>
						<Paper className={classes.baseCategories}>
							<Typography component="h3" variant="h6" className={classes.header}>
								{'Online-Shop Kategorien'}
							</Typography>
							<CategoryTree
								categories={this.state.categories}
							/>
						</Paper>
						<Paper className={classes.createdCategories}>
							<Typography component="h3" variant="h6" className={classes.header}>
								{'Erstellte Kategorien - ' + (this.state.selectedType !== KioskType.SMARTTERMINAL ? KioskType[this.state.selectedType] :  'Smart Terminal' )}
							</Typography>
							<EditableCategoryTree
								categories={this.state.categories}
								authService={this.authService}
								kioskType={this.state.selectedType}
							/>
						</Paper>
					</div>
				</Paper>
			</DndProvider>
		);
	}
}

export default withStyles(categoryMappingStyles)(CategoryMapping);
