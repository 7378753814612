// eslint-disable-next-line
/// <reference path="../dts/muibox.d.ts"/>
import React, { ReactNode, Component } from 'react';
import {
	Button,
	WithStyles,
	withStyles,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Checkbox,
	FormGroup
} from '@material-ui/core';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

import AuthService from '@services/AuthService';
import userEditorStyles from './UserEditorStyles';

import { UserModel, translatePermission } from '@models/User';

interface Props extends WithStyles<typeof userEditorStyles> {
	user: UserModel | null;
	userEditorOpen: boolean;
	closeHandler: () => void;
	dialog: ConfirmDialog;
}

interface State {
	user: UserModel;
}

class UserEditor extends Component<Props, State> {
	private authService: AuthService;
	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			user: this.getUserToSet(this.props.user),
		};
	}

	private getUserToSet(newUser: UserModel | null): UserModel {
		if (newUser) {
			return newUser;
		} else {
			const responseUser: UserModel = {
				username: '',
				password: '',
				permissions: []
			};

			return responseUser;
		}
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.user !== this.props.user) {
			this.setState({user: this.getUserToSet(this.props.user)});
		}
	}

	private addHeader(): ReactNode {
		if (this.props.user) {
			return <DialogTitle id="form-dialog-title">Benutzer {this.props.user.username} Bearbeiten</DialogTitle>;
		} else {
			return <DialogTitle id="form-dialog-title">Neuer Benutzer</DialogTitle>;
		}
	};

	private handleTextChange(key: keyof UserModel, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const tempUser = this.state.user;
		if (key === 'username') {
			tempUser.username = event.target.value;
		} else if (key === 'password') {
			tempUser.password = event.target.value;
		}
		this.setState({
			user : tempUser
		});
	};

	private handleUserSave(): void {
		let url = '/api/users/new';

		if (this.props.user){
			url = `/api/users/${this.props.user._id}`;
		}

		const user = this.state.user;
		if (user.password === undefined) {
			user.password = '';
		}

		this.authService.fetch<UserModel>(url, {
			method: 'POST',
			body: JSON.stringify(this.state.user)
		}).then((_): void => {
			const tmpUser = this.state.user;
			tmpUser.password = '';
			this.setState({user: tmpUser});
			this.props.closeHandler();
		});
	};

	private textInput(key: keyof UserModel): ReactNode {
		let placeholder = '';
		if (key === 'username') {
			placeholder = 'Benutzername';
		} else if (key === 'password') {
			placeholder = 'Neues Passwort';
		}

		return (
			<TextField
				id={key}
				label={(key === 'password') ? 'PASSWORT' : key.toUpperCase()}
				placeholder={placeholder}
				className={this.props.classes.textField}
				value={this.state.user[key]}
				type={(key === 'password') ? 'password' : ''}
				margin="dense"
				onChange={(event): void => {this.handleTextChange(key, event);}}
				fullWidth
			/>
		);
	}

	private permissionCheckbox(permission: string): ReactNode {
		return (
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={this.state.user.permissions.includes(permission)}
							onChange={(event): void => {this.handlePermissionChange(permission, event);}}
							value={permission}
							color="primary"
						/>
					}
					label={translatePermission(permission)}
					key={permission}
				/>
			</FormGroup>
		);
	}

	private handlePermissionChange(permission: string, _: React.ChangeEvent<HTMLInputElement>): void {
		const tmpUser = this.state.user;

		if (this.state.user.permissions.includes(permission)) {
			// remove from array
			tmpUser.permissions = tmpUser.permissions.filter((perm: string): boolean => {
				return perm !== permission;
			});
		} else {
			// add to array
			tmpUser.permissions.push(permission);
		}
		this.setState({user: tmpUser});
	}

	private deleteUserButton(): ReactNode {
		if (this.props.user) {
			const classes = this.props.classes;
			return (
				<Button className={classes.deleteButton} onClick={this.deleteUserButtonPressed.bind(this)} color="primary">
					Löschen
				</Button>
			);
		}
		return null;
	}

	private deleteUserButtonPressed(): void {
		if (this.props.user) {
			const userId = this.props.user._id;
			const { dialog } = this.props;

			dialog.confirm({
				title: 'Benutzer Löschen',
				message: 'Soll der Benutzer wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'error',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<UserModel>(`/api/users/${userId}`, {
					method: 'DELETE',
					body: JSON.stringify(this.props.user)
				}).then((_): void => {
					this.props.closeHandler();
				});
			});
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const permissions = [
			'admin',
			'advisor',
			'audit-logs',
			'category-feed',
			'category-mapping',
			'category-ranking',
			'category-overview',
			'comparator',
			'comparator-installation',
			'crawler',
			'dropshipping-export',
			'orders',
			'product-monitoring',
			'product-suggestions',
			'reporting',
			'slider',
			'shopping',
			'series'
		];

		return (
			<Dialog open={this.props.userEditorOpen} onClose={this.props.closeHandler} fullWidth={true}>
				{this.addHeader()}
				<DialogContent>
					{(this.props.user) ? null : this.textInput('username')}
					{this.textInput('password')}
					{ permissions.sort().map((permission: string): ReactNode => (this.permissionCheckbox(permission))) }
				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					{this.deleteUserButton()}
					<Button onClick={this.props.closeHandler} color="primary">
						Abbrechen
					</Button>
					<Button onClick={this.handleUserSave.bind(this)} color="primary">
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withDialog()(withStyles(userEditorStyles)(UserEditor));
