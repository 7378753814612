import React, { ReactNode, Component } from 'react';
import {
	Typography,
	TextField,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';

import sliderStyles from './SliderStyles';

/* eslint import/no-webpack-loader-syntax: off */
import sliderJS from '!raw-loader!./slider-loader.js.txt';

class Slider extends Component<WithStyles<typeof sliderStyles>> {

	private imgTag: string;
	private divTag: string;

	public constructor(props: WithStyles<typeof sliderStyles>) {
		super(props);

		const base64JS = btoa(sliderJS);
		this.imgTag = `<img src="" style="display: none; width: 0px; height: 0px;" class="pdctcmp-slider-hidden pdctcmp-slider-v9" onerror="Function(atob('${base64JS}'))();" />`;

		this.divTag = '<div id="pdctcmp-slider-container" class="pdctcmp-slider-hidden"></div>';
	}

	public render(): ReactNode {
		const classes = this.props.classes;


		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Slider Installation'}
				</Typography>
				<Typography component="h3" variant="h5" className={classes.subTitle}>
					{'Javascript (Mobile)'}
				</Typography>
				<TextField
					label={'Javascript (Mobile)'}
					multiline
					value={this.imgTag}
					className={classes.textField}
					margin="normal"
					fullWidth={true}
					InputProps={{
						classes: {
							input: classes.textField,
						},
					}}
					onFocus={(event): void => event.target.select()}
				/>
				<Typography component="h3" variant="h5" className={classes.subTitle}>
					{'Slider-DIV (Mobile)'}
				</Typography>
				<TextField
					label={'Slider-DIV (Mobile)'}
					multiline
					value={this.divTag}
					className={classes.textField}
					margin="normal"
					fullWidth={true}
					InputProps={{
						classes: {
							input: classes.textField,
						},
					}}
					onFocus={(event): void => event.target.select()}
				/>
			</Paper>
		);
	}
}

export default withStyles(sliderStyles)(Slider);
