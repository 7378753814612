import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const shoppingProductCardStyles = (theme: Theme): any => createStyles({
	otherCard: {
		height: '100%',
	},
	mxCard: {
		border:'3px solid',
		height: '100%',
		borderColor: theme.palette.secondary.main,
	},
	media: {
		height: 250,
	},
});

export default shoppingProductCardStyles;
