import React, { ReactNode, Component } from 'react';

import { WithStyles, withStyles, Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

import AuthService from '@services/AuthService';
import { CategoriesResponse, Category } from '@models/Category';

import subcategorySelectStyles from './SubcategorySelectStyles';

interface State {
	selectedCategory: Category | null;
}

interface Props extends WithStyles<typeof subcategorySelectStyles> {
	onChange: (excludedCategoryCodes: string) => void;
	selectedCategoryCode: string;
	excludedCategoryCodes: string;
}

class SubcategorySelect extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			selectedCategory: null,
		};
	}

	public componentDidMount(): void {
		this.loadSubcategories();
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.selectedCategoryCode !== this.props.selectedCategoryCode) {
			this.loadSubcategories();
		}
	}

	private loadSubcategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				const selectedCategory = this.findSelectedCategory(response.data, this.props.selectedCategoryCode);
				this.setState({selectedCategory: selectedCategory});
				this.props.onChange(this.props.excludedCategoryCodes);
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private findSelectedCategory(categories: Category[], selectedCategoryCode: string): Category | null {
		for (const category of categories) {
			if (category.code === selectedCategoryCode) {
				return category;
			} else if (category.children.length > 0) {
				const result = this.findSelectedCategory(category.children, selectedCategoryCode);

				if (result !== null) {
					return result;
				}
			}
		}

		return null;
	}

	public render(): ReactNode {
		if (this.state.selectedCategory && this.state.selectedCategory.children.length > 0) {
			return (
				<FormGroup>
					{this.renderCategories(this.state.selectedCategory.children, 0, false)}
				</FormGroup>
			);
		} else if (this.state.selectedCategory && this.state.selectedCategory.children.length === 0) {
			return (
				<div>{'Keine Unterkategorien gefunden'}</div>
			);
		}

		return (<div></div>);
	}

	private renderCategories(categories: Category[], level: number, parentIsDisabled: boolean): ReactNode {
		const classes = this.props.classes;

		const checkboxes: ReactNode[] = [];

		for (const category of categories) {

			const checked = !this.categoryIsExcluded(category) && !parentIsDisabled;

			let subcategories: ReactNode = <React.Fragment />;
			if (category.children.length > 0) {
				subcategories = this.renderCategories(category.children, level + 1, !checked);
			}

			checkboxes.push(
				<React.Fragment key={category.code}>
					<FormControlLabel
						className={classes.catgegoryCheckbox}
						classes={{
							label: classes.label,
						}}
						style={{marginLeft: level * 30}}
						control={
							<Checkbox
								color="primary"
								checked={checked}
								onChange={(event): void => {this.handleCheckboxChange(category);}}
								disabled={parentIsDisabled}
							/>
						}
						label={
							<React.Fragment>
								<span className={classes.nameContainer}>{category.name}</span>
							</React.Fragment>
						}
					/>
					{subcategories}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{checkboxes}
			</React.Fragment>
		);
	}

	private categoryIsExcluded(category: Category): boolean {
		const excludedCategories = this.props.excludedCategoryCodes.split(',');

		for (const code of excludedCategories) {
			if (category.code === code && code !== '') {
				return true;
			}
		}

		return false;
	}

	private handleCheckboxChange(category: Category): void {
		let excludedCategories = this.props.excludedCategoryCodes.split(',');

		if (excludedCategories.includes(category.code)) {
			// remove from exclusion
			excludedCategories = excludedCategories.filter((c) => c !== category.code);
		} else {
			excludedCategories.push(category.code);
		}

		excludedCategories = excludedCategories.filter((c) => c !== '');

		this.props.onChange(excludedCategories.join(','));
	}
}

export default withStyles(subcategorySelectStyles)(SubcategorySelect);
