import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const attributeValueSynonymOverviewStyles = (theme: Theme): any => createStyles({
	title: {
		padding: theme.spacing(2, 3),
	},
	addButton: {
		right: 20,
		bottom: 20,
		position: 'fixed',
	},
	description: {
		margin: theme.spacing(2),
	},

});

export default attributeValueSynonymOverviewStyles;
