import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productsMissingAttributesStyles = (theme: Theme): any => createStyles({
	dialogTitle: {
		display: 'inline-block',

	},
	dialogAttributeTitle: {
		display: 'inline-block',
		color: theme.palette.primary.main,
	},
	dialogActions: {
		marginTop: '10px',
		marginRight: '5px'
	}

});

export default productsMissingAttributesStyles;
