import React, {ReactElement, FC} from 'react';
import {
	Button,
	Divider,
	IconButton,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorHistoryBasedFollowingQuestionsStyles from './AdvisorHistoryBasedFollowingQuestionsStyles';
import {AdvisorAnswer, AdvisorQuestion, HistoryBased, HistoryBasedFollowingQuestion, dummyHistoryBasedFollowingQuestion, getAdvisorAnswersByIds, getAdvisorQuestionById} from '@common/models/Advisor';
import AdvisorQuestionSelect from './AdvisorQuestionSelect';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import AdvisorAnswerSelect from './AdvisorAnswerMultiSelect';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface Props extends WithStyles<typeof advisorHistoryBasedFollowingQuestionsStyles> {
	dialog?: ConfirmDialog;
	advisorQuestions: AdvisorQuestion[];
	advisorAnswer: AdvisorAnswer;
	changeHandler: (historyBased: HistoryBased) => void;
}

const AdvisorHistoryBasedFollowingQuestions: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	function handleDefaultQuestionChange(question: AdvisorQuestion | null): void {
		const tempHistoryBased = (JSON.parse(JSON.stringify(props.advisorAnswer.historyBased)) as HistoryBased);
		if (!question) {
			tempHistoryBased.defaultFollowingQuestionId = null;
			props.changeHandler(tempHistoryBased);
		} else if (question && question._id) {
			tempHistoryBased.defaultFollowingQuestionId = question._id;
			props.changeHandler(tempHistoryBased);
		}
	}

	function handleAnswerChange(answerIds: AdvisorAnswer[], index: number): void {
		const tempHistoryBased = (JSON.parse(JSON.stringify(props.advisorAnswer.historyBased)) as HistoryBased);
		const validAnswerIds: string[] = [];
		for (const answerId of answerIds) {
			if (answerId._id) {
				validAnswerIds.push(answerId._id);
			}
		}
		tempHistoryBased.historyBasedFollowingQuestions[index].historyBasedAnswerIds = validAnswerIds;
		props.changeHandler(tempHistoryBased);
	}

	function handleQuestionChange(question: AdvisorQuestion | null, index: number): void {
		const tempHistoryBased = (JSON.parse(JSON.stringify(props.advisorAnswer.historyBased)) as HistoryBased);
		if (!question) {
			tempHistoryBased.historyBasedFollowingQuestions[index].followingQuestionId = null;
			props.changeHandler(tempHistoryBased);
		} else if (question && question._id) {
			tempHistoryBased.historyBasedFollowingQuestions[index].followingQuestionId = question._id;
			props.changeHandler(tempHistoryBased);
		}
	}

	function handleNewHistoryBasedFollowingQuestionButtonClicked(): void {
		const tempAdvisorAnswer = JSON.parse(JSON.stringify(props.advisorAnswer));
		const tempHistoryBased = tempAdvisorAnswer.historyBased as HistoryBased;
		tempHistoryBased.historyBasedFollowingQuestions.push(dummyHistoryBasedFollowingQuestion);
		props.changeHandler(tempHistoryBased);
	}

	function getAllAdvisorAnswers(): AdvisorAnswer[] {
		const advisorAnswers: AdvisorAnswer[] = [];
		for (const advisorQuestion of props.advisorQuestions) {
			for (const advisorAnswer of advisorQuestion.answers) {
				advisorAnswers.push(advisorAnswer);
			}
		}
		return advisorAnswers;
	}

	

	function handleHistoryBasedFollowingQuestionDelete(index: number): void {
		const dialog = props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Eintrag Löschen',
				message: 'Soll der Eintrag wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then(async (): Promise<void> => {
				const tempHistoryBased = (JSON.parse(JSON.stringify(props.advisorAnswer.historyBased)) as HistoryBased);
				tempHistoryBased.historyBasedFollowingQuestions.splice(index, 1);
				props.changeHandler(tempHistoryBased);
			}).catch((error): void => {
				console.log(`Error while deleting advisor: ${error}`);
			});
		}
	}

	function followingQuestionIsAlreadyInUse(question: AdvisorQuestion): boolean {
		for (const selectedFollowingQuestion of props.advisorAnswer.historyBased.historyBasedFollowingQuestions) {
			if (question._id && selectedFollowingQuestion.followingQuestionId === question._id) {
				return true;
			}
		}
		return false;
	}

	return (
		<div className={classes.root}>
			<Typography variant="h6">Vergangenheitsabhängige nachfolgende Fragen</Typography>
			<div className={classes.defaultContainer}>
				<Typography variant="subtitle1">Default:</Typography>
				<AdvisorQuestionSelect 
					question={getAdvisorQuestionById(props.advisorAnswer.historyBased.defaultFollowingQuestionId, props.advisorQuestions)}
					allAdvisorQuestions={props.advisorQuestions}
					handleQuestionChange={(question): void => handleDefaultQuestionChange(question)}
					key={'default'}
					placeholder={'Frage auswählen...'}
				/>
			</div>
			{props.advisorAnswer.historyBased && props.advisorAnswer.historyBased.historyBasedFollowingQuestions.length > 0 &&
			<>
				<Typography className={classes.historyBasedFollowingQuestionsLabel} variant="body1">Einträge:</Typography>
				{props.advisorAnswer.historyBased.historyBasedFollowingQuestions.map((historyBasedFollowingQuestion: HistoryBasedFollowingQuestion, index: number) => (
					<div key={index}>
						<div  className={classes.hisoryBasedFollowingQuestionsContainer}>
							<div className={classes.historyBasedAnswersColumn}>
								<AdvisorAnswerSelect 
									answers={getAdvisorAnswersByIds(historyBasedFollowingQuestion.historyBasedAnswerIds, props.advisorQuestions)}
									allAdvisorAnswers={getAllAdvisorAnswers()}
									handleAnswerChange={(answers): void => handleAnswerChange(answers, index)}
									key={index}
									placeholder={'Vorherige Antworten auswählen...'}
								/>
							</div>
							<div className={classes.followingQuestionColumn}>
								<AdvisorQuestionSelect 
									question={getAdvisorQuestionById(historyBasedFollowingQuestion.followingQuestionId, props.advisorQuestions)}
									allAdvisorQuestions={props.advisorQuestions.filter(q => !followingQuestionIsAlreadyInUse(q) || q._id === historyBasedFollowingQuestion.followingQuestionId)}
									handleQuestionChange={(question): void => handleQuestionChange(question, index)}
									key={index}
									placeholder={'Nachfolgende Frage auswählen...'}
								/>
							</div>
							<div className={classes.deleteColumn}>
								<Tooltip title='Eintrag Löschen'>
									<span>
										<IconButton className={classes.deleteHistoryBasedFollowingQuestionButton} size="small" onClick={(event): void => {event.stopPropagation(); handleHistoryBasedFollowingQuestionDelete(index);}}>
											<DeleteIcon />
										</IconButton>
									</span>
								</Tooltip>
							</div>
						</div>
						<Divider className={classes.divider} />
					</div>
				))}
			</>
			}
			<div className={classes.addHistoryBasedFollowingQuestionButtonContainer}>
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					className={classes.addHistoryBasedFollowingQuestionButton}
					startIcon={<AddIcon />}
					onClick={handleNewHistoryBasedFollowingQuestionButtonClicked}
				>
					Neuer Eintrag
				</Button>
			</div>
		</div>
	);
};

export default withDialog()(withStyles(advisorHistoryBasedFollowingQuestionsStyles)(AdvisorHistoryBasedFollowingQuestions));
