import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userAdministrationStyles = (theme: Theme): any => createStyles({
	fab: {
		margin: '0px',
		top: 'auto',
		right: '20px',
		bottom: '20px',
		left: 'auto',
		position: 'fixed'
	},
	root: {
		padding: theme.spacing(2, 3),
	},
	table: {
		marginTop: theme.spacing(2),
	},
	editButton: {
		float: 'right',
	},
});

export default userAdministrationStyles;
