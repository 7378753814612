/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component, ChangeEvent } from 'react';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

import {
	Button,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { SplitDetail, SplitLevel, SplitLevelRuleType } from '@models/AutomatedComparator';
import { Comparator } from '@models/Comparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';

import SplitLevelPreview from '@common/SplitLevelPreview';
import SplitLevels from '@common/SplitLevels';
import AuthService from '@services/AuthService';

import editorSplitSettingsStyles from './EditorSplitSettingsStyles';

interface SplittedComparatorsResponse {
	data: Comparator[];
	success: boolean;
	message?: string;
}

interface SplitLevelToComparators {
	splitLevel: number;
	splittedComparators: Comparator[];
}

interface OptionType {
	label: string;
	value: string;
};

interface Props extends WithStyles<typeof editorSplitSettingsStyles> {
	dialog: ConfirmDialog;
	comparator: Comparator;
	getAttributeOptions: () => OptionType[];
	handlesplitDetailChange: (splitDetail: SplitDetail) => void;
}

interface State {
	comparator: Comparator;
	splitPreviewDialogOpen: boolean;
	levelIndex: number | null;
	loadingSplittedComparators: boolean;
	loadingPriceSplittedComparators: boolean;
	splitLevelsToComparators: SplitLevelToComparators[];
	priceSplittedComparators: Comparator[];
	openPriceSplittedPreview: boolean;
}

class EditorSplitSettings extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			comparator: props.comparator,
			splitPreviewDialogOpen: false,
			levelIndex: null,
			loadingSplittedComparators: false,
			loadingPriceSplittedComparators: false,
			splitLevelsToComparators: [],
			priceSplittedComparators: [],
			openPriceSplittedPreview: true
		};
	}

	private addNewSplitLevel(): void {
		const comparator = this.state.comparator;

		const dummySplitLevel: SplitLevel = {
			splitLevelRuleType: SplitLevelRuleType.SplitRestRemove,
			splitLevelGroups: []
		};

		comparator.splitDetail.levels.push(dummySplitLevel);
		this.props.handlesplitDetailChange(comparator.splitDetail);

		this.setState({
			comparator: comparator
		});
	}

	private deleteSplitLevel(index: number): void {
		const dialog = this.props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Aufteilungsebene Löschen',
				message: `Soll die Aufteilungsebene "${index}" wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				const comparator = this.state.comparator;

				comparator.splitDetail.levels.splice(index, 1);
				this.props.handlesplitDetailChange(comparator.splitDetail);
				this.setState({
					comparator: comparator
				});
			}).catch((error): void => {
				console.log(`Error while deleting split level: ${error}`);
			});
		}
	}

	private openPreview(index: number): void {
		this.setState({levelIndex: index, splitPreviewDialogOpen: true, openPriceSplittedPreview: false});
	}

	private openPriceSplittedPreview(): void {
		let lastIndex = 0;
		if (this.state.comparator.splitDetail && this.state.comparator.splitDetail.levels) {
			lastIndex = this.state.comparator.splitDetail.levels.length-1;
		}

		this.setState({levelIndex: lastIndex, splitPreviewDialogOpen: true, openPriceSplittedPreview: true});
	}

	private closePreview(): void {
		this.setState({splitPreviewDialogOpen: false});
	}

	private splitLevelChanged(splitLevel: SplitLevel, index: number): void {
		const comparator = this.state.comparator;
		comparator.splitDetail.levels[index] = splitLevel;
		this.props.handlesplitDetailChange(comparator.splitDetail);
		this.setState({comparator: comparator});
	}

	private async loadSplittedComparators(): Promise<void> {
		this.setState({loadingSplittedComparators: true});
		const splitLevelsToComparators: SplitLevelToComparators[] = [];

		for (let i = 0; i < this.state.comparator.splitDetail.levels.length; i++) {
			try {

				const response = await this.authService.fetch<SplittedComparatorsResponse>('/api/automated-comparators/splitted', {
					method: 'POST',
					body: JSON.stringify({comparator: this.props.comparator, isAutomated: false, index: i})
				});

				if (response && response.success && response.data) {
					const splitLevelToComparators: SplitLevelToComparators = {
						splitLevel: i,
						splittedComparators: response.data
					};
					splitLevelsToComparators.push(splitLevelToComparators);
				}
			} catch (error) {
				throw new Error(error);
			}
		}

		this.setState({splitLevelsToComparators: splitLevelsToComparators, loadingSplittedComparators: false});
	}

	private async loadPriceSplittedComparators(): Promise<void> {
		this.setState({loadingPriceSplittedComparators: true});
		try {
			const response = await this.authService.fetch<SplittedComparatorsResponse>('/api/automated-comparators/splitted', {
				method: 'POST',
				body: JSON.stringify({comparator: this.props.comparator, isAutomated: false, index: 'all'})
			});

			if (response && response.success && response.data) {
				this.setState({priceSplittedComparators: response.data});
			}
		} catch (error) {
			throw new Error(error);
		}

		this.setState({loadingPriceSplittedComparators: false});
	}

	private checkLessThan3Products(type: string, index?: number): boolean {
		let lessThan3Products = false;

		if (type === 'level' && index !== undefined && !isNaN(index)) {
			const splitLevelsToComparators = this.state.splitLevelsToComparators;
			if (splitLevelsToComparators[index]) {
				if (splitLevelsToComparators[index].splittedComparators.length === 0) {
					lessThan3Products = true;
				}
				for (const comparator of splitLevelsToComparators[index].splittedComparators) {
					if (comparator.products.length < 3) {
						lessThan3Products = true;
						break;
					}
				}
			} else {
				lessThan3Products = true;
			}
		} else if (type === 'price') {
			if (this.state.priceSplittedComparators.length === 0) {
				lessThan3Products = true;
			}
			for (const comparator of this.state.priceSplittedComparators) {
				if (comparator.products.length < 3) {
					lessThan3Products = true;
					break;
				}
			}
		}

		return lessThan3Products;
	}

	private changeValue(type: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const value = parseFloat(e.target.value);

		if (!isNaN(value) && value >= 0 && value <= 1000) {
			const currentComparator = JSON.parse(JSON.stringify(this.state.comparator));
			if (type === 'ideal') {
				currentComparator.splitDetail.idealNumberProducts = value;
			}
			this.setState({comparator: currentComparator}, () => {
				this.props.handlesplitDetailChange(currentComparator.splitDetail);
			});
		}
	}

	private refreshData(): void {
		this.loadSplittedComparators();
		this.loadPriceSplittedComparators();
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		if (!this.props.comparator) {
			return;
		}

		return (
			<ExpansionPanel
				defaultExpanded={this.props.comparator._id !== 'new'}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Aufteilung</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<React.Fragment>
						{this.props.comparator.products.length > 0 &&
							<>
								<div className={classes.splitRuleGroup}>
									{this.state.comparator.splitDetail && this.state.comparator.splitDetail.levels.map((level: SplitLevel, index: number) =>
										<div key={index}>
											<div className={classes.splitRuleGroupHeadlineContainer}>
												<div>
													<Typography className={classes.panelTitle} variant="body1">{'Aufteilungsebene: ' + index}</Typography>
												</div>
												<IconButton disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators} className={classes.deleteButton} size="small" onClick={this.deleteSplitLevel.bind(this, index)}>
													<HighlightOffIcon />
												</IconButton>
											</div>
											<div className={classes.splitRuleGroupContainer}>
												<SplitLevels
													splitLevel={level}
													splitLevelIndex={index}
													selectedCategoryCode={this.props.comparator.categoryCode}
													notifyParent={this.splitLevelChanged.bind(this)}
													loadingSplittedComparators={this.state.loadingSplittedComparators}
													loadingPriceSplittedComparators={this.state.loadingPriceSplittedComparators}
												/>
											</div>
											<div className={classes.openPreviewButton}>
												<Button
													variant="contained"
													color="primary"
													onClick={this.openPreview.bind(this, index)}
													disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators}
												>
												Aufteilung ansehen
												</Button>
												{this.checkLessThan3Products('level', index) &&
												<span className={classes.warning}>
													<Tooltip title={
														<span>Mindestens einer der Produktvergleicher enthält weniger als 3 Produkte.</span>
													}>
														<WarningIcon/>
													</Tooltip>
												</span>
												}
											</div>
										</div>
									)}
									<SplitLevelPreview
										splittedComparators={this.state.openPriceSplittedPreview ? this.state.priceSplittedComparators : this.state.splitLevelsToComparators[this.state.levelIndex ? this.state.levelIndex : 0] ? this.state.splitLevelsToComparators[this.state.levelIndex ? this.state.levelIndex : 0].splittedComparators : []}
										isAutomated={false}
										levelIndex={this.state.levelIndex}
										closeHandler={this.closePreview.bind(this)}
										editorOpen={this.state.splitPreviewDialogOpen}
										mainComparatorProducts={this.props.comparator.products}
										isPriceSplitPreview={this.state.openPriceSplittedPreview}
									/>
									<div className={classes.addNewSplitRuleGroupButton}>
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<AddIcon />}
											onClick={this.addNewSplitLevel.bind(this)}
											disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators}
										>
								Neue Aufteilungsebene
										</Button>
									</div>
								</div>
								{this.state.comparator.splitDetail && this.state.comparator.splitDetail.levels.length > 0 &&
									<>
										<div className={classes.priceSplitContainer}>
											<div>
												<TextField
													type={'number'}
													value={this.state.comparator.splitDetail.idealNumberProducts ?? ''}
													label={'Ideale Anzahl von Produkten pro Vergleicher:'}
													onChange={this.changeValue.bind(this, 'ideal')}
													disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators}
													style = {{width: 330}}
												/>
											</div>
										</div>
										<div className={classes.openPreviewButton}>
											<Button
												variant="contained"
												color="primary"
												onClick={this.openPriceSplittedPreview.bind(this)}
												disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators}
											>
											Preis-aufteilung ansehen
											</Button>
											{this.checkLessThan3Products('price') &&
									<span className={classes.warning}>
										<Tooltip title={
											<span>Mindestens einer der Produktvergleicher enthält weniger als 3 Produkte. Daher wird der Hauptvergleicher verwendet.</span>
										}>
											<WarningIcon/>
										</Tooltip>
									</span>
											}
										</div>
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<RefreshIcon color="secondary"/>}
											className={classes.refreshButton}
											onClick={this.refreshData.bind(this)}
											disabled={this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators}
										>
										Aktualisieren
										</Button>
									</>
								}
								{(this.state.loadingSplittedComparators || this.state.loadingPriceSplittedComparators) &&
									<CircularProgress className={classes.loadingIndicator}/>
								}
							</>
						}
						{this.props.comparator && this.props.comparator.products.length === 0 &&
								<Typography>{'Bitte zuerst Produkte auswählen'}</Typography>
						}
					</React.Fragment>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withDialog()(withStyles(editorSplitSettingsStyles)(EditorSplitSettings));
