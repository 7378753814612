import React, { ReactNode, Component } from 'react';

import {
	Tooltip,
	Link,
	WithStyles,
	withStyles
} from '@material-ui/core';

import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';

import { AnalyticsModel } from '@models/AnalyticsModel';
import { Product } from '@models/Product';
import { Comparator } from '@models/Comparator';

import analyticsDetailsStyles from './AnalyticsDetailsStyles';

interface ComparatorResponse extends Response {
	success: boolean;
	data: Comparator;
	message?: string;
}

interface Props extends WithStyles<typeof analyticsDetailsStyles> {
	analytics: AnalyticsModel;
}

interface State {
	comparator: Comparator | null;
	products: Product[];
}

class AnalyticsDetails extends Component<Props, State> {
	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.productsService = new ProductsService();

		this.state = {
			comparator: null,
			products: []
		};

		this.loadComparator(props.analytics.ComparatorID);
	}

	private loadComparator(comparatorId: string): void {
		this.setState({comparator: null, products: []});

		this.authService.fetch<ComparatorResponse>(`/api/comparators/${comparatorId}`, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				if (response.data) {
					const comparator = response.data;
					this.setState({comparator: comparator, products: comparator.products});
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private clicksForProduct(productId: string): number {
		let result = 0;

		if (this.props.analytics[`${productId}:Clicks`] && typeof this.props.analytics[`${productId}:Clicks`] === 'number') {
			result = this.props.analytics[`${productId}:Clicks`] as number;
		}

		return result;
	}

	private renderProduct(product: Product): ReactNode {
		const classes = this.props.classes;

		return (
			<div key={product.productCode} className={classes.rowContainer}>
				<span className={classes.clickContainer}>
					{this.clicksForProduct(product.productCode)}
				</span>
				<Tooltip
					placement="right"
					title={
						<React.Fragment>
							{product.images.length > 0 &&
								<img src={`${product.images[0]}?maxH=280&maxW=280&upscale=false`} alt=""/>
							}
						</React.Fragment>
					}
				>
					<span className={classes.imageContainer}>
						{product.images.length > 0 &&
							<img src={`${product.images[0]}?maxH=28&maxW=28&upscale=false`} alt=""/>
						}
					</span>
				</Tooltip>
				<span className={classes.codeContainer}>
					{product.productCode}
				</span>
				<span className={classes.nameContainer}>
					<Link
						href={`https://moebelix.at/p/${product.productCode}`}
						target="_blank"
						rel="noreferrer"
					>
						{product.name}
					</Link>
				</span>
				<span className={classes.priceContainer}>
					{product.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
				</span>
			</div>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.state.comparator || this.state.products.length === 0) {
			return (<div />);
		}

		this.state.products.sort((a, b): number => {
			return this.clicksForProduct(b.productCode) - this.clicksForProduct(a.productCode);
		});

		return (
			<div className={classes.root}>
				{this.state.products.map((product): ReactNode => this.renderProduct(product))}
			</div>
		);
	}
}

export default withStyles(analyticsDetailsStyles)(AnalyticsDetails);
