import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const crawlerStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	fab: {
		margin: '0px',
    	top: 'auto',
    	right: '20px',
    	bottom: '20px',
    	left: 'auto',
    	position: 'fixed'
	},
	fabMerge: {
		margin: '0px',
    	top: 'auto',
    	right: '90px',
    	bottom: '20px',
    	left: 'auto',
    	position: 'fixed'
	},
});

export default crawlerStyles;
