import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryMultiSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginBottom: theme.spacing(3),
		width: '100%'
	},
});

export default categoryMultiSelectStyles;
