import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorOverviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
		marginBottom: theme.spacing(8),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	addButton: {
		right: 20,
		bottom: 20,
		position: 'fixed',
	},
	addMultipleButton: {
		right: 90,
		bottom: 20,
		position: 'fixed',
	},
	addAutomatedButton: {
		right: 160,
		bottom: 20,
		position: 'fixed',
	},
	mergeComparatorButton: {
		right: 230,
		bottom: 20,
		position: 'fixed',
	},
	categoryContainer: {
		display: 'flex',
		padding: theme.spacing(1, 0.5),
	},
	categoryTitle: {
		flexGrow: 1,
	},
	categoryCount: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		marginTop: 3,
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	comparatorContainer: {
		padding: theme.spacing(1, 0.5),
		display: 'flex',
	},
	comparatorName: {
		color: theme.palette.primary.main,
		flexGrow: 1,
		alignSelf: 'center'
	},
	deleteButton: {
		float: 'right',
	},
	offlineBubble: {
		backgroundColor: theme.palette.error.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		marginRight: theme.spacing(1),
		fontSize: 13,
		alignSelf: 'center'
	},
	attributesBubble: {
		backgroundColor: 'orange',
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	productSumBubble: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.background.paper,
		padding: theme.spacing(0, 1),
		borderRadius: 9,
		height: 18,
		alignSelf: 'center',
		marginRight: theme.spacing(1),
		fontSize: 13,
	},
	filterSelect: {
		marginLeft: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	newContentButton: {
		right: 100,
		position: 'absolute',
	},
	editContentButton: {
		right: 100,
		position: 'absolute',
	},
	warning: {
		marginRight: '4px',
		alignSelf: 'center'
	},
	comparatorCheckbox: {
		padding: 3,
		marginRight: theme.spacing(1)
	},
	mergeIcon: {
		marginRight: theme.spacing(1)
	},
	splitIcon: {
		marginRight: theme.spacing(1)
	}
});

export default comparatorOverviewStyles;
