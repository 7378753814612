import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorAnswerMultiSelectStyles = (theme: Theme): any => createStyles({
	root: {
		width: '100%',
	},
	select: {
		marginTop: theme.spacing(0.5)
	}
});

export default advisorAnswerMultiSelectStyles;
