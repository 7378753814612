import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const overviewChartStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(10),
	},
	crosshair: {
		minWidth: '200px',
		backgroundColor: 'black',
		padding: theme.spacing(1),
	},
	crosshairTitle: {
		marginTop: 0,
		marginBottom: 0,
	},
	crosshairLine: {
		marginTop: 0,
		marginBottom: 0,
	},
	crosshairMethod: {},
	crosshairValue: {
		float: 'right',
	}
});

export default overviewChartStyles;
