import React, { ReactNode, Component } from 'react';
import {
	WithStyles,
	withStyles
} from '@material-ui/core';

import categoryTreeStyles from './CategoryTreeStyles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import CategoryTreeItemLabel from './CategoryTreeItemLabel';

import { Category } from '@models/Category';

interface Props extends WithStyles<typeof categoryTreeStyles> {
	categories: Category[];
}

class CategoryTree extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	private createChildTreeNodes(category: Category): ReactNode {
		return (
			<TreeItem key={category.code} nodeId={category.code} label={<CategoryTreeItemLabel category={category} />}>
				{category.children && category.children.length > 0 &&
					category.children.map((category: Category): ReactNode => (
						this.createChildTreeNodes(category)
					))
				}
			</TreeItem>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<>
				<div className={classes.top} />
				<TreeView
					defaultCollapseIcon={<ExpandMoreIcon />}
					defaultExpandIcon={<ChevronRightIcon />}
					className={classes.categoryTree}
				>
					{this.props.categories.map((category: Category): ReactNode => (
						<TreeItem key={category.code} nodeId={category.code} label={<CategoryTreeItemLabel key={category.code} category={category} />}>
							{category.children && category.children.length > 0 &&
							category.children.map((child: Category): ReactNode => (
								this.createChildTreeNodes(child)
							))
							}
						</TreeItem>
					))}
				</TreeView>
			</>
		);
	}
}

export default withStyles(categoryTreeStyles)(CategoryTree);
