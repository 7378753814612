import React, { ReactNode, Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';

import CategorySelect from '../Common/CategorySelect';
import { Category } from '@models/Category';
import ShoppingProducts from './ShoppingProducts';

import shoppingStyles from './ShoppingStyles';

interface State {
	keyword: string | null;
}



class Shopping extends Component<WithStyles<typeof shoppingStyles>, State> {
	private authService: AuthService;

	public constructor(props: WithStyles<typeof shoppingStyles>) {
		super(props);

		this.authService = new AuthService();
		this.state = {
			keyword: null
		};
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Google Shopping Resultate'}
				</Typography>
				<CategorySelect
					onChange={this.handleCategoryChange.bind(this)}
					selectedCategoryCode=""
					topLevelCategoriesExcluded={false}
					disabled={false}
				/>
				{ this.state.keyword &&
					<ShoppingProducts keyword={this.state.keyword} />
				}
			</Paper>
		);
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		this.setState({keyword: (selectedCategory ? selectedCategory.name : null)});
	}
}

export default withStyles(shoppingStyles)(Shopping);
