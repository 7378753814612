/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Button,
	Divider,
	Link,
	List,
	ListItem,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import { AutomatedComparator } from '@models/AutomatedComparator';
import { ProductStatus } from '@models/Product';
import ComparatorProductPreview from '../../Comparator/ComparatorProductPreview';
import WarningIcon from '@material-ui/icons/Warning';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import automatedEditorToMergeOverviewStyles from './AutomatedEditorToMergeOverviewStyles';



interface State {
	editorOpen: boolean;
	comparatorId: string;
}

interface Props extends WithStyles<typeof automatedEditorToMergeOverviewStyles> {
	automatedComparators: AutomatedComparator[];
}

class AutomatedEditorToMergeOverview extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			editorOpen: false,
			comparatorId: ''
		};
	}


	private offlineProductSum(comparator: Comparator | AutomatedComparator): number {
		return comparator.products.filter((p): boolean => p.status === ProductStatus.Offline).length;
	}

	private handleClickOpenEdit(id: string): void {
		this.setState({editorOpen: true, comparatorId: id});
	}

	private handleClose(): void {
		this.setState({editorOpen: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<ExpansionPanel defaultExpanded={true}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Ausgewähle automatisierte Produktvergleicher</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<List>
						{this.props.automatedComparators.map((automatedComparator: AutomatedComparator) => {
							return (
								<div key={automatedComparator._id}>
									<ListItem >

										<div className={classes.comparatorName}>
											<Link target={'_blank'} href={`/automated-comparator/${automatedComparator._id}/edit`}>{automatedComparator.nameBackend ? automatedComparator.nameBackend : automatedComparator.name} (ID: a{automatedComparator._id}, {automatedComparator.active ? 'Aktiv' : 'Inaktiv'})</Link>
										</div>

										{((automatedComparator.products.length - this.offlineProductSum(automatedComparator)) < 2) &&
								<span className={classes.warning}>
									<Tooltip title={
										<span>Der Produktvergleicher enthält weniger als 2 online Produkte und wird daher nicht angezeigt.</span>
									}>
										<WarningIcon/>
									</Tooltip>
								</span>
										}
										{this.offlineProductSum(automatedComparator) > 0 &&
									<span className={classes.offlineBubble}>{`Offline: ${this.offlineProductSum(automatedComparator)}/${automatedComparator.products.length}`}</span>
										}
										{automatedComparator.missingCount > 0 &&
									<span className={classes.attributesBubble}>{`Attribute: ${automatedComparator.missingCount}/${automatedComparator.products.length}`}</span>
										}
										<span className={classes.productSumBubble}>{`Produkte: ${automatedComparator.products.length}`}</span>
										<Button variant="outlined" color="secondary" size="small" onClick={this.handleClickOpenEdit.bind(this, automatedComparator._id)}>Vorschau</Button>
									</ListItem>
									<Divider />
								</div>
							);
						})}
						<ComparatorProductPreview
							closeHandler={this.handleClose.bind(this)}
							editorOpen={this.state.editorOpen}
							comparatorId={this.state.comparatorId}
							isAutomated={true}
						/>
					</List>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(automatedEditorToMergeOverviewStyles)(AutomatedEditorToMergeOverview);
