import React, { ReactNode, Component } from 'react';
import {
	Paper,
	Typography,
	Button,
	WithStyles,
	withStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Fab,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AuthService from '@services/AuthService';
import UserEditor from './UserEditor';
import userAdministrationStyles from './UserAdministrationStyles';

import { UserModel, translatePermission } from '@models/User';

interface State {
	users: UserModel[];
	userEditorOpen: boolean;
	user: UserModel | null;
}

interface UserResponse extends Response {
	success: boolean;
	data: UserModel[];
	message?: string;
}

class UserAdministration extends Component<WithStyles<typeof userAdministrationStyles>, State> {
	private authService: AuthService;
	public constructor(props: WithStyles<typeof userAdministrationStyles>) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			users: [],
			userEditorOpen: false,
			user: null,
		};
		this.loadUsers();
	}

	private loadUsers(): void {
		this.authService.fetch<UserResponse>('/api/users', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				if (response.data) {
					const tempUsers = response.data;
					for (const user of tempUsers){
						user.permissions = this.getSortedPermissions(user.permissions);
					}
					this.setState({users: tempUsers});
				} else {
					this.setState({users: []});
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private getSortedPermissions(inputPermissions: string[]): string[] {
		return inputPermissions.sort((r1, r2): number => {
			if (r1 > r2) {
				return 1;
			} else if (r1 < r2) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	private handleClickOpenEdit(user: UserModel | null): void {
		this.setState({
			userEditorOpen: true,
			user: user

		});
	};

	private handleClose(): void {
		this.setState({
			userEditorOpen: false
		});
		this.loadUsers();
	};

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h1" variant="h4" className={classes.title}>
				Benutzerverwaltung
				</Typography>
				<Table className={classes.table} size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left">Benutzer</TableCell>
							<TableCell align="center">Administrator</TableCell>
							<TableCell align="left">Rechte</TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.users.map((user: UserModel): ReactNode => (
							<TableRow className={classes.row} key={user._id} >
								<TableCell component="th" scope="row">
									{user.username}
								</TableCell>
								<TableCell align="center">{(user.permissions.includes('admin')) ? <CheckCircleIcon color="primary" /> : ''}</TableCell>
								<TableCell align="left">{(user.permissions.includes('admin')) ? 'Administrator' : user.permissions.map(p => translatePermission(p)).join(', ')}</TableCell>
								<TableCell align="left">{
									<Button className={classes.editButton} onClick={this.handleClickOpenEdit.bind(this, user)} color="primary" startIcon={<EditIcon color="primary"/>}>
										 Bearbeiten
									</Button>
								}
								</TableCell>
							</TableRow>
						))}

					</TableBody>
				</Table>
				<Fab aria-label='Add' className={classes.fab} color='primary' onClick={this.handleClickOpenEdit.bind(this, null)}>
					<AddIcon />
				</Fab>
				<UserEditor
					user = {this.state.user}
					closeHandler = {this.handleClose.bind(this)}
					userEditorOpen = {this.state.userEditorOpen}
				/>
			</Paper>
		);
	}
}

export default withStyles(userAdministrationStyles)(UserAdministration);
