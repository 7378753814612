import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorPreviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	container: {
		width: '100%',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	desktopPreview: {
		border: 'none',
		width: '100%',
		maxWidth: '980px',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	mobilePreview: {
		border: 'none',
		width: '320px',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	saveButton: {
		float: 'right',
	},
	comparatorPreviewCountContainer: {
		alignSelf: 'center'
	},
	numberOfProductsTooltip: {
		display: 'inline-flex',
		verticalAlign: 'bottom',
		marginLeft: '8px'
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		
	},
	refreshButton: {

	},
	mergeSaveButtonContainer: {

	},
	saveDeleteButton: {

	}
});

export default comparatorPreviewStyles;
