import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { AutomatedComparator } from '@models/AutomatedComparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ComparatorAttributeSelect from '../../Comparator/ComparatorAttributeSelect';
import { Product } from '@common/models/Product';
import automatedEditorAttributeSettingsStyles from './AutomatedEditorAttributeSettingsStyles';

interface Props extends WithStyles<typeof automatedEditorAttributeSettingsStyles> {
	automatedComparator: AutomatedComparator;
	handleAttributesChange: (selectedAttributes: string[]) => void;
	productsByRules: Product[];
	refreshed: boolean;
	loadingProducts: boolean;
}

class AutomatedEditorAttributeSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.automatedComparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.automatedComparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Attribute</Typography>
					{(this.props.refreshed && !this.props.loadingProducts) ? <Typography className={classes.panelSubtitle}>{this.props.automatedComparator.attributes.length} Attribute ausgewählt</Typography> : ''}
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>

					<ComparatorAttributeSelect
						onChange={this.props.handleAttributesChange.bind(this)}
						onChangeDelimiterRemoveAttributes={null}
						selectedProducts={this.props.productsByRules}
						selectedAttributeCodes={this.props.automatedComparator.attributes}
						sortingEnabled={true}
						templatesEnabled={true}
						maintainedEnabled={true}
						type={'automatedComparator'}
						categoryFeed={false}
					/>

				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(automatedEditorAttributeSettingsStyles)(AutomatedEditorAttributeSettings);
