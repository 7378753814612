import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorAttributeSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginBottom: theme.spacing(3),
	},
	container: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	attributeContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '66%',
		borderRight: `1px solid ${theme.palette.primary.main}`,
		alignContent: 'flex-start',
	},
	attributeContainerSolo: {
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		borderRight: 'none',
		alignContent: 'flex-start',
	},
	selectedAttributeContainer: {
		width: '33%',
		paddingLeft: theme.spacing(2),
	},
	subtitle: {
		width: '100%',
		marginBottom: theme.spacing(1),
		marginTop: theme.spacing(1),
	},
	attributeCheckbox: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '33%',
		minWidth: 300,
		maxWidth: '33%',
		margin: 0,
	},
	checkbox: {
		padding: theme.spacing(0.5),
	},
	nameContainer: {
		flexGrow: 1,
		flexShrink: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		minWidth: 0,
		whiteSpace: 'nowrap',
		height: 30,
		width: 0,
		lineHeight: '250%',
		marginRight: theme.spacing(1),
	},
	occurrenceContainer: {
		marginRight: theme.spacing(2),
		lineHeight: '250%',
		display: 'inline-block',
		width: 75,
		textAlign: 'right'
	},
	occurrenceContainerSelected: {
		marginRight: theme.spacing(2),
		paddingRight: '5px',
		lineHeight: '250%',
		display: 'inline-block',
		width: 75,
		textAlign: 'right',
		cursor: 'pointer',
		color: theme.palette.secondary.main,
 	},
	label: {
		width: 'calc(100% - 32px)',
		display: 'flex',
	},
	labelHidden: {
		color: theme.palette.grey[500],
	},
	dragContainer: {
		border: `1px solid ${theme.palette.primary.main}`,
	},
	dragableAttribute: {
		cursor: 'grab',
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1),
		width: '100%',
		border: `1px solid ${theme.palette.primary.main}`,
		display: 'flex',
	},
	dragableAttributeHidden: {
		color: theme.palette.grey[500],
	},
	switchContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: '100%',
	},
	switchAll: {
		display: 'inline-block',
		width: '58.5%'
	},
	switchExplainationContainer: {
		display: 'inline-block',
		width: '41.5%'
	},
	switchExplaination: {
		border: '1px solid #CCCCCC',
		borderRadius: 4,
	}
});

export default comparatorAttributeSelectStyles;
