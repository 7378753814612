import React, {ReactElement, ReactNode, FC, useEffect, useCallback} from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@material-ui/core';

import {useDrop} from 'react-dnd';

import TreeView from '@material-ui/lab/TreeView';
import EditableTreeNodes from './EditableTreeNodes';
import CategoryMappingEditor from './CategoryMappingEditor';

import AuthService from '@services/AuthService';

import { Category, EditableCategory, KioskType } from '@models/Category';

interface CategoryProp {
	categories: Category[];
	authService: AuthService;
	kioskType: KioskType;
}

interface EditableCategoriesResponse {
	data: EditableCategory[];
	success: boolean;
	message?: string;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		topDropArea: {
			width: '100%',
			height: '3vh',
		},
		topDropAreaColor: {
			width: '100%',
			height: '3vh',
			backgroundColor: theme.palette.secondary.main,
			opacity: 0.6
		},
		editableCategoryTree: {
			paddingLeft: '8px',
		},
		bottomDropArea: {
			width: '100%',
			height: '25vh'
		},
		bottomDropAreaColor: {
			width: '100%',
			height: '25vh',
			backgroundColor: theme.palette.secondary.main,
			opacity: 0.6
		},
		addButton: {
			float: 'right',
			marginRight: theme.spacing(2),
			marginTop: '-36px'
		}
	};
});

const EditableCategoryTree: FC<CategoryProp> = (props: CategoryProp): ReactElement => {
	const classes = useStyles();
	const [editableCategoriesToSave, setEditableCategoriesToSave] = React.useState<EditableCategory[]>([]);
	const [mainCategoryEditorOpen, setMainCategoryEditorOpen] = React.useState(false);

	const loadEditableCategories = useCallback(() => {
		props.authService.fetch<EditableCategoriesResponse>(`/api/editable-categories/${props.kioskType}`, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				setEditableCategoriesToSave(response.data);
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	},
	[props.authService, props.kioskType]
	);

	useEffect(() => {
		loadEditableCategories();

	}, [loadEditableCategories]);

	const ItemTypes = {
		Category: 'category'
	};

	const [{isOver, canDrop}, dropTop] = useDrop({
		accept: ItemTypes.Category,
		drop: (item, _) => addMainEditableCategory(item),
		collect: monitor  => ({
			isOver: !!monitor.isOver({ shallow: true }),
			canDrop: monitor.canDrop(),
		})
	});

	const [{isOverBottom, canDropBottom}, dropBottom] = useDrop({
		accept: ItemTypes.Category,
		drop: (item, _) => addMainEditableCategory(item),
		collect: monitor  => ({
			isOverBottom: !!monitor.isOver({ shallow: true }),
			canDropBottom: monitor.canDrop(),
		}),

	});

	const createEditableCategory = (editableCategory: EditableCategory): void => {
		props.authService.fetch<EditableCategory>('api/editable-categories/new', {
			method: 'POST',
			body: JSON.stringify(editableCategory)
		}).then((_): void => {
			loadEditableCategories();
		});
	};

	const deleteEditableCategory = (editableCategory: EditableCategory): void => {
		props.authService.fetch<EditableCategory>(`api/editable-categories/${editableCategory._id}`, {
			method: 'DELETE',
			body: JSON.stringify(editableCategory)
		}).then((_): void => {
			loadEditableCategories();
		});
	};

	const updateEditableCategory = (editableCategory: EditableCategory): void => {
		props.authService.fetch<EditableCategory>(`api/editable-categories/${editableCategory._id}`, {
			method: 'POST',
			body: JSON.stringify(editableCategory)
		}).then((_): void => {
			loadEditableCategories();
		});
	};

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const addMainEditableCategory = (item: any): void => {
		let articleName = '';
		if (item.articleName && item.articleName.length > 0) {
			articleName = item.articleName;
		}
		const editableCategory: EditableCategory  = {
			categoryName: item.categoryName,
			image: item.image,
			childCategories: [],
			sortPosition: editableCategoriesToSave.length,
			kioskType: props.kioskType,
			articleName: articleName,
			subgroups: []
		};

		if (props.kioskType === KioskType.SMARTTERMINAL) {
			editableCategory.smartTerminalAdded = true;
		}

		if (item.categoryCode) {
			editableCategory.categoryCode = item.categoryCode;
		}

		if (!hasCategory(editableCategoriesToSave, editableCategory)) {
			createEditableCategory(editableCategory);
		}
	};

	const undoDeleteMainEditableCategory = (editableCategoryToDelete: EditableCategory): void => {
		editableCategoryToDelete.smartTerminalDeleted = false;
		updateEditableCategory(editableCategoryToDelete);

	};

	const deleteMainEditableCategory = (editableCategoryToDelete: EditableCategory): void => {
		console.log(editableCategoryToDelete);
		if (editableCategoryToDelete.kioskType === KioskType.SMARTTERMINAL && !editableCategoryToDelete.smartTerminalAdded) {
			editableCategoryToDelete.smartTerminalDeleted = true;
			updateEditableCategory(editableCategoryToDelete);
		} else {
			deleteEditableCategory(editableCategoryToDelete);

			// Change sort position of other editable categories if one editable category gets deleted
			if (editableCategoryToDelete.sortPosition !== (editableCategoriesToSave.length - 1)) {
				for (const editableCategory of editableCategoriesToSave) {
					if (editableCategory.sortPosition > editableCategoryToDelete.sortPosition) {
						const editableCategoryToChangePosition = JSON.parse(JSON.stringify(editableCategory));
						editableCategoryToChangePosition.sortPosition -= 1;
						updateEditableCategory(editableCategoryToChangePosition);
					}
				}
			}
		}
	};

	const notifyParent = (editableCategoryToUpdate: EditableCategory): void => {
		for (const currentCategory of editableCategoriesToSave) {
			if (currentCategory._id === editableCategoryToUpdate._id) {
				updateEditableCategory(editableCategoryToUpdate);
			}
		}
	};

	const onChangePosition = (editableCategoryClicked: EditableCategory, moveUp: boolean): void => {
		// Get current position of editable category to replace
		let editableCategoryToReplacePosition = editableCategoryClicked.sortPosition + 1;
		if (moveUp) {
			editableCategoryToReplacePosition = editableCategoryClicked.sortPosition - 1;
		}

		// Change sort position of editable category to replace
		for (const editableCategory of editableCategoriesToSave) {
			if (editableCategory.sortPosition === editableCategoryToReplacePosition) {
				const editableCategoryToReplace = JSON.parse(JSON.stringify(editableCategory));
				if (moveUp) {
					editableCategoryToReplace.sortPosition += 1;
				} else {
					editableCategoryToReplace.sortPosition -= 1;
				}
				updateEditableCategory(editableCategoryToReplace);
			}
		}

		// Change sort Position of clicked editable category
		const editableCategoryChangedPosition = JSON.parse(JSON.stringify(editableCategoryClicked));
		if (moveUp) {
			editableCategoryChangedPosition.sortPosition -= 1;
		} else {
			editableCategoryChangedPosition.sortPosition += 1;
		}
		updateEditableCategory(editableCategoryChangedPosition);
	};

	const hasCategory = (categories: EditableCategory[], category: EditableCategory): boolean => {
		if (!category.categoryCode) {
			return false;
		}
		for (const currentCategory of categories) {
			if (currentCategory.categoryCode === category.categoryCode) {
				return true;
			}
		}
		return false;
	};

	const addSelfDefinedMainCategory = (): void => {
		setMainCategoryEditorOpen(true);
	};

	const closeEditor = (): void => {
		setMainCategoryEditorOpen(false);
	};

	return (
		<>
			<div className={classes.addButton}>
				<Button
					variant="outlined"
					color="secondary"
					size="small"
					startIcon={<AddIcon />}
					onClick={addSelfDefinedMainCategory}
				>
				Hauptkategorie
				</Button>
			</div>
			<div ref={dropTop} className={classes.topDropArea} />
			<TreeView
				defaultCollapseIcon={<ExpandMoreIcon />}
				defaultExpandIcon={<ChevronRightIcon />}
				className={classes.editableCategoryTree}
			>
				{editableCategoriesToSave.map((editableCategory: EditableCategory): ReactNode => (
					<EditableTreeNodes
						key={editableCategory._id}
						editableCategory={editableCategory}
						editableCategoryParent={editableCategory}
						onChange={notifyParent}
						onDelete={deleteMainEditableCategory}
						onUndoDelete={undoDeleteMainEditableCategory}
						level={1}
						categories={props.categories}
						hasCategory={hasCategory}
						categoriesCount={editableCategoriesToSave.length}
						onChangePosition={onChangePosition}
						kioskType={props.kioskType}
					/>
				))}
			</TreeView>
			<div ref={dropBottom} className={(isOver && canDrop) || (isOverBottom && canDropBottom) ? classes.bottomDropAreaColor : classes.bottomDropArea} />
			<CategoryMappingEditor
				editorOpen={mainCategoryEditorOpen}
				closeHandler={closeEditor}
				editableCategory={null}
				changeHandler={addMainEditableCategory}
				categories={props.categories}
			/>
		</>
	);
};

export default EditableCategoryTree;
