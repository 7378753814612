import React, { ReactNode, Component, CSSProperties } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	WithStyles,
	withStyles
} from '@material-ui/core';
import automatedComparatorRulesSelectStyles from './AutomatedComparatorRulesSelectStyles';
import { AutomatedComparator, RuleGroup } from '@models/AutomatedComparator';
import RuleGroupTable from './RuleGroupTable';
import Select, { ValueType } from 'react-select';
import AuthService from '@services/AuthService';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface OptionType {
	label: string;
	value: AutomatedComparator;
};

interface AutomatedComparatorsResponse {
	data: AutomatedComparator[];
	success: boolean;
	message?: string;
}

interface Props extends WithStyles<typeof automatedComparatorRulesSelectStyles> {
	dialogOpen: boolean;
	closeHandler: () => void;
	dialog?: ConfirmDialog;
	handleRulesChange: (ruleGroup: RuleGroup) => void;
	currentRules: RuleGroup;
}

interface State {
	automatedComparators: AutomatedComparator[];
	selectedAutomatedComparator: AutomatedComparator | null;
	isLoading: boolean;
	options: OptionType[];
}

class AutomatedComparatorRulesSelect extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			automatedComparators: [],
			selectedAutomatedComparator: null,
			isLoading: false,
			options: []
		};
	}

	public componentDidMount(): void {
		this.loadAutomatedComparators();
	}

	private saveButtonClicked(): void {
		const dialog = this.props.dialog;

		if (this.props.currentRules.rules.length > 0 || this.props.currentRules.ruleGroups.length > 0) {
			if (dialog) {
				dialog.confirm({
					title: 'Regeln auswählen und ersetzen',
					message: `Sollen die bestehenden Regeln wirklich durch die Regeln des Produktvergleichers a${this.state.selectedAutomatedComparator ? this.state.selectedAutomatedComparator._id : ''} ersetzt werden?`,
					ok: {
						text: 'Ersetzen',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					if (this.state.selectedAutomatedComparator) {
						this.props.handleRulesChange(this.state.selectedAutomatedComparator.rules);
					}
					this.props.closeHandler();
				}).catch((error): void => {
					console.log(`Error while replacing automated comparator rules: ${error}`);
				});
			}
		} else {
			if (this.state.selectedAutomatedComparator) {
				this.props.handleRulesChange(this.state.selectedAutomatedComparator.rules);
			}
			this.props.closeHandler();
		}
	}

	private cancelButtonClicked(): void {
		this.props.closeHandler();
	}

	private loadAutomatedComparators(): void {
		this.setState({isLoading: true});
		this.authService.fetch<AutomatedComparatorsResponse>('/api/automated-comparators/rules', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {

				const options: OptionType[] = [];
				for (const automatedComparator of response.data) {
					options.push({ value: automatedComparator, label: 'a' + automatedComparator._id + ' - ' +  (automatedComparator.nameBackend ? automatedComparator.nameBackend : automatedComparator.name)});
				}
				this.setState({automatedComparators: response.data, options: options});

			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
			this.setState({isLoading: false});
		});
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleAutomatedComparatorChange(option: any): void {
		this.setState({
			selectedAutomatedComparator: option.value,
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;


		let option: ValueType<OptionType> | null = null;
		if (this.state.selectedAutomatedComparator) {
			option = {
				label: 'a' + this.state.selectedAutomatedComparator._id + ' - ' +  (this.state.selectedAutomatedComparator.nameBackend ? this.state.selectedAutomatedComparator.nameBackend : this.state.selectedAutomatedComparator.name),
				value: this.state.selectedAutomatedComparator
			};
		}

		return (
			<Dialog open={this.props.dialogOpen} maxWidth={'lg'} onClose={this.props.closeHandler} fullWidth={true}>
				<DialogTitle id="form-dialog-title">Regeln ersetzen</DialogTitle>
				<DialogContent>
					<Select
						value={option}
						onChange={(option): void => { this.handleAutomatedComparatorChange(option); }}
						options={this.state.options}
						placeholder={'Automatisierten Produktvergleicher auswählen...'}
						noOptionsMessage={(): string => ('Keine Treffer')}
						menuPortalTarget={document.body}
						styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
						isSearchable
						isLoading={this.state.isLoading}
						loadingMessage={(): string => ('Wird geladen...')}
					>
					</Select>
					{this.state.selectedAutomatedComparator &&

					<div className={classes.rulesContainer}>
						<RuleGroupTable
							ruleGroup={this.state.selectedAutomatedComparator.rules}
							selectedCategoryCode={this.state.selectedAutomatedComparator.categoryCode}
							isTopLevel={true}
							editable={false}
							loading={false}
						/>
					</div>
					}
				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					<Button onClick={this.cancelButtonClicked.bind(this)} color="primary">
					Abbrechen
					</Button>
					<Button disabled={!this.state.selectedAutomatedComparator} onClick={this.saveButtonClicked.bind(this)} color="primary">
					Anwenden
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withDialog()(withStyles(automatedComparatorRulesSelectStyles)(AutomatedComparatorRulesSelect));
