import React, { ReactNode, Component } from 'react';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
	Typography,
	Paper,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	CircularProgress,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import comparatorEditorStyles from './ComparatorEditorStyles';

import AuditLogsTable from '../AuditLogs/AuditLogsTable';

import { Category } from '@models/Category';
import { Product, ProductAttribute, ProductStatus } from '@models/Product';
import { Comparator } from '@models/Comparator';
import { MergeDetail, SplitDetail } from '@models/AutomatedComparator';

import AuthService from '@services/AuthService';

import EditorGeneralSettings from './ComparatorEditorComponents/EditorGeneralSettings';
import EditorProductSettings from './ComparatorEditorComponents/EditorProductSettings';
import EditorSplitSettings from './ComparatorEditorComponents/EditorSplitSettings';
import EditorAttributeSettings from './ComparatorEditorComponents/EditorAttributeSettings';
import EditorPreview from './ComparatorEditorComponents/EditorPreview';
import EditorToMergeOverview from './ComparatorEditorComponents/EditorToMergeOverview';

interface Params {
	comparatorId: string;
}

interface OptionType {
	label: string;
	value: string;
};

interface ComparatorResponse extends Response {
	success: boolean;
	data: Comparator;
	message?: string;
}

interface ComparatorsResponse extends Response {
	success: boolean;
	data: Comparator[];
	message?: string;
}

interface Props extends WithStyles<typeof comparatorEditorStyles>, RouteComponentProps<Params>, WithSnackbarProps {
	dialog?: ConfirmDialog;
	merge: boolean;
}

interface State {
	comparator: Comparator;
	savedComparator: Comparator;
	loading: boolean;
	saving: boolean;
	selectedCategoryCode: string;
	selectedCategoryName: string;
	selectedToMergeComparators: Comparator[];
}

class ComparatorEditor extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		const comparatorId = this.props.match.params.comparatorId;

		const dummyMergeDetail: MergeDetail = {
			mergeComparator: this.props.merge ? true : false,
			mergeComparators: []
		};

		const dummyComparator: Comparator = {
			_id: comparatorId,
			active: true,
			name: 'Alternativen im Vergleich',
			nameBackend: '',
			position: '',
			fullPage: false,
			sortedBy: 1,
			sortWeight: 1,
			products: [],
			attributes: [],
			missingCount: 0,
			categoryCode: '',
			mergeDetail: dummyMergeDetail,
			splitDetail: this.getDummySplitDetails()
		};

		if (this.props.merge && this.props.location.state) {
			//eslint-disable-next-line @typescript-eslint/no-explicit-any
			const comparators = (this.props.location.state as any);
			dummyComparator.categoryCode = comparators[0].categoryCode;
			for (const comparator of comparators) {
				dummyComparator.mergeDetail.mergeComparators.push(comparator._id);
				for (const product of comparator.products) {
					if (dummyComparator.products.filter(p => p.productCode === product.productCode).length === 0) {
						dummyComparator.products.push(product);
					}
				}
				for (const attribute of comparator.attributes) {
					if (!dummyComparator.attributes.includes(attribute)) {
						dummyComparator.attributes.push(attribute);
					}
				}
			}
		}

		this.state = {
			comparator: dummyComparator,
			savedComparator: dummyComparator,
			loading: (comparatorId !== 'new'),
			saving: false,
			selectedCategoryCode: dummyComparator.categoryCode,
			selectedCategoryName: '',
			selectedToMergeComparators: []
		};

		this.loadComparator(comparatorId);
		if (this.props.merge) {
			this.loadSelectedComparators();
		}
	}

	public componentDidMount(): void {
		window.onbeforeunload = (): (boolean | undefined) => {
			return (this.isDirty()) ? true : undefined;
		};
	}

	public componentWillUnmount(): void {
		window.onbeforeunload = null;
	}

	private getDummySplitDetails(): SplitDetail {
		const dummySplitDetail: SplitDetail = {
			levels: [],
			idealNumberProducts: 10
		};

		return dummySplitDetail;
	}

	private loadComparator(comparatorId: string): void {
		if (comparatorId !== 'new') {
			this.authService.fetch<ComparatorResponse>(`/api/comparators/${comparatorId}`, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					if (response.data) {
						const comparator = response.data;
						if (!comparator.splitDetail) {
							comparator.splitDetail = this.getDummySplitDetails();
						}
						this.setState({comparator: comparator, savedComparator: comparator, selectedCategoryCode: comparator.categoryCode, loading: false}, () => {
							if (this.state.comparator.mergeDetail && this.state.comparator.mergeDetail.mergeComparator) {
								this.loadSelectedComparators();
							}
						});
					}
				} else if (response.message) {
					throw new Error(response.message);
				} else {
					throw new Error('Unkown Error');
				}
			});
		}
	}

	private loadSelectedComparators(): void {
		this.authService.fetch<ComparatorsResponse>('/api/comparators/ids', {
			method: 'POST',
			body: JSON.stringify(this.state.comparator.mergeDetail.mergeComparators)
		}).then((response): void => {
			if (response.success) {
				if (response.data) {
					this.setState({selectedToMergeComparators: response.data});
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private deleteComparatorButtonPressed(): void {
		const dialog = this.props.dialog;
		const comparator = this.state.comparator;

		if (dialog) {
			dialog.confirm({
				title: 'Produktvergleicher Löschen',
				message: `Soll der Produktvergleicher "${comparator.name}" (ID: ${comparator._id}) wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<{}>(`/api/comparators/${comparator._id}`, {
					method: 'DELETE',
				}).then((_): void => {
					this.props.history.push('/comparators');
					this.props.enqueueSnackbar(`Produktvergleicher mit der ID ${comparator._id} erfolgreich gelöscht!`, {variant: 'success'});
				});
			}).catch((error): void => {
				console.log(`Error while deleting comparator: ${error}`);
			});
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleComparatorChange(key: keyof Comparator, event: any): void {
		if (!this.state.comparator) {
			return;
		}

		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.setState((state: State): any => ({comparator: {...state.comparator, [key]: value }}));
	}

	private auditLogs(): ReactNode {
		const classes = this.props.classes;

		if (!this.state.comparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={false}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Chronik</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<AuditLogsTable
						loadAllLogs={false}
						entityId={this.state.comparator._id}
						showChrome={false}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		const currentComparator = this.state.comparator;
		currentComparator.categoryCode = (selectedCategory ? selectedCategory.code : '');
		this.setState({
			selectedCategoryCode: (selectedCategory ? selectedCategory.code : ''),
			selectedCategoryName: (selectedCategory ? selectedCategory.name : ''),
			comparator: currentComparator
		});
	}

	private getAttributeOptions(): OptionType[] {
		const options: OptionType[] = [];

		let attributes: ProductAttribute[] = [];

		for (const product of this.state.comparator.products) {
			for (const attribute of product.attributes) {

				if (attributes.filter(function (a) { return a.key === attribute.key; }).length === 0) {
					attributes.push(attribute);
				}
			}
		}

		attributes = attributes.sort((a, b): number => {
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}

			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}

			return 0;
		});

		attributes.forEach((attribute: ProductAttribute): void => {
			options.push({value: attribute.key, label: attribute.name});
		});

		return options;
	}

	private handleProductsChange(selectedProducts: Product[]): void {
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.products = selectedProducts;
		this.setState({comparator: comparator});
	}

	private handleAttributesChange(selectedAttributes: string[]): void {
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.attributes = selectedAttributes;
		this.setState({comparator: comparator});
	}

	private async handleSaveButton(): Promise<void> {
		this.setState({saving: true});
		try {
			const response = await this.authService.fetch<ComparatorResponse>(`/api/comparators/${this.state.comparator._id}`, {
				method: 'POST',
				body: JSON.stringify(this.state.comparator)
			});

			if (response.data) {
				const json = JSON.stringify(this.state.comparator);
				const comparator: Comparator = JSON.parse(json);
				comparator._id = response.data._id;
				this.setState({comparator: comparator, savedComparator: comparator});
				this.props.enqueueSnackbar(`Produktvergleicher mit der ID ${comparator._id} erfolgreich gespeichert!`, {variant: 'success'});
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	private async handleMergeInactiveSaveButton(): Promise<void> {
		this.setState({saving: true});

		let containsLessThanThreeProducts = false;

		for (const selectedComparator of this.state.selectedToMergeComparators) {
			if (selectedComparator.products.filter((p): boolean => p.status === ProductStatus.Online).length < 3) {
				containsLessThanThreeProducts = true;
				break;
			}

			let counterOnlineProducts = 0;
			for (const product of selectedComparator.products) {
				if (product.status === ProductStatus.Online) {
					counterOnlineProducts++;
				}
			}
			if (counterOnlineProducts < 3) {
				containsLessThanThreeProducts = true;
				break;
			}
		}

		try {
			if (!containsLessThanThreeProducts) {
				const comparator = this.state.comparator;
				comparator.active = false;
				this.setState({comparator: comparator}, async () => {
					await this.handleSaveButton();
				});
			} else {
				await this.handleSaveButton();
			}

			for (const selectedComparator of this.state.selectedToMergeComparators) {
				if (containsLessThanThreeProducts) {
					selectedComparator.active = false;
				}

				await this.authService.fetch<ComparatorResponse>(`/api/comparators/${selectedComparator._id}`, {
					method: 'POST',
					body: JSON.stringify(selectedComparator)
				});
			}

		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	private handlesplitDetailChange(splitDetail: SplitDetail): void {
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.splitDetail = splitDetail;
		this.setState({comparator: comparator});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const comparatorId = this.state.comparator._id;
		const title = (comparatorId === 'new') ? 'Neuer Produktvergleicher' : `Produktvergleicher ID ${comparatorId} Bearbeiten`;

		return (
			<React.Fragment>
				{this.isDirty() &&
					<Prompt
						message='Wollen Sie Ihren Vergleich wirklich verlassen? Dabei gehen die bereits erstellten Daten verloren'
					/>
				}
				<Paper square={true} className={classes.root}>
					<Typography component="h2" variant="h4" className={classes.title}>
						{title}
					</Typography>
					{this.state.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}
					{!this.state.loading && this.state.comparator.mergeDetail && this.state.comparator.mergeDetail.mergeComparator &&
					<EditorToMergeOverview
						comparators={this.state.selectedToMergeComparators}
					/>
					}
					{!this.state.loading &&
						<EditorGeneralSettings
							comparator={this.state.comparator}
							handleComparatorChange={this.handleComparatorChange.bind(this)}
							deleteComparatorButtonPressed={this.deleteComparatorButtonPressed.bind(this)}
							isBulk={false}
						/>
					}
					{!this.state.loading && this.authService.hasPermission('audit-logs') && this.state.comparator._id !== 'new' &&
						this.auditLogs()
					}
					{!this.state.loading &&
						<EditorProductSettings
							comparator={this.state.comparator}
							selectedCategoryName={this.state.selectedCategoryName}
							selectedCategoryCode={this.state.selectedCategoryCode}
							handleCategoryChange={this.handleCategoryChange.bind(this)}
							handleProductsChange={this.handleProductsChange.bind(this)}
							disabled={(this.state.comparator.mergeDetail && this.state.comparator.mergeDetail.mergeComparator) ? true : false}
						/>
					}
					{!this.state.loading &&
						<EditorAttributeSettings
							comparator={this.state.comparator}
							handleAttributesChange={this.handleAttributesChange.bind(this)}
						/>
					}
					{!this.state.loading &&
						<EditorSplitSettings
							comparator={this.state.comparator}
							getAttributeOptions={this.getAttributeOptions.bind(this)}
							handlesplitDetailChange={this.handlesplitDetailChange.bind(this)}
						/>
					}
					{!this.state.loading &&
						<EditorPreview
							comparator={this.state.comparator}
							handleSaveButton={this.handleSaveButton.bind(this)}
							saving={this.state.saving}
							merge={(this.state.comparator && this.state.comparator.mergeDetail && this.state.comparator.mergeDetail.mergeComparator) ? true : false}
							handleMergeInactiveSaveButton={this.handleMergeInactiveSaveButton.bind(this)}
						/>
					}
				</Paper>
			</React.Fragment>
		);
	}

	private isDirty(): boolean {
		const c1 = this.state.comparator;
		const c2 = this.state.savedComparator;

		if (c1.active !== c2.active ||
			c1.name !== c2.name ||
			c1.position !== c2.position ||
			c1.fullPage !== c2.fullPage ||
			c1.sortedBy !== c2.sortedBy ||
			c1.attributes !== c2.attributes ||
			c1.sortWeight !== c2.sortWeight ||
			c1.products.length !== c2.products.length ||
			c1.attributes.length !== c2.attributes.length) {
			return true;
		}

		for (const product of c1.products) {
			if (c2.products.find(this.findProductWithCode.bind(this, product.productCode)) === undefined) {
				return true;
			}
		}

		return false;
	}

	private findProductWithCode(pCode: string, p: Product): boolean {
		return p.productCode === pCode;
	}
}

export default withSnackbar(withDialog()(withStyles(comparatorEditorStyles)(ComparatorEditor)));
