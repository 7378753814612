import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorBulkEditorStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	textInput: {
		marginTop: theme.spacing(2),
	},
	sortSelect: {
		marginTop: theme.spacing(2),
		minWidth: 320,
	},
	sortWeightInput: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		minWidth: 320,
	},
	fullscreenCheckbox: {
		marginTop: theme.spacing(2),
		display: 'block',
		width: '100%',
	},
	categorySelect: {
		display: 'block',
		width: 'calc(100%)',
	},
	saveButton: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	splitByContainer: {
		marginTop: theme.spacing(2),
		display: 'inline-flex'
	},
	splitBy: {
		minWidth: 100,
		marginRight: theme.spacing(4)
	},
	splitByAttribute: {
		minWidth: 200,
		display: 'flex',
		marginTop: 'auto',
		marginRight: theme.spacing(4)
	},
	splitByOrder: {
		display: 'flex',
		marginTop: 'auto'
	}
});

export default comparatorBulkEditorStyles;
