import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productStatusTableStyles = (theme: Theme): any => createStyles({

	header: {
		marginTop: theme.spacing(2),
		padding: theme.spacing(2, 3),
	},
});

export default productStatusTableStyles;
