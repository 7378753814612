import React, { ReactNode, Component } from 'react';
import {
	Typography,
	TextField,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';

import kitchenInstallationStyles from './KitchenInstallationStyles';

interface State {
	desktopJs: string;
}

class KitchenInstallation extends Component<WithStyles<typeof kitchenInstallationStyles>, State> {

	public constructor(props: WithStyles<typeof kitchenInstallationStyles>) {
		super(props);

		this.state = {
			desktopJs: '',
		};
	}

	private generateMobileJs(desktopJs: string): string {
		const base64JS = btoa(desktopJs);
		return `<img src="" style="display: none; width: 0px; height: 0px;" class="pdctcmp-kitchen-hidden pdctcmp-kitchen-v9" onerror="Function(atob('${base64JS}'))();" />`;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Küchen Installation'}
				</Typography>
				<Typography component="h3" variant="h5" className={classes.subTitle}>
					{'Küchen Script (Desktop) eingeben'}
				</Typography>
				<TextField
					label={'Javascript (Desktop)'}
					multiline
					value={this.state.desktopJs}
					className={classes.textField}
					margin="normal"
					fullWidth={true}
					InputProps={{
						classes: {
							input: classes.textField,
						},
					}}
					onChange={(event): void => this.setState({desktopJs: event.target.value})}
				/>
				<Typography component="h3" variant="h5" className={classes.subTitle}>
					{'Javascript (Mobile)'}
				</Typography>
				<TextField
					label={'Javascript (Mobile)'}
					multiline
					value={this.generateMobileJs(this.state.desktopJs)}
					className={classes.textField}
					margin="normal"
					fullWidth={true}
					InputProps={{
						classes: {
							input: classes.textField,
						},
					}}
					onFocus={(event): void => event.target.select()}
				/>
			</Paper>
		);
	}
}

export default withStyles(kitchenInstallationStyles)(KitchenInstallation);
