import React, {ReactElement, FC} from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import {useDrag, DragSourceMonitor } from 'react-dnd';

import { Category } from '@models/Category';

interface CategoryProp {
	category: Category;
}

const useStyles = makeStyles((_: Theme) => {
	return {
		label: {
			display: 'flex',
			paddingTop: '7px',
			paddingBottom: '7px'
		}
	};
});

const CategoryTreeItemLabel: FC<CategoryProp> = (props: CategoryProp): ReactElement => {
	const classes = useStyles();
	const category = props.category;
	const categoryCode = props.category.code;
	const categoryName = props.category.name;
	const image = props.category.image;

	const ItemTypes = {
		Category: 'category'
	};

	const [, drag] = useDrag({
		item: {
			type: ItemTypes.Category, categoryCode, categoryName, image
		},
		collect: (monitor: DragSourceMonitor)  => ({
			isDragging: monitor.isDragging(),
		})
	});

	return (
		<span key={category.code} ref={drag} className={classes.label}>{category.code + ' - ' + category.name}</span>
	);
};

export default CategoryTreeItemLabel;
