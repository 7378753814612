import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editorSplitSettingsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300
	},
	panelDetails: {
		flexWrap: 'wrap',
		flexDirection: 'column'
	},
	splitByContainer: {
		marginTop: theme.spacing(2),
		display: 'inline-flex'
	},
	splitBy: {
		minWidth: 100,
		marginRight: theme.spacing(4)
	},
	splitByAttribute: {
		minWidth: 200,
		display: 'flex',
		marginTop: 'auto',
		marginRight: theme.spacing(4)
	},
	splitByOrder: {
		display: 'flex',
		marginTop: 'auto'
	},
	addNewSplitRuleGroupButton: {
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	splitRuleGroupContainer: {
		width: '100%',
		border: '2px solid #1F4C79',
		padding: '16px 24px',
		marginBottom: theme.spacing(3)
	},
	openPreviewButton: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: theme.spacing(3)
	},
	deleteButton: {
		color: '#EE0000'
	},
	splitRuleGroupHeadlineContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	splitRuleGroup: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: theme.spacing(3)
	},
	warning: {
		marginLeft: '4px',
		alignSelf: 'end'
	},
	priceSplitContainer: {
		width: '50%',
		border: '2px solid #4ABCB1',
		padding: '16px 24px',
		marginBottom: theme.spacing(3),
		marginLeft: 'auto',
		marginRight: 'auto'
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		marginTop: theme.spacing(2)
	},
	refreshButton: {
		alignSelf: 'flex-start',
		marginLeft: 'auto',
		marginRight: 'auto'
	}
});

export default editorSplitSettingsStyles;
