import { NodeModel } from '@projectstorm/react-diagrams';
import { QuestionPortModel } from './QuestionPortModel';
import { AdvisorAnswer, AdvisorQuestion, AdvisorQuestionType } from '@common/models/Advisor';
import { PortType, createPortModelName } from './PortFactory';

export class QuestionNodeModel extends NodeModel {
	constructor(
		question: AdvisorQuestion, 
		handleEditQuestionButtonClicked: (advisorQuestion: AdvisorQuestion) => void, 
		handleNewAnswerButtonClicked: (advisorQuestion: AdvisorQuestion) => void,
		handleEditAnswerButtonClicked: (advisorQuestion: AdvisorQuestion, advisorAnswer: AdvisorAnswer) => void,
		handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void,
		startingQuestionId?: string,
		
	) {
		super('question');
		
		this.addPort(new QuestionPortModel(handleQuestionChange, PortType.QUESTIONIN, 100, createPortModelName(PortType.QUESTIONIN, question._id ?? ''), question._id));

		if (question.questionType === AdvisorQuestionType.SINGLESELECT) {
			if (question.answers.length > 0) {
				for (const answer of question.answers) {
					let maximumLinks = 1;
					if (answer.historyBased && (answer.historyBased.defaultFollowingQuestionId || answer.historyBased.historyBasedFollowingQuestions.length > 0)) {
						maximumLinks = 100;
					}
					
					this.addPort(new QuestionPortModel(handleQuestionChange, PortType.ANSWEROUT, maximumLinks, createPortModelName(PortType.ANSWEROUT, answer._id ?? ''), answer._id));
				}
			}
		} else {
			this.addPort(new QuestionPortModel(handleQuestionChange, PortType.QUESTIONOUT, 1, createPortModelName(PortType.QUESTIONOUT, question._id ?? ''), question._id));
		}
		this.extras = {question, handleEditQuestionButtonClicked, handleNewAnswerButtonClicked, handleEditAnswerButtonClicked, handleQuestionChange, startingQuestionId};
	}
}