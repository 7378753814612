import React, { ReactNode, Component } from 'react';

import {
	Button,
	CircularProgress,
	Typography,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { Category } from '@models/Category';
import CategorySelect from '../Common/CategorySelect';
import DownloadService from '@services/DownloadService';

import productSuggestionOverviewStyles from './ProductSuggestionOverviewStyles';

interface State {
	loadingProducts: boolean;
	keyword: string | null;
}

class ProductSuggestionOverview extends Component<WithStyles, State> {
	private downloadService: DownloadService;
	public constructor(props: WithStyles) {
		super(props);
		this.downloadService = new DownloadService();

		this.state = {
			loadingProducts: false,
			keyword: null
		};
	}

	private handleProductsExport(): void {
		this.setState({loadingProducts: true});
		const category = this.state.keyword;
		const path = `/api/export-suggestions/download/${category}`;
		const fileName = category + '-suggestions.xlsx';
		this.downloadService.downloadBase64Excel(path, fileName, this.downloadFinished.bind(this));
	}

	private handleCategoriesExport(): void {
		const link = `${window.location.origin}/api/export-suggestions/download/category`;
		window.open(link, '_blank');
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		this.setState({keyword: (selectedCategory ? selectedCategory.code : null)});
	}

	private downloadFinished(): void {
		this.setState({loadingProducts: false});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<Paper className={classes.root}>

				<Typography component="h2" variant="h4">
					{'Export Kategorie- und Produktvorschläge'}
				</Typography>

				<Paper square={true} className={classes.area}>
					<Typography className={classes.subtitle} component="h3" variant="h6">
						{'Kategorieebene'}
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleCategoriesExport.bind(this)}
						disabled={this.state.loadingProducts}
					>
					Export Kategorien Excel
					</Button>
				</Paper>

				<Paper square={true} className={classes.area}>
					<Typography className={classes.subtitle} component="h3" variant="h6">
						{'Produktebene'}
					</Typography>
					<CategorySelect
						onChange={this.handleCategoryChange.bind(this)}
						selectedCategoryCode=""
						topLevelCategoriesExcluded={true}
						disabled={false}
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleProductsExport.bind(this)}
						disabled={this.state.keyword && !this.state.loadingProducts ? false : true}
					>
					Export Produkte Excel
					</Button>
					{this.state.loadingProducts &&
						<CircularProgress className={classes.loadingIndicatorProducts}/>
					}
				</Paper>
			</Paper>
		);
	}

}

export default (withStyles(productSuggestionOverviewStyles)(ProductSuggestionOverview));
