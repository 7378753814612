import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const splitLevelGroupsStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	splitLevelGroupContainer: {
		marginBottom: theme.spacing(3) 
	}
});

export default splitLevelGroupsStyles;
