import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const splitLevelsStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	splitLevelGroupContainer: {
		marginBottom: theme.spacing(3)
	},
	splitLevelGroupHeadlineContainer: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	deleteButton: {
		color: '#EE0000'
	},
	splitLevelRuleSelect: {
		marginBottom: theme.spacing(3)
	},
	splitLevelSelects: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'end',
		marginBottom: theme.spacing(3)
	},
	splitLevelAttributeSelect: {
		marginLeft: theme.spacing(2),
		width: 250
	}
});

export default splitLevelsStyles;
