import React, {ReactElement, FC, useState, ReactNode} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import folderEditorStyles from './FolderEditorStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AdvisorFolder, deleteAdvisorFolder, saveAdvisorFolder } from './models/AdvisorFolder';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface Props extends WithStyles<typeof folderEditorStyles>, WithSnackbarProps {
	folders: AdvisorFolder[];
	open: boolean;
	close: () => void;
	dialog?: ConfirmDialog;
}

const FolderEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const queryClient = useQueryClient();

	const [editMode, setEditMode] = useState(false);
	const [folderToCreate, setFolderToCreate] = useState<AdvisorFolder>({
		name: '',
	});
	const [folderToEdit, setFolderToEdit] = useState<AdvisorFolder | null>(null);


	const saveAdvisorFolderQuery = useMutation(saveAdvisorFolder, {
		onSuccess: (data) => {
			if (data) {
				queryClient.invalidateQueries({
					queryKey: ['advisorFolders'],
				});
				props.enqueueSnackbar('Ordner erfolgreich gespeichert!', {variant: 'success'});
			} else {
				props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
			}
			setFolderToCreate({name: ''});
		}
	});

	const deleteAdvisorFolderQuery = useMutation(
		deleteAdvisorFolder, {
			onSuccess: (data) => {
				if (!!data) {
					queryClient.invalidateQueries({
						queryKey: ['advisorFolders'],
					});
					queryClient.invalidateQueries({
						queryKey: ['advisors'],
					});
					props.enqueueSnackbar('Ordner erfolgreich gelöscht!', {variant: 'success'});
				} else {
					props.enqueueSnackbar('Aktion fehlgeschlagen!', {variant: 'error'});
				}
			}
		}
	);

	async function handleDeleteFolder(folder: AdvisorFolder): Promise<void> {
		const dialog = props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Ordner Löschen',
				message: 'Soll der Ordner wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then(async (): Promise<void> => {
				if (folder._id) {
					await deleteAdvisorFolderQuery.mutateAsync(folder._id);
				}
			}).catch((error): void => {
				console.log(`Error while deleting folder: ${error}`);
			});
		}
	}

	function getFoldersList(): ReactNode {
		return (
			<>
				{props.folders.length > 0 && (
					<>
						<Typography variant="h6" className={classes.subtitle}>
							{'Alle Ordner:'}
						</Typography>
						<List className={classes.list}>
							{props.folders.map(
								(folder: AdvisorFolder, index: number) => (
									<div key={index}>
										<ListItem>
											<>
												<TextField
													fullWidth
													value={folderToEdit && folderToEdit._id === folder._id ? folderToEdit.name : folder.name}
													onChange={(event): void => setFolderToEdit({...folderToEdit, name: event.target.value})}
													disabled={folderToEdit?._id !== folder._id}
													InputProps={{
														endAdornment: (
															<>
																{folderToEdit?._id === folder._id &&
																	<Tooltip title='Speichern'>
																		<span>
																			<IconButton
																				edge='end'
																				color="secondary"
																				onClick={async (): Promise<void> => {
																					setFolderToEdit(null);
																					setEditMode(false);
																					if (folderToEdit && folderToEdit.name !== folder.name) {
																						await saveAdvisorFolderQuery.mutateAsync(folderToEdit);
																					}
																				}}
																				size='small'
																				disabled={folderToEdit?.name === ''}
																			>
																				<CheckCircleOutlineIcon />
																			</IconButton>
																		</span>
																	</Tooltip>
																}
																{folderToEdit?._id !== folder._id &&
																<>
																	<Tooltip title='Bearbeiten'>
																		<span>
																			<IconButton
																				edge='end'
																				color="primary"
																				onClick={(): void => {
																					setFolderToEdit(folder);
																					setEditMode(true);
																				}
																				}
																				size='small'
																				disabled={editMode}
																			>
																				<EditIcon />
																			</IconButton>
																		</span>
																	</Tooltip>
																	<Tooltip title='Löschen'>
																		<span>
																			<IconButton
																				edge='end'
																				className={classes.deleteButton}
																				onClick={async (): Promise<void> => {
																					await handleDeleteFolder(folder); 
																				}}
																				size='small'
																				disabled={editMode}
																			>
																				<HighlightOffIcon />
																			</IconButton>
																		</span>
																	</Tooltip>
																</>
																}
															</>
														),
													}}
												/>
												
											</>
										</ListItem>
									</div>
								)
							)}
						</List>
					</>
				)}
			</>
		);
	}

	function handleClose(): void {
		props.close();
		setFolderToCreate({name: ''});
		setEditMode(false);
		setFolderToEdit(null);
	};

	return (
		<Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth={'md'}>
			<DialogTitle>{'Ordner'}</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					value={folderToCreate.name}
					onChange={(event): void => setFolderToCreate({name: event.target.value})}
					placeholder='Neuer Ordner ...'
					disabled={editMode}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<Tooltip title='Hinzufügen'>
									<span>
										<IconButton
											color='secondary'
											onClick={
												async (): Promise<void> => {await saveAdvisorFolderQuery.mutateAsync(folderToCreate);}
											}
											disabled={editMode || folderToCreate.name === ''}
										>
											<AddCircleIcon />
										</IconButton>
									</span>
								</Tooltip>
							</InputAdornment>
						),
					}}
				/>
				{getFoldersList()}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary" disabled={editMode}>
						Fertig
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withSnackbar(withDialog()(withStyles(folderEditorStyles)(FolderEditor)));
