import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productSuggestionOverviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	area: {
		marginTop: '16px',
		padding: theme.spacing(2, 2)
	},
	subtitle: {
		marginBottom: '16px'
	},
	loadingIndicatorProducts: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	}
});

export default productSuggestionOverviewStyles;
