import React, {ReactElement, FC, CSSProperties, useState} from 'react';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	TextField,
	FormControl,
	FormLabel,
	Button,
	Typography,
	WithStyles,
	withStyles,
	Box
} from '@material-ui/core';
import advisorEditorGeneralSettingsStyles from './AdvisorEditorGeneralSettingsStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Advisor, loadAdvisorVersions } from '@common/models/Advisor';
import DeleteIcon from '@material-ui/icons/Delete';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import Select from 'react-select';
import { useQuery } from '@tanstack/react-query';
import { AdvisorFolder, loadAdvisorFolders } from '@common/models/AdvisorFolder';
import moment from 'moment';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface FolderOptionType {
	label: string;
	value: AdvisorFolder;
};

interface VersionOptionType {
	label: string;
	value: Advisor;
};

interface Props extends WithStyles<typeof advisorEditorGeneralSettingsStyles> {
	dialog?: ConfirmDialog;
	advisor: Advisor;
	handleAdvisorChange: (key: keyof Advisor, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	handleAdvisorFolderChange: (folder: AdvisorFolder | undefined) => void;
	handleAdvisorVersionLoaded: (advisor: Advisor) => void;
	deleteAdvisorButtonPressed: (() => void);
	isNewAdvisor: boolean;
}

const AdvisorEditorGeneralSettings: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const [currentAdvisorVersion, setCurrentAdvisorVersion] = useState<Advisor | undefined>(undefined);

	const advisorFoldersQuery = useQuery(['advisorFolders'], () =>
		loadAdvisorFolders()
	);

	const advisorVersionsQuery = useQuery(['advisorVersions'], () =>
		loadAdvisorVersions(props.advisor.originalId ?? ''), {
		enabled: !!(!props.isNewAdvisor && !!props.advisor.originalId)
	}
	);

	function getFolderOptions(): FolderOptionType[] {
		const options: FolderOptionType[] = [];
		(advisorFoldersQuery.data ?? []).forEach((f: AdvisorFolder): void => {
			options.push({value: f ?? '', label: f.name});
		});

		return options;
	}

	function getFolderVersions(): VersionOptionType[] {
		const options: VersionOptionType[] = [];
		(advisorVersionsQuery.data ?? []).forEach((a: Advisor): void => {
			options.push({value: a ?? '', label: `Version: ${moment(a.updatedAt).format('DD.MM.YYYY HH:mm:ss')}` ?? ''});
		});

		return options;
	}

	function handleLoadVersion(): void {
		const dialog = props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Version laden',
				message: 'Soll wirklich eine ältere Version geladen werden? Aktuelle Änderungen würden dadurch verloren gehen.',
				ok: {
					text: 'Laden',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then(async (): Promise<void> => {
				if (currentAdvisorVersion) {
					props.handleAdvisorVersionLoaded({...currentAdvisorVersion, _id: props.advisor._id});
				}
			}).catch((error): void => {
				console.log(`Error while loading advisor version: ${error}`);
			});
		}
	}

	return (
		<ExpansionPanel
			defaultExpanded
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Allgemeine Einstellungen</Typography>
				<Typography className={classes.panelSubtitle}>Titel: {props.advisor.name}</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				<TextField
					required
					autoFocus
					fullWidth
					className={classes.textInput}
					label="Titel"
					placeholder="Produktberater"
					value={props.advisor.name}
					onChange={(event): void => {props.handleAdvisorChange('name', event);}}
				/>
				<FormControl className={classes.folderSelectContainer}>
					<FormLabel>Ordner</FormLabel>
					<Select
						className={classes.folderSelect}
						value={props.advisor.folder ? {value: props.advisor.folder, label: props.advisor.folder.name} : undefined}
						onChange={(selectedFolder): void => props.handleAdvisorFolderChange(!selectedFolder ? undefined : (selectedFolder as FolderOptionType).value)}
						options={getFolderOptions()}
						placeholder={'Ordner auswählen...'}
						noOptionsMessage={(): string => ('Keine Treffer')}
						styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
						menuPortalTarget={document.body}
						isClearable
					/>
				</FormControl>
				{!props.isNewAdvisor &&
				<>
					<FormControl className={classes.versionSelectFormControl}>
						<FormLabel>Ältere Versionen</FormLabel>
						<Box className={classes.versionSelectContainer}>
							<Select
								className={classes.versionSelect}
								value={currentAdvisorVersion ? {value: currentAdvisorVersion, label: `Version: ${moment(currentAdvisorVersion.updatedAt).format('DD.MM.YYYY HH:mm:ss')}`} : undefined}
								onChange={(selectedVersion): void => setCurrentAdvisorVersion(!selectedVersion ? undefined : (selectedVersion as VersionOptionType).value)}
								options={getFolderVersions()}
								placeholder={'Version auswählen...'}
								noOptionsMessage={(): string => ('Keine Treffer')}
								styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
								menuPortalTarget={document.body}
								isClearable
							/>
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								className={classes.versionLoadButton}
								startIcon={<SystemUpdateAltIcon />}
								onClick={handleLoadVersion}
								disabled={!currentAdvisorVersion}
							>
							Version laden
							</Button>
						</Box>
					</FormControl>
					<Button
						variant="outlined"
						size="small"
						className={classes.deleteButton}
						startIcon={<DeleteIcon />}
						onClick={props.deleteAdvisorButtonPressed}
					>
							Löschen
					</Button>
				</>
				}
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default withDialog()(withStyles(advisorEditorGeneralSettingsStyles)(AdvisorEditorGeneralSettings));
