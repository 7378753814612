import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorEditorCategoryAttributeStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		display: 'block'
	},
	categoriesAttributesList: {
		padding: 0
	},
	categoriesAttributesListRow: {
		display: 'flex',
		flexDirection: 'column'
	},
	addCategoriesAttributesButtonContainer: {
		display: 'flex',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		justifyContent: 'flex-end'
	},
	categoriesAttributesListItem: {
		justifyContent: 'space-between'
	},
	deleteCategoryAttributesButton: {
		color: theme.palette.error.main,
	},
	categoriesAttributesFormControl: {
		width: '100%'
	},
	categoriesAttributesLabel: {
		marginBottom: theme.spacing(1)
	},
});

export default advisorEditorCategoryAttributeStyles;
