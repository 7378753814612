import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editorProductSettingsStyles = (_: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	categorySelect: {
		display: 'block',
		width: 'calc(100%)',
	}
});

export default editorProductSettingsStyles;
