/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, Component, ChangeEvent } from 'react';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

import {
	Button,
	CircularProgress,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { AutomatedComparator, SplitDetail, SplitLevel, SplitLevelRuleType } from '@models/AutomatedComparator';
import { Product } from '@models/Product';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import WarningIcon from '@material-ui/icons/Warning';
import RefreshIcon from '@material-ui/icons/Refresh';

import SplitLevelPreview from '@common/SplitLevelPreview';
import SplitLevels from '@common/SplitLevels';
import AuthService from '@services/AuthService';

import automatedEditorSplitSettingsStyles from './AutomatedEditorSplitSettingsStyles';

interface SplittedComparatorsResponse {
	data: AutomatedComparator[];
	success: boolean;
	message?: string;
}

interface SplitLevelToAutomatedComparators {
	splitLevel: number;
	splittedAutomatedComparators: AutomatedComparator[];
}

interface OptionType {
	label: string;
	value: string;
};

interface Props extends WithStyles<typeof automatedEditorSplitSettingsStyles> {
	dialog: ConfirmDialog;
	automatedComparator: AutomatedComparator;
	getAttributeOptions: () => OptionType[];
	handlesplitDetailChange: (splitDetail: SplitDetail) => void;
	refreshed: boolean;
	productsByRules: Product[];
}

interface State {
	automatedComparator: AutomatedComparator;
	splitPreviewDialogOpen: boolean;
	levelIndex: number | null;
	loadingSplittedAutomatedComparators: boolean;
	loadingPriceSplittedAutomatedComparators: boolean;
	splitLevelsToAutomatedComparators: SplitLevelToAutomatedComparators[];
	priceSplittedAutomatedComparators: AutomatedComparator[];
	openPriceSplittedPreview: boolean;
}

class AutomatedEditorSplitSettings extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			automatedComparator: props.automatedComparator,
			splitPreviewDialogOpen: false,
			levelIndex: null,
			loadingSplittedAutomatedComparators: false,
			loadingPriceSplittedAutomatedComparators: false,
			splitLevelsToAutomatedComparators: [],
			priceSplittedAutomatedComparators: [],
			openPriceSplittedPreview: true
		};
	}

	private addNewSplitLevel(): void {
		const automatedComparator = this.state.automatedComparator;

		const dummySplitLevel: SplitLevel = {
			splitLevelRuleType: SplitLevelRuleType.SplitRestRemove,
			splitLevelGroups: []
		};

		automatedComparator.splitDetail.levels.push(dummySplitLevel);
		this.props.handlesplitDetailChange(automatedComparator.splitDetail);

		this.setState({
			automatedComparator: automatedComparator
		});
	}

	private deleteSplitLevel(index: number): void {
		const dialog = this.props.dialog;

		if (dialog) {
			dialog.confirm({
				title: 'Aufteilungsebene Löschen',
				message: `Soll die Aufteilungsebene "${index}" wirklich gelöscht werden?`,
				ok: {
					text: 'Löschen',
					color: 'default',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				const automatedComparator = this.state.automatedComparator;

				automatedComparator.splitDetail.levels.splice(index, 1);
				this.props.handlesplitDetailChange(automatedComparator.splitDetail);
				this.setState({
					automatedComparator: automatedComparator
				});


			}).catch((error): void => {
				console.log(`Error while deleting split level: ${error}`);
			});
		}
	}

	private openPreview(index: number): void {
		this.setState({levelIndex: index, splitPreviewDialogOpen: true, openPriceSplittedPreview: false});
	}

	private openPriceSplittedPreview(): void {
		let lastIndex = 0;
		if (this.state.automatedComparator.splitDetail && this.state.automatedComparator.splitDetail.levels) {
			lastIndex = this.state.automatedComparator.splitDetail.levels.length-1;
		}

		this.setState({levelIndex: lastIndex, splitPreviewDialogOpen: true, openPriceSplittedPreview: true});
	}

	private closePreview(): void {
		this.setState({splitPreviewDialogOpen: false});
	}

	private splitLevelChanged(splitLevel: SplitLevel, index: number): void {
		const automatedComparator = this.state.automatedComparator;
		automatedComparator.splitDetail.levels[index] = splitLevel;
		this.props.handlesplitDetailChange(automatedComparator.splitDetail);
		this.setState({automatedComparator: automatedComparator});
	}

	private async loadSplittedAutomatedComparators(): Promise<void> {
		this.setState({loadingSplittedAutomatedComparators: true});
		const splitLevelsToAutomatedComparators: SplitLevelToAutomatedComparators[] = [];

		const productsByRulesIds = this.props.productsByRules.map(p => p._id);

		for (let i = 0; i < this.state.automatedComparator.splitDetail.levels.length; i++) {
			const response = await this.authService.fetch<SplittedComparatorsResponse>('/api/automated-comparators/splitted', {
				method: 'POST',
				body: JSON.stringify({comparator: this.props.automatedComparator, isAutomated: true, productsByRules: productsByRulesIds, index: i})
			});

			if (response && response.success) {
				if (response.data) {
					const splitLevelToAutomatedComparators: SplitLevelToAutomatedComparators = {
						splitLevel: i,
						splittedAutomatedComparators: response.data
					};
					splitLevelsToAutomatedComparators.push(splitLevelToAutomatedComparators);
				}
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unknown error');
			}
		}

		this.setState({splitLevelsToAutomatedComparators: splitLevelsToAutomatedComparators, loadingSplittedAutomatedComparators: false});
	}

	private async loadPriceSplittedAutomatedComparators(): Promise<void> {
		this.setState({loadingPriceSplittedAutomatedComparators: true});

		const productsByRulesIds = this.props.productsByRules.map(p => p._id);

		const response = await this.authService.fetch<SplittedComparatorsResponse>('/api/automated-comparators/splitted', {
			method: 'POST',
			body: JSON.stringify({comparator: this.props.automatedComparator, isAutomated: true, productsByRules: productsByRulesIds, index: 'all'})
		});

		if (response && response.success) {
			if (response.data) {
				this.setState({priceSplittedAutomatedComparators: response.data});
			}
			this.setState({loadingPriceSplittedAutomatedComparators: false});
		} else if (response.message) {
			this.setState({loadingPriceSplittedAutomatedComparators: false});
			throw new Error(response.message);
		} else {
			this.setState({loadingPriceSplittedAutomatedComparators: false});
			throw new Error('Unknown error');
		}
	}

	private checkLessThan3Products(type: string, index?: number): boolean {
		let lessThan3Products = false;

		if (type === 'level' && index !== undefined && !isNaN(index)) {
			const splitLevelsToAutomatedComparators = this.state.splitLevelsToAutomatedComparators;

			if (splitLevelsToAutomatedComparators[index]) {
				if (splitLevelsToAutomatedComparators[index].splittedAutomatedComparators.length === 0) {
					lessThan3Products = true;
				}
				for (const automatedComparator of splitLevelsToAutomatedComparators[index].splittedAutomatedComparators) {
					if (automatedComparator.products.length < 3) {
						lessThan3Products = true;
						break;
					}
				}
			} else {
				lessThan3Products = true;
			}
		} else if (type === 'price') {
			if (this.state.priceSplittedAutomatedComparators.length === 0) {
				lessThan3Products = true;
			}
			for (const automatedComparator of this.state.priceSplittedAutomatedComparators) {
				if (automatedComparator.products.length < 3) {
					lessThan3Products = true;
					break;
				}
			}
		}

		return lessThan3Products;
	}

	private changeValue(type: string, e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
		const value = parseFloat(e.target.value);

		if (!isNaN(value) && value >= 0 && value <= 1000) {
			const currentComparator = JSON.parse(JSON.stringify(this.state.automatedComparator));
			if (type === 'ideal') {
				currentComparator.splitDetail.idealNumberProducts = value;
			}
			this.setState({automatedComparator: currentComparator}, () => {
				this.props.handlesplitDetailChange(currentComparator.splitDetail);
			});
		}
	}

	private refreshData(): void {
		this.loadSplittedAutomatedComparators();
		this.loadPriceSplittedAutomatedComparators();
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		if (!this.props.automatedComparator) {
			return;
		}

		return (
			<ExpansionPanel
				defaultExpanded={this.props.automatedComparator._id !== 'new'}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Aufteilung</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<React.Fragment>
						{this.props.refreshed &&
							<>
								<div className={classes.splitRuleGroup}>
									{this.state.automatedComparator.splitDetail && this.state.automatedComparator.splitDetail.levels.map((level: SplitLevel, index: number) =>
										<div key={index}>
											<div className={classes.splitRuleGroupHeadlineContainer}>
												<div>
													<Typography className={classes.panelTitle} variant="body1">{'Aufteilungsebene: ' + index}</Typography>
												</div>
												<IconButton disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators} className={classes.deleteButton} size="small" onClick={this.deleteSplitLevel.bind(this, index)}>
													<HighlightOffIcon />
												</IconButton>
											</div>
											<div className={classes.splitRuleGroupContainer}>
												<SplitLevels
													splitLevel={level}
													splitLevelIndex={index}
													selectedCategoryCode={this.props.automatedComparator.categoryCode}
													notifyParent={this.splitLevelChanged.bind(this)}
													loadingSplittedComparators={this.state.loadingSplittedAutomatedComparators}
													loadingPriceSplittedComparators={this.state.loadingPriceSplittedAutomatedComparators}
												/>
											</div>
											<div className={classes.openPreviewButton}>
												<Button
													variant="contained"
													color="primary"
													onClick={this.openPreview.bind(this, index)}
													disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators}
												>
												Aufteilung ansehen
												</Button>
												{this.checkLessThan3Products('level', index) &&
													<span className={classes.warning}>
														<Tooltip title={
															<span>Mindestens einer der Produktvergleicher enthält weniger als 3 Produkte. Daher wird der Hauptvergleicher verwendet.</span>
														}>
															<WarningIcon/>
														</Tooltip>
													</span>
												}
											</div>
										</div>
									)}
									<SplitLevelPreview
										splittedComparators={this.state.openPriceSplittedPreview ? this.state.priceSplittedAutomatedComparators : this.state.splitLevelsToAutomatedComparators[this.state.levelIndex ? this.state.levelIndex : 0] ? this.state.splitLevelsToAutomatedComparators[this.state.levelIndex ? this.state.levelIndex : 0].splittedAutomatedComparators : []}
										isAutomated={true}
										levelIndex={this.state.levelIndex}
										closeHandler={this.closePreview.bind(this)}
										editorOpen={this.state.splitPreviewDialogOpen}
										mainComparatorProducts={this.props.productsByRules}
										isPriceSplitPreview={this.state.openPriceSplittedPreview}
									/>
									<div className={classes.addNewSplitRuleGroupButton}>
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<AddIcon />}
											onClick={this.addNewSplitLevel.bind(this)}
											disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators}
										>
										Neue Aufteilungsebene
										</Button>
									</div>
								</div>
								{this.state.automatedComparator.splitDetail && this.state.automatedComparator.splitDetail.levels.length > 0 &&
									<>
										<div className={classes.priceSplitContainer}>
											<div>
												<TextField
													type={'number'}
													value={this.state.automatedComparator.splitDetail.idealNumberProducts ?? ''}
													label={'Ideale Anzahl von Produkten pro Vergleicher:'}
													onChange={this.changeValue.bind(this, 'ideal')}
													disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators}
													style = {{width: 330}}
												/>
											</div>
										</div>

										<div className={classes.openPreviewButton}>
											<Button
												variant="contained"
												color="primary"
												onClick={this.openPriceSplittedPreview.bind(this)}
												disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators}
											>
											Preis-aufteilung ansehen
											</Button>
											{this.checkLessThan3Products('price') &&
										<span className={classes.warning}>
											<Tooltip title={
												<span>Mindestens einer der Produktvergleicher enthält weniger als 3 Produkte. Daher wird der Hauptvergleicher verwendet.</span>
											}>
												<WarningIcon/>
											</Tooltip>
										</span>
											}
										</div>
										<Button
											variant="outlined"
											color="secondary"
											size="small"
											startIcon={<RefreshIcon color="secondary"/>}
											className={classes.refreshButton}
											onClick={this.refreshData.bind(this)}
											disabled={this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators}
										>
										Aktualisieren
										</Button>
									</>
								}
								{(this.state.loadingSplittedAutomatedComparators || this.state.loadingPriceSplittedAutomatedComparators) &&
									<CircularProgress className={classes.loadingIndicator}/>
								}
							</>
						}
						{this.props.automatedComparator && !this.props.refreshed &&
							<Typography>{'Bitte zuerst Produkte aktualisieren'}</Typography>
						}
					</React.Fragment>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withDialog()(withStyles(automatedEditorSplitSettingsStyles)(AutomatedEditorSplitSettings));
