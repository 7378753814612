import React, { Component, ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import { RouteComponentProps, Switch, Link } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import {
	AppBar,
	Toolbar,
	Typography,
	Drawer,
	List,
	ListItem,
	ListItemText,
	IconButton,
	WithStyles,
	withStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import Dashboard from '../Dashboard/Dashboard';
import Shopping from '../Shopping/Shopping';
import Reporting from '../Reporting/Reporting';
import Orders from '../Orders/Orders';
import Crawler from '../Crawler/Crawler';
import UserAdministration from '../UserAdministration/UserAdministration';
import Slider from '../Slider/Slider';
import ComparatorInstallation from '../ComparatorInstallation/ComparatorInstallation';
import KitchenInstallation from '../KitchenInstallation/KitchenInstallation';
import ComparatorOverview from '../Comparator/ComparatorOverview';
import ComparatorEditor from '../Comparator/ComparatorEditor';
import ComparatorBulkEditor from '../Comparator/ComparatorBulkEditor';
import ComparatorAnalytics from '../Comparator/ComparatorAnalytics';
import ComparatorExport from '../Comparator/ComparatorExport';
import ComparatorProductOverview from '../Comparator/ComparatorProductOverview';
import CmsContentEditor from '../CmsContent/CmsContentEditor';
import AttributeValueSynonymOverview from '../AttributeValueSynonym/AttributeValueSynonymOverview';
import AuditLogs from '../AuditLogs/AuditLogs';
import AutomatedComparatorOverview from '../AutomatedComparator/AutomatedComparatorOverview';
import AutomatedComparatorEditor from '../AutomatedComparator/AutomatedComparatorEditor';
import ProductSuggestionOverview from '../ProductSuggestion/ProductSuggestionOverview';
import CategoryMapping from '../CategoryMapping/CategoryMapping';
import CategoryRanking from '../CategoryRanking/CategoryRanking';
import CategoryOverview from '../CategoryOverview/CategoryOverview';
import SeriesTable from '../Series/SeriesTable';
import DropshippingOverview from '../Dropshipping/DropshippingOverview';
import AdvisorOverview from '../Advisor/AdvisorOverview';
import AdvisorEditor from 'Advisor/AdvisorEditor';

import AuthService from '@services/AuthService';

import appStyles from './AppStyles';

interface State {
	drawerOpen: boolean;
}

interface Props extends WithStyles<typeof appStyles>, RouteComponentProps {
}

class AppWrapper extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			drawerOpen: true
		};
	}

	private toggleDrawer(): void {
		this.setState((inState): State => ({drawerOpen: !inState.drawerOpen}));
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const containerClass = this.state.drawerOpen ? classes.container : classes.containerShift;

		return (
			<div className={classes.root}>
				<AppBar position="fixed" className={classes.appBar}>
					<Toolbar>
						<IconButton
							color="inherit"
							onClick={this.toggleDrawer.bind(this)}
							edge="start"
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6">
							{'Möbelix Techportal'}
						</Typography>
						<Button
							variant="contained"
							color="secondary"
							className={classes.logout}
							component={Link}
							to="/logout"
						>
							Logout
						</Button>
					</Toolbar>
				</AppBar>
				<Drawer
					className={classes.drawer}
					variant="persistent"
					classes={{
						paper: classes.drawerPaper,
					}}
					open={this.state.drawerOpen}
				>
					<div className={classes.toolbar} />
					<List>
						<ListItem
							component={Link}
							to="/"
							button
							key={'Dashboard'}
							selected={this.props.location.pathname === '/'}
						>
							<ListItemText primary={'Dashboard'} />
						</ListItem>
						{this.authService.hasPermission('admin') &&
							<ListItem
								component={Link}
								to="/administration"
								button
								key={'Administration'}
								selected={this.props.location.pathname === '/administration'}
							>
								<ListItemText primary={'Benutzerverwaltung'} />
							</ListItem>
						}
						{this.authService.hasPermission('orders') &&
							<ListItem
								component={Link}
								to="/orders"
								button
								key={'Bestellungen'}
								selected={this.props.location.pathname === '/orders'}
							>
								<ListItemText primary={'Bestellungen'} />
							</ListItem>
						}
						{this.authService.hasPermission('audit-logs') &&
							<ListItem
								component={Link}
								to="/audit-logs"
								button
								key={'Chronik'}
								selected={this.props.location.pathname === '/audit-logs'}
							>
								<ListItemText primary={'Chronik'} />
							</ListItem>
						}
						{this.authService.hasPermission('product-suggestions') &&
							<ListItem
								component={Link}
								to="/export-suggestions"
								button
								key={'Suggestion'}
								selected={this.props.location.pathname === '/export-suggestions'}
							>
								<ListItemText primary={'Export Vorschläge'} />
							</ListItem>
						}
						{this.authService.hasPermission('crawler') &&
							<ListItem
								component={Link}
								to="/crawler"
								button
								key={'Crawler'}
								selected={this.props.location.pathname === '/crawler'}
							>
								<ListItemText primary={'Crawler'} />
							</ListItem>
						}
						{this.authService.hasPermission('dropshipping-export') &&
							<ListItem
								component={Link}
								to="/dropshipping-overview"
								button
								key={'Dropshipping-Export'}
								selected={this.props.location.pathname === '/dropshipping-overview'}
							>
								<ListItemText primary={'Dropshipping-Export'} />
							</ListItem>
						}
						{this.authService.hasPermission('shopping') &&
							<ListItem
								component={Link}
								to="/shopping"
								button
								key={'Shopping'}
								selected={this.props.location.pathname === '/shopping'}
							>
								<ListItemText primary={'Google Shopping'} />
							</ListItem>
						}
						{this.authService.hasPermission('category-feed') &&
							<ListItem
								component={Link}
								to="/category"
								button
								key={'Category'}
								selected={this.props.location.pathname === '/category'}
							>
								<ListItemText primary={'Kategorie-Feeds'} />
							</ListItem>
						}
						{this.authService.hasPermission('category-mapping') &&
							<ListItem
								component={Link}
								to="/category-mapping"
								button
								key={'Category-Mapping'}
								selected={this.props.location.pathname === '/category-Mapping'}
							>
								<ListItemText primary={'Kategorie-Mapping'} />
							</ListItem>
						}
						{this.authService.hasPermission('category-ranking') &&
							<ListItem
								component={Link}
								to="/category-ranking"
								button
								key={'Category-Ranking'}
								selected={this.props.location.pathname === '/category-Ranking'}
							>
								<ListItemText primary={'Kategorie-Ranking'} />
							</ListItem>
						}
						{this.authService.hasPermission('category-overview') &&
							<ListItem
								component={Link}
								to="/category-overview"
								button
								key={'Category-Overview'}
								selected={this.props.location.pathname === '/category-overview'}
							>
								<ListItemText primary={'Kategorie-Übersicht'} />
							</ListItem>
						}
						{this.authService.hasPermission('product-monitoring') &&
							<ListItem
								component={Link}
								to="/monitoring"
								button
								key={'Monitoring'}
								selected={this.props.location.pathname === '/monitoring'}
							>
								<ListItemText primary={'Monitoring'} />
							</ListItem>
						}
						{this.authService.hasPermission('advisor') &&
							<ListItem
								component={Link}
								to="/advisors"
								button
								key={'Advisor-Overview'}
								selected={this.props.location.pathname === '/advisors'}
							>
								<ListItemText primary={'Produktberater'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator') &&
							<ListItem
								component={Link}
								to="/comparators"
								button
								key={'Comparator-Overview'}
								selected={this.props.location.pathname === '/comparators'}
							>
								<ListItemText primary={'Produktvergleicher'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator') &&
							<ListItem
								component={Link}
								to="/comparator-productOverview"
								button
								key={'Produktübersicht'}
								selected={this.props.location.pathname === '/comparator-productOverview'}
								className={classes.inset}
							>
								<ListItemText primary={'Produktübersicht'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator') &&
							<ListItem
								component={Link}
								to="/comparator-analytics"
								button
								key={'Analyse'}
								selected={this.props.location.pathname === '/comparator-analytics'}
								className={classes.inset}
							>
								<ListItemText primary={'Analyse'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator') &&
							<ListItem
								component={Link}
								to="/comparator-attribute-value-synonyms"
								button
								key={'Synonym'}
								selected={this.props.location.pathname === '/comparator-attribute-value-synonyms'}
								className={classes.inset}
							>
								<ListItemText primary={'Attributwert-Synonyme'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator') &&
							<ListItem
								component={Link}
								to="/comparator-export"
								button
								key={'Export'}
								selected={this.props.location.pathname === '/comparator-export'}
								className={classes.inset}
							>
								<ListItemText primary={'CSV-Export'} />
							</ListItem>
						}
						{this.authService.hasPermission('series') &&
							<ListItem
								component={Link}
								to="/series"
								button
								key={'Series'}
								selected={this.props.location.pathname === '/series'}
							>
								<ListItemText primary={'Serienanalyse'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator-installation') &&
							<ListItem
								component={Link}
								to="/comparator-installation"
								button
								key={'Comparator-Installation'}
								selected={this.props.location.pathname === '/comparator-installation'}
							>
								<ListItemText primary={'Produktvergleicher Installation'} />
							</ListItem>
						}
						{this.authService.hasPermission('comparator-installation') &&
							<ListItem
								component={Link}
								to="/kitchen-installation"
								button
								key={'kitchen-Installation'}
								selected={this.props.location.pathname === '/kitchen-installation'}
							>
								<ListItemText primary={'Küchen-Installation'} />
							</ListItem>
						}
						{this.authService.hasPermission('slider') &&
							<ListItem
								component={Link}
								to="/slider"
								button
								key={'Slider'}
								selected={this.props.location.pathname === '/slider'}
							>
								<ListItemText primary={'Slider Installation'} />
							</ListItem>
						}
						{this.authService.hasPermission('reporting') &&
							<ListItem
								component={Link}
								to="/reporting"
								button
								key={'Reporting'}
								selected={this.props.location.pathname === '/reporting'}
							>
								<ListItemText primary={'Umsatz'} />
							</ListItem>
						}

					</List>
				</Drawer>
				<div className={containerClass}>
					<div className={classes.toolbar} />
					<Switch>
						<PrivateRoute
							path="/shopping"
							permission="shopping"
							component={Shopping}
						/>
						<PrivateRoute
							path="/reporting"
							permission="reporting"
							component={Reporting}
						/>
						<PrivateRoute
							path="/orders"
							permission="orders"
							component={Orders}
						/>
						<PrivateRoute
							path="/export-suggestions"
							permission="product-suggestions"
							component={ProductSuggestionOverview}
						/>
						<PrivateRoute
							path="/crawler"
							permission="crawler"
							render={(props): ReactNode => <Crawler {...props} type="crawlers" />}
						/>
						<PrivateRoute
							path="/dropshipping-overview"
							permission="dropshipping-export"
							component={DropshippingOverview}
						/>
						<PrivateRoute
							path="/category"
							permission="category-feed"
							render={(props): ReactNode => <Crawler {...props} type="feeds" />}
						/>
						<PrivateRoute
							path="/category-mapping"
							permission="category-mapping"
							component={CategoryMapping}
						/>
						<PrivateRoute
							path="/category-ranking"
							permission="category-ranking"
							component={CategoryRanking}
						/>
						<PrivateRoute
							path="/category-overview"
							permission="category-overview"
							component={CategoryOverview}
						/>
						<PrivateRoute
							path="/monitoring"
							permission="product-monitoring"
							render={(props): ReactNode => <Crawler {...props} type="monitorings" />}
						/>
						<PrivateRoute
							path="/administration"
							permission="admin"
							component={UserAdministration}
						/>
						<PrivateRoute
							path="/slider"
							permission="slider"
							component={Slider}
						/>
						<PrivateRoute
							path="/comparator-installation"
							permission="comparator-installation"
							component={ComparatorInstallation}
						/>
						<PrivateRoute
							path="/kitchen-installation"
							permission="comparator-installation"
							component={KitchenInstallation}
						/>
						<PrivateRoute
							path="/advisors"
							permission="advisor"
							component={AdvisorOverview}
						/>
						<PrivateRoute
							path="/advisor/:advisorId/edit"
							permission="advisor"
							render={(props): ReactNode => <AdvisorEditor {...props} />}
						/>
						<PrivateRoute
							path="/comparators"
							permission="comparator"
							component={ComparatorOverview}
						/>
						<PrivateRoute
							path="/automated-comparators"
							permission="comparator"
							component={AutomatedComparatorOverview}
						/>
						<PrivateRoute
							path="/automated-comparator/:comparatorId/edit"
							permission="comparator"
							render={(props): ReactNode => <AutomatedComparatorEditor {...props} merge={false} />}
						/>
						<PrivateRoute
							path="/comparator-merge/:comparatorId"
							permission="comparator"
							render={(props): ReactNode => <ComparatorEditor {...props} merge={true} />}
						/>
						<PrivateRoute
							path="/automated-comparator-merge/:comparatorId"
							permission="comparator"
							render={(props): ReactNode => <AutomatedComparatorEditor {...props} merge={true} />}
						/>
						<PrivateRoute
							path="/comparator-productOverview"
							permission="comparator"
							component={ComparatorProductOverview}
						/>
						<PrivateRoute
							path="/comparator-analytics"
							permission="comparator"
							component={ComparatorAnalytics}
						/>
						<PrivateRoute
							path="/comparator-attribute-value-synonyms"
							permission="comparator"
							component={AttributeValueSynonymOverview}
						/>
						<PrivateRoute
							path="/comparator-export"
							permission="comparator"
							component={ComparatorExport}
						/>
						<PrivateRoute
							path="/comparator/:comparatorId/edit"
							permission="comparator"
							render={(props): ReactNode => <ComparatorEditor {...props} merge={false} />}
						/>
						<PrivateRoute
							path="/comparator-bulk/:comparatorId/edit"
							permission="comparator"
							component={ComparatorBulkEditor}
						/>
						<PrivateRoute
							path="/cms-content/:categoryCode/:contentId"
							permission="comparator"
							component={CmsContentEditor}
						/>
						<PrivateRoute
							path="/audit-logs"
							permission="audit-logs"
							component={AuditLogs}
						/>
						<PrivateRoute
							path="/series"
							permission="series"
							component={SeriesTable}
						/>
						<PrivateRoute
							permission=""
							component={Dashboard}
						/>
					</Switch>
				</div>
			</div>
		);
	}
}

export default withStyles(appStyles)(AppWrapper);
