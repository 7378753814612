export enum CmsColumnType {
	Text = 'text',
	Image = 'image',
	Link = 'link',
	Categories = 'categories'
}

export interface CmsColumn {
	type: CmsColumnType;
	data?: {
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
}

export interface CmsRow {
	columns: CmsColumn[];
}

export interface CmsContent {
	_id: string;
	categoryCode: string;

	topRows: CmsRow[];
	bottomRows: CmsRow[];
}
