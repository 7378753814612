import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const userEditorStyles = (theme: Theme): any => createStyles({
	deleteButton: {
		color: '#EE0000',
		marginRight: 'auto',
	},
	DialogActions: {
		display: 'flex',
	},

});

export default userEditorStyles;
