import React, { ReactNode, Component } from 'react';
import moment from 'moment';
import * as D3TimeFormat from 'd3-time-format';
import * as D3Time from 'd3-time';


// eslint-disable-next-line
/// <reference path="../../../dts/react-vis.d.ts"/>
import 'react-vis/dist/style.css';
import { FlexibleWidthXYPlot, XAxis, YAxis, HorizontalGridLines, VerticalGridLines, LineSeries, DiscreteColorLegend, Crosshair, LineSeriesPoint, RVNearestXData } from 'react-vis';
import { WithStyles, withStyles, Checkbox, FormControlLabel } from '@material-ui/core';

import AuthService from '@services/AuthService';
import overviewChartStyles from './OverviewChartStyles';


interface Props extends WithStyles<typeof overviewChartStyles>{
	selectedMethods: string[];
	fromDate?: moment.Moment;
	toDate?: moment.Moment;
}

interface State {
	selectedMethods: string[];
	reports: PaymentReports[];
	series: PlotSeries;
	seriesPercentage: PlotSeries;
	crosshairValues: PlotData[];
	fromDate?: moment.Moment;
	toDate?: moment.Moment;
	checkedPercent: boolean;
}

interface PaymentByMethodReport {
	paymentMethod: string;
	totalSum: number;
	orderCount: number;
}

interface PaymentReports {
	time: Date;
	orderCount: number;
	totalSum: number;
	paymentsByMethod: PaymentByMethodReport[];
}

interface TotalPaymentsResponse extends Response {
	success: boolean;
	message?: string;
	data: PaymentReports[];
}

interface PlotData {
	x: number;
	y: number;
}

interface PlotSeries {
	[id: string]: PlotData[];
}

interface PlotDescription {
	title: string;
	value: string;
}

class OverviewChart extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			reports: [],
			selectedMethods: props.selectedMethods,
			series: {},
			seriesPercentage: {},
			crosshairValues: [],
			fromDate: props.fromDate,
			toDate: props.toDate,
			checkedPercent: false
		};
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): object | null {
		if (nextProps.selectedMethods !== prevState.selectedMethods){
			return { selectedMethods: nextProps.selectedMethods};
		} else if (nextProps.fromDate !== prevState.fromDate || nextProps.toDate !== prevState.toDate){
			return { fromDate: nextProps.fromDate, toDate: nextProps.toDate};
		}

		return null;
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.selectedMethods !== this.state.selectedMethods){
			this.updateData();
		} else if (prevProps.fromDate !== this.state.fromDate || prevProps.toDate !== this.state.toDate){
			this.updateData();
		}
	}

	private hourlyData(): boolean {
		if (this.state.fromDate !== undefined && this.state.toDate !== undefined) {
			return (this.state.toDate.diff(this.state.fromDate, 'days', true) < 4);
		}

		return true;
	}

	private updateData(): void {
		let url = `/api/reporting/totalPayments?methods=${encodeURI(this.state.selectedMethods.join(','))}`;

		if (this.state.fromDate !== undefined && this.state.toDate !== undefined) {
			const hourly = (this.hourlyData()) ? 'true' : 'false';

			url += `&hourly=${hourly}`;
			url += `&timeFrom=${encodeURI(this.state.fromDate.toISOString())}`;
			url += `&timeTo=${encodeURI(this.state.toDate.toISOString())}`;
		}

		this.authService.fetch<TotalPaymentsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({reports: response.data});
				this.convertData(response.data);
			}
		});
	}

	private convertData(reports: PaymentReports[]): void {
		const series: PlotSeries = {};
		const seriesPercentage: PlotSeries = {};
		const timestamps: moment.Moment[] = [];

		if (reports.length > 0) {
			const timestamp = moment(reports.reverse()[0].time);

			const startDate = this.state.fromDate || timestamp.clone();
			const endDate = this.state.toDate || moment();

			while (timestamp.valueOf() > startDate.valueOf()) {
				timestamp.subtract(1, (this.hourlyData()) ? 'hour' : 'day');
			}

			while (timestamp.valueOf() <= endDate.valueOf()) {
				timestamps.push(timestamp.clone());
				timestamp.add(1, (this.hourlyData()) ? 'hour' : 'day');
			}
		}

		this.state.selectedMethods.forEach((method): void => {
			if (series[method] === undefined) {
				series[method] = [];
			}
			if (seriesPercentage[method] === undefined) {
				seriesPercentage[method] = [];
			}

			timestamps.forEach((timestamp): void => {
				let value = 0;

				const reportForTime = reports.find((r): boolean => (Math.abs(moment(r.time).diff(timestamp, (this.hourlyData()) ? 'hour' : 'day', true)) < 0.5));
				if (reportForTime) {
					const paymentMethodReport = reportForTime.paymentsByMethod.find((e): boolean => (e.paymentMethod === method));
					if (paymentMethodReport) {
						value = paymentMethodReport.totalSum;
					}
				}

				series[method].push({x: timestamp.clone().valueOf(), y: value});
			});
		});

		for (let index = 0; index < timestamps.length; index++) {
			let sum = 0;
			for (const method of this.state.selectedMethods) {
				sum += series[method][index].y;
			}

			let lastPercent = 0;
			for (const method of this.state.selectedMethods) {
				seriesPercentage[method].push({x: series[method][index].x, y: lastPercent + (series[method][index].y / sum)});
				lastPercent = seriesPercentage[method][index].y;
			}
		}
		this.setState({series: series, seriesPercentage: seriesPercentage});
	}

	private handlePercentCheckedChange(event: React.ChangeEvent<HTMLInputElement>): void  {
		if (this.state.checkedPercent){
			this.setState({checkedPercent: false});
		} else {
			this.setState({checkedPercent: true});
		}
	}

	private showGraphValues(crosshairTotals: number): ReactNode  {
		const classes = this.props.classes;
		if (!this.state.checkedPercent){
			return (
				this.state.selectedMethods.map((method, index): ReactNode => (
					<p key={index} className={classes.crosshairLine}>
						<span className={classes.crosshairMethod}>{method}:</span>
						<span className={classes.crosshairValue}>
							{this.state.crosshairValues[index].y.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
						</span>
					</p>
				)));
		} else {
			return (
				this.state.selectedMethods.map((method, index): ReactNode => (
					<p key={index} className={classes.crosshairLine}>
						<span className={classes.crosshairMethod}>{method}:</span>
						<span className={classes.crosshairValue}>
							{(this.state.crosshairValues[index].y/crosshairTotals).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
						</span>
					</p>
				)));
		}
	}

	private showGraphValuesTotal(crosshairTotals: number): ReactNode  {
		const classes = this.props.classes;
		if (!this.state.checkedPercent){
			return (
				<p key="Gesamt" className={classes.crosshairLine}>
					<span className={classes.crosshairMethod}><b>Gesamt:</b></span>
					<span className={classes.crosshairValue}>
						<b>{crosshairTotals.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
					</span>
				</p>
			);
		} else {
			return (
				<p key="Gesamt" className={classes.crosshairLine}>
					<span className={classes.crosshairMethod}><b>Gesamt:</b></span>
					<span className={classes.crosshairValue}>
						<b>100 %</b>
					</span>
				</p>
			);
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		let tickCount = 0;
		this.state.selectedMethods.forEach((method): void => {
			if (this.state.series[method]) {
				tickCount = this.state.series[method].length;
			}
		});
		tickCount = Math.min(tickCount, 14);

		return (
			<div className={classes.root}>
				<FormControlLabel
					control={
						<Checkbox
							checked={this.state.checkedPercent}
							onChange={(event): void => {this.handlePercentCheckedChange(event);}}
							value="percent"
							color="primary"
						/>
					}
					label="In Prozent"
				/>
				<FlexibleWidthXYPlot
					xType="time"
					height={350}
					onMouseLeave={this.onMouseLeave.bind(this)}
					margin={{left: 70, right: 10, top: 10, bottom: 40}}
				>
					<HorizontalGridLines />
					<VerticalGridLines
						tickTotal={tickCount}
					/>
					<XAxis
						tickFormat={(value): string => (this.formatTimeTick(value))}
						tickTotal={tickCount}
					/>
					{(!this.state.checkedPercent) ?
						<YAxis
							tickFormat={(value): string => (value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }))}
							width={70}
						/>:
						null}

					{this.state.selectedMethods.map((method): ReactNode => (
						<LineSeries
							key={method}
							data={((!this.state.checkedPercent) ? this.state.series[method] : this.state.seriesPercentage[method])}
							curve={'curveMonotoneX'}
							onNearestX={this.onNearestX.bind(this)}
						/>
					))}
					<Crosshair
						values={this.state.crosshairValues}
					>
						{this.renderCrosshair()}
					</Crosshair>
					<DiscreteColorLegend orientation="horizontal" items={this.state.selectedMethods} />
				</ FlexibleWidthXYPlot>
			</div>
		);
	}

	private renderCrosshair(): ReactNode {
		if (this.state.crosshairValues.length === 0) {
			return (<div></div>);
		}

		const classes = this.props.classes;
		let crosshairTotals = 0;

		this.state.selectedMethods.forEach((method, index): void => {
			crosshairTotals += this.state.crosshairValues[index].y;
		});

		return (
			<div className={classes.crosshair}>
				<h3 className={classes.crosshairTitle}>
					{moment(this.state.crosshairValues[0].x).format((this.hourlyData()) ? 'DD. MM. YYYY HH:mm' : 'DD. MM. YYYY')}
				</h3>
				{this.showGraphValues(crosshairTotals)}
				{this.showGraphValuesTotal(crosshairTotals)}
			</div>
		);
	}

	private formatTimeTick(date: Date): string {
		const formatMillisecond = D3TimeFormat.timeFormat('.%L'),
			formatSecond = D3TimeFormat.timeFormat(':%S'),
			formatMinute = D3TimeFormat.timeFormat('%H:%M'),
			formatHour = D3TimeFormat.timeFormat('%H Uhr'),
			formatDay = D3TimeFormat.timeFormat('%e. %b'),
			formatWeek = D3TimeFormat.timeFormat('%e. %b'),
			formatMonth = D3TimeFormat.timeFormat('%B'),
			formatYear = D3TimeFormat.timeFormat('%Y');

		return (D3Time.timeSecond(date) < date ? formatMillisecond
			: D3Time.timeMinute(date) < date ? formatSecond
				: D3Time.timeHour(date) < date ? formatMinute
					: D3Time.timeDay(date) < date ? formatHour
						: D3Time.timeMonth(date) < date ? (D3Time.timeWeek(date) < date ? formatDay : formatWeek)
							: D3Time.timeYear(date) < date ? formatMonth
								: formatYear)(date);
	}

	private onMouseLeave(): void {
		this.setState({crosshairValues: []});
	}

	private onNearestX(value: LineSeriesPoint, {index}: RVNearestXData<LineSeriesPoint>): void {
		let crosshairValues: PlotData[] = [];

		this.state.selectedMethods.forEach((method): void => {
			crosshairValues.push(this.state.series[method][index]);
		});

		const testValue = crosshairValues.find((v): boolean => (v.y > 0));
		if (!testValue) {
			crosshairValues = [];
		}

		this.setState({crosshairValues: crosshairValues});
	};
}

export default withStyles(overviewChartStyles)(OverviewChart);
