import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Button,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { AutomatedComparator } from '@models/AutomatedComparator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AutomatedComparatorProducts from '../AutomatedComparatorProducts';
import { Product } from '@common/models/Product';
import RefreshIcon from '@material-ui/icons/Refresh';

import automatedEditorProductSettingsStyles from './AutomatedEditorProductSettingsStyles';

interface Props extends WithStyles<typeof automatedEditorProductSettingsStyles> {
	automatedComparator: AutomatedComparator;
	refreshProductsButtonClicked: () => void;
	refreshProductsMissing: () => void;
	loadMissingProducts: boolean;
	productsMissing: Product[];
	productsByRules: Product[];
	loadingProducts: boolean;
	refreshed: boolean;
}

class AutomatedEditorProductSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.automatedComparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.automatedComparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Produkte</Typography>
					<Typography className={classes.panelSubtitle}>{this.props.loadMissingProducts ? this.props.productsMissing.length : this.props.productsByRules.length} Produkte</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						startIcon={<RefreshIcon color="secondary"/>}
						className={classes.productButton}
						onClick={this.props.refreshProductsButtonClicked.bind(this)}
					>
					Aktualisieren
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						size="small"
						className={classes.productButton}
						startIcon={<RefreshIcon color="secondary"/>}
						onClick={this.props.refreshProductsMissing.bind(this)}
					>
						Produkte die nicht vorkommen
					</Button>
					<AutomatedComparatorProducts
						comparator={this.props.automatedComparator}
						products={this.props.loadMissingProducts ? this.props.productsMissing : this.props.productsByRules}
						loading={this.props.loadingProducts}
						refreshed={this.props.refreshed}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(automatedEditorProductSettingsStyles)(AutomatedEditorProductSettings);
