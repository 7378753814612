import React, { ReactNode, Component } from 'react';
import { Prompt } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import {
	Typography,
	Paper,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	CircularProgress,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withDialog } from 'muibox';
import { withSnackbar, WithSnackbarProps } from 'notistack';

import comparatorBulkEditorStyles from './ComparatorBulkEditorStyles';

import ComparatorProductSelect from './ComparatorProductSelect';
import ComparatorAttributeSelect from './ComparatorAttributeSelect';

import CategorySelect from '@common/CategorySelect';
import { Category } from '@models/Category';
import { Product, ProductStatus, ProductInComparator, ProductAttribute } from '@models/Product';
import { Comparator } from '@models/Comparator';
import { SplitDetail, MergeDetail } from '@models/AutomatedComparator';

import ProductsService from '@services/ProductsService';
import AuthService from '@services/AuthService';

import EditorSplitSettings from './ComparatorEditorComponents/EditorSplitSettings';
import EditorAttributeSettings from './ComparatorEditorComponents/EditorAttributeSettings';
import EditorGeneralSettings from './ComparatorEditorComponents/EditorGeneralSettings';
import EditorPreview from './ComparatorEditorComponents/EditorPreview';

interface Params {
	comparatorId: string;
}

interface OptionType {
	label: string;
	value: string;
};

interface ComparatorResponse extends Response {
	success: boolean;
	data: Comparator;
	message?: string;
}

interface Props extends WithStyles<typeof comparatorBulkEditorStyles>, RouteComponentProps<Params>, WithSnackbarProps {
}

interface State {
	comparator: Comparator;
	savedComparator: Comparator;
	loading: boolean;
	loadingAttributeSettings: boolean;
	saving: boolean;
	selectedCategoryCode: string;
	selectedCategoryName: string;
	allProductsOfCategory: Product[];
	filteredProducts: Product[];
	selectedFilteredAttributes: string[];
	comparatorSaved: boolean;
}


class ComparatorBulkEditor extends Component<Props, State> {

	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.productsService = new ProductsService();
		const comparatorId = this.props.match.params.comparatorId;

		const dummyMergeDetail: MergeDetail = {
			mergeComparator: false,
			mergeComparators: []
		};

		const dummyComparator: Comparator = {
			_id: comparatorId,
			active: true,
			name: '',
			nameBackend: '',
			position: '',
			fullPage: false,
			sortedBy: 1,
			sortWeight: 1,
			products: [],
			attributes: [],
			missingCount: 0,
			categoryCode: '',
			mergeDetail: dummyMergeDetail,
			splitDetail: this.getDummySplitDetails()
		};

		this.state = {
			comparator: dummyComparator,
			savedComparator: dummyComparator,
			loading: (comparatorId !== 'new'),
			loadingAttributeSettings: false,
			saving: false,
			selectedCategoryCode: '',
			selectedCategoryName: '',
			allProductsOfCategory: [],
			filteredProducts: [],
			selectedFilteredAttributes: [],
			comparatorSaved: false
		};

	}

	private getDummySplitDetails(): SplitDetail {
		const dummySplitDetail: SplitDetail = {
			levels: [],
			idealNumberProducts: 10
		};

		return dummySplitDetail;
	}

	private handlesplitDetailChange(splitDetail: SplitDetail): void {
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.splitDetail = splitDetail;
		this.setState({comparator: comparator});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const title = 'Mehrere Produktvergleicher anlegen';

		return (
			<React.Fragment>
				{this.isDirty() &&
					<Prompt
						message='Wollen Sie Ihren Vergleich wirklich verlassen? Dabei gehen die bereits erstellten Daten verloren'
					/>
				}
				<Paper square={true} className={classes.root}>
					<Typography component="h2" variant="h4" className={classes.title}>
						{title}
					</Typography>
					{this.state.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}
					{!this.state.loading &&
						this.attributeSettings()
					}
					{!this.state.loading &&
						this.productSettings()
					}

					{!this.state.loading &&
						<EditorAttributeSettings
							comparator={this.state.comparator}
							handleAttributesChange={this.handleAttributesProductChange.bind(this)}
						/>
					}
					{!this.state.loading &&
						<EditorSplitSettings
							comparator={this.state.comparator}
							getAttributeOptions={this.getAttributeOptions.bind(this)}
							handlesplitDetailChange={this.handlesplitDetailChange.bind(this)}
							selectedCategoryCode={this.state.selectedCategoryCode}
						/>
					}
					{!this.state.loading &&
						<EditorGeneralSettings
							comparator={this.state.comparator}
							handleComparatorChange={this.handleComparatorChange.bind(this)}
							deleteComparatorButtonPressed={null}
							isBulk={true}
						/>
					}
					{!this.state.loading &&
						<EditorPreview
							comparator={this.state.comparator}
							handleSaveButton={this.handleSaveButton.bind(this)}
							saving={this.state.saving}
							merge={false}
							handleMergeInactiveSaveButton={null}
						/>
					}
				</Paper>
			</React.Fragment>
		);

	}

	private handleAttributesProductChange(selectedAttributes: string[]): void {
		if (this.state.comparatorSaved) {
			return;
		}
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.attributes = selectedAttributes;
		this.setState({comparator: comparator});

	}

	private async handleSaveButton(): Promise<void> {
		// if comparator already saved -> get new comparator id to save in db
		if (this.state.comparatorSaved) {
			const newComparator: Comparator = this.state.comparator;
			newComparator._id = this.props.match.params.comparatorId;
			this.setState({comparator: newComparator});
		}
		this.saveComparator();
	}

	private async saveComparator(): Promise<void> {
		this.setState({saving: true, comparatorSaved: true});
		try {
			// fix products
			const json = JSON.stringify(this.state.comparator);
			//eslint-disable-next-line
			const comparator: any = JSON.parse(json);

			const response = await this.authService.fetch<ComparatorResponse>('/api/comparators/new', {
				method: 'POST',
				body: JSON.stringify(comparator)
			});

			if (response.data) {

				const comparatorId = response.data._id;
				this.props.enqueueSnackbar(`Produktvergleicher mit der ID ${comparatorId} erfolgreich gespeichert!`, {variant: 'success'});

				for (const selectedProduct of this.state.comparator.products) {
					for (const product of this.state.allProductsOfCategory) {
						if (selectedProduct.productCode === product.productCode) {
							const productInComparator: ProductInComparator = {
								_id: comparatorId,
								name: this.state.comparator.name
							};
							if (product.usedInComparators) {
								product.usedInComparators.push(productInComparator);
							} else {
								const usedInComparators: ProductInComparator[] = [];
								usedInComparators.push(productInComparator);
								product.usedInComparators = usedInComparators;
							}

						}
					}
				}

				const filteredProducts: Product[] = this.generateFilteredProducts(this.state.selectedFilteredAttributes);

				const json = JSON.stringify(this.state.comparator);
				const comparator: Comparator = JSON.parse(json);
				comparator.products = [];
				this.setState({filteredProducts: filteredProducts, comparator: comparator});
			}
		} catch (error) {
			console.log(error);
		} finally {
			this.setState({saving: false});
		}
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleComparatorChange(key: keyof Comparator, event: any): void {
		if (!this.state.comparator) {
			return;
		}

		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		this.setState((state: State): any => ({comparator: {...state.comparator, [key]: value }}));
	}

	private checkProductHasAllSelectedAttributes(selectedAttributes: string[], attributesOfProduct: string[]): boolean {
		return selectedAttributes.every(i => attributesOfProduct.includes(i));
	}

	private productSettings(): ReactNode {
		const classes = this.props.classes;

		if (!this.state.comparator) {
			return;
		}

		return (
			<ExpansionPanel>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Produkte</Typography>
					<Typography className={classes.panelSubtitle}>{this.state.comparator.products.length} Produkte ausgewählt</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<ComparatorProductSelect
						onChange={this.handleProductsChange.bind(this)}
						selectedCategoryCode={null}
						productsToDisplay={this.state.filteredProducts}
						selectedProductCodes={this.state.comparator.products.map((p) => p.productCode)}
						comparatorId={'new'}
						loadUsedInComparators={true}
						disabled={false}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	private handleProductsChange(selectedProducts: Product[]): void {
		const json = JSON.stringify(this.state.comparator);
		const comparator: Comparator = JSON.parse(json);
		comparator.products = selectedProducts;
		this.setState({comparator: comparator});
	}

	private getAttributeOptions(): OptionType[] {
		const options: OptionType[] = [];

		let attributes: ProductAttribute[] = [];

		for (const product of this.state.comparator.products) {
			for (const attribute of product.attributes) {

				if (attributes.filter(function (a) { return a.key === attribute.key; }).length === 0) {
					attributes.push(attribute);
				}
			}
		}

		attributes = attributes.sort((a, b): number => {
			if (a.name.toLowerCase() > b.name.toLowerCase()) {
				return 1;
			}

			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}

			return 0;
		});

		attributes.forEach((attribute: ProductAttribute): void => {
			options.push({value: attribute.key, label: attribute.name});
		});

		return options;
	}

	private attributeSettings(): ReactNode {
		const classes = this.props.classes;

		if (!this.state.comparator) {
			return;
		}

		let subtitle = `${this.state.selectedFilteredAttributes.length} Attribute ausgewählt`;
		if (this.state.selectedCategoryName !== '') {
			subtitle = `${this.state.selectedCategoryName}, ${subtitle}`;
		}

		return (
			<ExpansionPanel defaultExpanded={true}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Kategorie</Typography>
					<Typography className={classes.panelSubtitle}>{subtitle}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<div className={classes.categorySelect}>
						<CategorySelect
							onChange={this.handleCategoryChange.bind(this)}
							selectedCategoryCode={this.state.selectedCategoryCode}
							topLevelCategoriesExcluded={false}
							disabled={false}
						/>
					</div>
					<ComparatorAttributeSelect
						onChange={this.handleAttributesChange.bind(this)}
						onChangeDelimiterRemoveAttributes={null}
						selectedProducts={this.state.allProductsOfCategory}
						selectedAttributeCodes={this.state.selectedFilteredAttributes}
						sortingEnabled={false}
						templatesEnabled={false}
						loadingAttributeSettings={this.state.loadingAttributeSettings}
						maintainedEnabled={false}
						categoryFeed={false}
						type={'comparator'}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	private handleAttributesChange(selectedAttributes: string[]): void {
		const filteredProducts: Product[] = this.generateFilteredProducts(selectedAttributes);
		this.setState({selectedFilteredAttributes: selectedAttributes, filteredProducts: filteredProducts});
	}

	private generateFilteredProducts(selectedAttributes: string[]): Product[] {
		const attributeKeys = selectedAttributes;
		return this.state.allProductsOfCategory.filter((p) => {
			const productAttributes = p.attributes.map((a) => a.key);
			return this.checkProductHasAllSelectedAttributes(attributeKeys, productAttributes);
		});
	}

	private async handleCategoryChange(selectedCategory: Category | null): Promise<void> {
		this.setState({loadingAttributeSettings: true});
		let products = await this.productsService.loadProductsInCategory(selectedCategory ? selectedCategory.code : '', true);
		products = products.filter((p) => p.status === ProductStatus.Online);

		const comparator: Comparator = this.state.comparator;
		comparator.products = [];
		comparator.attributes = [];
		comparator.categoryCode = (selectedCategory ? selectedCategory.code : '');

		this.setState({
			selectedCategoryCode: (selectedCategory ? selectedCategory.code : ''),
			selectedCategoryName: (selectedCategory ? selectedCategory.name : ''),
			allProductsOfCategory: products,
			filteredProducts: [],
			comparator: comparator,
			selectedFilteredAttributes: [],
			loadingAttributeSettings: false
		});
	}

	private isDirty(): boolean {
		const c1 = this.state.comparator;
		const c2 = this.state.savedComparator;

		if (c1.active !== c2.active ||
			c1.name !== c2.name ||
			c1.position !== c2.position ||
			c1.fullPage !== c2.fullPage ||
			c1.sortedBy !== c2.sortedBy ||
			c1.sortWeight !== c2.sortWeight ||
			c1.products.length !== c2.products.length ||
			c1.attributes !== c2.attributes) {
			return true;
		}

		for (const product of c1.products) {
			if (c2.products.find(this.findProductWithCode.bind(this, product.productCode)) === undefined) {
				return true;
			}
		}

		return false;
	}

	private findProductWithCode(pCode: string, p: Product): boolean {
		return p.productCode === pCode;
	}

}

export default withSnackbar(withDialog()(withStyles(comparatorBulkEditorStyles)(ComparatorBulkEditor)));
