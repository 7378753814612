import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorProductOverviewStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
		marginBottom: theme.spacing(8),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	productComparatorColumn: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	warningIcon: {
		marginLeft: '25px'
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		marginBottom: theme.spacing(2)
	},
	link: {
		margin: '0',
		cursor: 'pointer'
	},
	productImage: {
		height: 56,
		width: 56,
		objectFit: 'contain',
		display: 'flex',
		justifyContent: 'center'
	},
	productPrice: {
		display: 'flex',
		justifyContent: 'center'
	},
	selectExportContainer: {
		display: 'flex'
	},
	categorySelect: {
		flex: 1,
		marginRight: theme.spacing(3)
	}
});

export default comparatorProductOverviewStyles;
