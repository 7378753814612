import React, { ReactNode, Component, ReactElement } from 'react';
import {
	Checkbox,
	ClickAwayListener,
	Typography,
	TextField,
	CircularProgress,
	ListItemText,
	Button,
	Grow,
	Select,
	MenuList,
	MenuItem,
	Tooltip,
	Paper,
	Popper,
	WithStyles,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';
import EditIcon from '@material-ui/icons/Edit';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import Moment from 'react-moment';
import TableLocalization from '@common/TableLocalization';
import crawlerTableStyles from './CrawlerTableStyles';
import { CrawlerModel, CrawlerType, FolderType } from '@models/Crawler';

interface Props extends WithStyles<typeof crawlerTableStyles>{
	crawlers: CrawlerModel[];
	startHandler: (crawler: CrawlerModel) => void;
	editHandler: (crawler: CrawlerModel) => void;
	title: string;
	description: string;
	type: string;
	filterCrawlers: (folderFilter: string, calledFromTable: boolean) => void;
}

interface State {
	downloadAnchorElement: HTMLElement | null;
	downloadActiveCrawler: number;
	selectedFilters: string[];
}

class CrawlerTable extends Component<Props, State> {
	public constructor(props: Props) {
		super(props);
		this.state = {
			downloadAnchorElement: null,
			downloadActiveCrawler: -1,
			selectedFilters: []
		};
	}

	private startButtonClicked(crawler: CrawlerModel): void {
		this.props.startHandler(crawler);
	}

	private editButtonClicked(crawler: CrawlerModel): void {
		this.props.editHandler(crawler);
	}

	private getLink(crawler: CrawlerModel): string {
		let link = `${window.location.origin}/download/${crawler._id}/latest/exported.csv`;

		if (crawler.type === CrawlerType.CategoryFeed || crawler.type === CrawlerType.MultiCategoryFeed) {
			link = `${window.location.origin}/api/crawlers/download/${crawler._id}/export.csv`;
		}
		return link;
	}

	private handleMenuClick( crawlerId: number, event: React.MouseEvent<HTMLButtonElement>): void {
		this.setState({downloadAnchorElement: event.currentTarget, downloadActiveCrawler: crawlerId});
	}

	private handleMenuClose(downloadLink = ''): void {
		if (downloadLink !== '') {
			window.open(downloadLink, '_blank');
		}

		this.setState({downloadAnchorElement: null, downloadActiveCrawler: -1});
	}

	private downloadButton(crawler: CrawlerModel): ReactNode {
		if (crawler.type === CrawlerType.Monitoring) {
			return null;
		}

		const classes = this.props.classes;
		const link = this.getLink(crawler);

		if (crawler.type === CrawlerType.CategoryFeed || crawler.type === CrawlerType.MultiCategoryFeed) {
			const jsonLink = link.replace('.csv', '.json');
			return (
				<React.Fragment>
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={this.handleMenuClick.bind(this, crawler._id)}
						className={classes.downloadButton}
						color="primary"
						startIcon={<GetAppIcon color="primary"/>}
						variant="outlined"
					>
						Download
					</Button>

					<Popper open={Boolean(this.state.downloadAnchorElement && this.state.downloadActiveCrawler === crawler._id)} anchorEl={this.state.downloadAnchorElement} role={undefined} style={{ zIndex: 1 }} transition disablePortal>
						{({ TransitionProps, placement }): ReactNode => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
							>
								<Paper>
									<ClickAwayListener onClickAway={this.handleMenuClose.bind(this, '')}>
										<MenuList id="menu-list-grow">
											<MenuItem onClick={this.handleMenuClose.bind(this, link)}>CSV</MenuItem>
											<MenuItem onClick={this.handleMenuClose.bind(this, jsonLink)}>JSON</MenuItem>
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>

				</React.Fragment>
			);
		}

		return (
			<Button
				className={classes.downloadButton}
				color="primary"
				disabled={crawler.status === 1}
				download
				href={link}
				startIcon={<GetAppIcon color="primary"/>}
				variant="outlined"
			>
				Download
			</Button>

		);
	}

	private downloadTextField(crawler: CrawlerModel): ReactNode {
		if (crawler.type === CrawlerType.Monitoring) {
			return null;
		}

		const classes = this.props.classes;
		const link = this.getLink(crawler);

		return (<TextField
			value={link}
			className={classes.downloadTextField}
			margin="normal"
			disabled={crawler.status === 1}
			fullWidth={true}
			onFocus={(event): void => {
				event.target.select();
			}}
			InputProps={{
				readOnly: true,
			}}
		/>);

	}

	private getTitleAndDescription(): ReactElement {
		const classes = this.props.classes;

		return (
			<div>
				<Typography
					component="h1"
					variant="h4"
					className={classes.title}
				>
					{this.props.title}
				</Typography>
				<Typography
					component="h2"
					variant="body1"
					className={classes.description}
				>
					{this.props.description}
				</Typography>
			</div>
		);
	}

	private handleFilterChange(event: React.ChangeEvent<{name?: string | undefined; value: unknown}>): void {
		const currentSelectedFilters = event.target.value as string[];
		this.setState({selectedFilters: currentSelectedFilters}, () => {
			this.props.filterCrawlers((event.target.value as string[]).join(','), true);
		});
	}

	private getCustomFilterComponent(): ReactElement {
		const filterOptions = Object.values(FolderType);
		return (
			<Select
				multiple
				style={{minWidth: 150, maxWidth: 210}}
				value={this.state.selectedFilters}
				onChange={this.handleFilterChange.bind(this)}
				renderValue={(): string => this.state.selectedFilters.join(', ')}
			>
				{filterOptions.map((filter) => (
					<MenuItem key={filter} value={filter}>
						<Checkbox checked={this.state.selectedFilters.includes(filter)} />
						<ListItemText primary={filter} />
					</MenuItem>
				))}
			</Select>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		return (
			<MaterialTable
				columns={[
					{
						title: 'Name',
						field: 'name' as const,
						sorting: true,
						filtering: false,
						cellStyle: {
							fontSize: 18
						}
					},
					{
						title: 'Ordner',
						field: 'folderType' as const,
						hidden: this.props.type === 'feeds' ? false : true,
						sorting: false,
						filtering: true,
						filterComponent: (): ReactElement => this.getCustomFilterComponent(),
						cellStyle: {
							fontSize: 18,
						},
					},
					{
						field: 'downloadText' as const,
						filtering: false,
						render: (crawler): ReactNode => {
							return this.downloadTextField(crawler);
						},
					},
					{
						field: 'buttons' as const,
						filtering: false,
						cellStyle: {
							textAlign: 'right',
							whiteSpace: 'nowrap'
						},
						render: (crawler): ReactNode => {
							return (
								<div>
									<Tooltip title={<Moment format="DD.MM.YYYY HH:mm" date={crawler.lastRunDate} locale="de" />}>
										<span>
											<Button
												onClick={this.startButtonClicked.bind(this, crawler)}
												color="primary"
												disabled={crawler.status === 1 || (crawler.type === CrawlerType.MultiCategoryFeed && crawler.multiFeeds && crawler.multiFeeds.length === 0)}
												startIcon={<PlayCircleFilledWhiteIcon color="primary"/>}
												variant="outlined"
												className={classes.startButton}
											>
											Start
												{crawler.status === 1  && <CircularProgress size={24} className={classes.buttonProgress} />}
											</Button>
										</span>
									</Tooltip>
									<Button
										onClick={this.editButtonClicked.bind(this, crawler)}
										color="primary"
										disabled={crawler.status === 1}
										startIcon={<EditIcon color="primary"/>}
										variant="outlined"
										className={classes.editButton}
									>
									Bearbeiten
									</Button>
									{this.downloadButton(crawler)}
								</div>
							);
						}
					},
				]}
				options={{
					search: false,
					filtering: true,
					columnsButton: false,
					paging: false,
					emptyRowsWhenPaging: false,
					draggable: false,
				}}
				style={{
					borderRadius: 0,
					width: '100%',
					marginBottom: '60px'
				}}
				data={ this.props.crawlers }
				title={ this.getTitleAndDescription() }
				localization={ TableLocalization.localization }
			/>
		);
	}
}

export default withStyles(crawlerTableStyles)(CrawlerTable);
