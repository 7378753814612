import React, { ReactNode, Component } from 'react';
import {
	Button,
	TextField,
	FormControl,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ruleGroupEditorStyles from './RuleGroupEditorStyles';
import { RuleGroup } from '@models/AutomatedComparator';

interface Props extends WithStyles<typeof ruleGroupEditorStyles> {
	ruleGroup: RuleGroup;
	ruleGroupEditorOpen: boolean;
	closeHandler: () => void;
	ruleGroupHandler: (ruleGroup: RuleGroup) => void;
}

interface State {
	currentRuleGroup: RuleGroup;
}

class RuleGroupEditor extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		this.state = {
			currentRuleGroup: props.ruleGroup
		};
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (prevProps.ruleGroup !== this.props.ruleGroup) {
			this.setState({
				currentRuleGroup: this.props.ruleGroup
			});
		}
	}

	private saveButtonClicked(): void {
		this.props.ruleGroupHandler(this.state.currentRuleGroup);
	}

	private cancelButtonClicked(): void {
		this.props.closeHandler();
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleRuleGroupNameChange(event: any): void {
		if (this.state.currentRuleGroup) {
			const ruleGroupName = event.target.value;
			const currentRuleGroup = this.state.currentRuleGroup;

			currentRuleGroup.name = ruleGroupName;

			this.setState({
				currentRuleGroup: currentRuleGroup
			});
		}
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.ruleGroupEditorOpen} onClose={this.props.closeHandler} fullWidth={true}>
				<DialogTitle id="form-dialog-title">Neue Regelgruppe anlegen</DialogTitle>
				<DialogContent>
					<FormControl className={classes.formControl}>
						<TextField
							required
							fullWidth
							className={classes.textInput}
							label="Name"
							value={(this.state.currentRuleGroup) ? this.state.currentRuleGroup.name : ''}
							onChange={(event): void => {this.handleRuleGroupNameChange(event);}}
						/>
					</FormControl>

				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					<Button onClick={this.cancelButtonClicked.bind(this)} color="primary">
					Abbrechen
					</Button>
					<Button onClick={this.saveButtonClicked.bind(this)} color="primary">
					Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

}

export default withStyles(ruleGroupEditorStyles)(RuleGroupEditor);
