import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const attributeValueSynonymEditorStyles = (theme: Theme): any => createStyles({
	deleteButton: {
		color: '#EE0000',
		marginRight: 'auto',
	},
	deleteAttributeValueButton: {
		color: '#EE0000',
		borderColor: '#EE0000',
		float: 'right'
	},
	dialogActions: {
		display: 'flex',
	},
	attributeValueAddContainer: {
		display: 'flex',
		marginTop: theme.spacing(3),
	},
	addAttributeValueButton: {
		alignSelf: 'flex-end',
		marginBottom: '4px',
		marginRight: theme.spacing(2)
	},
	attributeValueTextField: {
		width: '100%',
		marginRight: '8px'
	},
	attributeValueContainsFieldsContainer: {
		marginTop: theme.spacing(5),
		marginBottom: theme.spacing(5)
	},
	selectedAttributeValuesTable: {
		marginBottom: '16px'
	},
	selectedAttributeValueTableHeader: {
		marginTop: theme.spacing(3)
	},
	radioButtons: {
		marginTop: theme.spacing(3)
	},
	categorySelect: {
		display: 'block',
		width: 'calc(100%)',
		marginTop: theme.spacing(3)
	},
	attributeCheckbox: {
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: '33%',
		minWidth: 350,
		maxWidth: '33%',
		margin: 0,
	},
	checkbox: {
		padding: theme.spacing(0.5),
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	containedValueDeleteButton: {
		color: '#EE0000',
		marginLeft: theme.spacing(1)
	},
	subtitle: {
		marginTop: theme.spacing(4)
	}
});

export default attributeValueSynonymEditorStyles;
