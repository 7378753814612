import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsComparatorStatisticsStyles = (theme: Theme): any => createStyles({
	totalHeader: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(2),
	}
});

export default analyticsComparatorStatisticsStyles;
