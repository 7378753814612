import { DiagramEngine, PortModel } from '@projectstorm/react-diagrams';
import { QuestionLinkModel } from './QuestionLinkModel';
import { PortType } from './PortFactory';
import { AdvisorQuestion } from '@common/models/Advisor';

export class QuestionPortModel extends PortModel {
	portType: PortType;
	private handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void;

	constructor(handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void, portType: PortType, maximumLinks: number, name = '', id = '') {
		super(name, 'port', id, maximumLinks);
		this.handleQuestionChange = handleQuestionChange;
		this.portType = portType;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	serialize(): any {
		return JSON.parse(JSON.stringify({
			name: this.name
		}));
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deSerialize(data: any, engine: DiagramEngine): void {
		super.deSerialize(data, engine);
		this.name = data.name;
	}

	createLinkModel(): QuestionLinkModel | null {
		return new QuestionLinkModel(this.handleQuestionChange);
	}
}