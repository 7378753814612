import React, { ReactNode, Component } from 'react';
import {
	Button,
	Paper,
	WithStyles,
	withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import CmsContentRow from './CmsContentRow';

import { CmsRow } from '@models/CmsContent';

import cmsContentRowStyles from './CmsContentRowsEditorStyles';

interface Props extends WithStyles<typeof cmsContentRowStyles> {
	rows: CmsRow[];
	onChange: (newRows: CmsRow[]) => void;
	categoryCode: string;
}

class CmsContentRowsEditor extends Component<Props> {
	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				{this.props.rows.map((row, index) => (
					<Paper key={index} className={classes.row} square elevation={2}>
						{`Zeile #${index + 1}:`}
						<CmsContentRow
							row={row}
							onChange={(newRow: CmsRow): void => {
								const rows = this.props.rows;
								rows[index] = newRow;

								this.props.onChange(rows);
							}}
							categoryCode={this.props.categoryCode}
						/>
						<Button
							variant="outlined"
							color="secondary"
							size="small"
							startIcon={<DeleteIcon />}
							className={classes.deleteButton}
							onClick={(): void => {
								const rows = this.props.rows;
								rows.splice(index, 1);
								this.props.onChange(rows);
							}}
						>
							Zeile Löschen
						</Button>
					</Paper>
				))}
				<Button
					variant="outlined"
					color="primary"
					onClick={(): void => {
						const rows = this.props.rows;
						rows.push({
							columns: []
						});

						this.props.onChange(rows);
					}}
				>
					Neue Zeile
				</Button>
			</div>
		);
	}
}

export default withStyles(cmsContentRowStyles)(CmsContentRowsEditor);
