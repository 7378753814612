import React, { ReactNode, Component } from 'react';
import {
	Typography,
	Paper,
	Link,
	WithStyles,
	CircularProgress,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	withStyles
} from '@material-ui/core';

import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';

import AnalyticsDateSelector from './AnalyticsDateSelector';
import AnalyticsDetails from './AnalyticsDetails';
import AnalyticsBestProduct from './AnalyticsBestProduct';
import AnalyticsComparatorStatistics from './AnalyticsComparatorStatistics';

import comparatorAnalyticsStyles from './ComparatorAnalyticsStyles';

import { Comparator } from '@models/Comparator';
import { AnalyticsModel } from '@models/AnalyticsModel';


import AuthService from '@services/AuthService';

interface State {
	comparators: Comparator[];
	analytics: AnalyticsModel[];
	isLoadingComparators: boolean;
	isLoadingAnalytics: boolean;
	dateString: string;
}

interface ComparatorsResponse {
	data: Comparator[];
	success: boolean;
	message?: string;
}

interface AnalyticsResponse {
	data: AnalyticsModel[];
	success: boolean;
	message?: string;
}

class ComparatorAnalytics extends Component<WithStyles, State> {

	private authService: AuthService;

	public constructor(props: WithStyles) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			comparators: [],
			analytics: [],
			isLoadingComparators: false,
			isLoadingAnalytics: false,
			dateString: ''
		};
	}

	public componentDidMount(): void {
		this.loadComparators();
	}

	private loadComparators(): void {
		this.setState({comparators: [], isLoadingComparators: true});
		this.authService.fetch<ComparatorsResponse>('/api/comparators', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({comparators: response.data, isLoadingComparators: false});
				this.loadAnalytics(this.state.dateString);
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadAnalytics(dateString: string): void {
		if (this.state.isLoadingComparators) {
			return;
		}

		this.setState({isLoadingAnalytics: true});
		this.setState({analytics: []});
		this.setState({isLoadingAnalytics: false});
	}

	private comparatorWithId(id: string): Comparator | null {
		const comparator = this.state.comparators.find((c: Comparator) => `${c._id}` === `${id}`);
		if (comparator) {
			return comparator;
		}

		return null;
	}

	private summary(): ReactNode {
		if (this.state.analytics.length === 0 || this.state.isLoadingAnalytics || this.state.isLoadingComparators) {
			return (<div />);
		}

		let totalImpressions = 0;
		let totalMobileImpressions = 0;
		let totalDesktopImpressions = 0;

		let totalClicks = 0;
		let totalMobileClicks = 0;
		let totalDesktopClicks = 0;

		let totalImageClicks = 0;
		let totalImageClicksMobile = 0;
		let totalImageClicksDesktop = 0;

		let totalButtonClicks = 0;
		let totalButtonClicksMobile = 0;
		let totalButtonClicksDesktop = 0;

		let totalNavigations = 0;
		let totalMobileNavigations = 0;
		let totalDesktopNavigations = 0;

		let totalAttributeToggleClicks = 0;
		let totalMobileAttributeToggleClicks = 0;
		let totalDesktopAttributeToggleClicks = 0;

		let totalCTAClicks = 0;
		let totalMobileCTAClicks = 0;
		let totalDesktopCTAClicks = 0;

		for (const a of this.state.analytics) {
			totalImpressions += a.impressions;
			totalMobileImpressions += a.impressionsMobile;
			totalDesktopImpressions += a.impressionsDesktop;

			totalClicks += a.clicks;
			totalMobileClicks += a.clicksMobile;
			totalDesktopClicks += a.clicksDesktop;

			totalImageClicks += a.imageClicks;
			totalImageClicksMobile += a.imageClicksMobile;
			totalImageClicksDesktop += a.imageClicksDesktop;

			totalButtonClicks += a.buttonClicks;
			totalButtonClicksMobile += a.buttonClicksMobile;
			totalButtonClicksDesktop += a.buttonClicksDesktop;

			totalNavigations += (a.leftNavCounter + a.rightNavCounter);
			totalMobileNavigations += (a.leftNavCounterMobile + a.rightNavCounterMobile);
			totalDesktopNavigations += (a.leftNavCounterDesktop + a.rightNavCounterDesktop);

			totalAttributeToggleClicks += a.attributeToggleCounter;
			totalMobileAttributeToggleClicks += a.attributeToggleCounterMobile;
			totalDesktopAttributeToggleClicks += a.attributeToggleCounterDesktop;

			totalCTAClicks += a.attributeToggleCounter;
			totalMobileCTAClicks += a.attributeToggleCounterMobile;
			totalDesktopCTAClicks += a.attributeToggleCounterDesktop;
		}

		const desktopImpressionsPercent = (totalDesktopImpressions / totalImpressions).toLocaleString('de-DE', { style: 'percent' });
		const mobileImpressionPercent = (totalMobileImpressions / totalImpressions).toLocaleString('de-DE', { style: 'percent' });

		const clickThroughRate = (totalClicks / totalImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const desktopClickThroughRate = (totalDesktopClicks / totalDesktopImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const mobileClickThroughRate = (totalMobileClicks / totalMobileImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const imageClickPercent = (totalImageClicks / totalClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const imageClickPercentMobile = (totalImageClicksMobile / totalMobileClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const imageClickPercentDesktop = (totalImageClicksDesktop / totalDesktopClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const buttonClickPercent = (totalButtonClicks / totalClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const buttonClickPercentMobile = (totalButtonClicksMobile / totalMobileClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const buttonClickPercentDesktop = (totalButtonClicksDesktop / totalDesktopClicks).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const navigationPercent = (totalNavigations / totalImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const desktopNavigationPercent = (totalDesktopNavigations / totalDesktopImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const mobileNavigationPercent = (totalMobileNavigations / totalMobileImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const totalAttributeTogglePercent = (totalAttributeToggleClicks / totalImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const desktopAttributeTogglePercent = (totalDesktopAttributeToggleClicks / totalDesktopImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const mobileAttributeTogglePercent = (totalMobileAttributeToggleClicks / totalMobileImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const totalCTAPercent = (totalCTAClicks / totalImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const desktopCTAPercent = (totalDesktopCTAClicks / totalDesktopImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });
		const mobileCTAPercent = (totalMobileCTAClicks / totalMobileImpressions).toLocaleString('de-DE', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 });

		const classes = this.props.classes;

		return (
			<div>
				<Typography component="h3" variant="h5" className={classes.totalHeader}>
					{'Gesamt Analyse'}
				</Typography>
				<TableContainer component={Paper} className={classes.totalTable}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Metrik</TableCell>
								<TableCell align="right">Gesamt</TableCell>
								<TableCell align="right">Desktop</TableCell>
								<TableCell align="right">Mobil</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row">Impressionen</TableCell>
								<TableCell align="right">{totalImpressions.toLocaleString('de-DE')}</TableCell>
								<TableCell align="right">{totalDesktopImpressions.toLocaleString('de-DE')} ({desktopImpressionsPercent})</TableCell>
								<TableCell align="right">{totalMobileImpressions.toLocaleString('de-DE')} ({mobileImpressionPercent})</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Clicks</TableCell>
								<TableCell align="right">{totalClicks.toLocaleString('de-DE')}</TableCell>
								<TableCell align="right">{totalDesktopClicks.toLocaleString('de-DE')}</TableCell>
								<TableCell align="right">{totalMobileClicks.toLocaleString('de-DE')}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Click-Through-Rate</TableCell>
								<TableCell align="right">{clickThroughRate}</TableCell>
								<TableCell align="right">{desktopClickThroughRate}</TableCell>
								<TableCell align="right">{mobileClickThroughRate}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Bild-Clicks</TableCell>
								<TableCell align="right">{imageClickPercent}</TableCell>
								<TableCell align="right">{imageClickPercentDesktop}</TableCell>
								<TableCell align="right">{imageClickPercentMobile}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Button-Clicks</TableCell>
								<TableCell align="right">{buttonClickPercent}</TableCell>
								<TableCell align="right">{buttonClickPercentDesktop}</TableCell>
								<TableCell align="right">{buttonClickPercentMobile}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Navigations-Rate</TableCell>
								<TableCell align="right">{navigationPercent}</TableCell>
								<TableCell align="right">{desktopNavigationPercent}</TableCell>
								<TableCell align="right">{mobileNavigationPercent}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Attribut Umschalter</TableCell>
								<TableCell align="right">{totalAttributeTogglePercent}</TableCell>
								<TableCell align="right">{desktopAttributeTogglePercent}</TableCell>
								<TableCell align="right">{mobileAttributeTogglePercent}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">CTA Klicks</TableCell>
								<TableCell align="right">{totalCTAPercent}</TableCell>
								<TableCell align="right">{desktopCTAPercent}</TableCell>
								<TableCell align="right">{mobileCTAPercent}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Produktvergleicher Analyse'}
				</Typography>
				<AnalyticsDateSelector onChange={this.handleDateChange.bind(this)} />
				{(this.state.isLoadingAnalytics ||  this.state.isLoadingComparators) &&
					<CircularProgress className={classes.loadingIndicator}/>
				}
				{(!this.state.isLoadingAnalytics &&  !this.state.isLoadingComparators) &&
					<MaterialTable
						columns={[
							{
								title: 'ID',
								field: 'ComparatorID' as const,
							},
							{
								title: 'Name',
								field: '_comparatorName' as const,
								render: (analyticsModel): ReactNode => {
									return (
										<Link href={`/comparator/${analyticsModel.ComparatorID}/edit`}>{analyticsModel._comparatorName}</Link>
									);
								},
							},
							{
								title: 'Bestes Produkt',
								field: '_bestProduct' as const,
								render: (analyticsModel): ReactNode => {
									return (<AnalyticsBestProduct analytics={analyticsModel}/>);
								},
							},
							{
								title: 'Impressionen (Gesamt)',
								field: 'impressions' as const,
							},
							{
								title: 'Impressionen (Mobil)',
								field: 'impressionsMobile' as const,
								hidden: true,
							},
							{
								title: 'Impressionen (Desktop)',
								field: 'impressionsDesktop' as const,
								hidden: true,
							},
							{
								title: 'Navigation (Links)',
								field: 'leftNavCounter' as const,
								hidden: true,
							},
							{
								title: 'Navigation (Rechts)',
								field: 'rightNavCounter' as const,
								hidden: true,
							},
							{
								title: 'Navigation (Mobil, Links)',
								field: 'leftNavCounterMobile' as const,
								hidden: true,
							},
							{
								title: 'Navigation (Mobil, Rechts)',
								field: 'rightNavCounterMobile' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Gesamt)',
								field: 'clicks' as const,
							},
							{
								title: 'Clicks (Mobil)',
								field: 'clicksMobile' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Desktop)',
								field: 'clicksDesktop' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Button, Gesamt)',
								field: 'buttonClicks' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Button, Mobil)',
								field: 'buttonClicksMobile' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Button, Desktop)',
								field: 'buttonClicksDesktop' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Bild, Gesamt)',
								field: 'imageClicks' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Bild, Mobil)',
								field: 'imageClicksMobile' as const,
								hidden: true,
							},
							{
								title: 'Clicks (Bild, Desktop)',
								field: 'imageClicksDesktop' as const,
								hidden: true,
							},
							{
								title: 'Click-Through-Rate',
								field: 'successRate' as const,
								render: (analyticsModel): ReactNode => {
									return (
										<span>{analyticsModel.successRate.toLocaleString('de-DE', { style: 'percent' })}</span>
									);
								},
								defaultSort: 'desc'
							},
							{
								title: 'Attribut Umschalter',
								field: 'attributeToggleCounter' as const,
								hidden: false,
							},
							{
								title: 'Attribut Umschalter (Mobil)',
								field: 'attributeToggleCounterMobile' as const,
								hidden: true,
							},
							{
								title: 'Attribut Umschalter (Desktop)',
								field: 'attributeToggleCounterDesktop' as const,
								hidden: true,
							},
							{
								title: 'CMS Klicks',
								field: 'openCTACounter' as const,
								hidden: true,
							},
							{
								title: 'CMS Klicks (Mobil)',
								field: 'openCTACounterMobile' as const,
								hidden: true,
							},
							{
								title: 'CMS Klicks (Desktop)',
								field: 'openCTACounterDesktop' as const,
								hidden: true,
							},
						]}
						options={{
							search: false,
							filtering: false,
							columnsButton: true,
							exportButton: true,
							pageSize: 25,
							pageSizeOptions: [25, 50, 100],
							emptyRowsWhenPaging: false,
							draggable: false,
							showTitle: false,
							toolbar: true,
							sorting: true
						}}
						style={{
							borderRadius: 0,
							width: '100%'
						}}
						data={ this.state.analytics }
						localization={ TableLocalization.localization }
						detailPanel={(rowData): ReactNode => {
							return (<AnalyticsDetails analytics={rowData}/>);
						}}
						onRowClick={(event, rowData, togglePanel): void => {
							if (togglePanel) {
								togglePanel();
							}
						}}
					/>
				}
				{ this.summary() }
				{this.state.analytics.length !== 0 && !this.state.isLoadingAnalytics && !this.state.isLoadingComparators &&
					<AnalyticsComparatorStatistics />
				}
			</Paper>
		);
	}

	private handleDateChange(dateString: string): void {
		if (this.state.dateString !== dateString) {
			this.setState({dateString: dateString});
			this.loadAnalytics(dateString);
		}
	}

}

export default withStyles(comparatorAnalyticsStyles)(ComparatorAnalytics);
