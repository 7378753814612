import React, { ReactNode, Component } from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { WithStyles, withStyles } from '@material-ui/core';

import shoppingProductCardStyles from './ShoppingProductCardStyles';

interface ShoppingSeller {
	name: string;
	price: number;
}

interface ShoppingProduct {
	_id: string;
	keyword: string;
	position: number;
	image: string;
	name: string;
	description?: string;
	sellers: ShoppingSeller[];
}

interface Props extends WithStyles<typeof shoppingProductCardStyles> {
	product: ShoppingProduct;
}

class ShoppingProductCard extends Component<Props> {
	public render(): ReactNode {
		const classes = this.props.classes;

		let cardClass = classes.otherCard;
		if (this.props.product.sellers.find((s): boolean => (s.name.toLowerCase().includes('moebelix') || s.name.toLowerCase().includes('möbelix')))) {
			cardClass = classes.mxCard;
		}

		return (
			<Card className={cardClass}>
				<div>
					<CardMedia
						className={classes.media}
						image={this.props.product.image}
						title={this.props.product.name}
					/>
					<CardContent>
						<Typography gutterBottom variant="body1" component="h2" style={{marginBottom: 10}}>
							{this.props.product.name}
						</Typography>
						{this.props.product.sellers.map((seller, index): ReactNode => (
							<div key={index}>
								<Divider />
								<Typography variant="body2" color="textPrimary" component="p">
									{seller.name}
									<span style={{float: 'right'}}>
										{seller.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
									</span>
								</Typography>
							</div>
						))}
					</CardContent>
				</div>
			</Card>
		);
	}

}

export default withStyles(shoppingProductCardStyles)(ShoppingProductCard);
