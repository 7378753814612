import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const comparatorEditorStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexWrap: 'wrap',
	}
});

export default comparatorEditorStyles;
