import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const timeRangeSelectStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 1),
	},
	datePicker: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	downloadButton: {
		bottom: '-28px',
		right: '-5px'
	}
});

export default timeRangeSelectStyles;
