import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const advisorPreviewStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	root: {
		padding: theme.spacing(2, 3),
	},
	container: {
		width: '100%',
	},
	loadingIndicator: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	desktopPreview: {
		border: 'none',
		width: '100%',
		maxWidth: '980px',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	mobilePreview: {
		border: 'none',
		width: '320px',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	saveButton: {
		float: 'right',
		alignSelf: 'start'
	},
	refreshButton: {
		marginBottom: theme.spacing(2)
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});

export default advisorPreviewStyles;