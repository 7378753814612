import React, {ReactElement, FC, CSSProperties} from 'react';
import {
	FormControl,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorQuestionSelectStyles from './AdvisorQuestionSelectStyles';
import {AdvisorQuestion} from '@common/models/Advisor';
import Select, { ValueType } from 'react-select';

interface OptionType {
	label: string;
	value: string;
	data: AdvisorQuestion;
};

interface Props extends WithStyles<typeof advisorQuestionSelectStyles> {
	question: AdvisorQuestion | null;
	allAdvisorQuestions: AdvisorQuestion[];
	handleQuestionChange: (question: AdvisorQuestion | null) => void;
	key: number | string;
	placeholder: string;
}

const AdvisorQuestionSelect: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	function getQuestionsOptions(): OptionType[] {
		const options: OptionType[] = [];
		props.allAdvisorQuestions.forEach((question: AdvisorQuestion): void => {
			options.push({value: question._id ?? '', label: question.question, data: question});
		});

		return options;
	}

	function getCurrentSelectValue(): OptionType | undefined {
		if (!props.question) {
			return undefined;
		}

		let currentQuestion = undefined;
		for (const question of props.allAdvisorQuestions) {
			if (props.question._id && question._id === props.question._id) {
				currentQuestion = question;
				break;
			}
		}

		if (currentQuestion) {
			return {value: currentQuestion._id ?? '', label: currentQuestion.question, data: currentQuestion};
		} else {
			return undefined;
		}
	}

	function handleChange(selectedQuestion: ValueType<OptionType>): void {
		if (selectedQuestion) {
			const question = (selectedQuestion as OptionType).data;
			props.handleQuestionChange(question);
		} else {
			props.handleQuestionChange(null);
		}
	}

	return (
		<FormControl className={classes.root} key={props.key}>
			<Select
				className={classes.select}
				value={getCurrentSelectValue()}
				onChange={(selectedQuestion): void => handleChange(selectedQuestion)}
				options={getQuestionsOptions()}
				placeholder={props.placeholder}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
				menuPortalTarget={document.body}
				isClearable
				isSearchable
			/>
		</FormControl>
	);
};

export default withStyles(advisorQuestionSelectStyles)(AdvisorQuestionSelect);
