import React, { ReactNode, Component } from 'react';
import {
	Checkbox,
	Divider,
	TextField,
	FormControl,
	FormControlLabel,
	List,
	ListItem,
	ListItemText,
	Select,
	InputLabel,
	MenuItem,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import RichTextEditor, {EditorValue} from 'react-rte';

import AuthService from '@services/AuthService';

import { CmsColumn, CmsColumnType } from '@models/CmsContent';

import cmsContentColumnStyles from './CmsContentColumnStyles';

import { CategoriesResponse, Category } from '@models/Category';

interface Props extends WithStyles<typeof cmsContentColumnStyles> {
	column: CmsColumn;
	onChange: (newColumn: CmsColumn) => void;
	categoryCode: string;
}

interface State {
	editorState: EditorValue;
	checkboxChecked: boolean;
	sisterCategories: Category[];
	savedSisterCategories: CategoryCms[];
	showImages: boolean;
}

interface CategoryCms {
	code: string;
	image?: string;
}

class CmsContentColumn extends Component<Props, State> {

	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		let checkboxChecked = false;
		let showImages = false;
		const savedSisterCategories: CategoryCms[]  = [];
		if (this.props.column.data !== undefined) {
			if (this.props.column.data.textButtonStyle) {
				checkboxChecked = this.props.column.data.textButtonStyle;
			}
			if (this.props.column.data.categories && this.props.column.data.categories.length > 0) {
				showImages = true;
				for (const category of this.props.column.data.categories) {
					const categoryDb: CategoryCms = {
						code: category.category.code
					};
					if (category.category.image) {
						categoryDb.image = category.category.image;
					}
					savedSisterCategories.push(categoryDb);
				}
			}
		}

		this.state = {
			editorState: this.props.column.data?.text ? RichTextEditor.createValueFromString(this.props.column.data?.text, 'html') :  RichTextEditor.createEmptyValue(),
			checkboxChecked: checkboxChecked,
			sisterCategories: [],
			savedSisterCategories: savedSisterCategories,
			showImages: showImages
		};
		this.loadSisterCategories();
	}

	private loadSisterCategories(): void {
		this.authService.fetch<CategoriesResponse>(`/api/categories/${this.props.categoryCode}/sisters`, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({sisterCategories: response.data});
				if (this.state.savedSisterCategories.length === 0) {
					const categoriesToSave: CategoryCms[] = [];
					for (const category of response.data) {
						categoriesToSave.push({
							code: category.code
						});
					}
					this.setState({savedSisterCategories: categoriesToSave});
				}

			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}


	private textColumn(): ReactNode {
		return (
			<React.Fragment>
				<Checkbox
					checked={this.state.checkboxChecked}
					color="primary"
					onChange={(event): void => {this.handleCheckboxChange(event);}}
				/>
				Links im Style von Buttons
				<RichTextEditor
					value={this.state.editorState}
					onChange={(value): void => {
						this.setState({editorState: value});

						const column = this.props.column;
						if (column.data === undefined) {
							column.data = {};
						}
						const newValue = value.toString('html');

						if (column.data.text !== newValue) {
							column.data.text = newValue;
							this.props.onChange(column);
						}

					}}
					toolbarConfig={{
						// Optionally specify the groups to display (displayed in the order listed).
						display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
						INLINE_STYLE_BUTTONS: [
							{label: 'Bold', style: 'BOLD'},
							{label: 'Italic', style: 'ITALIC'},
							{label: 'Underline', style: 'UNDERLINE'}
						],
						BLOCK_TYPE_DROPDOWN: [
							{label: 'Normal', style: 'unstyled'},
							{label: 'Heading 1', style: 'header-one'},
							{label: 'Heading 2', style: 'header-two'},
							{label: 'Heading 3', style: 'header-three'}
						],
						BLOCK_TYPE_BUTTONS: [
							{label: 'UL', style: 'unordered-list-item'},
							{label: 'OL', style: 'ordered-list-item'}
						]
					}}
				/>
			</React.Fragment>
		);
	}

	private handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>): void {

		const column = this.props.column;
		if (column.data !== undefined && column.data.textButtonStyle !== event.target.checked) {
			column.data.textButtonStyle = event.target.checked;
			this.props.onChange(column);
		}

		this.setState({checkboxChecked: event.target.checked});
	}

	private imageColumn(): ReactNode {
		const classes = this.props.classes;

		return (
			<React.Fragment>
				<TextField
					label="Bild-URL"
					value={this.props.column.data?.imgUrl}
					onChange={(event): void => {
						const column = this.props.column;
						if (column.data === undefined) {
							column.data = {};
						}
						column.data.imgUrl = event.target.value;
						this.props.onChange(column);
					}}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label="Button Link"
					value={this.props.column.data?.buttonLink}
					onChange={(event): void => {
						const column = this.props.column;
						if (column.data === undefined) {
							column.data = {};
						}
						column.data.buttonLink = event.target.value;
						this.props.onChange(column);
					}}
					fullWidth
					className={classes.textField}
				/>
				{this.props.column.data?.imgUrl &&
					<p>
						Vorschau:<br/>
						<img src={this.props.column.data?.imgUrl} alt="Vorschau" className={classes.previewImage} />
					</p>
				}
			</React.Fragment>
		);
	}

	private linkColumn(): ReactNode {
		const classes = this.props.classes;

		return (
			<React.Fragment>
				<TextField
					label="Button Text"
					value={this.props.column.data?.buttonText}
					onChange={(event): void => {
						const column = this.props.column;
						if (column.data === undefined) {
							column.data = {};
						}
						column.data.buttonText = event.target.value;
						this.props.onChange(column);
					}}
					fullWidth
					className={classes.textField}
				/>
				<TextField
					label="Button Link"
					value={this.props.column.data?.buttonLink}
					onChange={(event): void => {
						const column = this.props.column;
						if (column.data === undefined) {
							column.data = {};
						}
						column.data.buttonLink = event.target.value;
						this.props.onChange(column);
					}}
					fullWidth
					className={classes.textField}
				/>
				<FormControl className={classes.colorSelect}>
					<InputLabel>Button Farbe</InputLabel>
					<Select
						value={this.props.column.data?.buttonColor ?? 'dark_blue'}
						onChange={(event): void => {
							const column = this.props.column;
							if (column.data === undefined) {
								column.data = {};
							}
							column.data.buttonColor = (event.target.value as string);
							this.props.onChange(column);
						}}
					>
						<MenuItem value={'dark_blue'}>Dunkelblau</MenuItem>
						<MenuItem value={'turquoise'}>Türkis</MenuItem>
						<MenuItem value={'yellow'}>Gelb</MenuItem>
						<MenuItem value={'red'}>Rot</MenuItem>
						<MenuItem value={'light_grey'}>Hellgrau</MenuItem>
						<MenuItem value={'dark_grey'}>Dunkelgrau</MenuItem>
					</Select>
				</FormControl>

			</React.Fragment>
		);
	}

	private categoriesColumn(): ReactNode {
		const classes = this.props.classes;
		return (
			<React.Fragment>
				<FormControlLabel
					control={
						<Checkbox
							checked={this.state.showImages}
							color="primary"
							onChange={(event): void => {this.showImagesClicked(event);}}
							className={classes.showImagesCheckbox}
						/>
					}
					label="Schwester-Kategorien inklusive Bilder anzeigen"
				/>
				<Typography className={classes.info}>Die nachfolgend aufgelisteten Schwester-Kategorien werden angezeigt:</Typography>
				{this.getCategoryTable()}
			</React.Fragment>
		);
	}

	private showImagesClicked(event: React.ChangeEvent<HTMLInputElement>): void {
		this.setState({showImages: event.target.checked}, () => {
			this.changeColumnSaveData();
		});
	}

	private handleImageChange(category: CategoryCms, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const sisterCategories: CategoryCms[] = [];
		for (const sisterCategory of this.state.savedSisterCategories) {
			if (sisterCategory.code === category.code) {
				sisterCategory.image = event.target.value;
			}
			sisterCategories.push(sisterCategory);
		}

		this.setState({savedSisterCategories: sisterCategories}, () => {
			this.changeColumnSaveData();
		});
	}

	private useOwnImageClicked(category: CategoryCms, event: React.ChangeEvent<HTMLInputElement>): void {

		const sisterCategories: CategoryCms[] = [];
		for (const savedSisterCategory of this.state.savedSisterCategories) {

			// checkbox change
			if (savedSisterCategory.code === category.code) {
				const updatedSavedSisterCategory = savedSisterCategory;
				if (event.target.checked) {
					if (updatedSavedSisterCategory.image === undefined) {
						updatedSavedSisterCategory.image = this.getImageDb(updatedSavedSisterCategory.code);
						sisterCategories.push(updatedSavedSisterCategory);
					}
				} else {
					updatedSavedSisterCategory.image = undefined;
					sisterCategories.push(updatedSavedSisterCategory);
				}
			} else {
				sisterCategories.push(savedSisterCategory);
			}
		}

		this.setState({savedSisterCategories: sisterCategories}, () => {
			this.changeColumnSaveData();
		});
	}

	private changeColumnSaveData(): void {
		const column = this.props.column;
		if (column.data === undefined) {
			column.data = {};
		}
		if (this.state.showImages) {
			column.data.categories = [];
			for (const sisterCategory of this.state.savedSisterCategories) {
				const categoryCms: CategoryCms = {
					code: sisterCategory.code,
				};
				if (sisterCategory.image) {
					categoryCms.image = sisterCategory.image;
				}
				column.data.categories.push({
					category: categoryCms
				});
			}
		} else {
			column.data = undefined;
		}
	}

	private getCategoryName(code: string): string {
		for (const category of this.state.sisterCategories) {
			if (category.code === code) {
				return category.name;
			}
		}
		return '';
	}

	private getImageDb(code: string): string {
		for (const sisterCategory of this.state.sisterCategories) {
			if (code === sisterCategory.code) {
				return sisterCategory.image;
			}
		}
		return '';
	}

	private getCategoryTable(): ReactNode {
		const classes = this.props.classes;
		return (
			<List>
				{this.state.savedSisterCategories.map((category: CategoryCms, index: number): ReactNode => {
					return (
						<div key={index}>
							<Divider component="li" />
							<ListItem>
								<ListItemText primary={this.getCategoryName(category.code)}/>
							</ListItem>
							{this.state.showImages &&
								<div className={classes.imageLine}>
									<FormControlLabel
										control={
											<Checkbox
												checked={category.image !== undefined}
												color="primary"
												onChange={(event): void => this.useOwnImageClicked(category, event)}
											/>
										}
										label="Eigenes Bild verwenden"
										className={classes.ownImageCheckbox}
									/>
									<TextField
										value={category.image ? category.image : this.getImageDb(category.code)}
										margin="normal"
										disabled={!(category.image !== undefined)}
										fullWidth={true}
										onChange={(event): void => {this.handleImageChange(category, event);}}
										className={classes.imageLink}
									/>
								</div>
							}
						</div>
					);
				}
				)}
			</List>
		);
	}


	public render(): ReactNode {
		return (
			<div>
				{this.props.column.type === CmsColumnType.Text && this.textColumn()}
				{this.props.column.type === CmsColumnType.Image && this.imageColumn()}
				{this.props.column.type === CmsColumnType.Link && this.linkColumn()}
				{this.props.column.type === CmsColumnType.Categories && this.categoriesColumn()}
			</div>
		);
	}
}

export default withStyles(cmsContentColumnStyles)(CmsContentColumn);
