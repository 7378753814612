import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editorGeneralSettingsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelSubtitle: {
		flexGrow: 1,
		maxWidth: '50%',
		color: theme.palette.text.secondary,
		lineHeight: '36px',
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	textInput: {
		marginTop: theme.spacing(2),
	},
	sortSelect: {
		marginTop: theme.spacing(2),
		minWidth: 320,
	},
	sortWeightInput: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(2),
		minWidth: 320,
	},
	fullscreenCheckbox: {
		marginTop: theme.spacing(2),
		display: 'block',
		width: '100%',
	},
	deleteButton: {
		color: theme.palette.error.main
	}
});

export default editorGeneralSettingsStyles;
