import JWT from 'jwt-decode';

interface JWTPayload {
	exp: number;
	perm: string[];
}

interface LoginResponse extends Response {
	success: boolean;
	message?: string;
	token?: string;
}

class AuthService {
	public constructor() {
		this.fetch = this.fetch.bind(this);
		this.login = this.login.bind(this);
	}

	public login(username: string, password: string): Promise<LoginResponse> {
		return this.fetch<LoginResponse>('/api/users/login', {
			method: 'POST',
			body: JSON.stringify({
				username,
				password
			})
		}).then((response: LoginResponse): Promise<LoginResponse> => {
			if (response.success && response.token !== undefined ) {
				this.setToken(response.token);
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
			return Promise.resolve(response);
		});
	}

	public loggedIn(): boolean {
		const token = this.getToken();
		return !!token && !this.isTokenExpired(token);
	}

	public hasPermission(permission: string): boolean {
		if (this.loggedIn()) {
			const token = this.getToken();
			if (token) {
				const decoded: JWTPayload = JWT(token);

				if (decoded.perm && (decoded.perm.includes(permission) || decoded.perm.includes('admin'))) {
					return true;
				}
			}
		}

		return false;
	}

	public isTokenExpired(token: string): boolean {
		try {
			const decoded: JWTPayload = JWT(token);
			if (decoded.exp < Date.now() / 1000) {
				return true;
			} else
				return false;
		} catch (err) {
			return false;
		}
	}

	private setToken(idToken: string): void {
		localStorage.setItem('id_token', idToken);
	}

	private getToken(): string | null {
		return localStorage.getItem('id_token');
	}

	public logout(): void {
		localStorage.removeItem('id_token');
	}

	public fetch<T>(url: string, options: object, disableAuth = false): Promise<T> {
		const headers: Record<string, string> = {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		};

		if (this.loggedIn() && disableAuth !== true) {
			headers['Authorization'] = 'Bearer ' + this.getToken();
		}

		return fetch(url, { headers, ...options })
			.then(this.checkStatus)
			.then((response): Promise<T> => response.json() as Promise<T>);
	}

	private checkStatus(response: Response): Response {
		if ((response.status >= 200 && response.status < 300) ||
			response.url.includes('login')
		) {
			return response;
		} else if (response.status === 401) {
			window.location.reload();
		}

		throw new Error(response.statusText);
	}
}

export default AuthService;
