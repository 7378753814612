import {Theme, createStyles} from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productsPreviewStyles = (theme: Theme): any => createStyles({
	articleButtonContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginBottom: theme.spacing(2),
	},
	link: {
		cursor: 'pointer'
	}
});

export default productsPreviewStyles;
