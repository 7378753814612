import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const analyticsBestProductStyles = (theme: Theme): any => createStyles({
	rowContainer: {
		display: 'flex',
		lineHeight: '30px',
	},
	imageContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		height: '30px',
		width: '30px',
		textAlign: 'center',
		flexGrow: 0,
		flexShrink: 0,
	},
	codeContainer: {
		color: theme.palette.text.secondary,
		fontFamily: 'monospace',
		marginRight: theme.spacing(1),
		fontSize: '13px',
		lineHeight: '30px',
		minWidth: 100,
	},
	nameContainer: {
		flexGrow: 1,
		lineHeight: '30px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		minWidth: 0,
		marginRight: theme.spacing(1),
	},
	priceContainer: {
		marginRight: theme.spacing(1),
		lineHeight: '30px',
		whiteSpace: 'nowrap',
	},
});

export default analyticsBestProductStyles;
