// eslint-disable-next-line
/// <reference path="../dts/muibox.d.ts"/>
import React, { ReactNode, Component, CSSProperties } from 'react';
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import Select, { ValueType } from 'react-select';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

import AuthService from '@services/AuthService';
import ProductsService from '@services/ProductsService';
import CategorySelect from '../Common/CategorySelect';
import SubcategorySelect from '../Common/SubcategorySelect';
import ComparatorAttributeSelect from '../Comparator/ComparatorAttributeSelect';
import CrawlerFolderSelect from './CrawlerFolderSelect';
import { Category } from '@models/Category';
import { Product, ProductStatus } from '@models/Product';

import { CrawlerModel, CrawlerType, FolderType } from '@models/Crawler';

import crawlerEditorStyles from './CrawlerEditorStyles';

interface OptionType {
	label: string;
	value: CrawlerModel;
};

interface Props extends WithStyles<typeof crawlerEditorStyles> {
	crawler: CrawlerModel | null;
	type: string;
	editorOpen: boolean;
	closeHandler: () => void;
	isMultiple: boolean;
	dialog: ConfirmDialog;
	crawlers: CrawlerModel[];
}

interface State {
	crawler: CrawlerModel;
	allProductsOfCategory: Product[];
	isLoadingAllProducts: boolean;
}

class CrawlerEditor extends Component<Props, State> {
	private authService: AuthService;
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();
		this.productsService = new ProductsService();

		this.state = {
			crawler: this.getCrawlerToSet(this.props.crawler),
			allProductsOfCategory: [],
			isLoadingAllProducts: false
		};
	}

	public componentDidUpdate(prevProps: Props, _: State): void {

		if (prevProps.crawler !== this.props.crawler || prevProps.type !== this.props.type) {
			this.setState({crawler: this.getCrawlerToSet(this.props.crawler), isLoadingAllProducts: false, allProductsOfCategory: []});
		}

		if (prevProps.isMultiple !== this.props.isMultiple) {
			this.setState({crawler: this.getCrawlerToSet(this.props.crawler)});
		}
	}

	private getCrawlerToSet(newCrawler: CrawlerModel | null): CrawlerModel {
		if (newCrawler) {
			return newCrawler;
		} else {
			const responseCrawler: CrawlerModel = {
				_id: 0,
				name: '',
				url: '',
				status: 0,
				type: this.getCrawlerType(),
				categoryCode: '',
				excludedCategoryCodes: [],
				attributes: [],
				delimiterRemoveAttributes: [],
				products: [],
				multiFeeds: []
			};

			return responseCrawler;
		}
	}

	private getCrawlerType(): CrawlerType {
		let crawlerType = CrawlerType.CsvEnricher;
		if (this.props.type === 'feeds' && this.props.isMultiple) {
			crawlerType = CrawlerType.MultiCategoryFeed;
		} else if (this.props.type === 'feeds') {
			crawlerType = CrawlerType.CategoryFeed;
		} else if (this.props.type === 'monitorings') {
			crawlerType = CrawlerType.Monitoring;
		}

		return crawlerType;
	}

	private deleteCrawlerButtonPressed(): void {
		if (this.props.crawler) {
			const crawlerId = this.props.crawler._id;
			const { dialog } = this.props;

			dialog.confirm({
				title: 'Eintrag Löschen',
				message: 'Soll der Eintrag wirklich gelöscht werden?',
				ok: {
					text: 'Löschen',
					color: 'error',
					variant: 'text'
				},
				cancel: {
					text: 'Abbrechen',
					color: 'secondary',
					variant: 'text'
				},
			}).then((): void => {
				this.authService.fetch<CrawlerModel>(`/api/crawlers/${crawlerId}`, {
					method: 'DELETE',
					body: JSON.stringify(this.props.crawler)
				}).then((_): void => {
					this.closeEditor();
				});
			});
		}
	}

	private saveCrawlerButtonPressed(): void {
		let url = 'api/crawlers/new';
		if (this.props.crawler) {
			url = `/api/crawlers/${this.props.crawler._id}`;
		}

		this.authService.fetch<CrawlerModel>(url, {
			method: 'POST',
			body: JSON.stringify(this.state.crawler)
		}).then((_): void => {
			this.closeEditor();
		});

	}

	private closeEditor(): void {
		this.setState({crawler: this.getCrawlerToSet(null), isLoadingAllProducts: false, allProductsOfCategory: []});
		this.props.closeHandler();
	}

	private handleTextChange(key: keyof CrawlerModel, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
		const tempCrawler = this.state.crawler;

		if (key === 'name') {
			tempCrawler.name = event.target.value;
		} else if (key === 'url') {
			tempCrawler.url = event.target.value;
		}

		this.setState({crawler : tempCrawler});
	}

	private handleCategoryChange(selectedCategory: Category | null): void {
		const tempCrawler = this.state.crawler;

		let categoryCode = '';

		if (selectedCategory && selectedCategory.code) {
			categoryCode = selectedCategory.code;
		}

		if (tempCrawler.categoryCode !== categoryCode) {
			tempCrawler.categoryCode = categoryCode;
			tempCrawler.excludedCategoryCodes = [];
		}

		this.setState({crawler: tempCrawler, allProductsOfCategory: []});
	}

	private handleFolderChange(selectedFolder: FolderType | null): void {
		const tempCrawler = this.state.crawler;

		let folderType = undefined;

		if (selectedFolder) {
			folderType = selectedFolder;
		}

		if (tempCrawler.folderType !== folderType) {
			tempCrawler.folderType = folderType;
		}

		this.setState({crawler: tempCrawler});
	}


	private async handleSubcategoryChange(excludedCategories: string): Promise<void> {
		const tempCrawler = this.state.crawler;

		tempCrawler.excludedCategoryCodes = excludedCategories.split(',');

		this.setState({crawler: tempCrawler, allProductsOfCategory: []});

		this.loadAllProductsOfCategory(tempCrawler.categoryCode, tempCrawler.excludedCategoryCodes);
	}

	private async loadAllProductsOfCategory(categoryCode: string, excludedCategoryCodes: string[]): Promise<void> {
		this.setState({isLoadingAllProducts: true});

		let products = await this.productsService.loadProductsInCategory(categoryCode, false);

		// Filter offline products
		products = products.filter((p) => p.status === ProductStatus.Online);

		const excludedCategories = excludedCategoryCodes;
		for (const excludedCategoryCode of excludedCategories) {
			if (excludedCategoryCode === '') {
				continue;
			}

			products = products.filter((p) => !p.categoryCodes.includes(excludedCategoryCode));
		}

		// Check to make sure we don't provide stale data
		if (categoryCode !== this.state.crawler.categoryCode || excludedCategoryCodes !== this.state.crawler.excludedCategoryCodes) {
			return;
		}

		this.setState({allProductsOfCategory: products, isLoadingAllProducts: false});
	}

	private handleAttributesChange(selectedAttributes: string[]): void {
		if (this.state.allProductsOfCategory.length === 0) {
			return;
		}

		const tmpCrawler = this.state.crawler;
		tmpCrawler.attributes = selectedAttributes;
		this.setState({crawler: tmpCrawler});
	}

	private handleDelimiterRemoveAttributesChange(delimiterRemoveAttributes: string[]): void {
		if (this.state.allProductsOfCategory.length === 0) {
			return;
		}
		const tmpCrawler = this.state.crawler;
		tmpCrawler.delimiterRemoveAttributes = delimiterRemoveAttributes;
		this.setState({crawler: tmpCrawler});
	}

	private editorTitle(): ReactNode {
		let typeName = 'Crawler';

		if (this.props.type === 'feeds') {
			typeName = 'Kategorie-Feed';
		} else if (this.props.type === 'monitorings') {
			typeName = 'Monitoring';
		}

		let actionName = `Neuer ${typeName}`;

		if (this.props.crawler) {
			actionName = `${typeName} bearbeiten`;
		}

		return (
			<DialogTitle id="form-dialog-title">{actionName}</DialogTitle>
		);
	}

	private deleteButton(): ReactNode {
		if (this.props.crawler) {
			const classes = this.props.classes;

			return (
				<Button className={classes.deleteButton} onClick={this.deleteCrawlerButtonPressed.bind(this)} color="primary">
					Löschen
				</Button>
			);
		}

		return null;
	}

	private textInput(key: keyof CrawlerModel): ReactNode {
		let placeholder = '';

		if (key === 'name') {
			placeholder = 'Name';
		} else if (key === 'url') {
			placeholder = 'https://';
		}

		return (
			<TextField
				id={key}
				label={key.toUpperCase()}
				placeholder={placeholder}
				className={this.props.classes.textField}
				value={this.state.crawler[key]}
				margin="dense"
				onChange={(event): void => {this.handleTextChange(key, event);}}
				fullWidth
			/>
		);
	}

	private categorySelect(): ReactNode {
		let categoryCode = null;

		if (this.props.crawler) {
			categoryCode = this.props.crawler.categoryCode;
		}

		return (
			<CategorySelect
				onChange={this.handleCategoryChange.bind(this)}
				selectedCategoryCode={categoryCode}
				topLevelCategoriesExcluded={false}
				disabled={false}
			/>
		);
	}

	private getCrawlersById(ids: number[]): CrawlerModel[] {
		const crawlers: CrawlerModel[] = [];

		for (const crawlerId of ids) {
			for (const crawler of this.props.crawlers) {
				if (crawler._id === crawlerId) {
					crawlers.push(crawler);
				}
			}
		}

		return crawlers;
	}

	private mergeFeedsSelect(): ReactNode {

		return (
			<>
				<Select
					value={{value: {
						_id: 0,
						name: '',
						url: '',
						status: 0,
						type: this.getCrawlerType(),
						categoryCode: '',
						excludedCategoryCodes: [],
						attributes: [],
						delimiterRemoveAttributes: [],
						products: [],
						multiFeeds: []
					}, label: 'Kategorie-Feed hinzufügen...'}}
					onChange={this.addMultiFeed.bind(this)}
					options={this.getFeedOptions()}
					placeholder={'Kategorie-Feed hinzufügen...'}
					noOptionsMessage={(): string => ('Keine Treffer')}
					styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }), singleValue: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999, color: 'hsl(0,0%,50%)' })}}
					menuPortalTarget={document.body}
				/>
			</>
		);
	}

	private getFeedOptions(): OptionType[] {
		const options: OptionType[] = [];

		this.props.crawlers.filter(feed => feed.type === CrawlerType.CategoryFeed).forEach((crawler: CrawlerModel): void => {
			if (this.state.crawler && this.state.crawler.multiFeeds && this.state.crawler.multiFeeds.filter(m => m === crawler._id).length === 0) {
				options.push({value: crawler, label: crawler.name});
			}
		});

		return options;
	}

	private addMultiFeed(selectedFeed: ValueType<OptionType>): void {
		const tmpCrawler = this.state.crawler;
		if (selectedFeed && tmpCrawler.multiFeeds) {
			tmpCrawler.multiFeeds.push((selectedFeed as OptionType).value._id);
		}
		this.setState({crawler: tmpCrawler});
	}

	private subcategorySelect(): ReactNode {
		const categoryCode = this.state.crawler.categoryCode;

		if (categoryCode !== '') {
			return (
				<SubcategorySelect
					onChange={this.handleSubcategoryChange.bind(this)}
					selectedCategoryCode={categoryCode}
					excludedCategoryCodes={this.state.crawler.excludedCategoryCodes.join(',')}
				/>
			);
		}

		return null;
	}

	private attributeSelect(): ReactNode {
		const classes = this.props.classes;

		if (this.state.allProductsOfCategory.length > 0) {
			let selectedAttributes: string[] = [];

			if (this.state.crawler.attributes) {
				selectedAttributes = this.state.crawler.attributes;
			}

			let selectedDelimiterAttributes: string[] = [];
			if (this.state.crawler.delimiterRemoveAttributes) {
				selectedDelimiterAttributes = this.state.crawler.delimiterRemoveAttributes;
			}

			return (
				<ComparatorAttributeSelect
					onChange={this.handleAttributesChange.bind(this)}
					onChangeDelimiterRemoveAttributes={this.handleDelimiterRemoveAttributesChange.bind(this)}
					selectedProducts={this.state.allProductsOfCategory}
					selectedAttributeCodes={selectedAttributes}
					selectedDelimiterAttributeCodes={selectedDelimiterAttributes}
					sortingEnabled={false}
					templatesEnabled={false}
					maintainedEnabled={false}
					categoryFeed={true}
					type={'crawler'}
				/>
			);
		} else if (this.state.isLoadingAllProducts) {
			return (<CircularProgress className={classes.loadingIndicator}/>);
		}

		return null;
	}

	private folderSelect(): ReactNode {
		let folderType = null;

		if (this.props.crawler && this.props.crawler.folderType) {
			folderType = this.props.crawler.folderType;
		}

		return (
			<CrawlerFolderSelect
				onChange={this.handleFolderChange.bind(this)}
				selectedFolderType={folderType}
			/>
		);
	}

	private multiTable(): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.selectedMultiFeedsTable}>
				<Typography className={classes.selectedAttributeValueTableHeader}>
					{'Ausgewählte Kategorie-Feeds'}
				</Typography>
				<Table size="small" aria-label="a dense table">
					<TableHead>
						<TableRow>
							<TableCell align="left"></TableCell>
							<TableCell align="left"></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.crawler && this.state.crawler.multiFeeds && this.getCrawlersById(this.state.crawler.multiFeeds).map((crawler: CrawlerModel): ReactNode => (
							<TableRow key={crawler._id}>
								<TableCell component="th" scope="row">
									{crawler.name}
								</TableCell>
								<TableCell align="right">{
									<Button onClick={this.removeMultiFeed.bind(this, crawler._id)} variant="outlined" size="small" className={classes.deleteAttributeValueButton}>
								Entfernen
									</Button>
								}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		);
	}

	private removeMultiFeed(mutliFeedId: number): void {
		const currentCrawler = this.state.crawler;
		if (currentCrawler && currentCrawler.multiFeeds) {
			currentCrawler.multiFeeds.forEach((crawlerId, index) => {
				if (crawlerId === mutliFeedId && currentCrawler.multiFeeds) {
					currentCrawler.multiFeeds.splice(index, 1);
				}
			});
			this.setState({crawler: currentCrawler});
		}

	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Dialog open={this.props.editorOpen} onClose={this.closeEditor.bind(this)} fullWidth={true} maxWidth={'lg'}>
				{this.editorTitle()}
				<DialogContent>
					{this.textInput('name')}
					{this.props.type === 'feeds' && this.folderSelect()}
					{this.props.type !== 'feeds' && this.textInput('url')}
					{this.props.type === 'feeds' && (!this.props.isMultiple && this.state.crawler.type !== CrawlerType.MultiCategoryFeed) && this.categorySelect()}
					{this.props.type === 'feeds' && (!this.props.isMultiple && this.state.crawler.type !== CrawlerType.MultiCategoryFeed) && this.subcategorySelect()}
					{this.props.type === 'feeds' && (!this.props.isMultiple && this.state.crawler.type !== CrawlerType.MultiCategoryFeed) && this.attributeSelect()}
					{this.props.type === 'feeds' && (this.props.isMultiple || this.state.crawler.type === CrawlerType.MultiCategoryFeed) && this.mergeFeedsSelect()}
					{this.props.type === 'feeds' && (this.props.isMultiple || this.state.crawler.type === CrawlerType.MultiCategoryFeed) && this.multiTable()}
				</DialogContent>
				<DialogActions className={classes.DialogActions}>
					{this.deleteButton()}
					<Button onClick={this.closeEditor.bind(this)} color="primary">
						Abbrechen
					</Button>
					<Button onClick={this.saveCrawlerButtonPressed.bind(this)} color="primary" disabled={this.state.crawler.name === '' || (this.props.isMultiple && this.state.crawler.multiFeeds && this.state.crawler.multiFeeds.length === 0)}>
						Speichern
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withDialog()(withStyles(crawlerEditorStyles)(CrawlerEditor));
