import React, { ReactNode, Component } from 'react';
import {
	Paper,
	Button,
	FormControl,
	InputLabel,
	Select,
	Typography,
	MenuItem,
	WithStyles,
	withStyles
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { CmsRow, CmsColumn, CmsColumnType } from '@models/CmsContent';

import CmsContentColumn from './CmsContentColumn';

import cmsContentRowStyles from './CmsContentRowStyles';

interface Props extends WithStyles<typeof cmsContentRowStyles> {
	row: CmsRow;
	onChange: (newRow: CmsRow) => void;
	categoryCode: string;
}

interface State {
	disableColumnButton: boolean;
	categoryDisabled: boolean;
}

class CmsContentRow extends Component<Props, State> {

	public constructor(props: Props) {
		super(props);

		let disableColumnButton = false;
		for (const column of this.props.row.columns) {
			if (column.type === CmsColumnType.Categories) {
				disableColumnButton = true;
			}
		}


		this.state = {
			disableColumnButton: disableColumnButton,
			categoryDisabled: false
		};

	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<div>
				<div className={classes.columnContainer}>
					{this.props.row.columns.map((column, index) => (
						<Paper key={index} elevation={3} square className={classes.column}>
							<FormControl className={classes.columnTypeSelect}>
								<InputLabel>Spalten-Typ</InputLabel>
								<Select
									value={column.type}
									onChange={(event): void => {
										const row = this.props.row;
										row.columns[index].type = (event.target.value as CmsColumnType);
										if ((event.target.value as CmsColumnType) === CmsColumnType.Categories) {
											this.setState({disableColumnButton: true});
										} else {
											this.setState({disableColumnButton: false});
										}

										this.props.onChange(row);
									}}
								>
									<MenuItem value={CmsColumnType.Text}>Text</MenuItem>
									<MenuItem value={CmsColumnType.Image}>Bild</MenuItem>
									<MenuItem value={CmsColumnType.Link}>Link</MenuItem>
									<MenuItem disabled={this.state.categoryDisabled} value={CmsColumnType.Categories}>{this.state.categoryDisabled ? 'Kategorien (nur einmal pro Zeile möglich)': 'Kategorien'}</MenuItem>
								</Select>
							</FormControl>

							<CmsContentColumn
								column={column}
								onChange={(newColumn: CmsColumn): void => {
									const row = this.props.row;
									row.columns[index] = newColumn;
									this.props.onChange(row);
								}}
								categoryCode={this.props.categoryCode}
							/>
							<Button
								variant="outlined"
								color="secondary"
								size="small"
								startIcon={<DeleteIcon />}
								className={classes.deleteButton}
								onClick={(): void => {
									const row = this.props.row;
									row.columns.splice(index, 1);
									if (row.columns.length < 2) {
										this.setState({disableColumnButton: false, categoryDisabled: false});
									}
									this.props.onChange(row);
								}}
							>
							Spalte Löschen
							</Button>
						</Paper>
					))}
				</div>
				{this.props.row.columns.length < 3 && (
					<>
						<Button
							variant="outlined"
							color="primary"
							disabled={this.state.disableColumnButton}
							onClick={(): void => {
								const row = this.props.row;
								row.columns.push({
									type: CmsColumnType.Text
								});
								if (row.columns.length > 1) {
									this.setState({categoryDisabled: true});
								}

								this.props.onChange(row);
							}}
						>
						Neue Spalte
						</Button>
						{this.state.disableColumnButton &&
							<span> <Typography className={classes.info}>(Spalten-Typ "Kategorien" ist nur einmal pro Zeile verwendbar)</Typography></span>
						}
					</>
				)}
			</div>
		);
	}
}

export default withStyles(cmsContentRowStyles)(CmsContentRow);
