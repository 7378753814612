import React, {ReactElement, FC, useEffect, useState, useCallback, useRef} from 'react';
import {
	AppBar,
	Button,
	Dialog,
	IconButton,
	Toolbar,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { 
	DiagramEngine,
	DiagramModel, 
	DiagramWidget,
	PortModel
} from '@projectstorm/react-diagrams';
import advisorDiagramEditorStyles from './AdvisorDiagramEditorStyles';
import { PortFactory, PortType, createPortModelName } from './AdvisorDiagramEditorComponents/PortFactory';
import { QuestionNodeFactory } from './AdvisorDiagramEditorComponents/QuestionNodeFactory';
import { QuestionPortModel } from './AdvisorDiagramEditorComponents/QuestionPortModel';
import { QuestionNodeModel } from './AdvisorDiagramEditorComponents/QuestionNodeModel';
import { Advisor, AdvisorAnswer, AdvisorQuestion, AdvisorQuestionType, getAdvisorAnswersByIds } from '@common/models/Advisor';
import AddIcon from '@material-ui/icons/Add';
import { QuestionLinkFactory } from './AdvisorDiagramEditorComponents/QuestionLinkFactory';
import { QuestionLinkModel } from './AdvisorDiagramEditorComponents/QuestionLinkModel';
import AdvisorStartingQuestionSelect from './AdvisorStartingQuestionSelect';
import SaveIcon from '@material-ui/icons/Save';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';

interface Props extends WithStyles<typeof advisorDiagramEditorStyles> {
	dialog?: ConfirmDialog;
	editorOpen: boolean;
	closeHandler: () => void;
	advisor: Advisor;
	handleNewQuestionButtonClicked: () => void;
	handleEditQuestionButtonClicked: (advisorQuestion: AdvisorQuestion) => void;
	handleNewAnswerButtonClicked: (advisorQuestion: AdvisorQuestion) => void;
	handleEditAnswerButtonClicked: (advisorQuestion: AdvisorQuestion, advisorAnswer: AdvisorAnswer) => void;
	handleDiagramEditorChanged: (questions: AdvisorQuestion[], startingQuestionId: string | undefined) => void;
	currentAdvisor: Advisor;
	handleSetCurrentDiagramAdvisor: React.Dispatch<React.SetStateAction<Advisor>>;
}

const AdvisorDiagramEditor: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const oldAdvisorInitialized = useRef(false);
	const [currentEngine, setCurrentEngine] = useState<DiagramEngine | null>(null);
	const [oldAdvisor, setOldAdvisor] = useState<Advisor | null>(null);
	const currentZoom = useRef(80);
	const currentOffsetX = useRef(0);
	const currentOffsetY = useRef(0);

	useEffect(() => {
		if (props.editorOpen && !oldAdvisorInitialized.current && props.advisor) {
			setOldAdvisor(JSON.parse(JSON.stringify(props.advisor)));
			oldAdvisorInitialized.current = true;
		}
	}, [props.advisor, props.editorOpen]);

	const handleCurrentAdvisorQuestionChange = useCallback((advisorQuestion: AdvisorQuestion) => {
		const tempCurrentAdvisor: Advisor = JSON.parse(JSON.stringify(props.advisor));
		
		if (advisorQuestion._id) {
			const questionIndex = tempCurrentAdvisor.questions.findIndex(a => a._id === advisorQuestion._id);
			tempCurrentAdvisor.questions[questionIndex] = advisorQuestion;
		} else {
			tempCurrentAdvisor.questions.push(advisorQuestion);
		}

		props.handleSetCurrentDiagramAdvisor(tempCurrentAdvisor);
	}, [props]);

	const handleCurrentStartingQuestionChange = useCallback((startingQuestionId: string | undefined) => {
		const tempCurrentAdvisor: Advisor = JSON.parse(JSON.stringify(props.advisor));
		tempCurrentAdvisor.startingQuestion = startingQuestionId;
		props.handleSetCurrentDiagramAdvisor(tempCurrentAdvisor);
	}, [props]);

	const createLinkModel = useCallback((node: QuestionNodeModel, nodes: QuestionNodeModel[], portType: PortType, id: string, followingQuestion: string): QuestionLinkModel | null => {
		const sourcePort = node.getPort(createPortModelName(portType, id));
		const targetPort = getPortOfFollowingQuestion(followingQuestion, nodes);
		if (sourcePort && targetPort) {
			return new QuestionLinkModel(handleCurrentAdvisorQuestionChange, sourcePort, targetPort);
		} 
		return null;
	}, [handleCurrentAdvisorQuestionChange]);

	function getPortOfFollowingQuestion(followingQuestionId: string, nodes: QuestionNodeModel[]): PortModel | null {
		for (const node of nodes) {
			if (node.extras.question._id === followingQuestionId) {
				return node.getPort(createPortModelName(PortType.QUESTIONIN, node.extras.question._id));
			}
		}
		return null;
	}

	function handleSaveButtonClicked(): void {
		props.handleDiagramEditorChanged(props.advisor.questions, props.advisor.startingQuestion);
		oldAdvisorInitialized.current = false;
		props.closeHandler();
	}

	function sortAdvisorKeys(advisor: Advisor | null): Advisor | null {
		if (!advisor) {
			return null;
		}

		const advisorToSort = JSON.parse(JSON.stringify(advisor));
		const questions: AdvisorQuestion[] = [];

		for (const question of advisorToSort.questions) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			questions.push((Object.keys(question) as Array<keyof typeof question>).sort().reduce((r: any, key) => {r[key] = question[key]; return r;}, {}));
		}
		advisorToSort.questions = questions;

		return advisorToSort;
	}

	function handleCloseClicked(): void {
		if (JSON.stringify(props.advisor) !== JSON.stringify(oldAdvisor)) {
			const dialog = props.dialog;
			if (dialog) {
				dialog.confirm({
					title: 'Editor schließen?',
					message: 'Wollen Sie den Editor wirklich schließen? Die Änderungen würden dadurch verloren gehen.',
					ok: {
						text: 'Schließen',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					if (oldAdvisor) {
						props.handleDiagramEditorChanged(oldAdvisor.questions, oldAdvisor.startingQuestion);
						oldAdvisorInitialized.current = false;
						props.closeHandler();
					}
				}).catch((error): void => {
					console.log(`Error while deleting advisor question: ${error}`);
				});
			}
		} else {
			props.closeHandler();
		}
	}

	useEffect(() => {
		if (props.editorOpen) {
			const engine = new DiagramEngine();
			engine.installDefaultFactories();

			const model = new DiagramModel();
			model.addListener({
				zoomUpdated: (event) => {
					currentZoom.current = event.zoom;
				},
				offsetUpdated: (event) => {
					currentOffsetX.current = event.offsetX;
					currentOffsetY.current = event.offsetY;
				}
			});
			model.setZoomLevel(currentZoom.current);
			model.setOffset(currentOffsetX.current, currentOffsetY.current);
			engine.setDiagramModel(model);

			engine.registerPortFactory(new PortFactory('question', config => new QuestionPortModel(handleCurrentAdvisorQuestionChange, PortType.QUESTIONIN, 1)));
			engine.registerLinkFactory(new QuestionLinkFactory(handleCurrentAdvisorQuestionChange));
			engine.registerNodeFactory(
				new QuestionNodeFactory(
					props.advisor.questions, 
					props.handleEditQuestionButtonClicked, 
					props.handleNewAnswerButtonClicked, 
					props.handleEditAnswerButtonClicked,
					handleCurrentAdvisorQuestionChange,
					props.advisor.startingQuestion
				)
			);

			// reset links
			const linksToRemove = model.getLinks();
			for (const linkKey of Object.keys(linksToRemove)) {
				model.removeLink(linksToRemove[linkKey]);
			}

			// reset nodes
			const nodesToRemove = model.getNodes();
			for (const nodeKey of Object.keys(nodesToRemove)) {
				model.removeNode(nodesToRemove[nodeKey]);
			}

			// create nodes
			const nodes: QuestionNodeModel[] = [];
			for (const advisorQuestion of props.advisor.questions) {
				const questionNode = new QuestionNodeModel(
					advisorQuestion, 
					props.handleEditQuestionButtonClicked,
					props.handleNewAnswerButtonClicked,
					props.handleEditAnswerButtonClicked,
					handleCurrentAdvisorQuestionChange,
					props.advisor.startingQuestion
				);
				questionNode.setPosition(advisorQuestion.xCoordinate, advisorQuestion.yCoordinate);
				nodes.push(questionNode);
			}

			// create links
			const links: QuestionLinkModel[] = [];
			for (const node of nodes) {
				const question = node.extras.question;
				if (question.questionType === AdvisorQuestionType.SINGLESELECT) {
					for (const answer of question.answers) {
						if (answer.followingQuestion) {
							const linkModel = createLinkModel(node, nodes, PortType.ANSWEROUT, answer._id, answer.followingQuestion);
							if (linkModel) {
								links.push(linkModel);
							}
						} else if (answer.historyBased) {
							let standaloneDefaultLink = true;
							if (answer.historyBased.defaultFollowingQuestionId) {
								for (const historyBasedFollowingQuestion of answer.historyBased.historyBasedFollowingQuestions) {
									if (historyBasedFollowingQuestion.followingQuestionId === answer.historyBased.defaultFollowingQuestionId) {
										standaloneDefaultLink = false;
									}
								}
							}
							if (answer.historyBased.defaultFollowingQuestionId && standaloneDefaultLink) {
								const linkModel = createLinkModel(node, nodes, PortType.ANSWEROUT, answer._id, answer.historyBased.defaultFollowingQuestionId);
								if (linkModel) {
									linkModel.addLabel('Default');
									links.push(linkModel);
								}
							}
							for (const historyBasedFollowingQuestion of answer.historyBased.historyBasedFollowingQuestions) {
								const linkModel = createLinkModel(node, nodes, PortType.ANSWEROUT, answer._id, historyBasedFollowingQuestion.followingQuestionId);
								if (linkModel) {
									const advisorAnswers = getAdvisorAnswersByIds(historyBasedFollowingQuestion.historyBasedAnswerIds, props.advisor.questions);
									let labelString = advisorAnswers.map(a => a.answer).join(', ');
									if (answer.historyBased.defaultFollowingQuestionId === historyBasedFollowingQuestion.followingQuestionId) {
										labelString += ', Default';
									} 
									linkModel.addLabel(labelString);
									links.push(linkModel);
								}
							}
						}
					}
				} else {
					if (question.followingQuestion) {
						const linkModel = createLinkModel(node, nodes, PortType.QUESTIONOUT, question._id, question.followingQuestion);
						if (linkModel) {
							links.push(linkModel);
						}
					}
				}
			}

			model.addAll(...nodes, ...links);
			
			setCurrentEngine(engine);
		}

	}, [createLinkModel, handleCurrentAdvisorQuestionChange, props.advisor.questions, props.advisor.startingQuestion, props.editorOpen, props.handleEditAnswerButtonClicked, props.handleEditQuestionButtonClicked, props.handleNewAnswerButtonClicked]);


	

	return (
		<Dialog open={props.editorOpen} onClose={props.closeHandler} fullScreen>
			<AppBar className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<div className={classes.toolbarFirstRow}>
						<Typography variant="h5" component="div">
							{`Produktberater Editor - ${props.advisor.name} (ID: ${props.advisor.originalId})`}
						</Typography>
						<div className={classes.toolbarButtonsContainer}>
							<IconButton color="inherit" onClick={handleCloseClicked}>
								<ClearIcon />
							</IconButton>
						</div>
					</div>
					<div className={classes.toolbarSecondRow}>
						<div className={classes.questionSelectContainer}>
							<AdvisorStartingQuestionSelect 
								questions={props.advisor.questions}
								startingQuestionId={props.advisor.startingQuestion}
								handleStartingQuestionChange={handleCurrentStartingQuestionChange}
							/>
						</div>
						<div className={classes.addQuestionButtonContainer}>
							<Button
								variant="outlined"
								color="inherit"
								size="small"
								className={classes.addQuestionButton}
								startIcon={<AddIcon />}
								onClick={(): void => props.handleNewQuestionButtonClicked()}
							>
						Neue Frage
							</Button>
							<Button
								variant="contained"
								color="secondary"
								size="small"
								className={classes.saveEditorButton}
								startIcon={<SaveIcon />}
								onClick={handleSaveButtonClicked}
								disabled={JSON.stringify(sortAdvisorKeys(props.advisor)) === JSON.stringify(sortAdvisorKeys(oldAdvisor))}
							>
						Speichern
							</Button>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			{currentEngine !== null && (
				<DiagramWidget diagramEngine={currentEngine} allowLooseLinks={false}/>
			)}
		</Dialog>
	);
};

export default withDialog()(withStyles(advisorDiagramEditorStyles)(AdvisorDiagramEditor));
