import { ProductStatus } from './Product';

export enum CrawlerType {
	Monitoring = 'MONITORING',
	CategoryFeed = 'CATEGORY_FEED',
	CsvEnricher = 'CSV_ENRICHER',
	MultiCategoryFeed = 'MULTI_CATEGORY_FEED'
}

export enum FolderType {
	check = 'Check',
	adviser = 'Berater',
	multi = 'Multi',
	test = 'Test',
	others = 'Sonstige'
}

export enum CrawlerStatus {
	NotRun = 0,
	Running = 1,
	Finished = 2,
	Error = 3
}

export interface CrawlerProduct {
	_id: string;
	productCode: string;
	productName: string;
	productStatus: ProductStatus;
	actualPrice: number;
	oldPrice: number;
}

export interface CrawlerModel {
	_id: number;
	name: string;
	url: string;
	products: CrawlerProduct[];
	type: CrawlerType;
	categoryCode: string;
	excludedCategoryCodes: string[];
	attributes: string[];
	delimiterRemoveAttributes: string[];
	status: CrawlerStatus;
	lastRunDate?: Date;
	folderType?: FolderType;
	multiFeeds?: number[];
}
