import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const automatedEditorProductSettingsStyles = (theme: Theme): any => createStyles({
	panelTitle: {
		flexGrow: 1,
		maxWidth: 300,
	},
	panelDetails: {
		flexWrap: 'wrap',
	},
	productButton: {
		marginBottom: theme.spacing(2),
		marginRight: theme.spacing(1)
	}
});

export default automatedEditorProductSettingsStyles;
