import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const editableCategoryTreeLabelStyles = (theme: Theme): any => createStyles({
	root: {
		display: 'flex',
		paddingTop: '4px',
		paddingBottom: '4px'
	},
	buttons: {
		marginLeft: 'auto',
		display: 'flex'
	},
	editButton: {
		marginRight: '10px',
	},
	deleteButton: {
		color: 'red',
		marginRight: '6px',
	},
	undoDeleteButton: {
		color: 'green',
		marginRight: '6px',
	},
	name: {
		marginTop: '4px'
	},
	upButton: {
		marginRight: '10px',
	},
	downButton: {
		marginRight: '10px',
	},
	iconPlaceholder: {
		marginRight: '40px'
	},
	infoIcon: {
		marginRight: theme.spacing(1),
		alignSelf: 'flex-end'
	}
});

export default editableCategoryTreeLabelStyles;
