import React, {ReactElement, FC, CSSProperties, useState} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorAnswerRulesSelectStyles from './AdvisorAnswerRulesSelectStyles';
import Select, { ValueType } from 'react-select';
import RuleGroupTable from 'AutomatedComparator/RuleGroupTable';
import { AdvisorAnswer, loadAdvisors } from '@common/models/Advisor';
import { withDialog, Dialog as ConfirmDialog } from 'muibox';
import { RuleGroup } from '@common/models/AutomatedComparator';
import { useQuery } from '@tanstack/react-query';

interface OptionType {
	label: string;
	value: AdvisorAnswer;
};

interface Props extends WithStyles<typeof advisorAnswerRulesSelectStyles> {
	dialog?: ConfirmDialog;
	dialogOpen: boolean;
	closeHandler: () => void;
	currentRules: RuleGroup;
	handleRulesChange: (ruleGroup: RuleGroup) => void;
}

const AdvisorAnswerRulesSelect: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const [selectedAdvisorAnswer, setSelectedAdvisorAnswer] = useState<AdvisorAnswer | null>(null);
	const [options, setOptions] = useState<OptionType[]>([]);

	const advisorsQuery = useQuery(['advisors-rules'], () =>
		loadAdvisors(),
	{
		onSuccess: (data) => {
			if (data) {
				const options: OptionType[] = [];
				for (const advisor of data) {
					for (const question of advisor.questions) {
						for (const answer of question.answers) {
							answer.originalAdvisorId = advisor.originalId;
							answer.advisorName = advisor.name;
							answer.question = question.question;
							options.push({ value: answer, label: `ID: ${answer.originalAdvisorId} - ${answer.advisorName} - ${answer.question} - ${answer.answer}`});
						}
					}
				}
				setOptions(options);
			}
		},
		enabled: !!props.dialogOpen
	}
	);

	function cancelButtonClicked(): void {
		props.closeHandler();
	}

	function saveButtonClicked(): void {
		const dialog = props.dialog;

		if (props.currentRules.rules.length > 0 || props.currentRules.ruleGroups.length > 0) {
			if (dialog) {
				dialog.confirm({
					title: 'Regeln auswählen und ersetzen',
					message: `Sollen die bestehenden Regeln wirklich durch die Regeln der Antwort ${selectedAdvisorAnswer?.answer} ersetzt werden?`,
					ok: {
						text: 'Ersetzen',
						color: 'default',
						variant: 'text'
					},
					cancel: {
						text: 'Abbrechen',
						color: 'secondary',
						variant: 'text'
					},
				}).then((): void => {
					if (selectedAdvisorAnswer) {
						props.handleRulesChange(selectedAdvisorAnswer.rules);
					}
					props.closeHandler();
				}).catch((error): void => {
					console.log(`Error while replacing advisor answer rules: ${error}`);
				});
			}
		} else {
			if (selectedAdvisorAnswer) {
				props.handleRulesChange(selectedAdvisorAnswer.rules);
			}
			props.closeHandler();
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	function handleSelectedAdvisorAnswerChange(option: any): void {
		if (option) {
			setSelectedAdvisorAnswer(option.value);
		}
	}

	function getCurrentOption(): ValueType<OptionType> | null {
		let option: ValueType<OptionType> | null = null;
		if (selectedAdvisorAnswer) {
			option = {
				label: `ID: ${selectedAdvisorAnswer.originalAdvisorId} - ${selectedAdvisorAnswer.advisorName} - ${selectedAdvisorAnswer.question} - ${selectedAdvisorAnswer.answer}`,
				value: selectedAdvisorAnswer
			};
		}
		return option;
	}

	return (
		<Dialog open={props.dialogOpen} maxWidth={'lg'} onClose={props.closeHandler} fullWidth={true}>
			<DialogTitle>Regeln ersetzen</DialogTitle>
			<DialogContent>
				<Select
					value={getCurrentOption()}
					onChange={(option): void => handleSelectedAdvisorAnswerChange(option)}
					options={options}
					placeholder={'Produktberater-Antwort auswählen...'}
					noOptionsMessage={(): string => ('Keine Treffer')}
					menuPortalTarget={document.body}
					styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 })}}
					isSearchable
					isLoading={advisorsQuery.isLoading}
					loadingMessage={(): string => ('Wird geladen...')}
				>
				</Select>
				{selectedAdvisorAnswer &&

					<div className={classes.rulesContainer}>
						<RuleGroupTable
							ruleGroup={selectedAdvisorAnswer.rules}
							selectedCategoryCode={[]}
							isTopLevel={true}
							editable={false}
							loading={false}
							isAdvisor
						/>
					</div>
				}
			</DialogContent>
			<DialogActions className={classes.DialogActions}>
				<Button onClick={cancelButtonClicked} color="primary">
					Abbrechen
				</Button>
				<Button disabled={!selectedAdvisorAnswer} onClick={saveButtonClicked} color="primary">
					Anwenden
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withDialog()(withStyles(advisorAnswerRulesSelectStyles)(AdvisorAnswerRulesSelect));
