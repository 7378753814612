import React, { ReactNode, Component, ReactElement } from 'react';
import {
	Button,
	CircularProgress,
	TextField,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import AuthService from '@services/AuthService';
import MaterialTable from 'material-table';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import GetAppIcon from '@material-ui/icons/GetApp';

import comparatorExportStyles from './ComparatorExportStyles';

type Props = WithStyles<typeof comparatorExportStyles>;

interface State {
	loading: boolean;
}

class ComparatorExport extends Component<Props, State> {
	private authService: AuthService;


	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			loading: false
		};
	}

	private getTitleAndDescription(): ReactElement {
		const classes = this.props.classes;

		return (
			<div>
				<Typography
					component="h1"
					variant="h4"
					className={classes.title}
				>
					{'Produktvergleicher CSV-Export - Scan & Go Ricos'}
				</Typography>
				<Typography
					component="h2"
					variant="body1"
					className={classes.description}
				>
					{'Die CSV-Datei wird jede Nacht um 1 Uhr automatisch aktualisiert. Ebenfalls kann die CSV-Datei manuell aktualisiert werden. Während die CSV-Datei aktualisiert wird, werden die Buttons ausgegraut, eine Ladeanimation angezeigt und ein Herunterladen der CSV-Datei ist nicht möglich.'}
				</Typography>
			</div>
		);
	}

	private getLink(): string {
		return `${window.location.origin}/download/all/moebelix-vergleicher.csv`;
	}

	private downloadTextField(): ReactNode {
		return (<TextField
			value={this.getLink()}
			margin="normal"
			fullWidth={true}
			onFocus={(event): void => {
				event.target.select();
			}}
			InputProps={{
				readOnly: true,
			}}
		/>);
	}

	private downloadButton(): ReactNode {
		const classes = this.props.classes;

		return (
			<Button
				className={classes.downloadButton}
				color="primary"
				download
				disabled={this.state.loading}
				href={this.getLink()}
				startIcon={<GetAppIcon color="primary"/>}
				variant="outlined"
			>
				Download
			</Button>
		);
	}

	private async generateCsv(): Promise<void> {
		this.setState({ loading: true });
		await this.authService.fetch<{}>('/api/comparators/generate-csv', {
			method: 'GET'
		});
		this.setState({ loading: false });
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<MaterialTable
				columns={[
					{
						field: 'export' as const,
						sorting: true,
						filtering: false,
						cellStyle: {
							fontSize: 18
						}
					},
					{
						field: 'url' as const,
						filtering: false,
						render: (_): ReactNode => {
							return this.downloadTextField();
						}
					},
					{
						field: 'buttons' as const,
						filtering: false,
						cellStyle: {
							textAlign: 'right',
							whiteSpace: 'nowrap'
						},

						render: (_): ReactNode => {
							return (
								<div>
									<Button
										onClick={this.generateCsv.bind(this)}
										color="primary"
										startIcon={<PlayCircleFilledWhiteIcon color="primary"/>}
										variant="outlined"
										disabled={this.state.loading}
										className={classes.startButton}
									>
									Aktualisieren
										{this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
									</Button>
									{this.downloadButton()}
								</div>
							);
						}
					},
				]}
				options={{
					search: false,
					filtering: false,
					columnsButton: false,
					paging: false,
					emptyRowsWhenPaging: false,
					draggable: false,
				}}
				style={{
					borderRadius: 0,
					width: '100%',
				}}
				data={[{export: 'Alle Produktvergleicher', url: 'http'}]}
				title={ this.getTitleAndDescription() }
			/>
		);
	}
}

export default withStyles(comparatorExportStyles)(ComparatorExport);
