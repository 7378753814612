import React, { ReactNode, Component } from 'react';

import { RouteComponentProps } from 'react-router';
import {
	Button,
	Fab,
	Paper,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import { withDialog } from 'muibox';
import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';
import { AttributeValueSynonym } from '@models/AttributeValueSynonym';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AuthService from '@services/AuthService';
import AttributeValueSynonymEditor from './AttributeValueSynonymEditor';
import attributeValueSynonymOverviewStyles from './AttributeValueSynonymOverviewStyles';

interface Props extends WithStyles<typeof attributeValueSynonymOverviewStyles>, RouteComponentProps {
	dummy: boolean;
}

interface State {
	synonyms: AttributeValueSynonym[];
	synonymToEdit: AttributeValueSynonym | null;
	synonymEditorOpen: boolean;
	editorPropsHandlerActive: boolean;
}

interface AttributeValueSynonymResponse {
	data: AttributeValueSynonym[];
	success: boolean;
	message?: string;
}

class AttributeValueSynonymOverview extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			synonyms: [],
			synonymToEdit: null,
			synonymEditorOpen: false,
			editorPropsHandlerActive: false
		};
		this.loadAttributeValueSynonyms();
	}

	private loadAttributeValueSynonyms(): void {
		this.authService.fetch<AttributeValueSynonymResponse>('/api/attribute-value-synonyms', {
			method: 'GET'
		}).then((response): void => {
			if (response.success && response.data) {
				this.setState({synonyms: response.data});
			} else if (response.message) {
				this.setState({synonyms: []});
				throw new Error(response.message);
			} else {
				this.setState({synonyms: []});
				throw new Error('Unkown Error');
			}
		});
	}

	private closeSynonymEditor(): void {
		this.setState({synonymEditorOpen: false});
		this.loadAttributeValueSynonyms();
	}

	private editButtonClicked(synonym: AttributeValueSynonym): void {
		this.setState({
			editorPropsHandlerActive: true,
			synonymEditorOpen: true,
			synonymToEdit: synonym
		});
	}

	private openNewSynonymEditor(): void {
		this.setState({
			editorPropsHandlerActive: true,
			synonymEditorOpen: true,
			synonymToEdit: null
		});
	};

	private synonymsTable(): ReactNode {
		const classes = this.props.classes;
		const synonyms = this.state.synonyms;
		return (
			<MaterialTable
				columns={[
					{
						title: 'Attributwert-Synonym',
						field: 'name' as const,
						cellStyle: {
							fontSize: 18
						}
					},
					{
						title: 'Attributwerte',
						field: 'values' as const,
						render: (values): ReactNode => {
							let finalString = '';
							if (values.containedValues && values.containedValues?.length > 0) {
								finalString = 'Enthält-Attributwerte: ';
								for (const containedValue of values.containedValues) {
									finalString += '"' + containedValue + '" | ';
								}
								if (finalString.endsWith(' | ')) {
									finalString = finalString.slice(0, -3);
								}
							} else {
								for (const s of values.values) {
									if (finalString.length > 0) {
										finalString += ' | "' + s + '"';
									} else {
										finalString += '"' + s + '"';
									}
								}
							}
							return (
								<span>{finalString}</span>
							);
						}
					},
					{
						field: 'buttons' as const,
						filtering: false,
						cellStyle: {
							textAlign: 'right',
							whiteSpace: 'nowrap'
						},
						render: (attributeValueSynonym): ReactNode => {
							return (
								<div>
									<Button
										onClick={this.editButtonClicked.bind(this, attributeValueSynonym)}
										color="primary"
										startIcon={<EditIcon color="primary"/>}
										variant="outlined"
										className={classes.editButton}
									>
										Bearbeiten
									</Button>
								</div>
							);
						}
					},
				]}
				options={{
					search: false,
					filtering: false,
					sorting: false,
					columnsButton: false,
					paging: false,
					draggable: false,
					showTitle: false
				}}
				style={{
					borderRadius: 0,
					width: '100%',
				}}
				data={ synonyms }
				localization={ TableLocalization.localization}
			/>
		);
	}

	private changeEditorPropsHandlerActive(active: boolean): void {
		this.setState({
			editorPropsHandlerActive: active
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Paper square={true}>
				<Typography component="h2" variant="h4" className={classes.title}>
					{'Attributwert-Synonyme Übersicht'}
				</Typography>
				<Typography
					variant="body1"
					className={classes.description}
				>
					{`
						Attributwert-Synonyme können bei den Regeln "Produkt Attributwert enthält", "Produkt Attributwert enthält nicht", "Produkt Attributwert ist gleich", "Produkt Attributwert ist nicht gleich", "Produkt Attributwert ist größer", "Produkt Attributwert ist größer gleich", "Produkt Attributwert ist kleiner" und "Produkt Attributwert ist kleiner gleich" verwendet werden.
						Der Name des Attributwert-Synonyms (linke Spalte) muss bei der Erstellung einer der oben gelisteten Regel im Regel-Editor in das Textfeld eingetragen werden. Dadurch werden alle Attributwerte (mittlere Spalte) des Attributwert-Synonyms für die Regel verwendet.
						`}
				</Typography>
				{this.synonymsTable()}
				<Tooltip
					placement="bottom"
					arrow
					title={'Ein neues Attributwert-Synonym anlegen'}
				>
					<Fab
						color="primary"
						className={classes.addButton}
						onClick={this.openNewSynonymEditor.bind(this)}>
						<AddIcon />
					</Fab>
				</Tooltip>
				<AttributeValueSynonymEditor
					synonym={this.state.synonymToEdit}
					closeHandler={this.closeSynonymEditor.bind(this)}
					editorOpen={this.state.synonymEditorOpen}
					editorPropsHandlerActive={this.state.editorPropsHandlerActive}
					handleEditorPropsHandlerActiveChange={this.changeEditorPropsHandlerActive.bind(this)}
				/>
			</Paper>
		);
	}

}

export default withDialog()(withStyles(attributeValueSynonymOverviewStyles)(AttributeValueSynonymOverview));
