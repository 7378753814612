import React, {ReactElement, FunctionComponent} from 'react';

import { Theme, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Query, QueryResult } from 'material-table';

import AuthService from '@services/AuthService';
import OrdersTable from './OrdersTable';
import OrderDetailsModal from './OrderDetailsModal';

import { Order } from '@models/Order';

interface OrdersResponse extends Response {
	success: boolean;
	data: Order[];
	page: number;
	totalCount: number;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		root: {
			'& .MuiPaper-root div > div > div > .MuiTable-root': {
				[theme.breakpoints.down('xs')]: {
					display: 'block',
					height: 'calc(100vh - 220px)',
					width: 'calc(100vw - 50px)',
				},
			}
		},
	};
});

const Orders: FunctionComponent = (): ReactElement => {
	const theme = useTheme();
	const classes = useStyles();
	const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

	const [selectedOrder, setSelectedOrder] = React.useState<Order | null>(null);
	const [loadedOrders, setLoadedOrders] = React.useState<Order[]>([]);

	const authService = new AuthService();

	const handleDetailsModalClose = (): void => {
		setSelectedOrder(null);
	};

	const orderSelectedHandler = (order: Order): void => {
		setSelectedOrder(order);
	};

	const handleKeyPressed = (event: React.KeyboardEvent<HTMLDivElement>): void => {
		if (selectedOrder !== null && (event.keyCode === 37 || event.keyCode === 39)) {
			selectNextOrPreviousOrder(event.keyCode === 39);
		}
	};

	const selectNextOrPreviousOrder = (next: boolean): void => {
		let index = loadedOrders.findIndex((e: Order): boolean => {
			return selectedOrder !== null && e._id === selectedOrder._id;
		});

		if (index === -1) {
			return;
		}

		if (!next) {
			// Left Arrow
			// Try to subtract one from the current selection index
			index--;
		} else {
			// Right Arrow
			// Try to add one to the current selection index
			index++;
		}

		if (index >= 0 && index < loadedOrders.length) {
			setSelectedOrder(loadedOrders[index]);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const loadDataHandler = (query: Query<any>): Promise<QueryResult<any>> => {
		return new Promise((resolve, reject): void => {
			const url = `/api/orders/table?query=${encodeURIComponent(JSON.stringify(query))}`;

			authService.fetch<OrdersResponse>(url, {
				method: 'GET'
			}).then((response): void => {
				if (response.success) {
					setLoadedOrders(response.data);
					resolve({data: response.data, page: response.page - 1, totalCount: response.totalCount});
				} else {
					reject();
				}
			}).catch((error): void => {
				reject();
			});
		});
	};

	return (
		<div onKeyDown={handleKeyPressed} tabIndex={0} className={classes.root}>
			<OrdersTable
				orderSelectedHandler={orderSelectedHandler}
				loadDataHandler={loadDataHandler}
			/>
			<Dialog
				open={selectedOrder !== null}
				onClose={handleDetailsModalClose}
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth={'lg'}
			>
				<OrderDetailsModal
					closeHandler={handleDetailsModalClose}
					selectNextOrderHandler={selectNextOrPreviousOrder}
					order={selectedOrder}
				/>
			</Dialog>
		</div>
	);
};

export default Orders;
