import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryMappingEditorStyles = (theme: Theme): any => createStyles({
	deleteButton: {
		color: '#EE0000',
		marginRight: 'auto',
	},
	DialogActions: {
		display: 'flex',
	},
	disabledMargin: {
		marginTop: theme.spacing(4)
	},
	ownImageCheckbox: {
		marginTop: theme.spacing(4)
	},
	subgroupAddContainer: {
		display: 'flex',
		marginTop: theme.spacing(4),
	},
	addSubgroupButton: {
		alignSelf: 'flex-end',
		marginBottom: '4px',
		marginRight: theme.spacing(2)
	},
	subgroupTextField: {
		width: '100%',
		marginRight: '8px'
	},
	subgroupsTable: {
		marginBottom: '16px'
	},
	subgroupsTableHeader: {
		marginTop: theme.spacing(3)
	},
});

export default categoryMappingEditorStyles;
