import React, { ReactNode, Component } from 'react';

import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { Comparator } from '@models/Comparator';
import ComparatorPreview from '../../Comparator/ComparatorPreview';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import editorPreviewStyles from './EditorPreviewStyles';

interface Props extends WithStyles<typeof editorPreviewStyles> {
	comparator: Comparator;
	handleSaveButton: () => void;
	saving: boolean;
	merge: boolean;
	handleMergeInactiveSaveButton: (() => void)| null;
}

class EditorPreview extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.comparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.comparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Vorschau</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<ComparatorPreview
						comparator={this.props.comparator}
						saveHandler={this.props.handleSaveButton.bind(this)}
						isSaving={this.props.saving}
						showButtons={true}
						isAutomated={false}
						merge={this.props.merge}
						handleMergeInactiveSaveButton={this.props.handleMergeInactiveSaveButton ? this.props.handleMergeInactiveSaveButton.bind(this) : null}
					/>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}
}

export default withStyles(editorPreviewStyles)(EditorPreview);
