import React, {ReactElement, FC} from 'react';
import {
	Fab,
	Paper,
	Tooltip,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorOverviewStyles from './AdvisorOverviewStyles';
import AddIcon from '@material-ui/icons/Add';
import AdvisorTable from './AdvisorTable';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {loadAdvisors} from '@common/models/Advisor';
import { useQuery } from '@tanstack/react-query';
import { WithSnackbarProps, withSnackbar } from 'notistack';

interface Props extends WithStyles<typeof advisorOverviewStyles>, RouteComponentProps, WithSnackbarProps {
	dummy: boolean;
}

const AdvisorOverview: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;

	const advisorsQuery = useQuery(['advisors'], () =>
		loadAdvisors()
	);

	return (
		<Paper className={classes.root}>
			<Typography component="h2" variant="h4" className={classes.title}>
				{'Produktberater'}
			</Typography>
			<AdvisorTable
				advisors={advisorsQuery.data ?? []}
				isLoading={advisorsQuery.isFetching || advisorsQuery.isLoading}
			/>
			<Tooltip
				placement="bottom"
				arrow
				title={'Neuen Produktberater anlegen'}
			>
				<Fab
					aria-label="Add"
					className={classes.fab}
					color="primary"
					onClick={(): void => {
						props.history.push('/advisor/new/edit');
					}}
				>
					<AddIcon />
				</Fab>
			</Tooltip>
		</Paper>
	);
};

export default withSnackbar(withStyles(advisorOverviewStyles)(withRouter(AdvisorOverview)));
