import React, { ReactNode, Component } from 'react';
import moment from 'moment';
import Moment from 'react-moment';

import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';

import dashboardStyles from './DashboardStyles';


interface State {
	username: string;
	time: Date | null;
	reports: PaymentReports[];
	latestEmailTimestamp: Date | null;
	missingAttributeEmail: MissingAttributeEmail | null;
	latestShoppingTimestamp: Date | null;
	productsCount: number;
	cronjob: LatestCronjob | null;
}

interface MeResponse extends Response {
	success: boolean;
	message?: string;
	data: {
		time: Date;
		username: string;
	};
}

interface LatestResponse extends Response {
	success: boolean;
	message?: string;
	data: {
		timestamp: Date | null;
	};
}

interface LatestMissingAttributeEmailResponse extends Response {
	success: boolean;
	message?: string;
	data: MissingAttributeEmail | null;
}


// TODO: Move them to external declaration file
interface PaymentByMethodReport {
	paymentMethod: string;
	totalSum: number;
	orderCount: number;
}

interface PaymentReports {
	time: Date;
	orderCount: number;
	totalSum: number;
	paymentsByMethod: PaymentByMethodReport[];
}

interface MissingAttributeEmail {
	numberComparatorMissingAttributes: number;
	numberProductsMissingAttributes: number;
	numberMissingAttributes: number;
	emailSuccess: boolean;
	created_at: Date;
}

interface CronjobService {
	_id: string;
	name: string;
	status: CronStatus;
	startedAt?: Date;
	finishedAt?: Date;
}

interface LatestCronjob {
	_id: string;
	status: CronStatus;
	startedAt: Date;
	finishedAt?: Date;
	services: CronjobService[];
}

enum CronStatus {
	NotStarted,
	Running,
	Finished,
	Failed
}

interface TotalPaymentsResponse extends Response {
	success: boolean;
	message?: string;
	data: PaymentReports[];
}

interface ProductsStatisticsResponse extends Response {
	success: boolean;
	message?: string;
	data: {
		count: number;
	};
}

interface LatestCronjobResponse extends Response {
	success: boolean;
	message?: string;
	data: LatestCronjob;
}

class Dashboard extends Component<WithStyles<typeof dashboardStyles>, State> {

	private authService: AuthService;

	public constructor(props: WithStyles<typeof dashboardStyles>) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			username: '',
			time: null,
			reports: [],
			latestEmailTimestamp: null,
			missingAttributeEmail: null,
			latestShoppingTimestamp: null,
			productsCount: -1,
			cronjob: null,
		};
	}

	public componentDidMount(): void {
		this.authService.fetch<MeResponse>('/api/users/me', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({username: response.data.username, time: response.data.time});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});

		this.loadDailyTotals();
		this.loadLatestEmail();
		this.loadLatestMissingAttributeEmail();
		this.loadLatestShoppingResult();
		this.loadProductsStatistics();
		this.loadCronjob();
	}

	private loadDailyTotals(): void {
		if (!this.authService.hasPermission('reporting')) {
			return;
		}

		const fromDate = moment().startOf('day');
		const toDate = moment().endOf('day');
		let url = '/api/reporting/totalPayments?hourly=false&';
		url += `&timeFrom=${encodeURI(fromDate.toISOString())}`;
		url += `&timeTo=${encodeURI(toDate.toISOString())}`;

		this.authService.fetch<TotalPaymentsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({reports: response.data});
			}
		});
	}

	private loadCronjob(): void {
		if (!this.authService.hasPermission('admin')) {
			return;
		}

		const url = '/api/cronjobs/latest';

		this.authService.fetch<LatestCronjobResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({cronjob: response.data});
			}
		});
	}

	private loadLatestEmail(): void {
		this.authService.fetch<LatestResponse>('/api/reporting/latestEmail', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({latestEmailTimestamp: response.data.timestamp});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadLatestMissingAttributeEmail(): void {
		this.authService.fetch<LatestMissingAttributeEmailResponse>('/api/missing-attribute-emails/latestResult', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({missingAttributeEmail: response.data});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadLatestShoppingResult(): void {
		this.authService.fetch<LatestResponse>('/api/shopping/latestResult', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({latestShoppingTimestamp: response.data.timestamp});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private loadProductsStatistics(): void {
		this.authService.fetch<ProductsStatisticsResponse>('/api/products/statistics', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({productsCount: response.data.count});
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}


	public render(): ReactNode {
		const classes = this.props.classes;

		let report: PaymentReports | null = null;
		let paymentsByMethod: PaymentByMethodReport[] | null = null;

		if (this.state.reports.length > 0) {
			report = this.state.reports[0];
			paymentsByMethod = report.paymentsByMethod.sort((a: PaymentByMethodReport, b: PaymentByMethodReport): number => a.paymentMethod.localeCompare(b.paymentMethod));
		}

		return (
			<Paper square={true} className={classes.root}>
				<Typography component="h2" variant="h4">
					{`Guten Tag, ${this.state.username}!`}
				</Typography>
				{ (this.authService.hasPermission('reporting') && report && paymentsByMethod) &&
					<div>
						<Divider className={classes.spacer}/>
						<Typography component="h3" variant="h5">
							{'Reporting'}
						</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<Moment format="dddd, D. MMMM YYYY" date={report.time} locale="de" />
									</TableCell>
									<TableCell align="right">{'Umsatz'}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{paymentsByMethod.map((report): ReactNode => (this.renderPaymentMethod(report)))}
								<TableRow key="Gesamt">
									<TableCell component="th" scope="row">
										<b>{'Gesamt:'}</b>
									</TableCell>
									<TableCell align="right">
										<b>{report.totalSum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</b>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</div>
				}
				<Divider className={classes.spacer}/>
				<Typography component="h3" variant="h5">
					{'Server Informationen'}
				</Typography>
				{ this.state.time !== null &&
					<p>Die aktuelle Serverzeit beträgt: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.time)} locale="de" />.</p>
				}
				{ this.state.latestEmailTimestamp !== null &&
					<p>Die letzte E-Mail wurde empfangen: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.latestEmailTimestamp)} locale="de" />.</p>
				}
				{ this.state.latestShoppingTimestamp !== null &&
					<p>Die letzte Google Shopping Abfrage wurde gesendet: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.latestShoppingTimestamp)} locale="de" />.</p>
				}
				{ this.state.productsCount >= 0 &&
					<p>Produkte in der Datenbank: {this.state.productsCount}</p>
				}
				{ this.state.missingAttributeEmail !== null &&
					<div style={{marginTop: '16px'}}>
						<Typography component="h4" variant="h6">
							{'Attribute aktive automatisierte Prouktvergleicher:'}
						</Typography>
						<p>Anzahl der aktiven automatisierten Produktvergleicher, welche Produkte mit ungepflegten Attributen enthalten: {this.state.missingAttributeEmail.numberComparatorMissingAttributes}.</p>
						<p>Anzahl der Produkte (in aktiven automatisierten Produktvergleichern), welche ungepflegte Attribute enthalten: {this.state.missingAttributeEmail.numberProductsMissingAttributes}.</p>
						<p>Anzahl ungepflegter Attribute (von Produkten in aktiven automatisierten Produktvergleichern): {this.state.missingAttributeEmail.numberMissingAttributes}.</p>
						<p>Letztes Update: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.missingAttributeEmail.created_at)} locale="de" />.</p>
						<p>E-Mail erfolgreich versendet: {this.state.missingAttributeEmail.emailSuccess ? 'Ja' : 'Nein'}.</p>
					</div>
				}
				{ this.state.cronjob !== null &&
					<div style={{marginTop: '16px'}}>
						<Typography component="h4" variant="h6">
							{'Cronjob Status:'}
						</Typography>
						<p>Status: {CronStatus[this.state.cronjob.status]}</p>
						<p>Letzter Cronjob Start: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.cronjob.startedAt)} locale="de" /></p>
						{ this.state.cronjob.finishedAt && (
							<p>Letzter Cronjob Ende: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(this.state.cronjob.finishedAt)} locale="de" /></p>
						)}
						<ul>
							{this.state.cronjob.services.map((service): ReactNode => (this.renderCronjobService(service)))}
						</ul>
					</div>
				}
			</Paper>
		);
	}

	private renderPaymentMethod(paymentMethod: PaymentByMethodReport): ReactNode {
		return (
			<TableRow key={paymentMethod.paymentMethod}>
				<TableCell component="th" scope="row">
					{paymentMethod.paymentMethod}
				</TableCell>
				<TableCell align="right">{paymentMethod.totalSum.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
			</TableRow>
		);
	}

	private renderCronjobService(service: CronjobService): ReactNode {
		let duration = 0;

		if (service.startedAt && service.finishedAt) {
			const start = moment(service.startedAt);
			const end = moment(service.finishedAt);
			duration = Math.round(moment.duration(end.diff(start)).asMinutes());
		}

		return (
			<li key={service._id}><p>
				{service.name} ({CronStatus[service.status]})
				{service.startedAt && (
					<><br />Start: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(service.startedAt)} locale="de" /></>
				)}
				{service.finishedAt && (
					<><br />Ende: <Moment format="dddd, D. MMMM YYYY HH:mm" date={moment(service.finishedAt)} locale="de" /></>
				)}
				{service.startedAt && service.finishedAt && (
					<><br />Dauer: {duration}mins</>
				)}
			</p></li>
		);
	}
}

export default withStyles(dashboardStyles)(Dashboard);
