import React, { ReactNode, Component } from 'react';

import {
	Button,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';

import { AutomatedComparator, RuleGroup } from '@models/AutomatedComparator';
import CategorySelect from '@common/CategorySelect';
import { Category } from '@models/Category';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RuleGroupTable from '../RuleGroupTable';
import AutomatedComparatorRulesSelect from '../AutomatedComparatorRulesSelect';
import TuneIcon from '@material-ui/icons/Tune';

import automatedEditorRuleSettingsStyles from './AutomatedEditorRuleSettingsStyles';

interface Props extends WithStyles<typeof automatedEditorRuleSettingsStyles> {
	automatedComparator: AutomatedComparator;
	handleRulesChange: (ruleGroup: RuleGroup) => void;
	handleCategoryChange: (selectedCategory: Category | null) => void;
	rulesSelectOpen: boolean;
	closeRulesSelect: () => void;
	openRulesSelect: () => void;
}

class AutomatedEditorRuleSettings extends Component<Props> {

	public constructor(props: Props) {
		super(props);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		if (!this.props.automatedComparator) {
			return;
		}

		return (
			<ExpansionPanel defaultExpanded={this.props.automatedComparator._id !== 'new'}>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography className={classes.panelTitle} variant="h6">Regeln</Typography>

				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.panelDetails}>
					<Typography className={classes.panelTitle} variant="body1">Kategorie:</Typography>
					<div className={classes.categorySelect}>
						<CategorySelect
							onChange={this.props.handleCategoryChange.bind(this)}
							selectedCategoryCode={this.props.automatedComparator.categoryCode}
							topLevelCategoriesExcluded={false}
							disabled={false}
						/>
					</div>
					{ this.props.automatedComparator.categoryCode &&
						<React.Fragment>
							<Typography className={classes.panelTitle} variant="body1">Regeln:</Typography>
							<Button
								variant="outlined"
								color="primary"
								size="small"
								className={classes.replaceRulesOpenDialogButton}
								onClick={this.props.openRulesSelect.bind(this)}
								startIcon={<TuneIcon color={'primary'}/>}
							>
							Regeln auswählen
							</Button>
							<RuleGroupTable
								ruleGroup={this.props.automatedComparator.rules}
								changeHandler={this.props.handleRulesChange.bind(this)}
								selectedCategoryCode={this.props.automatedComparator.categoryCode}
								isTopLevel={true}
								editable={true}
								loading={false}
							/>
						</React.Fragment>
					}
				</ExpansionPanelDetails>
				<AutomatedComparatorRulesSelect
					dialogOpen={this.props.rulesSelectOpen}
					closeHandler={this.props.closeRulesSelect.bind(this)}
					handleRulesChange={this.props.handleRulesChange.bind(this)}
					currentRules={this.props.automatedComparator.rules}
				/>
			</ExpansionPanel>
		);
	}
}

export default withStyles(automatedEditorRuleSettingsStyles)(AutomatedEditorRuleSettings);
