import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const attributeSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
	},
	formControl: {
		width: '100%'
	},
	loadingIndicator: {
		display: 'inline-block'
	},
	loadingIndicatorContainer: {
		textAlign: 'center'
	}
});

export default attributeSelectStyles;
