import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reportingStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	timeRange: {
		float: 'left',
		position: 'relative'
	}
});

export default reportingStyles;
