import React, {ReactElement, FC, useState} from 'react';
import {
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Typography,
	WithStyles,
	withStyles
} from '@material-ui/core';
import advisorEditorAttributeSettingsStyles from './AdvisorEditorAttributeSettingsStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Advisor } from '@common/models/Advisor';
import ComparatorAttributeSelect from 'Comparator/ComparatorAttributeSelect';
import { Product, loadOnlineProductsByCategories } from '@common/models/Product';
import { useQuery } from '@tanstack/react-query';

interface Props extends WithStyles<typeof advisorEditorAttributeSettingsStyles> {
	advisor: Advisor;
	handleAdvisorAttributesChange: (selectedAttributes: string[]) => void;
	expanded: boolean;
}

const AdvisorEditorAttributeSettings: FC<Props> = (props: Props): ReactElement => {
	const classes = props.classes;
	const [productsByCategories, setProductsByCategories] = useState<Product[]>([]);

	const advisorCategoryProductsQuery = useQuery(['advisorCategoryProducts'], () =>
		loadOnlineProductsByCategories(props.advisor.categoryCodes),
	{
		onSuccess: (data) => {
			if (data) {
				setProductsByCategories(data);
			}
		},
		enabled: props.advisor.categoryCodes.length > 0,
	});

	return (
		<ExpansionPanel
			defaultExpanded={props.expanded}
		>
			<ExpansionPanelSummary
				expandIcon={<ExpandMoreIcon />}
			>
				<Typography className={classes.panelTitle} variant="h6">Attribute (Default)</Typography>
			</ExpansionPanelSummary>
			<ExpansionPanelDetails className={classes.panelDetails}>
				<ComparatorAttributeSelect
					onChange={(selectedAttributes): void => {props.handleAdvisorAttributesChange(selectedAttributes);}}
					onChangeDelimiterRemoveAttributes={null}
					selectedProducts={productsByCategories}
					selectedAttributeCodes={props.advisor.attributes}
					sortingEnabled={true}
					templatesEnabled={true}
					maintainedEnabled={false}
					categoryFeed={false}
					loadingAttributeSettings={advisorCategoryProductsQuery.isLoading || advisorCategoryProductsQuery.isFetching}
					type={'advisor'}
				/>
			</ExpansionPanelDetails>
		</ExpansionPanel>
	);
};

export default withStyles(advisorEditorAttributeSettingsStyles)(AdvisorEditorAttributeSettings);
