import React, { ReactNode, Component } from 'react';
import {Moment} from 'moment';

import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { WithStyles, withStyles } from '@material-ui/core';


import AuthService from '@services/AuthService';
import paymentSelectStyles from './PaymentSelectStyles';

interface Props extends WithStyles<typeof paymentSelectStyles> {
	onChange?: Function;
	fromDate?: Moment;
	toDate?: Moment;
}

interface State {
	availableMethods: PaymentMethod[];
	selectedMethods: string[];
	fromDate?: Moment;
	toDate?: Moment;
}

interface PaymentMethod {
	name: string;
}

interface PaymentMethodsResponse extends Response {
	success: boolean;
	message?: string;
	data: PaymentMethod[];
}

class PaymentSelect extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);
		this.authService = new AuthService();

		this.state = {
			availableMethods: [],
			selectedMethods: []
		};

		// Manually force initial loading as times wont change on first open
		this.updateData();
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): object | null {
		if (nextProps.fromDate !== prevState.fromDate || nextProps.toDate !== prevState.toDate){
			return { fromDate: nextProps.fromDate, toDate: nextProps.toDate};
		}

		return null;
	}

	public componentDidUpdate(prevProps: Props, prevState: State): void {
		if (prevProps.fromDate !== this.state.fromDate || prevProps.toDate !== this.state.toDate){
			this.updateData();
		}
	}

	private updateData(): void {
		let url = '/api/reporting/paymentMethods';

		if (this.state.fromDate !== undefined && this.state.toDate !== undefined) {
			url += `?timeFrom=${encodeURI(this.state.fromDate.toISOString())}`;
			url += `&timeTo=${encodeURI(this.state.toDate.toISOString())}`;
		}

		this.authService.fetch<PaymentMethodsResponse>(url, {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				const selectedMethods = response.data.map((method): string => method.name);
				selectedMethods.sort();

				const availableMethods = response.data;
				availableMethods.sort((a: PaymentMethod, b: PaymentMethod): number => a.name.localeCompare(b.name));

				this.setState({availableMethods: response.data, selectedMethods: selectedMethods});

				if (this.props.onChange) {
					this.props.onChange(this.state.selectedMethods);
				}
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<FormControl className={classes.root}>
				<InputLabel htmlFor="payment-method-select">
					{'Zahlungsmethoden'}
				</InputLabel>
				<Select
					multiple
					value={this.state.selectedMethods}
					onChange={this.handleChange.bind(this)}
					input={<Input id="payment-method-select" />}
					renderValue={(selected): string => this.state.selectedMethods.length === this.state.availableMethods.length ? 'Alle' : (selected as string[]).join(', ')}
				>
					{this.state.availableMethods.map((method): ReactNode => (
						<MenuItem key={method.name} value={method.name}>
							<Checkbox checked={this.state.selectedMethods.includes(method.name)} />
							<ListItemText primary={method.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}

	private handleChange(event: React.ChangeEvent<{ value: unknown }>): void {
		const selectedMethods = event.target.value as string[];
		selectedMethods.sort();
		this.setState({selectedMethods: selectedMethods});

		if (this.props.onChange) {
			this.props.onChange(selectedMethods);
		}
	}
}

export default withStyles(paymentSelectStyles)(PaymentSelect);
