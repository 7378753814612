import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ruleEditorStyles = (theme: Theme): any => createStyles({
	root: {
		padding: theme.spacing(2, 3),
	},
	title: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 300,
		marginTop: 0,
	},
	formControlRelational: {
		display: 'flex',
		height: '32px',
		width: '100%',
		margin: theme.spacing(1),
		minWidth: 300,
		marginTop: 0,
	},
	formControlRelationalPrice: {
		display: 'flex',
		height: '32px',
		width: '95%',
		margin: theme.spacing(1),
		minWidth: 300,
		marginTop: theme.spacing(3)
	},
	relationalOperator: {
		width: '40px',
		textAlign: 'center',
		fontSize: '24px',
		color: theme.palette.secondary.main,
		border: '1px solid' + theme.palette.secondary.main,
		borderRadius: '4px',
		marginRight: '10px',
		paddingBottom: '34px',
		marginTop: '12px'
	},
	relationalAttributeValue: {
		width: '100%',
		marginRight: theme.spacing(1)
	},
	categorySelectContainer: {
		marginTop: theme.spacing(1)
	}
});

export default ruleEditorStyles;
