import React, { ReactNode, Component } from 'react';
import {
	CircularProgress,
	Typography,
	Tooltip,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	WithStyles,
	withStyles
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import ReportIcon from '@material-ui/icons/Report';
import { Product, ProductStatus, ProductInComparator } from '@models/Product';
import { AutomatedComparator } from '@models/AutomatedComparator';
import { Comparator } from '@models/Comparator';
import automatedComparatorProductsStyles from './AutomatedComparatorProductsStyles';

enum ProductSort {
	Name = 0,
	Price
}

interface Props extends WithStyles<typeof automatedComparatorProductsStyles> {
	comparator: AutomatedComparator | Comparator | null;
	products: Product[];
	loading: boolean;
	refreshed: boolean;
}

interface State {
	sort: ProductSort;
	hideAlreadyUsedProducts: boolean;
}

class AutomatedComparatorProducts extends Component<Props, State>{

	public constructor(props: Props) {
		super(props);

		this.state = {
			sort: ProductSort.Name,
			hideAlreadyUsedProducts: true,
		};
	}

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	private handleSortChange(event: any): void {
		this.setState({sort: event.target.value});
	}

	private attentionTooltip(product: Product): ReactNode {
		let comparators: ProductInComparator[] = [];

		if (product.usedInComparators) {
			comparators = product.usedInComparators;
		}


		comparators = comparators.filter((c) => this.props.comparator && c._id !== this.props.comparator._id);


		if (comparators.length === 0) {
			return (<React.Fragment />);
		}

		const tooltips: string[] = ['Bereits verwendet in:'];

		for (const comparator of comparators) {
			if (comparator.isAutomated) {
				tooltips.push(`a${comparator._id}: ${comparator.name}`);
			} else {
				tooltips.push(`${comparator._id}: ${comparator.name}`);
			}
		}

		return (
			<Tooltip title={
				<span dangerouslySetInnerHTML={{__html:tooltips.join('<br />')}} />
			}>
				<WarningIcon />
			</Tooltip>
		);
	}

	private offlineTooltip(product: Product): ReactNode {
		if (product.status === ProductStatus.Online) {
			return (<React.Fragment />);
		}

		return (
			<Tooltip title={
				<span>Produkt ist Offline</span>
			}>
				<ReportIcon />
			</Tooltip>
		);
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		const products = this.props.products;

		products.sort((a: Product, b: Product): number => {
			if (this.state.sort === ProductSort.Name) {
				if (a.name.toUpperCase() < b.name.toUpperCase()) {
					return -1;
				} else if (a.name.toUpperCase() > b.name.toUpperCase()) {
					return 1;
				}

				return 0;
			} else if (this.state.sort === ProductSort.Price) {
				return a.price - b.price;
			}

			return 0;
		});

		return (
			<div className={classes.container}>
				{!this.props.loading && this.props.products.length > 0 &&
				<FormControl className={classes.sortSelect}>
					<InputLabel id="sort-label">Sortierung</InputLabel>
					<Select
						labelId="sort-label"
						value={this.state.sort}
						onChange={(event): void => {this.handleSortChange(event);}}
					>
						<MenuItem value={ProductSort.Name}>Name A -&gt; Z</MenuItem>
						<MenuItem value={ProductSort.Price}>Preis &euro; -&gt; &euro; &euro;</MenuItem>
					</Select>
				</FormControl>
				}
				<div>
					{this.props.loading &&
						<CircularProgress className={classes.loadingIndicator}/>
					}

					{!this.props.loading && this.props.products.length > 0 &&
						<div className={classes.elementContainer}>
							{this.props.products.map((product: Product): ReactNode => (
								this.renderProduct(product)
							))}
						</div>
					}
					{!this.props.loading && this.props.products.length === 0 && this.props.refreshed &&
						<Typography>{'Keine Produkte gefunden'}</Typography>
					}
				</div>

			</div>
		);

	}

	private renderProduct(product: Product): ReactNode {
		const classes = this.props.classes;

		return (
			<div className={classes.labelContainer} key={product.productCode}>
				<Tooltip
					placement="right"
					title={
						<React.Fragment>
							{product.images.length > 0 &&
							<img src={`${product.images[0]}?maxH=280&maxW=280&upscale=false`} alt=""/>
							}
						</React.Fragment>
					}
				>
					<span className={classes.imageContainer}>
						{product.images.length &&
						<img src={`${product.images[0]}?maxH=28&maxW=28&upscale=false`} alt=""/>
						}
					</span>
				</Tooltip>
				<span className={classes.codeContainer}>{product.productCode}</span>
				<span className={classes.nameContainer}>{product.name}</span>
				<span className={classes.priceContainer}>
					{product.price.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
				</span>
				<span className={classes.attentionContainer}>{this.attentionTooltip(product)}</span>
				<span className={classes.attentionContainer}>{this.offlineTooltip(product)}</span>
			</div>
		);
	}

}

export default withStyles(automatedComparatorProductsStyles)(AutomatedComparatorProducts);
