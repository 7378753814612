import React, { ReactNode, Component } from 'react';

import {
	WithStyles,
	withStyles,
	Button,
	ButtonGroup,
	IconButton,
	DialogTitle,
	DialogActions,
	DialogContent,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
	Link,
	Typography,
	Hidden
} from '@material-ui/core';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

import MaterialTable from 'material-table';
import TableLocalization from 'Common/TableLocalization';

import Moment from 'react-moment';

import orderDetailsModalStyles from './OrderDetailsModalStyles';

import { Order, OrderItem, Address, StatusUpdate, Coupon } from '@models/Order';
import { Product } from '@models/Product';

import ProductsService from '@services/ProductsService';


interface Props extends WithStyles<typeof orderDetailsModalStyles> {
	order: Order | null;
	closeHandler: Function;
	selectNextOrderHandler: Function;
}

interface State {
	order: Order | null;
	products: Product[];
}

class OrderDetailsModal extends Component<Props, State> {
	private productsService: ProductsService;

	public constructor(props: Props) {
		super(props);
		this.productsService = new ProductsService();

		this.state = {
			order: props.order,
			products: []
		};
	}

	public static getDerivedStateFromProps(nextProps: Props, prevState: State): object | null {
		if (nextProps.order !== prevState.order) {
			return { order: nextProps.order};
		}

		return null;
	}

	public componentDidUpdate(prevProps: Props, _prevState: State): void {
		if (prevProps.order !== this.state.order) {
			this.loadProducts();
		}
	}

	public componentDidMount(): void {
		this.loadProducts();
	}

	private closeButtonPressed(): void {
		this.props.closeHandler();
	}

	private async loadProducts(): Promise<void> {
		if (!this.state.order) {
			this.setState({products: []});
			return;
		}

		const products = await this.productsService.loadProductsFromOrder(this.state.order._id);
		this.setState({products: products});
	}

	private stringFromAddress(address?: Address): string {
		if (address) {
			return `${address.zipCode} ${address.cityName}`;
		}

		return '';
	}

	private totalSummary(amount: number, title: string, key = ''): ReactNode {
		const classes = this.props.classes;

		if (key === '') {
			key = title;
		}

		if (amount !== 0) {
			return (
				<div className={classes.groupContainer} key={key}>
					<dt>{title}:</dt>
					<dd>{ amount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</dd>
				</div>
			);
		}

		return (<div key={key} />);
	}

	private couponSummary(coupons: Coupon[]): ReactNode[] {
		if (coupons && coupons.length > 0) {
			const nodes = [];

			for (const coupon of coupons) {
				nodes.push(this.totalSummary(coupon.couponValue * -1, `Gutschein "${coupon.couponCode}"`, coupon._id));
			}
			return nodes;
		}

		return [<div key='coupons-empty'/>];
	}

	private nameForMailType(mailType: number): string {
		const lookup: {[k: number]: string } = {
			0: 'Bestellbestätigung',
			1: 'Paket wurde versandt',
			2: 'Paket wurde in Lieferfahrzeug geladen',
			3: 'Paket konnte nicht zugestellt werden',
			4: 'Paket wurde durch Dritten angenommen',
			5: 'Ihre Reservierung',
			6: 'Reservierung Selbstabholbus',
			7: 'Selbstabholbus wurde storniert',
			8: 'Ihre Rechnung'
		};

		if (!lookup[mailType]) {
			return 'Unbekannt';
		}

		return lookup[mailType];
	}

	private productImageRenderer(orderItem: OrderItem): ReactNode {
		const classes = this.props.classes;

		for (const product of this.state.products) {
			if (product.productCode === orderItem.productCode && product.images.length > 0) {
				return (
					<img src={product.images[0]+'?maxH=56&maxW=56&upscale=false'} alt="" className={classes.productImage}/>
				);
			}
		}

		return (
			<div className={classes.dummyImage} />
		);
	}

	private productNameRenderer(orderItem: OrderItem): ReactNode {
		let productName = orderItem.productName;

		const product = this.state.products.find((p) => p.productCode === orderItem.productCode);

		if (product) {
			productName = product.name;
		}

		return (<Link href={`https://www.moebelix.at/p/${orderItem.productCode}`} target="_blank">{productName}</Link>);
	}

	public render(): ReactNode {
		if (this.props.order === null) {
			return (<div></div>);
		}

		const classes = this.props.classes;

		const order = this.props.order;

		let orderItems: OrderItem[] = [];
		if (order.orderItems) {
			orderItems = order.orderItems;
		}

		let statusUpdates: StatusUpdate[] = [];
		if (order.statusUpdate) {
			statusUpdates = order.statusUpdate;
		}

		statusUpdates = statusUpdates.sort((a: StatusUpdate, b: StatusUpdate): number => {
			return new Date(a.mailDate).getTime() - new Date(b.mailDate).getTime();
		});

		return (
			<div className={classes.root}>
				<DialogTitle>
					<ButtonGroup color="secondary" size="small" className={classes.headerActions}>
						<IconButton onClick={this.props.selectNextOrderHandler.bind(this, false)} color="primary">
							<NavigateBeforeIcon />
						</IconButton>
						<IconButton onClick={this.props.selectNextOrderHandler.bind(this, true)} color="primary">
							<NavigateNextIcon />
						</IconButton>
						<Hidden mdUp>
							<IconButton onClick={this.closeButtonPressed.bind(this)} color="secondary" className={classes.upperClose}>
								<CloseIcon />
							</IconButton>
						</Hidden>
					</ButtonGroup>
					Bestellung {order.jobNumber} ({order.orderNumber})
				</DialogTitle>
				<DialogContent className={classes.dialogRoot}>
					<dl className={classes.threeColumns}>
						<div className={classes.groupContainer}>
							<dt>Bestelldatum:</dt>
							<dd><Moment format="DD.MM.YYYY HH:mm" date={order.imapReceived} locale="de" /></dd>
						</div>
						<Hidden smDown>
							<div className={classes.groupContainer}>
								<dt>Auftragsnummer:</dt>
								<dd>{ order.jobNumber }</dd>
							</div>
							<div className={classes.groupContainer}>
								<dt>Bestellnummer:</dt>
								<dd>{ order.orderNumber }</dd>
							</div>
						</Hidden>
						<Hidden smDown>
							<div className={classes.groupContainer}>
								<dt>Rechnungsort:</dt>
								<dd>{ this.stringFromAddress(order.invoiceAddress) }</dd>
							</div>
							<div className={classes.groupContainer}>
								<dt>Lieferort:</dt>
								<dd>{ this.stringFromAddress(order.shippingAddress) }</dd>
							</div>
							<div className={classes.groupContainer}>
								<dt>Zahlungsmethode:</dt>
								<dd>{order.paymentMethod}</dd>
							</div>
						</Hidden>
						<Hidden smDown>
							{ this.totalSummary(order.cartSum, 'Warenwert') }
							{ this.totalSummary(order.setupCost, 'Montagekosten') }
							{ this.totalSummary(order.shippingCost, 'Lieferkosten') }
						</Hidden>
						<Hidden xsDown>
							{ this.couponSummary(order.coupons) }
						</Hidden>
						{ this.totalSummary(order.totalSum, 'Gesamtsumme') }
					</dl>
					<Hidden smDown>
						<ExpansionPanel
							defaultExpanded={false}
						>
							<ExpansionPanelSummary
								expandIcon={<ExpandMoreIcon />}
							>
								<Typography className={classes.panelTitle} variant="h6">Bestellverlauf</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails className={classes.panelDetails}>
								<MaterialTable
									columns={[
										{
											title: 'Status',
											field: 'mailType' as const,
											render: (statusUpdate): ReactNode => {
												let text = this.nameForMailType(statusUpdate.mailType);
												if (statusUpdate.jobNumber) {
													text += ' (' + statusUpdate.jobNumber + ')';
												}
												if (statusUpdate.productName) {
													text += ' (' + statusUpdate.productName + ')';
												}
												return (
													<div>{text}</div>
												);
											}
										},
										{
											title: 'Datum',
											field: 'mailDate' as const,
											type: 'date' as const,
											render: (statusUpdate): ReactNode => {
												return (
													<Moment format="DD.MM.YYYY HH:mm" date={statusUpdate.mailDate} locale="de" />
												);
											}
										},
									]}
									options={{
										search: false,
										filtering: false,
										columnsButton: false,
										exportButton: false,
										paging: false,
										draggable: false,
										showTitle: false,
										toolbar: false,
										sorting: false
									}}
									style={{
										borderRadius: 0,
										width: '100%'
									}}
									data={ statusUpdates }
									localization={ TableLocalization.localization }
								/>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					</Hidden>
					<ExpansionPanel
						defaultExpanded={true}
					>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							className={classes.itemsToggle}
						>
							<Typography className={classes.panelTitle} variant="h6">Bestellinhalt</Typography>
						</ExpansionPanelSummary>
						<ExpansionPanelDetails className={classes.panelDetails}>
							<MaterialTable
								columns={[
									{
										title: '',
										render: (orderItem): ReactNode => this.productImageRenderer(orderItem),
										cellStyle: {
											textAlign: 'center'
										}
									},
									{
										title: 'Artikel',
										field: 'productName' as const,
										render: (orderItem): ReactNode => this.productNameRenderer(orderItem)
									},
									{
										title: 'Artikelnummer',
										field: 'productCode' as const
									},
									{
										title: 'Aufbau',
										field: 'setup' as const
									},
									{
										title: 'Einzelpreis',
										field: 'singlePrice' as const,
										type: 'currency' as const,
										currencySetting: {
											locale: 'DE',
											currencyCode: 'EUR',
											minimumFractionDigits: 2,
											maximumFractionDigits: 2
										},
										cellStyle: {
											textAlign: 'right'
										},
										headerStyle: {
											textAlign: 'right'
										}
									},
									{
										title: 'Menge',
										field: 'amount' as const,
										type: 'numeric' as const
									},
									{
										title: 'Gesamtsumme',
										field: 'totalPrice' as const,
										type: 'currency' as const,
										currencySetting: {
											locale: 'DE',
											currencyCode: 'EUR',
											minimumFractionDigits: 2,
											maximumFractionDigits: 2
										},
										cellStyle: {
											textAlign: 'right'
										},
										headerStyle: {
											textAlign: 'right'
										}
									}
								]}
								options={{
									search: false,
									filtering: false,
									columnsButton: false,
									exportButton: false,
									paging: false,
									draggable: false,
									showTitle: false,
									toolbar: false,
									sorting: false
								}}
								style={{
									borderRadius: 0,
									width: '100%'
								}}
								data={ orderItems }
								localization={ TableLocalization.localization }
							/>
						</ExpansionPanelDetails>
					</ExpansionPanel>
				</DialogContent>
				<DialogActions>
					<Hidden smDown>
						<Button onClick={this.closeButtonPressed.bind(this)} color="secondary" className={classes.lowerClose}>
							Schließen
						</Button>
					</Hidden>
				</DialogActions>
			</div>
		);
	}
}

export default withStyles(orderDetailsModalStyles)(OrderDetailsModal);
