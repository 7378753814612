import {Theme, createStyles} from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const articleCombinationStyles = (theme: Theme): any => createStyles({
	productContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		marginBottom: theme.spacing(1)
	},
	productImage: {
		marginRight: theme.spacing(2)
	}
});

export default articleCombinationStyles;
