import React, { ReactNode, Component } from 'react';
import {
	Typography,
	Paper,
	WithStyles,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	withStyles
} from '@material-ui/core';

import analyticsComparatorStatisticsStyles from './AnalyticsComparatorStatisticsStyles';

import AuthService from '@services/AuthService';

interface ComparatorStatisticsModel {
	productsSum: number;
	comparatorsActiveSum: number;
	comparatorsInactiveSum: number;
	productsInDBSum: number;
}

interface ComparatorsStatisticsResponse extends Response {
	success: boolean;
	message?: string;
	data: ComparatorStatisticsModel;
}

interface State {
	comparatorStatisticsModel: ComparatorStatisticsModel | null;
}

class AnalyticsComparatorStatistics extends Component<WithStyles, State> {
	private authService: AuthService;

	public constructor(props: WithStyles) {
		super(props);
		this.authService = new AuthService();
		this.state = {
			comparatorStatisticsModel: null
		};
	}

	public componentDidMount(): void {
		this.loadStatistics();
	}

	private loadStatistics(): void {
		this.authService.fetch<ComparatorsStatisticsResponse>('/api/comparators/statistics', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				this.setState({ comparatorStatisticsModel: response.data });
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	public render(): ReactNode {
		const classes = this.props.classes;
		const statisticsModel = this.state.comparatorStatisticsModel;

		if (statisticsModel === null) {
			return (<div />);
		}

		return (
			<div>
				<Typography component="h3" variant="h5" className={classes.totalHeader}>
					{'Gesamt Produktvergleicher Analyse'}
				</Typography>
				<TableContainer component={Paper} className={classes.totalTable}>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell>Metrik</TableCell>
								<TableCell align="right">Wert</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell component="th" scope="row">Aktive Produktvergleicher</TableCell>
								<TableCell align="right">{statisticsModel.comparatorsActiveSum} ({(statisticsModel.comparatorsActiveSum/(statisticsModel.comparatorsActiveSum + statisticsModel.comparatorsInactiveSum)).toLocaleString(undefined, {style: 'percent', minimumFractionDigits:2})})</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Inaktive Produktvergleicher</TableCell>
								<TableCell align="right">{statisticsModel.comparatorsInactiveSum} ({(statisticsModel.comparatorsInactiveSum/(statisticsModel.comparatorsActiveSum + statisticsModel.comparatorsInactiveSum)).toLocaleString(undefined, {style: 'percent', minimumFractionDigits:2})})</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Produktvergleicher Gesamt</TableCell>
								<TableCell align="right">{statisticsModel.comparatorsActiveSum + statisticsModel.comparatorsInactiveSum}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Produkte in Produktvergleicher</TableCell>
								<TableCell align="right">{statisticsModel.productsSum} ({(statisticsModel.productsSum/statisticsModel.productsInDBSum).toLocaleString(undefined, {style: 'percent', minimumFractionDigits:2})})</TableCell>
							</TableRow>
							<TableRow>
								<TableCell component="th" scope="row">Produkte in Datenbank</TableCell>
								<TableCell align="right">{statisticsModel.productsInDBSum}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}

export default withStyles(analyticsComparatorStatisticsStyles)(AnalyticsComparatorStatistics);
