import { DefaultLinkFactory, DefaultLinkWidget } from '@projectstorm/react-diagrams';
import { QuestionLinkModel } from './QuestionLinkModel';
import React, { ReactElement } from 'react';
import { AdvisorQuestion } from '@common/models/Advisor';

export class QuestionLinkFactory extends DefaultLinkFactory {
	private handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void;


	constructor(handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void) {
		super();
		this.type = 'link';
		this.handleQuestionChange = handleQuestionChange;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	getNewInstance(initialConfig?: any): QuestionLinkModel {
		return new QuestionLinkModel(this.handleQuestionChange);
	}

	generateLinkSegment(model: QuestionLinkModel, widget: DefaultLinkWidget, selected: boolean, path: string): ReactElement {
		return (<path
			strokeWidth={model.width}
			stroke='#1F4C79'
			d={path}
		/>);
	}
}