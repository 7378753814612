import { Theme, createStyles } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const crawlerFolderSelectStyles = (theme: Theme): any => createStyles({
	root: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(3),
	},
});

export default crawlerFolderSelectStyles;
