import * as SRD from '@projectstorm/react-diagrams';
import QuestionNodeWidget from './QuestionNodeWidget';
import { QuestionNodeModel } from './QuestionNodeModel';
import * as React from 'react';
import { AdvisorAnswer, AdvisorQuestion } from '@common/models/Advisor';

export class QuestionNodeFactory extends SRD.AbstractNodeFactory {
	private advisorQuestions: AdvisorQuestion[] = [];
	private handleEditQuestionButtonClicked: (advisorQuestion: AdvisorQuestion) => void;
	private handleNewAnswerButtonClicked: (advisorQuestion: AdvisorQuestion) => void;
	private handleEditAnswerButtonClicked: (advisorQuestion: AdvisorQuestion, advisorAnswer: AdvisorAnswer) => void;
	private handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void;
	private startingQuestionId?: string;

	constructor(
		advisorQuestions: AdvisorQuestion[], 
		handleEditQuestionButtonClicked: (advisorQuestion: AdvisorQuestion) => void,
		handleNewAnswerButtonClicked: (advisorQuestion: AdvisorQuestion) => void,
		handleEditAnswerButtonClicked: (advisorQuestion: AdvisorQuestion, advisorAnswer: AdvisorAnswer) => void,
		handleQuestionChange: (advisorQuestion: AdvisorQuestion) => void,
		startingQuestionId?: string
	) {
		super('question');
		this.advisorQuestions = advisorQuestions;
		this.handleEditQuestionButtonClicked = handleEditQuestionButtonClicked;
		this.handleNewAnswerButtonClicked = handleNewAnswerButtonClicked;
		this.handleEditAnswerButtonClicked = handleEditAnswerButtonClicked;
		this.handleQuestionChange = handleQuestionChange;
		this.startingQuestionId = startingQuestionId;
	}

	generateReactWidget(diagramEngine: SRD.DiagramEngine, node: SRD.NodeModel): JSX.Element {
		return <QuestionNodeWidget node={node} />;
	}

	getNewInstance(): QuestionNodeModel {
		return new QuestionNodeModel(this.advisorQuestions[0], this.handleEditQuestionButtonClicked, this.handleNewAnswerButtonClicked, this.handleEditAnswerButtonClicked, this.handleQuestionChange, this.startingQuestionId);
	}
}