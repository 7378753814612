import AuthService from '@common/services/AuthService';
import { AdvisorFolder } from './AdvisorFolder';
import { RuleGroup } from './AutomatedComparator';

export enum AdvisorStatus {
	UNUSED = 'Unused',
	CURRENT = 'Current',
	VERSION = 'Version'
}

export const dummyAdvisor: Advisor = {
	name: '',
	categoryCodes: [],
	attributes: [],
	questions: [],
	status: AdvisorStatus.UNUSED,
	categoryAttributes: []
};

export const dummyHistoryBased: HistoryBased = {
	defaultFollowingQuestionId: null,
	historyBasedFollowingQuestions: [],
};

export const dummyHistoryBasedFollowingQuestion: HistoryBasedFollowingQuestion = {
	historyBasedAnswerIds: [],
	followingQuestionId: null
};

export enum AdvisorQuestionType {
	SINGLESELECT = 'SingleSelect',
	MULTISELECT = 'MultiSelect',
	PRICESLIDER = 'PriceSlider'
}

export function getAdvisorQuestionType(advisorQuestionType: string): AdvisorQuestionType | null {
	switch (advisorQuestionType) {
		case 'SingleSelect':
			return AdvisorQuestionType.SINGLESELECT;
		case 'MultiSelect':
			return AdvisorQuestionType.MULTISELECT;
		case 'PriceSlider':
			return AdvisorQuestionType.PRICESLIDER;
		default:
			return null;
	}
}

export function getAdvisorQuestionTypeName(advisorQuestionType: AdvisorQuestionType): string {
	switch (advisorQuestionType) {
		case AdvisorQuestionType.SINGLESELECT:
			return 'Single-Select';
		case AdvisorQuestionType.MULTISELECT:
			return 'Multi-Select';
		case AdvisorQuestionType.PRICESLIDER:
			return 'Preis-Slider';
		default:
			return '';
	}
}

export enum AdvisorVisualType {
	IMAGES = 'Images',
	ICONS = 'Icons',
}

export function getAdvisorVisualType(advisorVisualType: string): AdvisorVisualType | null {
	switch (advisorVisualType) {
		case 'Images':
			return AdvisorVisualType.IMAGES;
		case 'Icons':
			return AdvisorVisualType.ICONS;
		default:
			return null;
	}
}

export interface AdvisorCategoryAttribute {
	_id?: string;
	categories: string[];
	attributes: string[];
}

export interface HistoryBasedFollowingQuestion {
	historyBasedAnswerIds: string[];
	followingQuestionId: string | null;
}

export interface HistoryBased {
	defaultFollowingQuestionId: string | null;
	historyBasedFollowingQuestions: HistoryBasedFollowingQuestion[];
}

export interface AdvisorAnswer {
	_id?: string;
	answer: string;
	image: string;
	followingQuestion?: string;
	rules: RuleGroup;
	combinable: boolean;
	originalAdvisorId?: string;
	advisorName?: string;
	question?: string;
	historyBased: HistoryBased;
}

export interface AdvisorQuestion {
	_id?: string;
	question: string;
	subtext: string;
	questionType: AdvisorQuestionType;
	visualType: AdvisorVisualType;
	answers: AdvisorAnswer[];
	xCoordinate: number;
	yCoordinate: number;
	followingQuestion?: string;
}

export interface Advisor {
	_id?: string;
	name: string;
	categoryCodes: string[];
	attributes: string[];
	folder?: AdvisorFolder;
	questions: AdvisorQuestion[];
	updatedAt?: Date;
	startingQuestion?: string;
	originalId?: string;
	status: AdvisorStatus;
	categoryAttributes: AdvisorCategoryAttribute[];
}

// Requests

interface AdvisorsResponse {
	data: Advisor[];
	success: boolean;
	message?: string;
}

interface AdvisorResponse extends Response {
	success: boolean;
	data: Advisor;
	message?: string;
}

interface RuleCountResponse extends Response {
	success: boolean;
	data: AdvisorAnswerProductCount[];
	message?: string;
}

interface UpdateAdvisorJsonResponse extends Response {
	success: boolean;
	message?: string;
}

export interface AdvisorAnswerProductCount {
	advisorId: string;
	answers: AnswerProductCount[];
}

interface AnswerProductCount {
	answerId: string;
	numberOfProducts: number;
}

export function getAdvisorQuestionById(advisorQuestionId: string | null, advisorQuestions: AdvisorQuestion[]): AdvisorQuestion | null {
	if (!advisorQuestionId) {
		return null;
	}
	for (const advisorQuestion of advisorQuestions) {
		if (advisorQuestion._id && advisorQuestion._id === advisorQuestionId) {
			return advisorQuestion;
		}
	}
	return null;
}

export function getAdvisorAnswersByIds(advisorAnswerIds: string[], advisorQuestions: AdvisorQuestion[]): AdvisorAnswer[] {
	const advisorAnswers: AdvisorAnswer[] = [];
	
	for (const advisorQuestion of advisorQuestions) {
		for (const advisorAnswer of advisorQuestion.answers) {
			for (const advisorAnswerId of advisorAnswerIds) {
				if (advisorAnswer._id && advisorAnswer._id === advisorAnswerId) {
					advisorAnswers.push(advisorAnswer);
				}
			}
		}
	}
	return advisorAnswers;
}

const authService = new AuthService();

export async function loadAdvisors(): Promise<Advisor[] | null> {
	const response = await authService.fetch<AdvisorsResponse>('/api/advisors', {
		method: 'GET'
	});

	if (response.success && response.data) {
		return response.data;
	} else {
		return null;
	}
}

export async function loadAdvisorVersions(originalId: string): Promise<Advisor[] | null> {
	const response = await authService.fetch<AdvisorsResponse>(`/api/advisors/versions/${originalId}`, {
		method: 'GET'
	});

	if (response.success && response.data) {
		return response.data;
	} else {
		return null;
	}
}

export async function loadAdvisor(advisorId: string): Promise<Advisor | null> {
	const response = await authService.fetch<AdvisorResponse>(`/api/advisors/${advisorId}`, {
		method: 'GET'
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}

export async function deleteAdvisor(advisorId: string): Promise<boolean> {
	const response = await authService.fetch<{success: boolean}>(`/api/advisors/${advisorId}`, {
		method: 'DELETE',
	});

	return response.success;
}

export async function saveAdvisor(id: string, changes: Advisor | {startingQuestion?: string} | {questions: AdvisorQuestion[]; startingQuestion?: string}, versioning: boolean): Promise<Advisor | null> {	
	
	const response = await authService.fetch<AdvisorResponse>(`/api/advisors${versioning ? '/versioning' : ''}/${id}`, {
		method: 'POST',
		body: JSON.stringify(changes)
	});

	if (response.success) {
		return response.data;
		
	} else {
		return null;
	}
}

export async function saveAdvisorCategoryCodes(id: string, categoryCodes: string[], versioning: boolean): Promise<Advisor | null> {	
	const response = await authService.fetch<AdvisorResponse>(`/api/advisors${versioning ? '/versioning' : ''}/${id}`, {
		method: 'POST',
		body: JSON.stringify({categoryCodes})
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}

export async function updateAdvisorJson(id: string): Promise<boolean | null> {
	try {
		const response = await authService.fetch<UpdateAdvisorJsonResponse>(`/api/advisors/update-json/${id}`, {
			method: 'GET'
		});

		if (response.success) {
			return true;
		} else {
			return null;
		}
	} catch (error) {
		return null;
	}
}

export async function getCountByRules(data: {id: string | undefined; questions: AdvisorQuestion[]; categoryCodes: string[]}[]): Promise<AdvisorAnswerProductCount[] | null> {
	const response = await authService.fetch<RuleCountResponse>('/api/products/rules-count', {
		method: 'POST',
		body: JSON.stringify({advisors: data})
	});

	if (response.success) {
		return response.data;
	} else {
		return null;
	}
}