/* eslint-disable @typescript-eslint/no-explicit-any */
import { PortModel, AbstractPortFactory } from '@projectstorm/react-diagrams';

export enum PortType {
	QUESTIONIN = 'questionIn',
	QUESTIONOUT = 'questionOut',
	ANSWEROUT = 'answerOut'
}

export function createPortModelName(portType: PortType, id: string): string {
	return `${portType}-${id}`;
}

export class PortFactory extends AbstractPortFactory {
	cb: (initialConfig?: any) => PortModel;

	constructor(type: string, cb: (initialConfig?: any) => PortModel) {
		super(type);
		this.cb = cb;
	}

	getNewInstance(initialConfig?: any): PortModel {
		return this.cb(initialConfig);
	}
}