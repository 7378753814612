import React, { ReactNode, Component, CSSProperties } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select';

import { WithStyles, withStyles } from '@material-ui/core';

import AuthService from '@services/AuthService';
import { CategoriesResponse, Category } from '@models/Category';

import categoryMultiSelectStyles from './CategoryMultiSelectStyles';

interface OptionType {
	label: string;
	value: string;
};

interface State {
	categories: Category[];
	selectedCategories: ValueType<OptionType> | null;
}

interface Props extends WithStyles<typeof categoryMultiSelectStyles> {
	onChange: (categories: Category[] | null) => void;
	selectedCategoryCodes: string[];
	topLevelCategoriesExcluded: boolean;
	disabled: boolean;
	filterCategoryCodes?: string[];
}

class CategoryMultiSelect extends Component<Props, State> {
	private authService: AuthService;

	public constructor(props: Props) {
		super(props);

		this.authService = new AuthService();

		this.state = {
			categories: [],
			selectedCategories: null
		};

		this.loadCategories();
	}

	public componentDidUpdate(prevProps: Props, _: State): void {
		if (JSON.stringify(prevProps.selectedCategoryCodes) !== JSON.stringify(this.props.selectedCategoryCodes)) {
			this.updateSelectedOption();
		}
	}

	private updateSelectedOption(): void {
		let selectedOptions = null;

		if (this.props.selectedCategoryCodes.length > 0) {
			selectedOptions = [];
			const options = this.getOptions();

			for (const option of options) {
				for (const selectedCategoryCode of this.props.selectedCategoryCodes) {
					if (option.value === selectedCategoryCode) {
						selectedOptions.push(option);
					}
				}
			}
		}

		this.setState({selectedCategories: selectedOptions});
	}


	private loadCategories(): void {
		this.authService.fetch<CategoriesResponse>('/api/categories', {
			method: 'GET'
		}).then((response): void => {
			if (response.success) {
				if (!this.props.topLevelCategoriesExcluded) {
					this.setState({categories: this.filterByCategoryCodes(response.data)});
				} else {
					const categories: Category[] = [];
					for (const category of response.data) {
						for (const categoryChild of category.children) {
							categories.push(categoryChild);
						}
					}
					this.setState({categories: this.filterByCategoryCodes(categories)});
				}

				this.updateSelectedOption();
			} else if (response.message) {
				throw new Error(response.message);
			} else {
				throw new Error('Unkown Error');
			}
		});
	}

	private filterByCategoryCodes(categories: Category[]): Category[] {
		if (!this.props.filterCategoryCodes || this.props.filterCategoryCodes.length === 0) {
			return categories;
		}

		const filteredCategories: Category[] = [];
		for (const filterCategoryCode of this.props.filterCategoryCodes) {
			const filteredCategory = this.getFilteredCategory(categories, filterCategoryCode);
			if (filteredCategory) {
				filteredCategories.push(filteredCategory);
			}
		}

		return filteredCategories;
	}

	private getFilteredCategory(categories: Category[], categoryCode: string): Category | null {

		for (const category of categories) {
			if (category.code === categoryCode) {
				return category;
			}

			const result = this.getFilteredCategory(category.children, categoryCode);
			if (result) {
				return result;
			}
		}
		return null;
	}

	public render(): ReactNode {
		const classes = this.props.classes;

		return (
			<Select
				className={classes.root}
				value={this.state.selectedCategories}
				onChange={this.handleChange.bind(this)}
				options={this.getOptions()}
				placeholder={'Kategorien auswählen...'}
				noOptionsMessage={(): string => ('Keine Treffer')}
				styles={{ menuPortal: (base: CSSProperties): CSSProperties => ({ ...base, zIndex: 9999 }) }}
				menuPortalTarget={document.body}
				isSearchable
				isDisabled={this.props.disabled}
				isMulti
			/>
		);
	}

	private getOptions(): OptionType[] {
		let options: OptionType[] = [];

		this.state.categories.sort((a: Category, b: Category) => (a.name > b.name) ? 1 : -1).forEach((c: Category): void => {
			options = options.concat(this.optionsForCategory(c, 0));
		});

		return options;
	}

	private optionsForCategory(category: Category, level: number): OptionType[] {
		let options: OptionType[] = [];

		options.push({value: category.code, label: this.leftPad(`${category.name} (${category.productCount})`, level)});

		category.children.sort((a: Category, b: Category) => (a.name > b.name) ? 1 : -1).forEach((c: Category): void => {
			options = options.concat(this.optionsForCategory(c, level+1));
		});

		return options;
	}

	private leftPad(string: string, level: number): string {
		let result = (level > 0) ? '|' : '';

		for (let i = 0; i < level; i++) {
			result += '–';
		}

		return result + string;
	}

	private handleChange(selectedCategories: ValueType<OptionType>): void {
		this.setState({selectedCategories: selectedCategories});
		const categories: Category[] = [];
		
		if (selectedCategories) {
			for (const selectedCategory of selectedCategories as []) {
				const categoryCode = (selectedCategory as OptionType).value;
				const category = this.categoryWithCode(categoryCode, this.state.categories);
				if (category) {
					categories.push(category);
				}
			}
		}

		this.props.onChange(categories);
	}

	private categoryWithCode(code: string, categories: Category[]): Category | null {
		for (const category of categories) {
			if (category.code === code) {
				return category;
			}

			if (category.children && category.children.length > 0) {
				const result = this.categoryWithCode(code, category.children);
				if (result) {
					return result;
				}
			}
		}

		return null;
	}

}

export default withStyles(categoryMultiSelectStyles)(CategoryMultiSelect);
